
import { useEffect } from 'react'


import { useGlobal, useGlobalUpdate } from '../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../contexts/ProtectedContext'

import { handle_user_view_banner, classNames } from '../../libs/helpers'



import { useLocation, useNavigate, Outlet, Link } from "react-router-dom"

import { browserName, isMobile, isDesktop } from "react-device-detect";

import {
  CreditCardIcon,
  UserCircleIcon,
  BookOpenIcon,
  BuildingOffice2Icon,
  UserGroupIcon,
  ClipboardDocumentListIcon,
  PaperAirplaneIcon
} from '@heroicons/react/24/outline'


const universal_dashboard_navigation = [
  { name: 'My Programs', href: 'programs', icon: BookOpenIcon, ref: "programs" },
  { name: 'Account', href: 'account', icon: UserCircleIcon, ref: "account" },
]

const privileged_dashboard_navigation = [
  { name: 'Campaigns', href: 'campaigns', icon: PaperAirplaneIcon, ref: "campaigns" },
  { name: 'Users', href: 'users', icon: UserGroupIcon, ref: "users" },
  { name: 'Groups', href: 'groups', icon: ClipboardDocumentListIcon, ref: "groups" },
  { name: 'Organization Settings', href: 'organization', icon: BuildingOffice2Icon, ref: "organization" },
  { name: 'Plan & Billing', href: 'plan', icon: CreditCardIcon, ref: "plan" },
]





const DashboardPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // Location
  const location = useLocation()
  // const dashboard_route = location.pathname.slice(1).split('/')[1]
  const dashboard_route = location.pathname.split('/dashboard/')[1] || ''

  // Render - handle user view banner 
  useEffect(() => {
    handle_user_view_banner(["account", "programs"].includes(dashboard_route), global_context, protected_context, protected_update)
  }, [location])

  return (
    <div className="absolute w-full flex flex-col justify-center items-center z-10">
      {isDesktop
      ? <div className="flex w-full min-h-full flex-1 flex-col justify-center">

          {/* Dashboard */}
          <div className="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
            <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
              <nav className="flex-none px-4 sm:px-6 lg:px-0">
                <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">

                  {/* Privileged navigation */}
                  {["owner", "admin"].includes(global_context.type)
                  ? <>
                    <li key="privileged_navigation_header" className="text-sm leading-6 font-medium py-2">
                      Organization
                    </li>
                    {privileged_dashboard_navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={`/dashboard/${item.href}`}
                          className={classNames(
                            item.ref === dashboard_route
                              ? 'bg-gray-50 text-blue-600'
                              : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.ref === dashboard_route ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                    <li key="universal_navigation_header" className="text-sm leading-6 font-medium py-2">
                      Personal
                    </li>
                  </>
                  : <></>}

                  {/* Universal navigation */}
                  {universal_dashboard_navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={`/dashboard/${item.href}`}
                        className={classNames(
                          item.ref === dashboard_route
                            ? 'bg-gray-50 text-blue-600'
                            : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.ref === dashboard_route ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}

                </ul>
              </nav>
            </aside>
            <main className="lg:w-[60vw] w-[90vw]">
              <Outlet />
            </main>
          </div>

          
        </div>
      : <div className="flex min-h-full flex-col bg-transparent pt-16 pb-12">
          <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="text-base font-semibold text-gray-600">Error</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Unsupported access.</h1>
                <p className="mt-2 text-base text-gray-500">This page can only be accessed from a desktop.</p>
                <div className="mt-6">
                  <Link to='/'>
                    <span className="text-base font-medium text-gray-600 hover:text-gray-500">
                      Go back home
                      <span aria-hidden="true"> &rarr;</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </main>
          <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-center space-x-4">
              <Link to="mailto:support@vansec.com" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Contact Support
              </Link>
            </nav>
          </footer>
        </div>
      }
    </div>
  )
}

export default DashboardPage