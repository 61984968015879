import React, { useContext, useState } from "react"


export const default_global_context = {
  authenticated: false,
  user_id: "",
}

const GlobalContext = React.createContext(undefined);
const GlobalUpdateContext = React.createContext(undefined)


export const useGlobal = () => {
  return useContext(GlobalContext)
}

export const useGlobalUpdate = () => {
  return useContext(GlobalUpdateContext)
}

export const GlobalProvider = ({ children }) => {
  const [global_context, set_global_context] = useState(default_global_context)

  const update_global_context = (new_global_context : {
    authenticated: boolean,
    user_id: string,
  }) => {
    set_global_context(new_global_context)
  }

  return (
    <GlobalContext.Provider value={global_context}>
      <GlobalUpdateContext.Provider value={update_global_context}>
        {children}
      </GlobalUpdateContext.Provider>
    </GlobalContext.Provider>
  )
}