import React, { useContext, useState } from "react"


export const default_protected_context = {
  notifications: [],
  banners: [],
}

const ProtectedContext = React.createContext(undefined);
const ProtectedUpdateContext = React.createContext(undefined)


export const useProtected = () => {
  return useContext(ProtectedContext)
}

export const useProtectedUpdate = () => {
  return useContext(ProtectedUpdateContext)
}

export const ProtectedProvider = ({ children }) => {
  const [protected_context, set_protected_context] = useState(default_protected_context)

  const update_protected_context = (new_protected_context : {
    notifications: any,
    banners: any,
  }) => {
    set_protected_context(new_protected_context)
  }

  return (
    <ProtectedContext.Provider value={protected_context}>
      <ProtectedUpdateContext.Provider value={update_protected_context}>
        {children}
      </ProtectedUpdateContext.Provider>
    </ProtectedContext.Provider>
  )
}