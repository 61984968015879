
import { useEffect, useState } from 'react'

import ReactLoading from "react-loading"

import { useGlobal, useGlobalUpdate } from '../../../../contexts/GlobalContext'

import { useLocation, useNavigate, useParams, Link } from "react-router-dom"
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'


import { auth_axios } from '../../../../libs/authWeb';
import { mixpanel_client_track } from '../../../../libs/mixpanelClient';
import { program_search_filter_fn } from '../../../../libs/searchFilterFunctions'
import { program_status_map, campaign_type_map, get_date_string_short, get_date_string, get_user_property_value } from '../../../../libs/formats'
import { classNames } from '../../../../libs/helpers'


import DeactivateUsersModal from '../DeactivateUsersModal'
import DeleteUsersModal from '../DeleteUsersModal'
import ArchiveProgramsModal from '../../programs/ArchiveProgramsModal'
import DeleteProgramsModal from '../../programs/DeleteProgramsModal'
import TerminateProgramsModal from '../../programs/TerminateProgramsModal'





const Program = ({
  program,
  program_checked_map,
  set_program_checked_map,
} : {
  program : any
  program_checked_map : any
  set_program_checked_map : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr 
      className={classNames(program_checked_map[program.program_id] ? 'bg-gray-50' : "", "hover:bg-gray-100 cursor-pointer")}
      onClick={() => navigate(`/dashboard/programs/${program.program_id}`)}
    >
      <td className="relative px-7 sm:w-12 sm:px-6">
        {program_checked_map[program.program_id] && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          checked={program_checked_map[program.program_id]}
          onChange={(e) =>
            set_program_checked_map({
            ...program_checked_map,
            [program.program_id]: e.target.checked
          })}
          onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
        />
      </td>


      <td
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm font-medium',
          program_checked_map[program.program_id] ? 'text-blue-600' : 'text-gray-900'
        )}
      >
        {program.campaign_metadata.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {campaign_type_map[program.campaign_metadata.type]}
      </td>
      <td 
        className={classNames((() => {
          switch (program.simulation_status) {
            case "none": {
              return "text-gray-500"
            }
            case "draft": {
              return "text-yellow-500"
            }
            case "active": {
              return "text-blue-500"
            }
            case "reported": {
              return "text-green-500"
            }
            case "failed": {
              return "text-red-500"
            }
            case "terminated": {
              return "text-gray-500"
            }
            default: {
              return "text-gray-500"
            }
          }
        })(), "whitespace-nowrap px-3 py-4 text-sm")}
      >
        {program_status_map[program.simulation_status]}
      </td>
      <td 
        className={classNames((() => {
          switch (program.training_status) {
            case "none": {
              return "text-gray-500"
            }
            case "draft": {
              return "text-yellow-500"
            }
            case "active": {
              return "text-blue-500"
            }
            case "completed": {
              return "text-green-500"
            }
            case "terminated": {
              return "text-gray-500"
            }
            default: {
              return "text-gray-500"
            }
          }
        })(), "whitespace-nowrap px-3 py-4 text-sm")}
      >
        {program_status_map[program.training_status]}
      </td>
    </tr>
  )
}


const UsersUserIdPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Use params
  const { user_id } = useParams()  

  // States
  const [user_data, set_user_data] = useState({})
  const [programs, set_programs] = useState([])

  const [active_program_checked_map, set_active_program_checked_map] = useState({})
  const [inactive_program_checked_map, set_inactive_program_checked_map] = useState({})

  const [user_data_and_programs_are_fetched, set_user_data_and_programs_are_fetched] = useState(false)
  const [current_tab, set_current_tab] = useState("active")

  // Refresh variable
  const [refresh_variable, set_refresh_variable] = useState(Date.now())

  // Modal
  const [deactivate_users_modal_is_open, set_deactivate_users_modal_is_open] = useState(false)
  const [delete_users_modal_is_open, set_delete_users_modal_is_open] = useState(false)
  const [deactivate_programs_modal_is_open, set_deactivate_programs_modal_is_open] = useState(false)
  const [delete_programs_modal_is_open, set_delete_programs_modal_is_open] = useState(false)
  const [terminate_programs_modal_is_open, set_terminate_programs_modal_is_open] = useState(false)

  // Search & filters
  const [search_text, set_search_text] = useState("")

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "search_text": {
        set_search_text(value)

        // Always break
        break
      }
    }
  }

  const get_user_data_and_programs = async () => {
    // Set is_fetched to false
    set_user_data_and_programs_are_fetched(false)

    // Execute get user data
    const get_user_data_res = await auth_axios.get(`/api/users/${user_id}`)

    if (!get_user_data_res.data.success) {
      switch (get_user_data_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get programs
    const get_user_programs_res = await auth_axios.get(`/api/users/${user_id}/programs`)

    if (!get_user_programs_res.data.success) {
      switch (get_user_programs_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_user_data(get_user_data_res.data.user_data)
    set_programs(get_user_programs_res.data.programs)
    set_active_program_checked_map(get_user_programs_res.data.programs.filter(program => program.active).reduce((acc, program) => ({...acc, [program.program_id]: false}), {}))
    set_inactive_program_checked_map(get_user_programs_res.data.programs.filter(program => !program.active).reduce((acc, program) => ({...acc, [program.program_id]: false}), {}))
    set_user_data_and_programs_are_fetched(true)
  }

  const toggle_all_active_programs = (e) => {
    set_active_program_checked_map(Object.keys(active_program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: e.target.checked }), {}))
  }

  const toggle_all_inactive_programs = (e) => {
    set_inactive_program_checked_map(Object.keys(inactive_program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: e.target.checked }), {}))
  }

  const uncheck_all_active_programs = () => {
    set_active_program_checked_map(Object.keys(active_program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: false }), {}))
  }

  const uncheck_all_inactive_programs = () => {
    set_inactive_program_checked_map(Object.keys(inactive_program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: false }), {}))
  }

  // Renders  
  // render upon load & upon action_fetch_start_index change
  useEffect(() => {

    get_user_data_and_programs()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_users_user_id_visited", global_context.user_id)

  }, [refresh_variable])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">

      {/* Header */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            User information
          </h1>
          <div className="mt-2 text-sm text-gray-700">
            {user_data_and_programs_are_fetched
            ? user_data["email_address"]
            : <ReactLoading
              type='spokes'
              color='#000'
              height={20}
              width={20}
            />}
          </div>
        </div>
        <Link
          type="button"
          className={classNames(!user_data_and_programs_are_fetched ? "bg-blue-500" : "bg-blue-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")} 
          to={`edit`}
        >
          Edit user
        </Link>
        
      </div>

      {/* Data */}
      <div className="mt-8 flow-root">

        {/* User information */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm">

          {/* Email address */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {user_data_and_programs_are_fetched ? (
                  <span>{user_data["email_address"]}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* First name */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">First name</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {user_data_and_programs_are_fetched ? (
                  <span>{(user_data["user_metadata"]["first_name"] && user_data["user_metadata"]["first_name"] !== "UNSET") ? user_data["user_metadata"]["first_name"] : "(Unspecified)"}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Last name */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Last name</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {user_data_and_programs_are_fetched ? (
                  <span>{(user_data["user_metadata"]["last_name"] && user_data["user_metadata"]["last_name"] !== "UNSET") ? user_data["user_metadata"]["last_name"] : "(Unspecified)"}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Position */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Position</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {user_data_and_programs_are_fetched ? (
                  <span>{user_data["user_metadata"]["position"] === "UNSET" ? "(Unspecified)" : user_data["user_metadata"]["position"]}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Provision type */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Provision method</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {user_data_and_programs_are_fetched ? (
                  <span>{get_user_property_value(user_data, "provision_type", true)}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Authentication type */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Login method</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {user_data_and_programs_are_fetched ? (
                  <span>{get_user_property_value(user_data, "authentication_type", true)}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>
          
          {/* Additional attributes */}
          <Disclosure as="div" className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex w-full items-center text-left text-gray-900">
                    <span className="font-medium text-sm">Additional properties</span>
                    <span className="ml-6 flex h-7 items-center">
                      {open ? (
                        <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                      ) : (
                        <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="div" className="mt-8 space-y-6 divide-y divide-gray-100 text-sm leading-6">

                  {/* Department */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Department</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["department"] && user_data["user_metadata"]["department"] !== "UNSET") ? user_data["user_metadata"]["department"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Division */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Division</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["division"] && user_data["user_metadata"]["division"] !== "UNSET") ? user_data["user_metadata"]["division"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Organization */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Organization</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["organization"] && user_data["user_metadata"]["organization"] !== "UNSET") ? user_data["user_metadata"]["organization"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Manager ID */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Manager ID</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["manager_id"] && user_data["user_metadata"]["manager_id"] !== "UNSET") ? user_data["user_metadata"]["manager_id"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Manager name */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Manager name</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["manager_name"] && user_data["user_metadata"]["manager_name"] !== "UNSET") ? user_data["user_metadata"]["manager_name"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Preferred language */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Preferred language</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["preferred_language"] && user_data["user_metadata"]["preferred_language"] !== "UNSET") ? user_data["user_metadata"]["preferred_language"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* City */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">City</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["city"] && user_data["user_metadata"]["city"] !== "UNSET") ? user_data["user_metadata"]["city"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Country */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Country</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["country"] && user_data["user_metadata"]["country"] !== "UNSET") ? user_data["user_metadata"]["country"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Locale */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Locale</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["locale"] && user_data["user_metadata"]["locale"] !== "UNSET") ? user_data["user_metadata"]["locale"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Timezone */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Timezone</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["timezone"] && user_data["user_metadata"]["timezone"] !== "UNSET") ? user_data["user_metadata"]["timezone"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Phone number */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Phone number</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["phone_number"] && user_data["user_metadata"]["phone_number"] !== "UNSET") ? user_data["user_metadata"]["phone_number"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* LinkedIn URL */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">LinkedIn URL</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["social_metadata"]["linkedin_url"] && user_data["user_metadata"]["social_metadata"]["linkedin_url"] !== "UNSET") ? user_data["user_metadata"]["social_metadata"]["linkedin_url"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Instagram URL */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Instagram URL</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["social_metadata"]["instagram_url"] && user_data["user_metadata"]["social_metadata"]["instagram_url"] !== "UNSET") ? user_data["user_metadata"]["social_metadata"]["instagram_url"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>

                  {/* Facebook URL */}
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Facebook URL</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {user_data_and_programs_are_fetched ? (
                          <span>{(user_data["user_metadata"]["social_metadata"]["facebook_url"] && user_data["user_metadata"]["social_metadata"]["facebook_url"] !== "UNSET") ? user_data["user_metadata"]["social_metadata"]["facebook_url"] : "(Unspecified)"}</span>
                        ) : (
                        <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />)}
                      </div>
                    </dd>
                  </div>




                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          
        </dl>

        {/* Tabs */}
        <div className="mt-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {/* Active tab */}
              <div
                className={classNames(
                  current_tab === "active"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_inactive_programs(); set_current_tab("active") }}
              >
                Programs
                <span
                  className={classNames(
                    current_tab === "active" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {programs.filter(program => program.active).length}
                </span>
              </div>

              {/* Inactive tab */}
              <div
                className={classNames(
                  current_tab === "inactive"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_active_programs(); set_current_tab("inactive") }}
              >
                Archived programs
                <span
                  className={classNames(
                    current_tab === "inactive" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {programs.filter(program => !program.active).length}
                </span>
              </div>
            </nav>
          </div>
        </div>

        {/* Table area */}
        <div className="mt-2">

          {/* Top row - bulk actions and search field */}
          <div className="flex justify-between items-center px-2 py-2 space-x-4">
            <div>
              {Object.values(active_program_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-medium" >{`${Object.values(active_program_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_terminate_programs_modal_is_open(true)}
                >
                  Terminate selected
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_deactivate_programs_modal_is_open(true)}
                >
                  Archive selected
                </button>
              </div>
              : <></>}
              {Object.values(inactive_program_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-medium" >{`${Object.values(inactive_program_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_delete_programs_modal_is_open(true)}
                >
                  Delete selected
                </button>
              </div>
              : <></>}
            </div>

            {/* Search bar */}
            {/* <div className="w-64">
              <div className="relative mt-1 rounded-md shadow-sm">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  value={search_text}
                  onChange={(e) => handle_user_input("search_text", e.target.value)}
                />
              </div>
            </div> */}
          </div>

          {/* Table */}
          <div className="overflow-x-auto resize">
            <div className="inline-block min-w-full align-middle h-[500px]">
              <div className="relative">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="h-14">
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                          checked={(() => {
                            switch(current_tab) {
                              case "active": {
                                return Object.values(active_program_checked_map).length > 0 && Object.values(active_program_checked_map).every(is_checked => is_checked)
                              }
                              case "inactive": {
                                return Object.values(inactive_program_checked_map).length > 0 && Object.values(inactive_program_checked_map).every(is_checked => is_checked)
                              }
                              default: {
                                return
                              }
                            }
                          })()}
                          onChange={(() => {
                            switch(current_tab) {
                              case "active": {
                                return toggle_all_active_programs
                              }
                              case "inactive": {
                                return toggle_all_inactive_programs
                              }
                              default: {
                                return
                              }
                            }
                          })()}
                        />
                      </th>
                      <th scope="col" 
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                      >
                        Campaign name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Program status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {user_data_and_programs_are_fetched
                    ? programs.filter((() => {
                      switch(current_tab) {
                        case "active": {
                          return program => program.active
                        }
                        case "inactive": {
                          return program => !program.active
                        }
                        default: {
                          return
                        }
                      }
                    })()).filter((program) => program_search_filter_fn(program, search_text)).map((program) => (
                      <Program 
                        key={program.program_id} 
                        program={program} 
                        program_checked_map={(() => {
                          switch(current_tab) {
                            case "active": {
                              return active_program_checked_map
                            }
                            case "inactive": {
                              return inactive_program_checked_map
                            }
                            default: {
                              return
                            }
                          }
                        })()}
                        set_program_checked_map={(() => {
                          switch(current_tab) {
                            case "active": {
                              return set_active_program_checked_map
                            }
                            case "inactive": {
                              return set_inactive_program_checked_map
                            }
                            default: {
                              return
                            }
                          }
                        })()}
                      />
                    ))
                    : <tr>
                      <td className="py-4 px-4">
                        <ReactLoading
                          type='spokes'
                          color='#000'
                          height={20}
                          width={20}
                        />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Danger zone */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm">
          <div className="pt-6 sm:flex">
            <dt className="text-red-500 sm:w-64 sm:flex-none sm:pr-6 flex">
              <span className="font-semibold">Danger zone</span>
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-red-500">
                {/* TODO */}
              </div>
              {user_data["active"]
              ? <button
                type="button"
                className={classNames(!user_data_and_programs_are_fetched ? "bg-red-500" : "bg-red-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600")} 
                onClick={() => set_deactivate_users_modal_is_open(true)}
                disabled={!user_data_and_programs_are_fetched}
              >
                Deactivate user
              </button>
              : <button
                type="button"
                className={classNames(!user_data_and_programs_are_fetched ? "bg-red-500" : "bg-red-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600")} 
                onClick={() => set_delete_users_modal_is_open(true)}
                disabled={!user_data_and_programs_are_fetched}
              >
                Delete user
              </button>}
            </dd>
          </div>
        </dl>
        
      </div>


      {/* Modals */}
      <DeactivateUsersModal 
        is_open={deactivate_users_modal_is_open}
        set_is_open={set_deactivate_users_modal_is_open}
        user_ids={[user_id]}
        set_refresh_variable={set_refresh_variable}
      />
      <DeleteUsersModal 
        is_open={delete_users_modal_is_open}
        set_is_open={set_delete_users_modal_is_open}
        user_ids={[user_id]}
        set_refresh_variable={() => null} // Don't refresh, since the user no longer exists
      />
      <ArchiveProgramsModal 
        is_open={deactivate_programs_modal_is_open}
        set_is_open={set_deactivate_programs_modal_is_open}
        program_ids={Object.keys(active_program_checked_map).filter(program_id => active_program_checked_map[program_id])}
        set_refresh_variable={set_refresh_variable}
      />
      <DeleteProgramsModal 
        is_open={delete_programs_modal_is_open}
        set_is_open={set_delete_programs_modal_is_open}
        program_ids={Object.keys(inactive_program_checked_map).filter(program_id => inactive_program_checked_map[program_id])}
        set_refresh_variable={set_refresh_variable}
      />
      <TerminateProgramsModal 
        is_open={terminate_programs_modal_is_open}
        set_is_open={set_terminate_programs_modal_is_open}
        program_ids={Object.keys(active_program_checked_map).filter(program_id => active_program_checked_map[program_id])}
        set_refresh_variable={set_refresh_variable}
      />
    </div>
  )
}

export default UsersUserIdPage