
import { useState } from 'react'

import { useNavigate, Link, useLocation } from "react-router-dom"


import { set as idb_set } from 'idb-keyval';

import { useGlobal, useGlobalUpdate } from '../contexts/GlobalContext'

import { Dialog } from '@headlessui/react'

import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import { ReactComponent as TextLogo } from "../media/logos/text_logo_320x132.svg"
import axios from 'axios';


const unprotected_navigation = [
  { name: 'Menu1', to: '#' },
  { name: 'Menu2', to: '#' },
]

const protected_navigation = [
  { name: 'Menu3', to: '#' },
  { name: 'Menu4', to: '#' },
]

const login_routes = ["/login"]


const Navbar = ({

}: { 

}) => {

  // Global
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()


  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()



  const logout = async () => {
    const delete_auth_res = await axios.delete(`/api/auth`)

    if (!delete_auth_res.data.success) {
      // TODO
      return
    }

    // Set session_token to UNSET
    await idb_set('session_token', "UNSET");

    global_update({
      ...global_context,
      authenticated: false,
      user_id: "UNSET",
      type: "UNSET"
    })

    // This doesn't appear to be necessary, but just having it here for safety and for code readability
    navigate("/login")
  }


  // Whether menu is open or not
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)



  
  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="m-1.5 p-1.5">
            <span className="sr-only">Vansec</span>
            <TextLogo className="h-3.5 w-auto" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {/* {(global_context.authenticated ? protected_navigation : unprotected_navigation).map((item) => (
            <Link key={item.name} to={item.to} className="text-sm font-semibold leading-6 text-gray-900">
              {item.name}
            </Link>
          ))} */}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {global_context.authenticated
          ? <button 
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={logout}
            >
              Logout
            </button>
          : login_routes.includes(location.pathname)
            ? <Link to="/signup" className="text-sm font-semibold leading-6 text-gray-900">
                Sign up <span aria-hidden="true">&rarr;</span>
              </Link>
            : <Link to="/login" className="text-sm font-semibold leading-6 text-gray-900">
                Log in <span aria-hidden="true">&rarr;</span>
              </Link>
          }
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Vansec</span>
              <TextLogo className="h-10 w-auto" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {/* {(global_context.authenticated ? protected_navigation : unprotected_navigation).map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))} */}
              </div>
              <div className="py-6">
                {global_context.authenticated
                ? <button
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={logout}
                  >
                    Logout
                  </button>
                : login_routes.includes(location.pathname)
                  ? <Link
                      to="/signup"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign up
                    </Link>
                  : <Link
                      to="/login"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </Link>
                }
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}


export default Navbar;