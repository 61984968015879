
import { useEffect, useState } from 'react'

import ReactLoading from "react-loading"

import { useGlobal, useGlobalUpdate } from '../../../../contexts/GlobalContext'

import { useLocation, useNavigate, useParams } from "react-router-dom"
import { MagnifyingGlassIcon, InformationCircleIcon } from '@heroicons/react/20/solid'


import { auth_axios } from '../../../../libs/authWeb';
import { mixpanel_client_track } from '../../../../libs/mixpanelClient';
import { program_search_filter_fn } from '../../../../libs/searchFilterFunctions'
import { action_type_map, campaign_type_map, get_date_string, job_status_map, job_type_map, program_status_map } from '../../../../libs/formats'
import { classNames } from '../../../../libs/helpers'


import EventModal from './EventModal'
import ArchiveProgramsModal from '../ArchiveProgramsModal'
import DeleteProgramsModal from '../DeleteProgramsModal'
import TerminateProgramsModal from '../TerminateProgramsModal'






const Event = ({
  event,
  event_checked_map,
  set_event_checked_map,

  set_current_event,
  set_event_modal_is_open
} : {
  event : any
  event_checked_map : any
  set_event_checked_map : any

  set_current_event: any
  set_event_modal_is_open : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr 
      className={classNames(event_checked_map[event.event_id] ? 'bg-gray-50' : "", "hover:bg-gray-100 cursor-pointer")}
      onClick={() => { set_current_event(event); set_event_modal_is_open(true); }}
    >
      {/* Checkbox */}
      <td className="relative px-7 sm:w-12 sm:px-6">
        {event_checked_map[event.event_id] && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          checked={event_checked_map[event.event_id]}
          onChange={(e) =>
            set_event_checked_map({
            ...event_checked_map,
            [event.event_id]: e.target.checked
          })}
          onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
        />
      </td>

      {/* Agent */}
      <td
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm font-medium',
          event_checked_map[event.event_id] ? 'text-blue-600' : 'text-gray-900'
        )}
      >
        {(() => {
          switch(event.event_type) {
            case "job": {
              return "Simulation"
            }
            case "action": {
              return "User"
            }
            default: {
              return "Unknown"
            }
          }
        })()}
      </td>

      {/* Type */}
      <td 
        className={classNames(event.type === "open_email" ? "flex z-50" : "whitespace-nowrap" , "px-3 py-4 text-sm text-gray-500")}
      >
        {(() => {
          switch(event.event_type) {
            case "job": {
              return job_type_map[event.type]
            }
            case "action": {
              return action_type_map[event.type]
            }
            default: {
              return "Unknown"
            }
          }
        })()}
        {event.type === "open_email"
        ? <>
          <span className="flex relative items-center">
            <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
            <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
              For accuracy, the "opened email" event is only tracked once, when it first occurs.
            </span>
          </span>
        </> 
        : <></>}
      </td>

      {/* Status */}
      <td 
        className={classNames(event.event_is_upcoming ? "text-blue-500" : "text-gray-500", "whitespace-nowrap px-3 py-4 text-sm")} 
      >
        {(() => {
          switch(event.event_type) {
            case "job": {
              return event.event_is_upcoming ? "Scheduled" : "Executed"
            }
            case "action": {
              return event.event_is_upcoming ? "Upcoming" : "Tracked"
            }
            default: {
              return "Unknown"
            }
          }
        })()}
      </td>

      {/* Delivery status */}
      <td 
        className={classNames(event.status === "email_bounced" ? "text-red-500" : "text-gray-500", "whitespace-nowrap px-3 py-4 text-sm")} 
      >
        {event.status ? job_status_map[event.status] : "-"}
      </td>

      {/* Timestamp */}
      <td 
        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
      >
        {get_date_string(event.event_occurred_at)}
      </td>
    </tr>
  )
}

const ProgramsProgramIdPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Use params
  const { program_id } = useParams()  

  // States
  const [program, set_program] = useState({})
  const [user, set_user] = useState({})
  const [campaign, set_campaign] = useState({})
  const [events, set_events] = useState([])

  const [event_checked_map, set_event_checked_map] = useState({})

  const [program_is_fetched, set_program_is_fetched] = useState(false)

  // Tab
  const [current_tab, set_current_tab] = useState("events")

  // Refresh variable
  const [refresh_variable, set_refresh_variable] = useState(Date.now())

  // Search & filters
  const [search_text, set_search_text] = useState("")

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)

  // Modal
  const [current_event, set_current_event] = useState({})
  const [event_modal_is_open, set_event_modal_is_open] = useState(false)

  const [deactivate_programs_modal_is_open, set_deactivate_programs_modal_is_open] = useState(false)
  const [delete_programs_modal_is_open, set_delete_programs_modal_is_open] = useState(false)
  const [terminate_programs_modal_is_open, set_terminate_programs_modal_is_open] = useState(false)
  
  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "search_text": {
        set_search_text(value)

        // Always break
        break
      }
    }
  }

  const get_program = async () => {

    // Set is_fetched to false
    set_program_is_fetched(false)

    // Execute get campaign data
    const get_program_res = await auth_axios.get(`/api/programs/${program_id}`)

    if (!get_program_res.data.success) {
      switch (get_program_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_program(get_program_res.data.program)
    set_user(get_program_res.data.user)
    set_campaign(get_program_res.data.campaign)
    set_events(get_program_res.data.events)
    set_event_checked_map(get_program_res.data.events.reduce((acc, event) => ({...acc, [event.event_id]: false}), {}))
    set_program_is_fetched(true)
  }

  const toggle_all_events = (e) => {
    set_event_checked_map(Object.keys(event_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: e.target.checked }), {}))
  }

  // Renders  
  // render upon load & upon action_fetch_start_index change
  useEffect(() => {

    get_program()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_programs_program_id_visited", global_context.user_id)

  }, [refresh_variable])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">

      {/* Header */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Program information
          </h1>
          <div className="mt-2 text-sm text-gray-700">
            {program_is_fetched
            ? program["program_metadata"]["name"]
            : <ReactLoading
              type='spokes'
              color='#000'
              height={20}
              width={20}
            />}
          </div>
        </div>
        {/* {program["active"] && program["status"] === "active"
        ? <button
          type="button"
          className={classNames(!program_is_fetched ? "bg-red-500" : "bg-red-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600")} 
          onClick={() => set_terminate_programs_modal_is_open(true)}
          disabled={!program_is_fetched}
        >
          Terminate program
        </button>
        : <></>} */}
      </div>

      {/* Data */}
      <div className="mt-8 flow-root">

        {/* Program information */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm">

          {/* User name */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">User full name</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {program_is_fetched ? (
                  <span>{user["user_metadata"]["first_name"] === "UNSET" || user["user_metadata"]["last_name"] === "UNSET" ? "(Unspecified)" : `${user["user_metadata"]["first_name"]} ${user["user_metadata"]["last_name"]}`}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
              {/* {program["active"] && program["status"] === "active"
              ? <div className="font-semibold text-red-500 hover:text-red-400 cursor-pointer" onClick={() => set_terminate_programs_modal_is_open(true)}>
                Terminate program
              </div>
              : <></>} */}
            </dd>
          </div>

          {/* User email address */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">User email address</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {program_is_fetched ? (
                  <span>{user["email_address"]}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
              {/* <Link to="update/name" className="font-semibold text-blue-600 hover:text-blue-500">
                Edit
              </Link> */}
            </dd>
          </div>

          {/* Campaign name */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Campaign name</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {program_is_fetched ? (
                  <span>{campaign["campaign_metadata"]["name"]}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
              {/* <Link to="update/name" className="font-semibold text-blue-600 hover:text-blue-500">
                Edit
              </Link> */}
            </dd>
          </div>

          {/* Program type */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Program type</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {program_is_fetched ? (
                  <span>{campaign_type_map[campaign["campaign_metadata"]["type"]]}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
              {/* <Link to="update/name" className="font-semibold text-blue-600 hover:text-blue-500">
                Edit
              </Link> */}
            </dd>
          </div>

          {/* Simulation status */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Simulation status</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {program_is_fetched ? (
                  <span>{program_status_map[program["simulation_status"]]}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
              {program["active"] && program["simulation_status"] === "active"
              ? <div className="font-semibold text-red-500 hover:text-red-400 cursor-pointer" onClick={() => set_terminate_programs_modal_is_open(true)}>
                Terminate program
              </div>
              : <></>}
            </dd>
          </div>

          {/* Training status */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Training status</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {program_is_fetched ? (
                  <span>{program_status_map[program["training_status"]]}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
              {program["active"] && program["training_status"] === "active"
              ? <div className="font-semibold text-red-500 hover:text-red-400 cursor-pointer" onClick={() => set_terminate_programs_modal_is_open(true)}>
                Terminate program
              </div>
              : <></>}
            </dd>
          </div>
          
        </dl>

        {/* Tabs */}
        <div className="mt-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {/* Events tab */}
              <div
                className={classNames(
                  current_tab === "events"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { set_current_tab("events") }}
              >
                Events
                <span
                  className={classNames(
                    current_tab === "events" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {events.length}
                </span>
              </div>

              {/* Inactive tab */}
              {/* <div
                className={classNames(
                  current_tab === "inactive"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_active_programs(); set_current_tab("inactive") }}
              >
                Archived programs
                <span
                  className={classNames(
                    current_tab === "inactive" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {programs.filter(program => !program.active).length}
                </span>
              </div> */}
            </nav>
          </div>
        </div>

        {/* Table area */}
        <div className="mt-2">

          {/* Top row - bulk actions and search field */}
          <div className="flex justify-between items-center px-2 py-2 space-x-4">
            {Object.values(event_checked_map).some(is_checked => is_checked)
            ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
              <div className="text-blue-600 text-sm font-medium" >{`${Object.values(event_checked_map).filter(is_checked => is_checked).length} selected`}</div>
              {/* <button
                type="button"
                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
              >
                Edit selected
              </button> */}
              {/* <button
                type="button"
                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
              >
                Delete selected
              </button> */}
            </div>
            : <div></div>}
            {/* Search bar */}
            {/* <div className="w-64">
              <div className="relative mt-1 rounded-md shadow-sm">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  value={search_text}
                  onChange={(e) => handle_user_input("search_text", e.target.value)}
                />
              </div>
            </div> */}
          </div> 

          {/* Table */}
          <div className="overflow-x-auto resize">
            <div className="inline-block min-w-full align-middle h-[500px]">
              <div className="relative">
                
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="h-14">
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                          checked={Object.values(event_checked_map).length > 0 && Object.values(event_checked_map).every(is_checked => is_checked)}
                          onChange={toggle_all_events}
                        />
                      </th>
                      <th scope="col" 
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                      >
                        User / simulation
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Delivery status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Timestamp
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {program_is_fetched
                    ? events.filter((event) => program_search_filter_fn(program, search_text)).map((event) => (
                      <Event 
                        key={event.event_id} 
                        event={event} 
                        event_checked_map={event_checked_map}
                        set_event_checked_map={set_event_checked_map}

                        set_current_event={set_current_event}
                        set_event_modal_is_open={set_event_modal_is_open}
                      />
                    ))
                    : <tr>
                      <td className="py-4 px-4">
                        <ReactLoading
                          type='spokes'
                          color='#000'
                          height={20}
                          width={20}
                        />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Danger zone */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm">
          <div className="pt-6 sm:flex">
            <dt className="text-red-500 sm:w-64 sm:flex-none sm:pr-6 flex">
              <span className="font-semibold">Danger zone</span>
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-red-500">
                {/* TODO */}
              </div>
              <div className="flex flex-col space-y-8">
                {/* {program["active"] && program["status"] === "active"
                ? <div className="font-semibold text-red-500 hover:text-red-400 cursor-pointer" onClick={() => set_terminate_programs_modal_is_open(true)}>
                  Terminate program
                </div>
                : <></>} */}
                {program["active"]
                ? <button
                  type="button"
                  className={classNames(!program_is_fetched ? "bg-red-500" : "bg-red-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600")} 
                  onClick={() => set_deactivate_programs_modal_is_open(true)}
                  disabled={!program_is_fetched}
                >
                  Archive program
                </button>
                : <button
                  type="button"
                  className={classNames(!program_is_fetched ? "bg-red-500" : "bg-red-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600")} 
                  onClick={() => set_delete_programs_modal_is_open(true)}
                  disabled={!program_is_fetched}
                >
                  Delete program
                </button>}
              </div>
            </dd>
          </div>
        </dl>
        
      </div>

      {/* Modals */}
      <EventModal 
        is_open={event_modal_is_open}
        set_is_open={set_event_modal_is_open}
        event={current_event}
      />
      <ArchiveProgramsModal 
        is_open={deactivate_programs_modal_is_open}
        set_is_open={set_deactivate_programs_modal_is_open}
        program_ids={[program_id]}
        set_refresh_variable={set_refresh_variable}
      />
      <DeleteProgramsModal 
        is_open={delete_programs_modal_is_open}
        set_is_open={set_delete_programs_modal_is_open}
        program_ids={[program_id]}
        set_refresh_variable={() => null} // Don't refresh, since the program no longer exists
      />
      <TerminateProgramsModal
        is_open={terminate_programs_modal_is_open}
        set_is_open={set_terminate_programs_modal_is_open}
        program_ids={[program_id]}
        set_refresh_variable={set_refresh_variable}
      />
    </div>
  )
}

export default ProgramsProgramIdPage