

export const get_text_blocks = (page_data: any) => {

  return page_data.map((page_item, index) => {
    switch(page_item[0]) {
      case "h2": {
        return (
          <h2 key={index}>{page_item[1]}</h2>
        )
      }
      case "p": {
        return (
          <p key={index}>{page_item[1]}</p>
        )
      }
      case "p<strong>": {
        return (
          <strong key={index}><p>{page_item[1]}</p></strong>
        )
      }
      case "ul": {


        return (
          <ul role="list" key={index}>{page_item[1].map((item_data, item_index) => {return(<li key={`${index}-${item_index}`}>{item_data}</li>)})}</ul>
        )
      }
      case "blockquote": {


        return (
          <blockquote key={index}>{page_item[1].map((item_data, item_index) => {return(<p key={`${index}-${item_index}`}>{item_data}</p>)})}</blockquote>
        )
      }
      default: {
        return
      }
    }
  
  })




}


export const get_list_item_blocks = (list_data: any) => {

  return list_data.map((list_item, index) => {
    return (
      <li key={index}>
        {list_item}
      </li>
    )
  })
}