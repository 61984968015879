
import { useEffect, useState } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'

import { useLocation, Link } from "react-router-dom"

import { ClipboardDocumentListIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/20/solid'


import { auth_axios } from '../../../libs/authWeb';
import { mixpanel_client_track } from '../../../libs/mixpanelClient';
import { simulation_default_domain_options } from '../../../libs/options';
import { show_notification, sleep, classNames } from '../../../libs/helpers'
import { user_property_map } from '../../../libs/formats'


import DomainsAllowlistedModal from './DomainsAllowlistedModal';
import SendTestEmailModal from './SendTestEmailModal'
import EnableSCIMModal from './EnableSCIMModal';
import UpdateUserActivePropertiesModal from './UpdateUserActivePropertiesModal'
import ContentVisibilityModal from './ContentVisibilityModal'
import DisconnectVantaModal from './DisconnectVantaModal'
import GenerateReportModal from './GenerateReportModal'








const OrganizationPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // User data
  const [organization_data, set_organization_data] = useState({})
  const [organization_data_is_fetched, set_organization_data_is_fetched] = useState(false)

   // Clipboard copied
   const [scim_url_is_copied_to_clipboard, set_scim_url_is_copied_to_clipboard] = useState(false)

  // Refresh variable
  const [refresh_variable, set_refresh_variable] = useState(Date.now())

  // Modal
  const [domains_allowlisted_modal_is_open, set_domains_allowlisted_modal_is_open] = useState(false)
  const [send_test_email_modal_is_open, set_send_test_email_modal_is_open] = useState(false)
  const [enable_scim_modal_is_open, set_enable_scim_modal_is_open] = useState(false)
  const [update_active_user_properties_modal_is_open, set_update_active_user_properties_modal_is_open] = useState(false)
  const [content_visibility_modal_is_open, set_content_visibility_modal_is_open] = useState(false)
  const [disconnect_vanta_modal_is_open, set_disconnect_vanta_modal_is_open] = useState(false)
  const [generate_report_modal_is_open, set_generate_report_modal_is_open] = useState(false)






  const get_organization_data = async () => {
    // Set is_fetched to false
    set_organization_data_is_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_organization_data_is_fetched(true)
  }


  // Handle copy to clipboard
  const handle_copy_to_clipboard = async (type) => {
    switch(type) {
      case "scim_url": {
        await navigator.clipboard.writeText(`https://${organization_data["organization_id"]}.vansec.com/scim/v2`)

        set_scim_url_is_copied_to_clipboard(true)

        show_notification(protected_context, protected_update, "success", "Success", `Tenant / base URL is copied to clipboard`)

        await sleep(3.0)

        set_scim_url_is_copied_to_clipboard(false)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }


  // Renders 
  useEffect(() => {

    get_organization_data()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_organization_visited", global_context.user_id)

  }, [refresh_variable])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Organization</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Organization information
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">

            {/* Organization name */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Name</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {organization_data_is_fetched ? (
                    <span>{organization_data["organization_metadata"]["name"]}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                {/* Only available to owner */}
                {["owner"].includes(global_context.type)
                ? <Link to="update/name" className="font-semibold text-blue-600 hover:text-blue-500">
                    Update
                  </Link>
                : <></>}
              </dd>
            </div>

            {/* Organization domain */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Organization domain</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900 flex flex-col space-y-4">
                  {organization_data_is_fetched ? (<>
                    <div className="flex flex-col space-y-2">
                      <div className="flex">
                        <span className="font-medium">Primary domain</span>
                        <span className="flex relative items-center">
                          <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                          <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                            This is the primary domain for your organization. You may only add users whose email addresses belong to this domain.
                          </span>
                        </span>
                      </div>
                      <div>{organization_data["organization_domain"]}</div>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex">
                        <span className="font-medium">Additional domains for the organization</span>
                        <span className="flex relative items-center">
                          <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                          <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                            If needed, you may add users with email addresses that also belong to these additional domains. Please reach out to support@vansec.com to add such domains.
                          </span>
                        </span>
                      </div>
                      {organization_data["organization_metadata"]["additional_organization_domains"].map((domain_option) => 
                        <div key={domain_option}>{domain_option}</div>
                      )}
                    </div>
                  </>  
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                {/* <Link to="update/domain" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update
                </Link> */}
              </dd>
            </div>

            {/* Available domains */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Domains available for simulation</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900 flex flex-col space-y-4">
                  {organization_data_is_fetched ? (<>
                    <div className="flex flex-col space-y-2">
                      <div className="flex">
                        <span className="font-medium">Default domains</span>
                        <span className="flex relative items-center">
                          <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                          <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                            Domains that are available by default.
                          </span>
                        </span>
                      </div>
                      {simulation_default_domain_options.map((domain_option) => 
                        <div key={domain_option}>{domain_option}</div>
                      )}
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex">
                        <span className="font-medium">Custom domains</span>
                        <span className="flex relative items-center">
                          <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                          <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                            To add custom domains for your organization, please reach out to support@vansec.com.
                          </span>
                        </span>
                      </div>
                      {organization_data["organization_metadata"]["custom_simulation_domains"].map((domain_option) => 
                        <div key={domain_option}>{domain_option}</div>
                      )}
                    </div>
                  </>  
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                {/* <Link to="update/domain" className="font-semibold text-blue-600 hover:text-blue-500">
                  Update
                </Link> */}
              </dd>
            </div>

            {/* Domains allowlisted */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Domains are allowlisted (self-reported)</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900 flex items-center">
                  {organization_data_is_fetched ? (
                    <>
                      <span>{organization_data["organization_metadata"]["domains_are_allowlisted"] ? "Yes" : "No"}</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          Please confirm that you have allowlisted all domains available to your organization following the <span className="text-blue-400 hover:text-blue-500">How to allowlist domains</span> guide.
                        </span>
                      </span>
                      <span><Link to={`/docs/guides/allowlist`} target="_blank" className="ml-4 font-medium text-blue-600 hover:text-blue-500">How to allowlist domains</Link></span>
                    </>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                {organization_data_is_fetched && !organization_data["organization_metadata"]["domains_are_allowlisted"]
                ? <div 
                  className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                  onClick={() => set_domains_allowlisted_modal_is_open(true)}
                >
                  Self report
                </div>
                : <div 
                  className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                  onClick={() => set_send_test_email_modal_is_open(true)}
                >
                  Send test email
                </div>}
              </dd>
            </div>

            {/* Display settings */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Active properties</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900 flex flex-col space-y-4">
                  {organization_data_is_fetched ? (<>
                    <div className="flex flex-col space-y-2">
                      <div className="flex">
                        <span className="font-medium">User active properties</span>
                        <span className="flex relative items-center">
                          <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                          <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                            The active properties (and their order) for any tables of users, as well as properties that can be used for campaigns.
                          </span>
                        </span>
                      </div>
                      {organization_data["organization_metadata"]["active_user_properties"].map(property => user_property_map[property]).join(", ")}
                    </div>
                    {/* <div className="flex flex-col space-y-2">
                      <div className="flex">
                        <span className="font-medium">Custom domains</span>
                        <span className="flex relative items-center">
                          <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                          <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                            To add custom domains for your organization, please reach out to support@vansec.com.
                          </span>
                        </span>
                      </div>
                      {organization_data["organization_metadata"]["custom_simulation_domains"].map((domain_option) => 
                        <div key={domain_option.value}>{domain_option.value}</div>
                      )}
                    </div> */}
                  </>  
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                <div 
                  className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                  onClick={() => set_update_active_user_properties_modal_is_open(true)}
                >
                  Update
                </div>
              </dd>
            </div>

            {/* Content visibility settings */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Content visibility for admins</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900 flex flex-col space-y-4">
                  {organization_data_is_fetched ? (<>
                    <div className="flex flex-col space-y-2">
                      <div className="flex">
                        <div className="font-medium">Simulation content:{" "}</div>
                        <div className="ml-1">{organization_data["organization_metadata"]["simulation_content_is_visible"] ? "Visible" : "Hidden"}</div>
                        <span className="flex relative items-center">
                          <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                          <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                            {organization_data["organization_metadata"]["simulation_content_is_visible"] ? "Admins are currently able to view contents of simulation campaigns." : "Admins are currently unable to view contents of simulation campaigns."}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex">
                        <span className="font-medium">Training content</span>
                        <div className="ml-1">{organization_data["organization_metadata"]["training_content_is_visible"] ? "Visible" : "Hidden"}</div>
                        <span className="flex relative items-center">
                          <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                          <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                            {organization_data["organization_metadata"]["training_content_is_visible"] ? "Admins are currently able to view contents of training campaigns." : "Admins are currently unable to view contents of training campaigns."}
                          </span>
                        </span>
                      </div>
                    </div>
                  </>  
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
                {/* Only available to owner */}
                {["owner"].includes(global_context.type)
                ? <div 
                  className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                  onClick={() => set_content_visibility_modal_is_open(true)}
                >
                  Update
                </div>
                : <></>}
              </dd>
            </div>

            {/* SCIM integration */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">SCIM integration</dt>
              <dd className="mt-1 flex gap-y-6 sm:mt-0 sm:flex-auto flex-col">

                <div className="flex justify-between gap-x-6">
                  {/* Enabled / disabled */}
                  <div className="text-gray-900 flex items-center">
                    {organization_data_is_fetched ? (
                      <>
                        <span>{organization_data["provision_metadata"]["scim_is_enabled"] ? "Enabled" : "Disabled"}</span>
                        <span className="flex relative items-center">
                          <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                          <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                            To set up SCIM integration, please follow the <span className="text-blue-400 hover:text-blue-500">How to set up SCIM integration</span> guide.
                          </span>
                        </span>
                        <span><Link to={`/docs/guides/scim`} target="_blank" className="ml-4 font-medium text-blue-600 hover:text-blue-500">How to set up SCIM integration</Link></span>
                      </>
                    ) : (
                    <ReactLoading
                      type='spokes'
                      color='#343D46'
                      height={20}
                      width={20}
                    />)}
                  </div>

                  {/* Enable/update button */}
                  {organization_data_is_fetched
                  ? <div 
                    className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                    onClick={() => set_enable_scim_modal_is_open(true)}
                  >
                    {organization_data["provision_metadata"]["scim_is_enabled"] ? "Update SCIM" : "Enable SCIM"} 
                  </div>
                  : <></>}
                </div>

                {organization_data_is_fetched && organization_data["provision_metadata"]["scim_is_enabled"]
                ? <>
                  {/* SCIM URL */}
                  <div className="flex justify-between gap-x-6">
                    {/* Data */}
                    <div className="font-normal text-gray-900 flex gap-x-4">
                      <div className="min-w-[140px]">{`Tenant / base URL: `}</div>
                      <div className={classNames(scim_url_is_copied_to_clipboard ? "" : "cursor-pointer", "text-gray-600 hover:text-gray-900")} onClick={scim_url_is_copied_to_clipboard ? null : async () => await handle_copy_to_clipboard("scim_url")}>{`https://${organization_data["organization_id"]}.vansec.com/scim/v2`}</div>
                    </div>

                    {/* Copy to clipboard button */}
                    {scim_url_is_copied_to_clipboard
                    ? <ClipboardDocumentCheckIcon
                      className="h-6 w-6 text-green-400"
                    />
                    : <ClipboardDocumentListIcon
                      className={classNames(scim_url_is_copied_to_clipboard ? "" : "cursor-pointer" , "h-6 w-6 text-blue-400 hover:text-blue-500")}
                      onClick={scim_url_is_copied_to_clipboard ? null : async () => await handle_copy_to_clipboard("scim_url")}
                    />}
                  </div>

                  {/* SCIM token */}
                  <div className="flex justify-between gap-x-6">
                    {/* Data */}
                    <div className="font-normal text-gray-900 flex gap-x-4">
                      <div className="min-w-[140px]">{`SCIM token (hidden): `}</div>
                      <div className="text-gray-600">{`${organization_data["provision_metadata"]["scim_token"].slice(0, 4)}************************************${organization_data["provision_metadata"]["scim_token"].slice(-4)}`}</div>
                    </div>

                    {/* Copy to clipboard button */}
                    {/* {scim_url_is_copied_to_clipboard
                    ? <ClipboardDocumentCheckIcon
                      className="h-6 w-6 text-green-400"
                    />
                    : <ClipboardDocumentListIcon
                      className={classNames(scim_url_is_copied_to_clipboard ? "" : "cursor-pointer" , "h-6 w-6 text-blue-400 hover:text-blue-500")}
                      onClick={scim_url_is_copied_to_clipboard ? null : async () => await handle_copy_to_clipboard("scim_url")}
                    />} */}
                  </div>
                </>
                : <></>}
              </dd>
            </div>

            {/* Integration settings */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Third party integrations</dt>
              <dd className="mt-1 flex gap-y-6 sm:mt-0 sm:flex-auto flex-col">

                {/* None */}
                {organization_data_is_fetched
                ? (
                    !organization_data["integration_metadata"]["vanta"]["active"] // Vanta integration is not active
                  )
                  ? <span>None</span>
                  : <></>
                : <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />}

                {/* Vanta */}
                {organization_data_is_fetched && organization_data["integration_metadata"]["vanta"]["active"]
                ? <div className="flex justify-between gap-x-6">
                  {/* Enabled / disabled */}
                  <div className="text-gray-900 flex items-center">
                    <span>Vanta: Connected</span>
                    <span className="flex relative items-center">
                      <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        The Vanta integration was made via your Vanta account.
                      </span>
                    </span>
                  </div>

                  {/* Disconnect button */}
                  <div 
                    className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                    onClick={() => set_disconnect_vanta_modal_is_open(true)}
                  >
                    Disconnect Vanta
                  </div>
                </div>
                : <></>}
                
              </dd>
            </div>

            {/* Reports */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Reports</dt>
              <dd className="mt-1 flex gap-y-6 sm:mt-0 sm:flex-auto flex-col">

                <div className="flex justify-between gap-x-6">

                  {/* Text */}
                  {organization_data_is_fetched
                  ? <div className="text-gray-900 flex items-center">
                    <span>Generate and download Vansec reports</span>
                    <span className="flex relative items-center">
                      <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        Please reach out to support@vansec.com to inquire about reports.
                      </span>
                    </span>
                  </div>
                  : <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />}

                  {/* Generate report button */}
                  {organization_data_is_fetched
                  ? <div 
                    className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                    onClick={() => set_generate_report_modal_is_open(true)}
                  >
                    Generate report
                  </div>
                  : <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />}
                  
                </div>
                
              </dd>
            </div>

            {/* Delete organization */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-red-400">Delete organization</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">To close the organization account, please reach out to support@vansec.com</div>
              </dd>
            </div>
          </dl>
        </div>

      </div>

      {/* Modals */}
      <DomainsAllowlistedModal
        is_open={domains_allowlisted_modal_is_open}
        set_is_open={set_domains_allowlisted_modal_is_open}
        set_refresh_variable={set_refresh_variable}
      />
      <SendTestEmailModal
        is_open={send_test_email_modal_is_open}
        set_is_open={set_send_test_email_modal_is_open}
        set_refresh_variable={set_refresh_variable}
      />
      <EnableSCIMModal  
        is_open={enable_scim_modal_is_open}
        set_is_open={set_enable_scim_modal_is_open}
        set_refresh_variable={set_refresh_variable}
      />
      <UpdateUserActivePropertiesModal  
        is_open={update_active_user_properties_modal_is_open}
        set_is_open={set_update_active_user_properties_modal_is_open}
        set_refresh_variable={set_refresh_variable}
      />
      <ContentVisibilityModal  
        is_open={content_visibility_modal_is_open}
        set_is_open={set_content_visibility_modal_is_open}
        set_refresh_variable={set_refresh_variable}
      />
      <DisconnectVantaModal  
        is_open={disconnect_vanta_modal_is_open}
        set_is_open={set_disconnect_vanta_modal_is_open}
        set_refresh_variable={set_refresh_variable}
      />
      <GenerateReportModal 
        is_open={generate_report_modal_is_open}
        set_is_open={set_generate_report_modal_is_open}
        set_refresh_variable={set_refresh_variable}
      />
    </div>
  )
}

export default OrganizationPage