
import { useEffect } from 'react'

import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'




import { useGlobal, useGlobalUpdate } from '../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../contexts/ProtectedContext'

import { handle_user_view_banner, classNames } from '../../../../libs/helpers'


import scim1 from "./media/scim1.png"
import scim2 from "./media/scim2.png"
import scim3 from "./media/scim3.png"


import { useLocation, useNavigate, Outlet, Link } from "react-router-dom"
import { mixpanel_client_track } from '../../../../libs/mixpanelClient'



const bullets = [
  {
    title: "Provision & deprovision users",
    content: "Enabling SCIM integration allows you to easily provision and deprovision users on Vansec via your identity provider. Please note that the provisioning should be one-way, and that Vansec should not be used as a profile source."
  },
  {
    title: "Update user profiles",
    content: "Any update made to the profile of a user who is assigned to Vansec will be synced automatically with their Vansec user profile."
  },
  {
    title: "Provision & deprovision groups",
    content: "You can easily manage groups and group memberships via your identity provider."
  },
  {
    title: "Supported integrations",
    content: "Vansec integrates with the following identity providers: Okta, Microsoft Entra ID (formerly Azure Active Directory)"
  },
]





const DocsGuidesSCIM = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // Location
  const location = useLocation()

  // Render - handle user view banner 
  useEffect(() => {
    // Mixpanel tracking
    mixpanel_client_track("app_docs_guides_how_to_setup_scim_visited", null)
  }, [])

  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-blue-600">Guides</p>
        
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How to setup a SCIM integration</h1>

        <p className="mt-6 text-xl leading-8">
          Follow the following instructions to configure your Vansec organization to allow SCIM provisionig via your identity provider.
        </p>

        <div className="mt-10 max-w-2xl">
          <p>
            Enabling SCIM provisioning for your organization is simple. Enabling SCIM provisioning allows you to:
          </p>
          <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
            {bullets.map((bullet, index) => 
              <li key={index} className="flex gap-x-3">
                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                <span>
                  <strong className="font-semibold text-gray-900">{bullet.title}.</strong> {bullet.content}
                </span>
              </li>
            )}
          </ul>
        </div>

        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Instructions</h2>

        <p className="mt-6">
          To enable SCIM provisioning for your organization, first, visit the "Organization Settings" tab in your Vansec dashboard. In the "SCIM integration" section, click on the "Enable SCIM" button.
        </p>

        <figure className="mt-8">
          <img
            className="rounded-xl"
            src={scim1}
          />
          <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
            <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
            If your SCIM integration is already enabled, you can update your SCIM token using the "Update SCIM" button.
          </figcaption>
        </figure>

        <p className="mt-12">
          When the "Enable SCIM" modal appears, click on the "Generate SCIM Token" button to generate a SCIM token.
        </p>

        <figure className="mt-8">
          <img
            className="rounded-xl"
            src={scim2}
          />
        </figure>

        <p className="mt-6">
          Copy the SCIM token by clicking on the token, and save it somewhere safe. Please note that for security purposes, your SCIM token will not be shown again. Then click on the "Enable" button to enable the SCIM integration.
        </p>

        <figure className="mt-8">
          <img
            className="rounded-xl"
            src={scim3}
          />
          <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
            <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
            The SCIM token will not be shown again once the modal is closed.
          </figcaption>
          <figcaption className="mt-2 flex gap-x-2 text-sm leading-6 text-gray-500">
            <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
            Your SCIM integration will not be enabled / updated unless the "Enable" / "Update" button is clicked.
          </figcaption>
        </figure>

        <p className="mt-6">
          Apply the tenant / base URL and the saved SCIM token to the respective SCIM integration section of your identity provider and test the integration.
        </p>

        {/* OKTA */}
        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">Okta integration</h2>
          <p className="mt-6">
            For the Okta integration, the apply the tenant / base URL into the "Base URL" field, and the SCIM token into the "API Token" field. Once you "Test API Credentials", you should click on the "Save" button.
          </p>
          <p className="mt-8">
            In the "Sign On" tab, you must select "Email" for the "Application username format".
          </p>
          <p className="mt-8">
            In the "Provisioning" tab, under "To App" tab, you should enable "Create Users", "Update User Attributes", and "Deactivate Users" features. The "Sync Password" feature should be disabled.
          </p>
          <p className="mt-8">
            To assign users to Vansec, go to the "Assignments" tab and assign users by finding them under "People" or "Groups". Please note that assigning users via a group will not add the group itself.
          </p>
          <p className="mt-8">
            To provision groups in Okta to Vansec, go to the "Push Groups" tab, and add a group by searching for a group using the "Push Groups" feature. Please note that the "Push Groups" feature only pushes group membership onto Vansec. Thus, within a group, only users who are assigned Vansec will show up under the group in Vansec.
          </p>
          <p className="mt-8">
            Known issues / troubleshooting. Please note that the import features of the "Import" tab should never be used. Email addresses in Vansec are case insensitive (e.g. john@example.com and John@example.com are considered the same email address). Email addresses must belong to the organization domain in order to be provisioned via SCIM.
          </p>
        </div>

        {/* MICROSOFT ENTRA ID */}
        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">Microsoft Entra ID integration</h2>
          <p className="mt-6">
            For the Microsoft Entra ID integration, the apply the tenant / base URL into the "Tenant URL" field, and the SCIM token into the "Secret Token" field. Once you "Test Connection", you should click on the "Save" button.
          </p>
          <p className="mt-8">
            To assign users to Vansec, go to the "Users and groups" tab and click on the "Add user/group" to select the users or groups you would like to sync. After selecting the users or groups, click on "Select".
          </p>
          <p className="mt-8">
            To start the sync, go to the "Provisioning" tab and click on the "Start provisioning" button.
          </p>
        </div>

        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">That's it!</h2>
          <p className="mt-6">
            If you have any questions, please reach out to <Link to="mailto:support@vansec.com">support@vansec.com</Link>.
          </p>
          <p className="mt-8">
            Team Vansec
          </p>
        </div>
      </div>
    </div>
  )
}

export default DocsGuidesSCIM