



export const campaigns_stats_default_value = [
  { name: "Total campaigns", primary: null, secondary: null },
  { name: "Total simulation campaigns", primary: null, secondary: null },
  { name: "Total training campaigns", primary: null, secondary: null },
  { name: "Active campaigns", primary: null, secondary: null },
  { name: "Active simulation campaigns", primary: null, secondary: null },
  { name: "Active training campaigns", primary: null, secondary: null },
  // { name: "Users across active campaigns (unique)", primary: null, secondary: null },
]



export const users_stats_default_value = [
  { name: "Total users", primary: null, secondary: null },
  // { name: "Users in active campaigns", primary: null, secondary: null },
  // { name: "Users in active simulation campaigns", primary: null, secondary: null },
  // { name: "Users in active training campaigns", primary: null, secondary: null },
  { name: "Users who failed latest simulation", primary: null, secondary: null },
  // { name: "Users who hasn't completed assigned training", primary: null, secondary: null },
]


export const groups_stats_default_value = [
  { name: "Total groups", primary: null, secondary: null },
]


export const campaign_common_stats_default_value = [
  { name: "Users in campaign / # programs", primary: null, secondary: null },
  { name: "Initial email sent", primary: null, secondary: null },
  { name: "Initial email delivered", primary: null, secondary: null },
  { name: "Initial email bounced", primary: null, secondary: null },
  { name: "Active users", primary: null, secondary: null },
]


export const simulation_stats_default_value = [
  { name: "Users in campaign / # programs", primary: null, secondary: null },
  { name: "Initial email sent", primary: null, secondary: null },
  { name: "Initial email delivered", primary: null, secondary: null },
  { name: "Initial email bounced", primary: null, secondary: null },
  { name: "Active users", primary: null, secondary: null },
  { name: "Failed users", primary: null, secondary: null },
  { name: "Reported users", primary: null, secondary: null },
  { name: "Users opened email", primary: null, secondary: null },
  { name: "Users opened link", primary: null, secondary: null },
  { name: "Users replied to email", primary: null, secondary: null },
  { name: "Debrief training active users", primary: null, secondary: null },
  { name: "Debrief training completed", primary: null, secondary: null },    
]


export const training_stats_default_value = [
  { name: "Users in campaign / # programs", primary: null, secondary: null },
  { name: "Initial email sent", primary: null, secondary: null },
  { name: "Initial email delivered", primary: null, secondary: null },
  { name: "Initial email bounced", primary: null, secondary: null },
  { name: "Active users", primary: null, secondary: null },
  { name: "Training completed", primary: null, secondary: null },
]
