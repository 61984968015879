import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition, Listbox } from '@headlessui/react'


import { useGlobal, useGlobalUpdate } from '../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../contexts/ProtectedContext'


import ReactLoading from "react-loading"


import { useNavigate } from "react-router-dom";
import { auth_axios } from '../libs/authWeb';
import { show_notification, classNames } from '../libs/helpers'


import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { XMarkIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { filter_type_display_map, get_user_property_filters, user_property_map } from '../libs/formats'
import { user_property_options } from '../libs/options'






const AddFilterModal = ({
  is_open,
  set_is_open,
  filters,
  set_filters
} : {
  is_open : boolean
  set_is_open : any // todo
  filters : any // todo
  set_filters : any // todo
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // User input
  const [property, set_property] = useState("most_recent_x_simulations_failed")
  const [filter_type, set_filter_type] = useState("gte_number")
  const [filter_value, set_filter_value] = useState("")

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [users_are_deleted, set_users_are_deleted] = useState(false)

  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "property": {
        set_property(value)

        set_filter_type(Object.keys(get_user_property_filters(value))[0])
        set_filter_value("")
  
        // Always break
        break
      }
      case "filter_type": {
        set_filter_type(value)

        set_filter_value("")
  
        // Always break
        break
      }
      case "filter_value": {
        set_filter_value(value)
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    // set_error_message("")
  }

  const submit = async () => {
    
    // Reset error message
    set_error_message("")

    // Set is_fetched to false
    set_is_awaiting(true)

    // START OF USER INPUT CHECK


    // END OF USER INPUT CHECK

    set_filters([...filters, `{{${property}}}:{{${filter_type}}}:{{${filter_value}}}`])

    // Initialize variables
    set_property("most_recent_x_simulations_failed")
    set_filter_type("gte_number")
    set_filter_value("")
    
    // Close the modal
    set_is_open(false)
  }

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <Transition.Root show={is_open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={set_is_open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">

                
                {/* Dismiss button */}
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <div
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                    onClick={() => set_is_open(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                 
                {/* Modal body */}
                <div>
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-blue-500">
                        Add filter
                      </h1>
                      <p className="mt-2 text-sm text-gray-700">
                        Add a filter
                      </p>
                    </div>
                  </div>

                  {/* Top row */}
                  <div className="mt-2 flex items-center space-x-4">
                    {/* Description text */}
                    <div className="text-sm font-medium">
                      User property:
                    </div>

                    {/* Property */}
                    <Listbox value={property} onChange={(e) => handle_user_input("property", e)}>
                      {({ open }) => (
                        <>
                          <div className="relative w-1/2">
                            <Listbox.Button 
                              className="relative w-full cursor-default rounded-md px-2.5 py-1 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            >
                              <span className="block truncate">{user_property_map[property]}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {user_property_options.map((property_option) => (
                                  <Listbox.Option
                                    key={property_option}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                      )
                                    }
                                    value={property_option}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                          {user_property_map[property_option]}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-blue-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  {/* Bottom row */}
                  <div className="mt-2 flex items-center space-x-4 justify-between">

                      {/* Left side */}
                    <div className="flex flex-auto items-center space-x-4">
                      {/* Description text */}
                      <div className="text-sm font-medium">
                        Value:
                      </div>

                      {/* Filter type */}
                      <Listbox value={filter_type} onChange={(e) => handle_user_input("filter_type", e)}>
                        {({ open }) => (
                          <>
                            <div className="relative w-5/6">
                              <Listbox.Button 
                                className="relative w-full cursor-default rounded-md px-2.5 py-1 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                              >
                                <span className="block truncate">{filter_type_display_map[filter_type]}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {Object.keys(get_user_property_filters(property)).map((filter_option) => (
                                    <Listbox.Option
                                      key={filter_option}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                          'relative cursor-default select-none py-2 pl-3 pr-9'
                                        )
                                      }
                                      value={filter_option}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                            {filter_type_display_map[filter_option]}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active ? 'text-white' : 'text-blue-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>

                      {/* Filter value */}
                      {(() => {
                        switch(filter_type) {
                          case "includes_text": {
                            return <div className="relative rounded-md shadow-sm">
                              <div
                                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                aria-hidden="true"
                              >
                                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                              </div>
                              <input
                                type="text"
                                className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                                placeholder="Text"
                                value={filter_value}
                                onChange={(e) => handle_user_input("filter_value", e.target.value)}
                              />
                            </div>
                          }
                          case "equals_text": {
                            return <div className="relative rounded-md shadow-sm">
                              <div
                                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                aria-hidden="true"
                              >
                                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                              </div>
                              <input
                                type="text"
                                className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                                placeholder="Text"
                                value={filter_value}
                                onChange={(e) => handle_user_input("filter_value", e.target.value)}
                              />
                            </div>
                          }
                          case "equals_option": {
                            return <Listbox value={filter_value} onChange={(e) => handle_user_input("filter_value", e)}>
                              {({ open }) => (
                                <>
                                  <div className="relative w-1/2">
                                    <Listbox.Button 
                                      className="relative w-full cursor-default rounded-md px-2.5 py-1 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    >
                                      <span className="block truncate">{filter_value === "" ? "Select" : get_user_property_filters(property)[filter_type].format(filter_value)}</span>
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                      </span>
                                    </Listbox.Button>
                    
                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {get_user_property_filters(property)[filter_type].options.map((option) => (
                                          <Listbox.Option
                                            key={option}
                                            className={({ active }) =>
                                              classNames(
                                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                              )
                                            }
                                            value={option}
                                          >
                                            {({ selected, active }) => (
                                              <>
                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                  {get_user_property_filters(property)[filter_type].format(option)}
                                                </span>
                    
                                                {selected ? (
                                                  <span
                                                    className={classNames(
                                                      active ? 'text-white' : 'text-blue-600',
                                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                                    )}
                                                  >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          }
                          case "before_date": {
                            return <div className="relative mt-1 rounded-md shadow-sm">
                              <input
                                type="date"
                                required
                                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                value={filter_value}
                                onChange={(e) => handle_user_input("filter_value", e.target.value)}
                              />
                            </div>
                          }
                          case "after_date": {
                            return <div className="relative mt-1 rounded-md shadow-sm">
                              <input
                                type="date"
                                required
                                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                value={filter_value}
                                onChange={(e) => handle_user_input("filter_value", e.target.value)}
                              />
                            </div>
                          }
                          case "equals_number": {
                            return <div className="relative rounded-md shadow-sm">
                              <input
                                type="number"
                                required
                                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                value={filter_value}
                                onChange={(e) => handle_user_input("filter_value", e.target.value)}
                              />
                            </div>
                          }
                          case "lt_number": {
                            return <div className="relative rounded-md shadow-sm">
                              <input
                                type="number"
                                required
                                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                value={filter_value}
                                onChange={(e) => handle_user_input("filter_value", e.target.value)}
                              />
                            </div>
                          }
                          case "gt_number": {
                            return <div className="relative rounded-md shadow-sm">
                              <input
                                type="number"
                                required
                                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                value={filter_value}
                                onChange={(e) => handle_user_input("filter_value", e.target.value)}
                              />
                            </div>
                          }
                          case "lte_number": {
                            return <div className="relative rounded-md shadow-sm">
                              <input
                                type="number"
                                required
                                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                value={filter_value}
                                onChange={(e) => handle_user_input("filter_value", e.target.value)}
                              />
                            </div>
                          }
                          case "gte_number": {
                            return <div className="relative rounded-md shadow-sm">
                              <input
                                type="number"
                                required
                                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                value={filter_value}
                                onChange={(e) => handle_user_input("filter_value", e.target.value)}
                              />
                            </div>
                          }
                          default: {
                            return <></>
                          }
                        }
                      })()}
                    </div>

                    <div className="flex">
                      {/* Submit button */}
                      <button 
                        className={classNames(filter_value === "" ? "cursor-default bg-blue-500" : "bg-blue-600", "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")}
                        onClick={submit}
                        disabled={filter_value === ""}
                      >
                        Add filter
                      </button>
                    </div>
                    
                    
                  </div>


                  

                </div>

              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddFilterModal;
