
import { useEffect, useState, Fragment } from 'react'

import ReactLoading from "react-loading"

import { useGlobal, useGlobalUpdate } from '../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../contexts/ProtectedContext'

import { useLocation, useNavigate, useParams } from "react-router-dom"
import { MagnifyingGlassIcon, InformationCircleIcon, ChevronDownIcon, ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'

import { Tab, Transition, Listbox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'


import { auth_axios } from '../../../../libs/authWeb';
import { mixpanel_client_track } from '../../../../libs/mixpanelClient';
import { program_filters_filter_fn, program_search_filter_fn } from '../../../../libs/searchFilterFunctions'
import { campaign_status_map, campaign_type_map, filter_type_display_map, get_program_property_filters, get_program_property_value, program_property_map, program_status_map, simulation_objective_map, simulation_type_map, training_module_map, user_property_map } from '../../../../libs/formats'
import { get_prompt_text, show_notification, classNames, filter_regex, timestamp_to_local_datetime } from '../../../../libs/helpers'
import { program_property_options } from '../../../../libs/options'
import { campaign_common_stats_default_value } from '../../../../libs/stats'


import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import ArchiveCampaignsModal from '../ArchiveCampaignsModal'
import DeleteCampaignsModal from '../DeleteCampaignsModal'
import TerminateCampaignsModal from '../TerminateCampaignsModal'

import ArchiveProgramsModal from '../../programs/ArchiveProgramsModal'
import DeleteProgramsModal from '../../programs/DeleteProgramsModal'
import TerminateProgramsModal from '../../programs/TerminateProgramsModal'




const Program = ({
  program,
  program_checked_map,
  set_program_checked_map,
} : {
  program : any
  program_checked_map : any
  set_program_checked_map : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr 
      className={classNames(program_checked_map[program.program_id] ? 'bg-gray-50' : "", "hover:bg-gray-100 cursor-pointer")}
      onClick={() => navigate(`/dashboard/programs/${program.program_id}`)}
    >
      {/* Checkbox */}
      <td className="relative px-7 sm:w-12 sm:px-6">
        {program_checked_map[program.program_id] && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          checked={program_checked_map[program.program_id]}
          onChange={(e) =>
            set_program_checked_map({
            ...program_checked_map,
            [program.program_id]: e.target.checked
          })}
          onClick={(e) => e.stopPropagation()}
        />
      </td>

      {/* Program properties */}
      {program_property_options.map((property, index) => <td
        key={index}
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm',
          index === 0 ? (program_checked_map[program.program_id] ? 'text-blue-600 font-medium' : 'text-gray-900 font-medium') : "text-gray-500"
        )}
      >
        {get_program_property_value(program, property, true)}
      </td>)}


      {/* <td
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm font-semibold',
          program_checked_map[program.program_id] ? 'text-blue-600' : 'text-gray-900'
        )}
      >
        <span className="cursor-pointer" onClick={() => navigate(`/dashboard/programs/${program.program_id}`)}>
          {program.user_metadata.first_name === "UNSET" || program.user_metadata.last_name === "UNSET" ? "(Unspecified)" : `${program.user_metadata.first_name} ${program.user_metadata.last_name}`}
        </span>
      </td>
      <td 
        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
      >
        <span className="cursor-pointer" onClick={() => navigate(`/dashboard/programs/${program.program_id}`)}>
          {program.email_address}
        </span>
      </td>
      <td 
        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
      >
        <span className="cursor-pointer" onClick={() => navigate(`/dashboard/programs/${program.program_id}`)}>
          {program.program_metadata.name === "UNSET" ? "(Unspecified)" : `${program.program_metadata.name}`}
        </span>
      </td>
      <td 
        className={classNames((() => {
          switch (program.status) {
            case "draft": {
              return "text-yellow-500"
            }
            case "active": {
              return "text-blue-500"
            }
            case "reported": {
              return "text-green-500"
            }
            case "failed": {
              return "text-red-500"
            }
            case "terminated": {
              return "text-gray-500"
            }
            default: {
              return "text-gray-500"
            }
          }
        })(), "whitespace-nowrap px-3 py-4 text-sm")}
      >
        {program_status_map[program.status]}
      </td> */}
    </tr>
  )
}


const Filter = ({
  property,
  filters,
  set_filters,
  set_current_filter
} : {
  property : any
  filters : any
  set_filters : any
  set_current_filter : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // States
  const [filter_type, set_filter_type] = useState(Object.keys(get_program_property_filters(property))[0])
  const [filter_value, set_filter_value] = useState("")




  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "filter_type": {
        set_filter_type(value)

        set_filter_value("")
  
        // Always break
        break
      }
      case "filter_value": {
        set_filter_value(value)
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    // set_error_message("")
  }


  const submit_filter_value = () => {
    set_filters([...filters, `{{${property}}}:{{${filter_type}}}:{{${filter_value}}}`])
    set_filter_value("")
    set_current_filter("")
  }

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <div className="absolute top-full left-4 w-72 bg-white px-4 py-4 rounded-md font-normal shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 space-y-4">
      
      {/* Filter type */}
      <div className="font-normal flex items-center space-x-2">
        <div>{`${program_property_map[property]} `}</div>
        <Listbox value={filter_type} onChange={(e) => handle_user_input("filter_type", e)}>
          {({ open }) => (
            <>
              <div className="relative w-1/2">
                <Listbox.Button 
                  className="relative w-full cursor-default rounded-md px-2.5 py-1 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{filter_type_display_map[filter_type]}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {Object.keys(get_program_property_filters(property)).map((filter_option) => (
                      <Listbox.Option
                        key={filter_option}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={filter_option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {filter_type_display_map[filter_option]}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-blue-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        <div>{`:`}</div>
      </div>

      {/* Filter value */}
      {(() => {
        switch(filter_type) {
          case "includes_text": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <div
                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                aria-hidden="true"
              >
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                placeholder="Text"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "equals_text": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <div
                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                aria-hidden="true"
              >
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                placeholder="Text"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "equals_option": {
            return <Listbox value={filter_value} onChange={(e) => handle_user_input("filter_value", e)}>
              {({ open }) => (
                <>
                  <div className="relative w-1/2">
                    <Listbox.Button 
                      className="relative w-full cursor-default rounded-md px-2.5 py-1 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span className="block truncate">{filter_value === "" ? "Select" : get_program_property_filters(property)[filter_type].format(filter_value)}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>
    
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {get_program_property_filters(property)[filter_type].options.map((option) => (
                          <Listbox.Option
                            key={option}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                  {get_program_property_filters(property)[filter_type].format(option)}
                                </span>
    
                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-blue-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          }
          case "before_date": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="date"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "after_date": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="date"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          default: {
            return <></>
          }
        }
      })()}

      {/* Submit */}
      <div className="flex justify-end">
        <button
          onClick={submit_filter_value}
          className={classNames(filter_value === "" ? "cursor-default bg-blue-500" : "bg-blue-600", "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")}
          disabled={filter_value === ""}
        >
          Add filter
        </button>
      </div>
    </div>
  )
}



const CampaignsCampaignIdPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Use params
  const { campaign_id } = useParams()  

  // States
  const [campaign, set_campaign] = useState({})
  const [programs, set_programs] = useState([])
  const [stats, set_stats] = useState(campaign_common_stats_default_value)
  
  const [active_program_checked_map, set_active_program_checked_map] = useState({})
  const [inactive_program_checked_map, set_inactive_program_checked_map] = useState({})

  const [campaign_and_programs_are_fetched, set_campaign_and_programs_are_fetched] = useState(false)
  
  
  // Current
  const [current_tab, set_current_tab] = useState("active")
  const [current_filter, set_current_filter] = useState("")

  // Refresh variable
  const [refresh_variable, set_refresh_variable] = useState(Date.now())

  // Search & filters
  const [search_text, set_search_text] = useState("")
  const [filters, set_filters] = useState([])

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)

  // Preview states
  const [is_preview, set_is_preview] = useState(false)

  // Modal
  const [deactivate_programs_modal_is_open, set_deactivate_programs_modal_is_open] = useState(false)
  const [delete_programs_modal_is_open, set_delete_programs_modal_is_open] = useState(false)
  const [terminate_programs_modal_is_open, set_terminate_programs_modal_is_open] = useState(false)

  const [deactivate_campaigns_modal_is_open, set_deactivate_campaigns_modal_is_open] = useState(false)
  const [delete_campaigns_modal_is_open, set_delete_campaigns_modal_is_open] = useState(false)
  const [terminate_campaigns_modal_is_open, set_terminate_campaigns_modal_is_open] = useState(false)


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "search_text": {
        set_search_text(value)

        // Always break
        break
      }
    }
  }

  const get_campaign_and_programs = async () => {
    // Set is_fetched to false
    set_campaign_and_programs_are_fetched(false)

    // Execute get campaign data
    const get_campaign_res = await auth_axios.get(`/api/campaigns/${campaign_id}`)

    if (!get_campaign_res.data.success) {
      switch (get_campaign_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // If campaign is active and still a draft, redirect to edit page
    if (get_campaign_res.data.campaign.active && get_campaign_res.data.campaign.status === "draft") {
      // alert("Campaign is still a draft")
      show_notification(protected_context, protected_update, "error", "Error", "Campaign is still a draft")
      navigate(`/dashboard/campaigns/${campaign_id}/edit`)
      return
    }

    // Execute get programs
    const get_campaign_programs_res = await auth_axios.get(`/api/campaigns/${campaign_id}/programs`)

    if (!get_campaign_programs_res.data.success) {
      switch (get_campaign_programs_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // If campaign is single send simulation, show preview upon load
    if (get_campaign_res.data.campaign.campaign_metadata.type === "simulation" && get_campaign_res.data.campaign.campaign_metadata.simulation_type === "single_send") {
      set_is_preview(true)
    }






    // Set states
    set_campaign(get_campaign_res.data.campaign)
    set_programs(get_campaign_programs_res.data.programs)
    set_stats(get_campaign_programs_res.data.stats)

    set_active_program_checked_map(get_campaign_programs_res.data.programs.filter(program => program.active).reduce((acc, program) => ({...acc, [program.program_id]: false}), {}))
    set_inactive_program_checked_map(get_campaign_programs_res.data.programs.filter(program => !program.active).reduce((acc, program) => ({...acc, [program.program_id]: false}), {}))
    set_campaign_and_programs_are_fetched(true)
    set_current_tab(get_campaign_res.data.campaign.active ? "active" : "inactive")
  }

  const toggle_active_programs = (e) => {
    const filtered_program_ids_set = new Set(programs
      // Filter by search
      .filter(program => program_search_filter_fn(program, search_text))
      // Filter by filters
      .filter(program => program_filters_filter_fn(program, filters))

      // Filter by active
      .filter(program => program.active)
      // Map by user_id
      .map(program => program.program_id)
    ) 
    set_active_program_checked_map(Object.keys(active_program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: filtered_program_ids_set.has(program_id) ? e.target.checked : active_program_checked_map[program_id] }), {}))

    // set_active_program_checked_map(Object.keys(active_program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: e.target.checked }), {}))
  }

  const toggle_inactive_programs = (e) => {
    const filtered_program_ids_set = new Set(programs
      // Filter by search
      .filter(program => program_search_filter_fn(program, search_text))
      // Filter by filters
      .filter(program => program_filters_filter_fn(program, filters))

      // Filter by active
      .filter(program => !program.active)
      // Map by user_id
      .map(program => program.program_id)
    ) 
    set_inactive_program_checked_map(Object.keys(inactive_program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: filtered_program_ids_set.has(program_id) ? e.target.checked : inactive_program_checked_map[program_id] }), {}))

    // set_inactive_program_checked_map(Object.keys(inactive_program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: e.target.checked }), {}))
  }

  const uncheck_all_active_programs = () => {
    set_active_program_checked_map(Object.keys(active_program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: false }), {}))
  }

  const uncheck_all_inactive_programs = () => {
    set_inactive_program_checked_map(Object.keys(inactive_program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: false }), {}))
  }

  const export_campaign_data_to_csv = async () => {

    // Validation

    if ((() => {
      switch(current_tab) {
        case "active": {
          return programs.filter(program => program.active).length === 0
        }
        case "inactive": {
          return programs.filter(program => !program.active).length === 0
        }
        default: {
          return false
        }
      }
    })()) {
      show_notification(protected_context, protected_update, "error", "Error", "There isn't any data to export")
      // End of the line
      return
    }
    
    // Execute get csv
    const get_campaign_csv_res = await auth_axios.get(`/api/campaigns/${campaign_id}/csv/${current_tab}`)

    if (get_campaign_csv_res.data === "FATAL_ERROR") {
      alert("fatal error")
      return
    }

    // Download file
    const blob = new Blob([get_campaign_csv_res.data], { type: 'text/csv' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = 'export.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Renders  
  // render upon load & upon action_fetch_start_index change
  useEffect(() => {

    get_campaign_and_programs()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_campaigns_campaign_id_visited", global_context.user_id)

  }, [refresh_variable])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">

      {/* Header */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Campaign information
          </h1>
          <div className="mt-2 text-sm text-gray-700">
            {campaign_and_programs_are_fetched
            ? campaign["campaign_metadata"]["name"]
            : <ReactLoading
              type='spokes'
              color='#000'
              height={20}
              width={20}
            />}
          </div>
        </div>
        {/* Export data button */}
        <button
          type="button"
          className={classNames(!campaign_and_programs_are_fetched ? "bg-blue-500" : "bg-blue-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")} 
          onClick={export_campaign_data_to_csv}
          disabled={!campaign_and_programs_are_fetched}
        >
          {(() => {
            switch(current_tab) {
              case "active": {
                return "Export campaign data to CSV"
              }
              case "inactive": {
                return "Export archived campaign data to CSV"
              }
              default: {
                return ""
              }
            }
          })()}
        </button>
      </div>

      {/* Data */}
      <div className="mt-8 flow-root">

        {/* Analytics area */}
        <div className="mt-0">
          {/* <h3 className="text-sm font-medium leading-6 text-gray-900">Campaign statistics</h3> */}
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {campaign_and_programs_are_fetched
            ? stats.map((item) => (
              <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="text-3xl font-semibold tracking-tight text-gray-900">
                    {item.primary}
                  </div>
                  <div className="bg-gray-500 text-white inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                    {item.secondary}
                  </div>
                </dd>
              </div>
            ))
            : stats.map((item) => (
              <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="text-3xl font-semibold tracking-tight text-gray-900">
                    <ReactLoading
                      type='spokes'
                      color='#000'
                      height={20}
                      width={20}
                    />
                  </div>
                  <div className="bg-gray-500 text-white inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                    <ReactLoading
                      type='spokes'
                      color='#ffffff'
                      height={12}
                      width={12}
                    />
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* Tabs */}
        <div className="mt-8">
          <div className="border-b border-gray-200 flex justify-between items-center">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {/* Active tab */}
              <div
                className={classNames(
                  current_tab === "active"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_inactive_programs(); set_current_tab("active") }}
              >
                Programs
                <span
                  className={classNames(
                    current_tab === "active" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {programs.filter(program => program.active).length}
                </span>
              </div>

              {/* Inactive tab */}
              <div
                className={classNames(
                  current_tab === "inactive"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_active_programs(); set_current_tab("inactive") }}
              >
                Archived programs
                <span
                  className={classNames(
                    current_tab === "inactive" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {programs.filter(program => !program.active).length}
                </span>
              </div>
            </nav>
          </div>
        </div>

        {/* Table area */}
        <div className="mt-2">
          {/* Top row - bulk actions and search field */}
          <div className="flex justify-between items-center px-2 py-2 space-x-4">
            {/* Number selected and bulk action buttons */}
            <div>
              {Object.values(active_program_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-semibold" >{`${Object.values(active_program_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_terminate_programs_modal_is_open(true)}
                >
                  Terminate selected
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_deactivate_programs_modal_is_open(true)}
                >
                  Archive selected
                </button>
              </div>
              : <></>}
              {Object.values(inactive_program_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-semibold" >{`${Object.values(inactive_program_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_delete_programs_modal_is_open(true)}
                >
                  Delete selected
                </button>
              </div>
              : <></>}
            </div>
            
            {/* Search field */}
            <div className="w-64">
              <div className="relative mt-1 rounded-md shadow-sm">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  value={search_text}
                  onChange={(e) => handle_user_input("search_text", e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Bottom row (hidden) - active filters */}
          {filters.length > 0
          ? <div className="bg-gray-50 rounded-md">
            <div className="mx-auto max-w-7xl px-4 py-2 my-2 sm:flex sm:items-center sm:px-6 lg:px-8">
              <h3 className="text-sm font-medium text-gray-500">
                Filters
                <span className="sr-only">, active</span>
              </h3>

              <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

              <div className="mt-2 sm:ml-4 sm:mt-0">
                <div className="-m-1 flex flex-wrap items-center">
                  {filters.map((filter) => (
                    <span
                      key={filter}
                      className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                    >
                      <span>{`'${program_property_map[filter.match(filter_regex)[1]]}' ${filter_type_display_map[filter.match(filter_regex)[2]]} '${filter.match(filter_regex)[3]}'`}</span>
                      <button
                        type="button"
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                        onClick={() => set_filters(filters.filter(elm => elm !== filter))}
                      >
                        <span className="sr-only">Remove filter for {filter}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          : <></>}

          {/* Table */}
          <div className="overflow-x-auto resize">
            <div className="inline-block min-w-full align-middle h-[500px]">
              <div className="relative">
                
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="h-14">
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                          checked={(() => {
                            const filtered_programs = programs
                              // Filter by search
                              .filter(program => program_search_filter_fn(program, search_text))
                              // Filter by filters
                              .filter(program => program_filters_filter_fn(program, filters))

                            switch(current_tab) {
                              case "active": {
                                return filtered_programs.filter(program => program.active).length > 0 && filtered_programs
                                  // Filter by active
                                  .filter(program => program.active)
                                  // Map by is_checked
                                  .map(program => active_program_checked_map[program.program_id])
                                  // Check if every is true
                                  .every(is_checked => is_checked)

                                // return Object.values(active_program_checked_map).length > 0 && Object.values(active_program_checked_map).every(is_checked => is_checked)
                              }
                              case "inactive": {
                                return filtered_programs.filter(program => !program.active).length > 0 && filtered_programs
                                  // Filter by active
                                  .filter(program => !program.active)
                                  // Map by is_checked
                                  .map(program => inactive_program_checked_map[program.program_id])
                                  // Check if every is true
                                  .every(is_checked => is_checked)
                                  
                                // return Object.values(inactive_program_checked_map).length > 0 && Object.values(inactive_program_checked_map).every(is_checked => is_checked)
                              }
                              default: {
                                return
                              }
                            }
                          })()}
                          onChange={(() => {
                            switch(current_tab) {
                              case "active": {
                                return toggle_active_programs
                              }
                              case "inactive": {
                                return toggle_inactive_programs
                              }
                              default: {
                                return
                              }
                            }
                          })()}
                        />
                      </th>

                      {/* Columns */}
                      {program_property_options.map((property, index) => <th 
                        key={index}
                        scope="col" 
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                      >
                        <div className="flex items-center space-x-2">
                          <div className="">{program_property_map[property]}</div>
                          <span className="flex relative items-center">
                            <ChevronDownIcon 
                              className="h-[16px] w-[16px] min-h-[16px] min-w-[16px] cursor-pointer" 
                              onClick={current_filter === property ? () => set_current_filter("") : () => set_current_filter(property)}
                            />
                          </span>
                        </div>
                        {current_filter === property
                        ? <Filter 
                          property={property}
                          filters={filters}
                          set_filters={set_filters}
                          set_current_filter={set_current_filter}
                        />
                        : <></>}
                      </th>)}

                      {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                      >
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                      >
                        Email address
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Program
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Status
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {campaign_and_programs_are_fetched
                    ? programs.filter((() => {
                      switch(current_tab) {
                        case "active": {
                          return program => program.active
                        }
                        case "inactive": {
                          return program => !program.active
                        }
                        default: {
                          return
                        }
                      }
                    })())
                    // Filter by search
                    .filter(program => program_search_filter_fn(program, search_text))
                    // Filter by filters
                    .filter(program => program_filters_filter_fn(program, filters))
                    // Map users
                    .map((program) => (
                      <Program 
                        key={program.program_id} 
                        program={program} 
                        program_checked_map={(() => {
                          switch(current_tab) {
                            case "active": {
                              return active_program_checked_map
                            }
                            case "inactive": {
                              return inactive_program_checked_map
                            }
                            default: {
                              return
                            }
                          }
                        })()}
                        set_program_checked_map={(() => {
                          switch(current_tab) {
                            case "active": {
                              return set_active_program_checked_map
                            }
                            case "inactive": {
                              return set_inactive_program_checked_map
                            }
                            default: {
                              return
                            }
                          }
                        })()}
                      />
                    ))
                    : <tr>
                      <td className="py-4 px-4">
                        <ReactLoading
                          type='spokes'
                          color='#000'
                          height={20}
                          width={20}
                        />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Header */}
        <h1 className="mt-12 text-base font-semibold leading-6 text-gray-900">
          Campaign details
        </h1>

        {/* Campaign information */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm">

          

          {/* Campaign status */}
          <div className="pt-6 sm:flex">
            <dt className="font-semibold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Status</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {campaign_and_programs_are_fetched ? (
                  <span>{campaign_status_map[campaign["status"]]}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
              {campaign["active"] && campaign["status"] === "active"
              ? <div className="font-semibold text-red-500 hover:text-red-400 cursor-pointer" onClick={() => set_terminate_campaigns_modal_is_open(true)}>
                Terminate campaign
              </div>
              : <></>}
            </dd>
          </div>

          {/* Campaign type */}
          <div className="pt-6 sm:flex">
            <dt className="font-semibold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Campaign type</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {campaign_and_programs_are_fetched ? (
                  <span>{campaign_type_map[campaign["campaign_metadata"]["type"]]}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Initial delivery window */}
          <div className="pt-6 sm:flex">
            <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
              <span className="font-semibold">Initial delivery window</span>
              <span className="flex relative items-center">
                <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                  Window during which the initial message to the user is sent.
                </span>
              </span>
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {campaign_and_programs_are_fetched ? (
                  <span>
                    {(() => {
                      switch(campaign["campaign_metadata"]["delivery_type"]) {
                        case "window": {
                          return `Between ${timestamp_to_local_datetime(campaign["campaign_metadata"]["start_time"])[0]} ${timestamp_to_local_datetime(campaign["campaign_metadata"]["start_time"])[1]} and ${timestamp_to_local_datetime(campaign["campaign_metadata"]["end_time"])[0]} ${timestamp_to_local_datetime(campaign["campaign_metadata"]["end_time"])[1]}`
                        }
                        case "same": {
                          return `At ${timestamp_to_local_datetime(campaign["campaign_metadata"]["start_time"])[0]} ${timestamp_to_local_datetime(campaign["campaign_metadata"]["start_time"])[1]}`
                        }
                        default: {
                          return ``
                        }
                      }
                    })()}
                  </span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* Number of users in the campaign */}
          <div className="pt-6 sm:flex">
            <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
              <span className="font-semibold">Number of users in the campaign</span>
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {campaign_and_programs_are_fetched ? (
                  <span>{campaign["campaign_metadata"]["user_ids"].length}</span>
                ) : (
                <ReactLoading
                  type='spokes'
                  color='#343D46'
                  height={20}
                  width={20}
                />)}
              </div>
            </dd>
          </div>

          {/* SIMULATION INFO */}
          {campaign_and_programs_are_fetched && ["simulation"].includes(campaign["campaign_metadata"]["type"])
          ? <>

            {/* Simulation type */}
            <div className="pt-6 sm:flex">
              <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                <span className="font-semibold">Simulation type</span>
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {campaign_and_programs_are_fetched ? (
                    <span>{simulation_type_map[campaign["campaign_metadata"]["simulation_type"]]}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
              </dd>
            </div>

            {/* Simulation objective */}
            <div className="pt-6 sm:flex">
              <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                <span className="font-semibold">Simulation objective</span>
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {campaign_and_programs_are_fetched ? (
                    <span>{simulation_objective_map[campaign["campaign_metadata"]["simulation_objective"]]}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
              </dd>
            </div>

            {/* Simulation user properties  */}
            <div className="pt-6 sm:flex">
              <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                <span className="font-semibold">User properties included in the simulation</span>
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {campaign_and_programs_are_fetched ? (
                    <span>{campaign["campaign_metadata"]["simulation_user_properties"].length > 0 ? campaign["campaign_metadata"]["simulation_user_properties"].map(property => user_property_map[property]).join(", ") : "(Not selected)"}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
              </dd>
            </div>

            {/* Simulation failure training enabled */}
            <div className="pt-6 sm:flex">
              <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                <span className="font-semibold">Failed users must complete personalized post-simulation training module</span>
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {campaign_and_programs_are_fetched ? (
                    <span>{campaign["campaign_metadata"]["simulation_failure_training_is_enabled"] ? "Yes" : "No"}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
              </dd>
            </div>

            {/* Simulation failure redirect is enabled */}
            <div className="pt-6 sm:flex">
              <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                <span className="font-semibold">Redirect failed users to a custom URL</span>
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {campaign_and_programs_are_fetched ? (
                    <span>{campaign["campaign_metadata"]["simulation_failure_redirect_is_enabled"] ? "Yes" : "No"}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
              </dd>
            </div>

            {/* Simulation failure redirect URL */}
            {campaign_and_programs_are_fetched && campaign["campaign_metadata"]["simulation_failure_redirect_is_enabled"]
            ? <div className="pt-6 sm:flex">
              <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                <span className="font-semibold">Simulation failure redirect URL</span>
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {campaign_and_programs_are_fetched ? (
                    <span>{campaign["campaign_metadata"]["simulation_failure_redirect_url"]}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
              </dd>
            </div>
            : <></>}

            {/* Simulation objective dependent fields */}
            {(() => {
              switch(campaign["campaign_metadata"]["simulation_objective"]) {
                case "open_link": {
                  return <>

                    {/* Simulation email address */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">From email address</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{`${campaign["campaign_metadata"]["simulation_email_prefix"]}@${campaign["campaign_metadata"]["simulation_domain"]}`}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation display name */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">From display name</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_display_name"]}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation subject line  */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">Email subject line</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_subject_line"]}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                  </>
                }
                case "open_link_submit_website": {
                  return <>


                    {/* Simulation email address */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">From email address</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{`${campaign["campaign_metadata"]["simulation_email_prefix"]}@${campaign["campaign_metadata"]["simulation_domain"]}`}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation display name */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">From display name</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_display_name"]}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation subject line  */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">Email subject line</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_subject_line"]}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation website URL  */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">Simulation website URL</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_website_url"]}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation website open tracking is enabled  */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">Track whther the simulation website is opened</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_website_open_link_tracking_is_enabled"] ? "Yes" : "No"}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation submit website capture is enabled  */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">Capture the submitted info on the website</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_website_submit_website_capture_is_enabled"] ? "Yes" : "No"}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>



                  </>
                }
                case "open_attachment": {
                  return <>

                    {/* Simulation email address */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">From email address</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{`${campaign["campaign_metadata"]["simulation_email_prefix"]}@${campaign["campaign_metadata"]["simulation_domain"]}`}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation display name */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">From display name</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_display_name"]}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation subject line  */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">Email subject line</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_subject_line"]}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation attachment */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">Attachment</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{`${campaign["campaign_metadata"]["simulation_attachment_name"]}.${campaign["campaign_metadata"]["simulation_attachment_extension"]}`}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                  </>
                }
                case "reply_info": {
                  return <>

                    {/* Simulation email address */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">From email address</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{`${campaign["campaign_metadata"]["simulation_email_prefix"]}@${campaign["campaign_metadata"]["simulation_domain"]}`}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation display name */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">From display name</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_display_name"]}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation subject line  */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">Email subject line</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_subject_line"]}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                    {/* Simulation reply info objective */}
                    <div className="pt-6 sm:flex">
                      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                        <span className="font-semibold">Description of data to try to elicit from the user</span>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {campaign_and_programs_are_fetched ? (
                            <span>{campaign["campaign_metadata"]["simulation_reply_info_objective"]}</span>
                          ) : (
                          <ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />)}
                        </div>
                      </dd>
                    </div>

                  </>
                }
                default: {
                  return
                }
              }
            })()}


            

            

            {/* SINGLE SEND */}
            {["single_send"].includes(campaign["campaign_metadata"]["simulation_type"])
            ? <div className="pt-6">

              {/* Custom HTML and preview */}
              <Tab.Group onChange={(index) => { if (index === 1) set_is_preview(true); else set_is_preview(false); }} selectedIndex={is_preview ? 1 : 0}>
                <label className="block text-sm leading-6 text-gray-900 flex">
                  <span className="font-semibold">Single-send email HTML</span>
                </label>
                <Tab.List className="flex items-center mt-6">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                          : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                        'rounded-md border border-transparent px-3 py-1.5 text-sm font-semibold'
                      )
                    }
                  >
                    Email HTML
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                          : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                        'ml-2 rounded-md border border-transparent px-3 py-1.5 text-sm font-semibold'
                      )
                    }
                  >
                    Email preview
                  </Tab>
                </Tab.List>
                <Tab.Panels className="mt-6">
                  <Tab.Panel className="-m-0.5 rounded-lg p-0.5 ">
                    <CodeMirror 
                      value={campaign["campaign_metadata"]["simulation_single_send_email_html"]} 
                      className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 h-[600px] overflow-auto resize"
                      extensions={[html({})]}
                      editable={false}
                    />
                  </Tab.Panel>
                  <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                    <iframe 
                      id="email_preview"
                      className="block w-full rounded-md border-0 py-2 px-4 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 h-[600px] overflow-auto resize"
                      srcDoc={campaign["campaign_metadata"]["simulation_single_send_email_html"]}
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>

            </div>
            : <></>}

            {/* MULTI MESSAGE */}
            {["multi_message"].includes(campaign["campaign_metadata"]["simulation_type"])
            ? <div className="pt-6">

              {/* Custom prompt and preview */}
              <Tab.Group onChange={(index) => { if (index === 1) set_is_preview(true); else set_is_preview(false); }} selectedIndex={is_preview ? 1 : 0}>
                <label className="block text-sm leading-6 text-gray-900 flex">
                  <span className="font-semibold">Multi-message prompt information</span>
                </label>
                <Tab.List className="flex items-center mt-6">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                          : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                        'rounded-md border border-transparent px-3 py-1.5 text-sm font-semibold'
                      )
                    }
                  >
                    Prompt editor
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                          : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                        'ml-2 rounded-md border border-transparent px-3 py-1.5 text-sm font-semibold'
                      )
                    }
                  >
                    Prompt preview
                  </Tab>
                </Tab.List>
                <Tab.Panels className="mt-6">
                  <Tab.Panel className="-m-0.5 rounded-lg p-0.5 space-y-6">
                    {/* Instructions */}
                    <div>
                      <label className="block text-sm leading-6 text-gray-900 flex">
                        <span className="font-semibold">Instructions for the AI</span>
                      </label>
                      <textarea
                        className={classNames(is_awaiting ? "bg-gray-50" : "", "mt-2 block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 bg-gray-50")}
                        rows={3}
                        value={campaign["campaign_metadata"]["simulation_multi_message_instructions"]}
                        disabled
                      />
                    </div>
                    
                  </Tab.Panel>
                  <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                    <textarea
                      className="mt-2 block w-full rounded-md border-0 py-2 px-4 bg-gray-50 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      rows={24}
                      value={get_prompt_text(campaign, "{{organization_name}}")}
                      disabled
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            
            </div>
            : <></>}

          </>
          : <></>}

          
          {/* TRAINING INPUTS */}
          {campaign_and_programs_are_fetched && ["training"].includes(campaign["campaign_metadata"]["type"])
          ? <>

            {/* Training module */}
            <div className="pt-6 sm:flex">
              <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                <span className="font-semibold">Training module</span>
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {campaign_and_programs_are_fetched ? (
                    <span>{training_module_map[campaign["campaign_metadata"]["training_module"]]}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
              </dd>
            </div>

            {/* Skip completed users enabled */}
            <div className="pt-6 sm:flex">
              <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
                <span className="font-semibold">Skip users who have previously completed this module</span>
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {campaign_and_programs_are_fetched ? (
                    <span>{campaign["campaign_metadata"]["training_skip_completed_users_is_enabled"] ? "Yes" : "No"}</span>
                  ) : (
                  <ReactLoading
                    type='spokes'
                    color='#343D46'
                    height={20}
                    width={20}
                  />)}
                </div>
              </dd>
            </div>


          </>
          : <></>}
          
        </dl>

        {/* Danger zone */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm">
          <div className="pt-6 sm:flex">
            <dt className="text-red-500 sm:w-64 sm:flex-none sm:pr-6 flex">
              <span className="font-semibold">Danger zone</span>
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-red-500">
                {/* TODO */}
              </div>
              {campaign["active"]
              ? <button
                type="button"
                className={classNames(!campaign_and_programs_are_fetched ? "bg-red-500" : "bg-red-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600")} 
                onClick={() => set_deactivate_campaigns_modal_is_open(true)}
                disabled={!campaign_and_programs_are_fetched}
              >
                Archive campaign
              </button>
              : <button
                type="button"
                className={classNames(!campaign_and_programs_are_fetched ? "bg-red-500" : "bg-red-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600")} 
                onClick={() => set_delete_campaigns_modal_is_open(true)}
                disabled={!campaign_and_programs_are_fetched}
              >
                Delete campaign
              </button>}
            </dd>
          </div>
        </dl>
        
      </div>

      {/* Modals */}
      <ArchiveProgramsModal 
        is_open={deactivate_programs_modal_is_open}
        set_is_open={set_deactivate_programs_modal_is_open}
        program_ids={Object.keys(active_program_checked_map).filter(program_id => active_program_checked_map[program_id])}
        set_refresh_variable={set_refresh_variable}
      />
      <DeleteProgramsModal 
        is_open={delete_programs_modal_is_open}
        set_is_open={set_delete_programs_modal_is_open}
        program_ids={Object.keys(inactive_program_checked_map).filter(program_id => inactive_program_checked_map[program_id])}
        set_refresh_variable={set_refresh_variable}
      />
      <TerminateProgramsModal 
        is_open={terminate_programs_modal_is_open}
        set_is_open={set_terminate_programs_modal_is_open}
        program_ids={Object.keys(active_program_checked_map).filter(program_id => active_program_checked_map[program_id])}
        set_refresh_variable={set_refresh_variable}
      />
      <ArchiveCampaignsModal
        is_open={deactivate_campaigns_modal_is_open}
        set_is_open={set_deactivate_campaigns_modal_is_open}
        campaign_ids={[campaign_id]}
        set_refresh_variable={set_refresh_variable}
      />
      <DeleteCampaignsModal
        is_open={delete_campaigns_modal_is_open}
        set_is_open={set_delete_campaigns_modal_is_open}
        campaign_ids={[campaign_id]}
        set_refresh_variable={() => null} // Don't refresh, since the campaign no longer exists
      />
      <TerminateCampaignsModal
        is_open={terminate_campaigns_modal_is_open}
        set_is_open={set_terminate_campaigns_modal_is_open}
        campaign_ids={[campaign_id]}
        set_refresh_variable={set_refresh_variable}
      />

    </div>
  )
}

export default CampaignsCampaignIdPage