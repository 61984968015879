import { IS_DEV } from './env';


// Mixpanel
import mixpanel_client from 'mixpanel-browser' // client
mixpanel_client.init("4fa4f47384d862b09f3465a94c2d158d") // client



// CLIENT
export const mixpanel_client_track = (event_name, user_id) => {
  mixpanel_client.track(event_name, {   
    distinct_id: user_id, // if null, then it'll be an anonymous user
    is_dev: IS_DEV
  });
}