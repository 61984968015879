
import React, { useEffect } from 'react'

import axios from "axios"

import { useNavigate } from "react-router-dom"



const IndexPage = ({
  
} : {
  
}) => {

  // Navigate
  const navigate = useNavigate()
  
  useEffect(() => {
    navigate("/dashboard")
  }, [])

  return (
    <>
    </>
  )
}

export default IndexPage