
import React, { useEffect, useState, Fragment } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../contexts/ProtectedContext'

import { useLocation, useNavigate, useParams } from "react-router-dom"

import { MagnifyingGlassIcon, ArrowLongLeftIcon, InformationCircleIcon, CheckCircleIcon, ExclamationTriangleIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

import { Transition, Listbox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, PlusIcon } from '@heroicons/react/24/outline'

import AddFilterModal from '../../../../../components/AddFilterModal'

import { mixpanel_client_track } from '../../../../../libs/mixpanelClient';
import { auth_axios } from '../../../../../libs/authWeb'
import { provision_type_map, get_user_property_value, filter_type_display_map, get_user_property_filters, user_property_map } from '../../../../../libs/formats'
import { user_filters_filter_fn, user_search_filter_fn } from '../../../../../libs/searchFilterFunctions'
import { arrays_have_same_elements, multi_message_user_count_limit, show_notification, classNames, filter_regex } from '../../../../../libs/helpers'




const User = ({
  user,
  active_user_properties,
  campaign,
  campaign_user_ids_set,
  set_campaign,
} : {
  user : any
  active_user_properties : any
  campaign : any
  campaign_user_ids_set : any
  set_campaign : any
}) => {

  // Navigate
  const navigate = useNavigate()

  const toggle_user = () => {
    set_campaign({
      ...campaign,
      campaign_metadata: {
        ...campaign.campaign_metadata,
        user_ids: !campaign_user_ids_set.has(user.user_id) ? [...campaign.campaign_metadata.user_ids, user.user_id] : campaign.campaign_metadata.user_ids.filter(user_id => user_id !== user.user_id)
      }
    })
  }

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr 
      className={classNames(campaign_user_ids_set.has(user.user_id) ? 'bg-gray-50' : "", "hover:bg-gray-100 cursor-pointer")}
      onClick={toggle_user}
    >
      {/* Checkbox */}
      <td className="relative px-7 sm:w-12 sm:px-6">
        {campaign_user_ids_set.has(user.user_id) && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          checked={campaign_user_ids_set.has(user.user_id)}
          onChange={toggle_user}
        />
      </td>

      {/* User properties */}
      {active_user_properties.map((property, index) => <td
        key={index}
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm',
          index === 0 ? (campaign_user_ids_set.has(user.user_id) ? 'text-blue-600 font-medium' : 'text-gray-900 font-medium') : "text-gray-500"
        )}
      >
        {get_user_property_value(user, property, true)}
      </td>)}
    </tr>
  )
}

const Filter = ({
  property,
  filters,
  set_filters,
  set_current_filter
} : {
  property : any
  filters : any
  set_filters : any
  set_current_filter : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // States
  const [filter_type, set_filter_type] = useState(Object.keys(get_user_property_filters(property))[0])
  const [filter_value, set_filter_value] = useState("")




  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "filter_type": {
        set_filter_type(value)

        set_filter_value("")
  
        // Always break
        break
      }
      case "filter_value": {
        set_filter_value(value)
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    // set_error_message("")
  }


  const submit_filter_value = () => {
    set_filters([...filters, `{{${property}}}:{{${filter_type}}}:{{${filter_value}}}`])
    set_filter_value("")
    set_current_filter("")
  }

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <div className="absolute top-full left-4 w-72 bg-white px-4 py-4 rounded-md font-normal shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 space-y-4">
      
      {/* Filter type */}
      <div className="font-normal flex items-center space-x-2">
        <div>{`${user_property_map[property]} `}</div>
        <Listbox value={filter_type} onChange={(e) => handle_user_input("filter_type", e)}>
          {({ open }) => (
            <>
              <div className="relative w-1/2">
                <Listbox.Button 
                  className="relative w-full cursor-default rounded-md px-2.5 py-1 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{filter_type_display_map[filter_type]}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {Object.keys(get_user_property_filters(property)).map((filter_option) => (
                      <Listbox.Option
                        key={filter_option}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={filter_option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {filter_type_display_map[filter_option]}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-blue-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        <div>{`:`}</div>
      </div>

      {/* Filter value */}
      {(() => {
        switch(filter_type) {
          case "includes_text": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <div
                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                aria-hidden="true"
              >
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                placeholder="Text"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "equals_text": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <div
                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                aria-hidden="true"
              >
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                placeholder="Text"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "equals_option": {
            return <Listbox value={filter_value || "Select"} onChange={(e) => handle_user_input("filter_value", e)}>
              {({ open }) => (
                <>
                  <div className="relative w-1/2">
                    <Listbox.Button 
                      className="relative w-full cursor-default rounded-md px-2.5 py-1 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span className="block truncate">{get_user_property_filters(property)[filter_type].format(filter_value) || "Select"}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>
    
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {get_user_property_filters(property)[filter_type].options.map((option) => (
                          <Listbox.Option
                            key={option}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                  {get_user_property_filters(property)[filter_type].format(option)}
                                </span>
    
                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-blue-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          }
          case "before_date": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="date"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          case "after_date": {
            return <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="date"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={filter_value}
                onChange={(e) => handle_user_input("filter_value", e.target.value)}
              />
            </div>
          }
          default: {
            return <></>
          }
        }
      })()}

      {/* Submit */}
      <div className="flex justify-end">
        <button
          onClick={submit_filter_value}
          className={classNames(!filter_value ? "cursor-default bg-blue-500" : "bg-blue-600", "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")}
          disabled={!filter_value}
        >
          Add filter
        </button>
      </div>
    </div>
  )
}



const Group = ({
  group,
  campaign,
  campaign_user_ids_set,
  set_campaign,
} : {
  group : any
  campaign : any
  campaign_user_ids_set: any
  set_campaign : any
}) => {

  // Navigate
  const navigate = useNavigate()

  const get_campaign_user_ids_includes_group_active_user_ids = () => {
    return group.active_user_ids.every(user_id => campaign_user_ids_set.has(user_id))
  }

  const toggle_group = () => {

    const group_active_user_ids_set = new Set(group.active_user_ids)

    const campaign_user_ids_sans_group_active_user_ids = campaign.campaign_metadata.user_ids.filter(user_id => !group_active_user_ids_set.has(user_id))

    set_campaign({
      ...campaign,
      campaign_metadata: {
        ...campaign.campaign_metadata,
        user_ids: !get_campaign_user_ids_includes_group_active_user_ids() ? [...campaign_user_ids_sans_group_active_user_ids, ...group.active_user_ids] : campaign_user_ids_sans_group_active_user_ids
      }
    })
  }

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr 
      className={classNames(get_campaign_user_ids_includes_group_active_user_ids() ? 'bg-gray-50' : "", "hover:bg-gray-100 cursor-pointer")}
      onClick={toggle_group}
    >
      <td className="relative px-7 sm:w-12 sm:px-6">
        {get_campaign_user_ids_includes_group_active_user_ids() && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          checked={get_campaign_user_ids_includes_group_active_user_ids()}
          onChange={toggle_group}
        />
      </td>


      <td
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm font-medium',
          get_campaign_user_ids_includes_group_active_user_ids() ? 'text-blue-600' : 'text-gray-900'
        )}
      >
        {group.group_metadata.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {group.active_user_ids.length}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {provision_type_map[group.provision_metadata.type]}
      </td>
    </tr>
  )
}


const CampaignsCampaignIdEditPageTwoPage = ({
  is_awaiting,
  set_is_awaiting,

  error_message,
  set_error_message,

  success_message,
  set_success_message,

  campaign,
  set_campaign,

  set_edit_page,
  update_campaign,
} : {
  is_awaiting : any
  set_is_awaiting : any

  error_message : any
  set_error_message : any

  success_message : any
  set_success_message : any

  campaign : any
  set_campaign : any

  set_edit_page : any
  update_campaign : any
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Use params
  const { campaign_id } = useParams()  

  // Org data
  const [organization_data, set_organization_data] = useState({})
  const [organization_data_and_users_and_campaign_are_fetched, set_organization_data_and_users_and_campaign_are_fetched] = useState(false)
  const [users, set_users] = useState([])
  const [groups, set_groups] = useState([])

  const [active_user_properties, set_active_user_properties] = useState([])

  // Current
  const [current_tab, set_current_tab] = useState("users")
  const [current_filter, set_current_filter] = useState("")


  // Search & filters
  const [search_text, set_search_text] = useState("")
  const [filters, set_filters] = useState([])

  // Modal
  const [add_filter_modal_is_open, set_add_filter_modal_is_open] = useState(false)


  const get_organization_data_and_users_and_campaign = async () => {
    // Set is_fetched to false
    set_organization_data_and_users_and_campaign_are_fetched(false)

    // Execute get organization data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get org users data
    const get_org_users_res = await auth_axios.get(`/api/users`)

    if (!get_org_users_res.data.success) {
      switch (get_org_users_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get org users data
    const get_org_groups_res = await auth_axios.get(`/api/groups`)

    if (!get_org_groups_res.data.success) {
      switch (get_org_groups_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get campaign
    const get_campaign_res = await auth_axios.get(`/api/campaigns/${campaign_id}`)

    if (!get_campaign_res.data.success) {
      switch (get_campaign_res.data.status) {
        case "FATAL_ERROR": {
          // alert("Could not find the campaign")
          show_notification(protected_context, protected_update, "error", "Error", "Could not find the campaign")

          // Redirect to dashboard/campaigns page
          navigate(`/dashboard/campaigns`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // If campaign has already been created, redirect to view page
    if (get_campaign_res.data.campaign.status !== "draft") {
      // alert("Campaign has already been created")
      show_notification(protected_context, protected_update, "error", "Error", "Campaign has already been created")
      navigate(`/dashboard/campaigns/${campaign_id}`)
      return
    }

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_users(get_org_users_res.data.users.filter(user => user.active)) // only active users can be part of a campaign
    set_groups(get_org_groups_res.data.groups)
    set_campaign(get_campaign_res.data.campaign)
    
    set_active_user_properties(get_org_res.data.organization_data.organization_metadata.active_user_properties)

    set_organization_data_and_users_and_campaign_are_fetched(true)
  }



  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "search_text": {
        set_search_text(value)

        // Always break
        break
      }
    }
  }


  // Save functions

  const get_user_input_is_valid = () => {

    // If data has not been fetched yet, don't do anything
    if (!organization_data_and_users_and_campaign_are_fetched) {
      set_is_awaiting(false)

      // End of the line
      return false
    }

    // If status is not active, alert and redirect them to the plan page
    if (organization_data["status"] !== "active") {
      // Show alert
      // alert("You cannot create a campaign without an active plan")
      show_notification(protected_context, protected_update, "warning", "Inactive plan", "You cannot create a campaign without an active plan")

      // Redirect to dashboard/plan page
      navigate(`/dashboard/plan`)

      // End of the line
      return false
    }

    // If domains have not been allowlisted, redirect them to organization page
    if (!organization_data["organization_metadata"]["domains_are_allowlisted"]) {
      // Show error notification
      show_notification(protected_context, protected_update, "warning", "Domains have not been allowlisted", "You must allowlist all domains available to your organization before creating a campaign.")

      // Redirect to dashboard/organization page
      navigate(`/dashboard/organization`)

      // End of the line
      return
    }

    // If no users are chosen, show error
    if (campaign.campaign_metadata.user_ids.length < 1) {
      // Toggle awaiting
      set_is_awaiting(false)

      // Show error message
      set_error_message("You must select at least one user")

      // End of the line
      return false
    }

    // If simulation type is multi message and more than 20 users are selected, show error
    if (campaign.campaign_metadata.user_ids.length > multi_message_user_count_limit && campaign.campaign_metadata.simulation_type === "multi_message") {
      // Toggle awaiting
      set_is_awaiting(false)

      // Show error message
      set_error_message("You cannot select more than 20 users for a multi-message simulation. Please select only up to 20 users and try again.")

      // End of the line
      return false
    }

    return true
  }

  const save_and_go_back = async () => {

    // Clear status messages
    set_error_message("")
    set_success_message("")

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (!get_user_input_is_valid()) {
      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Save the campaign
    const save_is_successful = await update_campaign()

    if (save_is_successful) {
      // Go back to page one
      set_edit_page(0) 
    } 
    else {
      set_error_message("Error occurred while saving")
    }

    // Set awaiting
    set_is_awaiting(false)
  }

  const save_progress = async () => {

    // Clear status messages
    set_error_message("")
    set_success_message("")

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (!get_user_input_is_valid()) {
      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Save the campaign
    const save_is_successful = await update_campaign()

    if (save_is_successful) {
      set_success_message("Progress saved")
    } 
    else {
      set_error_message("Error occurred while saving")
    }

    // Set awaiting
    set_is_awaiting(false)
  }

  const save_and_continue = async () => {

    // Clear status messages
    set_error_message("")
    set_success_message("")

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (!get_user_input_is_valid()) {
      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Save the campaign
    const save_is_successful = await update_campaign()
  
    // Move to page two if successful
    if (save_is_successful) {
      // Move to page three
      set_edit_page(2) 
    }
    else {
      set_error_message("Error occurred while saving")
    }

    // Toggle awaiting
    set_is_awaiting(false)
  }

  // Other functions
  const toggle_users = (e) => {
    const filtered_user_ids_set = new Set(users
      // Filter by search
      .filter(user => user_search_filter_fn(user, search_text))
      // Filter by filters
      .filter(user => user_filters_filter_fn(user, filters))

      // Filter by active
      .filter(user => user.active)
      // Map by user_id
      .map(user => user.user_id)
    ) 

    const campaign_user_ids_sans_filtered_user_ids = campaign.campaign_metadata.user_ids.filter(existing_user_id => !filtered_user_ids_set.has(existing_user_id))

    set_campaign({
      ...campaign,
      campaign_metadata: {
        ...campaign.campaign_metadata,
        user_ids: e.target.checked ? [...campaign_user_ids_sans_filtered_user_ids, ...filtered_user_ids_set] : campaign_user_ids_sans_filtered_user_ids
      }
    })
  }

  // Renders  
  useEffect(() => {

    // Get org data
    get_organization_data_and_users_and_campaign()

  }, [])

  return (
    organization_data_and_users_and_campaign_are_fetched
    ? <div className="mt-8"> 

      {/* Label */}
      <label className="block text-sm leading-6 text-gray-900 flex">
        <span className="font-semibold">Select users to be included in the campaign</span>
        <span className="flex relative items-center">
          <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
          <span className="peer-hover:opacity-100 peer-hover:z-20 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto">
            Only active users can be included in the campaign.
          </span>
        </span>
      </label>

      {/* Tabs */}
      <div className="mt-8">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {/* Users tab */}
            <div
              className={classNames(
                current_tab === "users"
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
              )}
              onClick={() => { set_current_tab("users") }}
            >
              Users
              <span
                className={classNames(
                  current_tab === "users" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                  'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                )}
              >
                {users.length}
              </span>
            </div>

            {/* Groups tab */}
            <div
              className={classNames(
                current_tab === "groups"
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
              )}
              onClick={() => { set_current_tab("groups") }}
            >
              Select users by group
              <span
                className={classNames(
                  current_tab === "groups" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                  'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                )}
              >
                {groups.length}
              </span>
            </div>
          </nav>
        </div>
      </div>

      {/* Table area */}
      <div className="mt-2">

        {/* Top row - bulk actions and search field */}
        <div className="flex justify-between items-center px-2 py-2 space-x-4">
          {/* Number selected and bulk action buttons */}
          {campaign.campaign_metadata.user_ids.length > 0
          ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
            <div className="text-blue-600 text-sm font-medium" >{`${campaign.campaign_metadata.user_ids.length === 1 ? `${campaign.campaign_metadata.user_ids.length} user` : `${campaign.campaign_metadata.user_ids.length} users`} selected`}</div>
            {/* <button
              type="button"
              className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
            >
              Edit selected
            </button> */}
            {/* <button
              type="button"
              className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
            >
              Delete selected
            </button> */}
          </div>
          : <div></div>}

          <div className="flex space-x-4 items-center">
            {/* Add a filter button */}
            <button
              type="button"
              className="inline-flex items-center rounded-3xl bg-white px-4 py-1 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white h-8"
              onClick={() => set_add_filter_modal_is_open(true)}
            >
              Add a filter<PlusIcon className="ml-1 h-4 w-4" />
            </button>

            {/* Search field */}
            <div className="w-64">
              <div className="relative mt-1 rounded-md shadow-sm">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  value={search_text}
                  onChange={(e) => handle_user_input("search_text", e.target.value)}
                />
              </div>
            </div>
          </div>



        </div>

        {/* Bottom row (hidden) - active filters */}
        {filters.length > 0
        ? <div className="bg-gray-50 rounded-md">
          <div className="mx-auto max-w-7xl px-4 py-2 my-2 sm:flex sm:items-center sm:px-6 lg:px-8">
            <h3 className="text-sm font-medium text-gray-500">
              Filters
              <span className="sr-only">, active</span>
            </h3>

            <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

            <div className="mt-2 sm:ml-4 sm:mt-0">
              <div className="-m-1 flex flex-wrap items-center">
                {filters.map((filter) => (
                  <span
                    key={filter}
                    className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                  >
                    <span>{`'${user_property_map[filter.match(filter_regex)[1]]}' ${filter_type_display_map[filter.match(filter_regex)[2]]} '${filter.match(filter_regex)[3]}'`}</span>
                    <button
                      type="button"
                      className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                      onClick={() => set_filters(filters.filter(elm => elm !== filter))}
                    >
                      <span className="sr-only">Remove filter for {filter}</span>
                      <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        : <></>}
        
        {/* Table */}
        <div className="overflow-x-auto resize">          
          <div className="inline-block min-w-full align-middle h-[400px]">
            <div className="relative">
              <table className="min-w-full table-fixed divide-y divide-gray-300 ">
                <thead className="h-14">
                  <tr>
                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                      {/* Toggle all */}
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                        checked={(() => {

                          const campaign_user_ids_set = new Set(campaign.campaign_metadata.user_ids)
                          
                          const filtered_user_ids = users
                            // Filter by search
                            .filter(user => user_search_filter_fn(user, search_text))
                            // Filter by filters
                            .filter(user => user_filters_filter_fn(user, filters))
                            .map(user => user.user_id)

                          return filtered_user_ids.every(user_id => campaign_user_ids_set.has(user_id))
                        })()}
                        onChange={(() => {
                          switch(current_tab) {
                            case "users": {
                              return toggle_users
                            }
                            case "groups": {
                              return toggle_users
                            }
                            default: {
                              return
                            }
                          }
                        })()}
                      />
                    </th>

                    {/* Rest of columns */}
                    {(() => {
                      switch(current_tab) {
                        case "users": {
                          return active_user_properties.map((property, index) => 
                            <th 
                              key={index}
                              scope="col" 
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                            >
                              <div className="flex items-center space-x-2">
                                <div className="">{user_property_map[property]}</div>
                                <span className="flex relative items-center">
                                  <ChevronDownIcon 
                                    className="h-[16px] w-[16px] min-h-[16px] min-w-[16px] cursor-pointer" 
                                    onClick={current_filter === property ? () => set_current_filter("") : () => set_current_filter(property)}
                                  />
                                </span>
                              </div>
                              {current_filter === property
                              ? <Filter 
                                property={property}
                                filters={filters}
                                set_filters={set_filters}
                                set_current_filter={set_current_filter}
                              />
                              : <></>}
                            </th>
                          )
                        }
                        case "groups": {
                          return (<>
                            <th scope="col" 
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                            >
                              Name
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                              Number of users
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                              Provision type
                            </th>
                          </>)
                        }
                        default: {
                          return
                        }
                      }
                    })()}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {(() => {
                    const campaign_user_ids_set = new Set(campaign.campaign_metadata.user_ids)
                    switch(current_tab) {
                      case "users": {
                        return users
                        // Filter by search
                        .filter(user => user_search_filter_fn(user, search_text))
                        // Filter by filters
                        .filter(user => user_filters_filter_fn(user, filters))
                        // Map users
                        .map((user) => (
                          <User 
                            key={user.user_id} 
                            user={user} 
                            active_user_properties={active_user_properties}
                            campaign={campaign}
                            campaign_user_ids_set={campaign_user_ids_set}
                            set_campaign={set_campaign}
                          />
                        ))
                      }
                      case "groups": {
                        return groups.filter((user) => user_search_filter_fn(user, search_text)).map((group) => (
                          <Group 
                            key={group.group_id} 
                            group={group} 
                            campaign={campaign}
                            campaign_user_ids_set={campaign_user_ids_set}
                            set_campaign={set_campaign}
                          />
                        ))
                      }
                      default: {
                        return
                      }
                    }
                  })()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Error message */}
      {error_message
      ? <div className="mt-6 flex space-x-2 items-start">
          <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
          {/* Multi-line error message */}
          <div className="text-sm font-medium text-red-400">
            {error_message.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
      : <></>}

      {/* Success message */}
      {success_message
      ? <div className="mt-6 flex space-x-2 items-start">
          <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
          <div className="text-sm font-medium text-green-600">{success_message}</div>
        </div>
      : <></>}

      {/* Loading wheel */}
      {is_awaiting
      ? <div className="mt-6 flex space-x-2 items-start">
        <ReactLoading
          type='spokes'
          color='#000000'
          height={20}
          width={20}
        />
      </div>
      : <></>}

      {/* Buttons */}
      <div className="mt-12 flex justify-between">
        <div
          className="flex items-center text-sm font-medium text-gray-900 hover:text-gray-700 cursor-pointer"
          onClick={save_and_go_back}
        >
          <ArrowLongLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
          Save & go back
        </div>

        <button
          type="submit"
          onClick={save_progress}
          className={classNames(is_awaiting ? "cursor-default bg-gray-50" : "", "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50")}
          disabled={is_awaiting}
        >
          Save progress
        </button>

        <button
          type="submit"
          onClick={save_and_continue}
          className={classNames(is_awaiting ? "cursor-default bg-blue-500" : "bg-blue-600", "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")}
          disabled={is_awaiting}
        >
          Save & continue
        </button>
      </div>

      {/* Modals */}
      <AddFilterModal
        is_open={add_filter_modal_is_open}
        set_is_open={set_add_filter_modal_is_open}
        filters={filters}
        set_filters={set_filters}
      />
    </div> 
    : <div className="mt-8">
      <ReactLoading
        type='spokes'
        color='#000000'
        height={20}
        width={20}
      />
    </div>
  )
}

export default CampaignsCampaignIdEditPageTwoPage