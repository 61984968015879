import { useEffect, useState } from 'react'

import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useProtected, useProtectedUpdate } from '../contexts/ProtectedContext'
import { sleep } from '../libs/helpers'
import { v4 as uuidv4 } from 'uuid'

// Glboal variable for delete_schedule_id
var delete_schedule_id = ""

const Notifications = ({

}: { 
  
}) => {

  // Global
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  const delay_delete_notifications = async () => {
    const new_delete_schedule_id = uuidv4()
    delete_schedule_id = new_delete_schedule_id

    // After 5 seconds, remove all notifications
    await sleep(5.0)
    if (delete_schedule_id === new_delete_schedule_id) {
      protected_update({ ...protected_context, notifications: [] })
    }
  }

  useEffect(() => {
    if (protected_context.notifications.length >= 1) {
      delay_delete_notifications()
    }
  }, [protected_context.notifications.length])

  return (
    protected_context.notifications.length > 0
    ? <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-[52]"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {protected_context.notifications.map(notification => (
            <div key={notification.id} className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {(() => {
                      switch (notification.type) {
                        case "success": {
                          return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                        }
                        case "warning": {
                          return <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
                        }
                        case "error": {
                          return <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                        }
                        default: {
                          return <></>
                        }
                      }
                  })()}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{notification.header}</p>
                    <p className="mt-1 text-sm text-gray-500">{notification.body}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                      onClick={() => {
                        protected_update({ ...protected_context, notifications: protected_context.notifications.filter(existing_notification => existing_notification.id !== notification.id) })
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    : <></>
  )
}


export default Notifications;