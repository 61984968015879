
import { useEffect, useState } from 'react'


import { useGlobal, useGlobalUpdate } from '../../../../../contexts/GlobalContext'

import { useLocation, useNavigate } from "react-router-dom"


import { mixpanel_client_track } from '../../../../../libs/mixpanelClient';
import { auth_axios } from '../../../../../libs/authWeb'
import { classNames } from '../../../../../libs/helpers'


// Edit pages
import CampaignsCampaignIdEditPageOnePage from './CampaignsCampaignIdEditPageOnePage'
import CampaignsCampaignIdEditPageTwoPage from './CampaignsCampaignIdEditPageTwoPage'
import CampaignsCampaignIdEditPageThreePage from './CampaignsCampaignIdEditPageThreePage'







const CampaignsCampaignIdEditPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Org data
  const [campaign, set_campaign] = useState({})

  // Error message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")

  // Status
  const [edit_page, set_edit_page] = useState(0) // one of 0, 1, 2 (three pages in total)
  const [is_awaiting, set_is_awaiting] = useState(false)



  const update_campaign = async () => {

    // Execute save campaign
    const put_campaign_res = await auth_axios.put(`/api/campaigns/${campaign["campaign_id"]}`, {
      campaign: campaign
    })


    if (!put_campaign_res.data.success) {
      switch (put_campaign_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/campaigns page
          navigate(`/dashboard/campaigns`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      // Save is unsuccessful
      return false
    }

    // React to response
    switch (put_campaign_res.data.status) {
      case "SUCCESS": {
        // Save is successful
        return true
      }
      case "VALIDATION_FAILURE": {
        // Show error message
        set_error_message(put_campaign_res.data.error_message)

        // Save is unsuccessful
        return false
      }
      default: {
        // Save is unsuccessful
        return false
      }
    } 
  }


  // Renders  
  useEffect(() => {

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_campaigns_campaign_id_edit_visited", global_context.user_id)

  }, [])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Campaign configuration</h1>
          <p className="mt-2 text-sm text-gray-700">
            Configure campaign details
          </p>
        </div>
      </div>
      {edit_page === 0
      ? <CampaignsCampaignIdEditPageOnePage
        is_awaiting={is_awaiting}
        set_is_awaiting={set_is_awaiting}

        error_message={error_message}
        set_error_message={set_error_message}

        success_message={success_message}
        set_success_message={set_success_message}

        campaign={campaign}
        set_campaign={set_campaign}

        set_edit_page={set_edit_page}

        update_campaign={update_campaign}
      />
      : <></> }
      {edit_page === 1
      ? <CampaignsCampaignIdEditPageTwoPage
        is_awaiting={is_awaiting}
        set_is_awaiting={set_is_awaiting}

        error_message={error_message}
        set_error_message={set_error_message}

        success_message={success_message}
        set_success_message={set_success_message}

        campaign={campaign}
        set_campaign={set_campaign}

        set_edit_page={set_edit_page}

        update_campaign={update_campaign}
      />
      : <></> }
      {edit_page === 2
      ? <CampaignsCampaignIdEditPageThreePage
        is_awaiting={is_awaiting}
        set_is_awaiting={set_is_awaiting}

        error_message={error_message}
        set_error_message={set_error_message}

        success_message={success_message}
        set_success_message={set_success_message}

        campaign={campaign}
        set_campaign={set_campaign}

        set_edit_page={set_edit_page}

        update_campaign={update_campaign}
      />
      : <></> }
    </div>
  )
}

export default CampaignsCampaignIdEditPage