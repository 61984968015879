import React, { useState, useEffect } from "react"
import axios from "axios"


// GlobalProvider
import { useGlobal, useGlobalUpdate } from "./contexts/GlobalContext";

// Routing
import { Routes, Route, Navigate } from "react-router-dom";




//////////////////////// 
// Unprotected routes
////////////////////////

// Core routes
import IndexPage from "./pages";
import LoginPage from "./pages/login";
import SignupPage from "./pages/signup";
import ValidateEmailPage from "./pages/validate-email";
import SetPasswordPage from "./pages/set-password";
import ResetPage from "./pages/reset";

// Unprotected OAuth routes
import OAuthGooglePage from "./pages/oauth/google";



//////////////////////// 
// Protected routes
////////////////////////
import DashboardPage from "./pages/dashboard";

// Dashboard pages


// CAMPAIGNS
import CampaignsPage from "./pages/dashboard/campaigns";
import CampaignsCampaignIdPage from "./pages/dashboard/campaigns/[campaign_id]";
import CampaignsCampaignIdEditPage from "./pages/dashboard/campaigns/[campaign_id]/edit";




// USERS
import UsersPage from "./pages/dashboard/users";

import UsersAddPage from "./pages/dashboard/users/add";
import UsersAddInputProfilePage from "./pages/dashboard/users/add/input/profile";
import UsersAddUploadCSVPage from "./pages/dashboard/users/add/upload/csv";
import UsersAddInviteEmailsPage from "./pages/dashboard/users/add/invite/emails";

import UsersUserIdPage from "./pages/dashboard/users/[user_id]";
import UsersUserIdEditPage from "./pages/dashboard/users/[user_id]/edit";




// GROUPS
import GroupsPage from "./pages/dashboard/groups";
import GroupsAddPage from "./pages/dashboard/groups/add";
import GroupsGroupIdPage from "./pages/dashboard/groups/[group_id]";
import GroupsGroupIdEditPage from "./pages/dashboard/groups/[group_id]/edit";




// ORGANIZATION
import OrganizationPage from "./pages/dashboard/organization";
import OrganizationUpdateNamePage from "./pages/dashboard/organization/update/name";



// PLAN
import PlanPage from "./pages/dashboard/plan";
import PlanUpdatePlanPage from "./pages/dashboard/plan/update/plan";




// PROGRAMS
import ProgramsPage from "./pages/dashboard/programs";
import ProgramsProgramIdPage from "./pages/dashboard/programs/[program_id]";

// ACCOUNT
import AccountPage from "./pages/dashboard/account";
import AccountUpdateEmailPage from "./pages/dashboard/account/update/email";
import AccountUpdatePasswordPage from "./pages/dashboard/account/update/password";
import AccountUpdateProfilePage from "./pages/dashboard/account/update/profile";


// Protected OAuth routes
import OAuthVantaPage from "./pages/oauth/vanta";




//////////////////////// 
// Universal routes
////////////////////////

import FailureProgramsProgramIDPage from "./pages/failure/programs/[program_id]";

import TrackingProgramsProgramIDLinkPage from "./pages/tracking/programs/[program_id]/link";

import DocsGuidesSCIM from "./pages/docs/guides/scim";
import DocsGuidesAllowlist from "./pages/docs/guides/allowlist";


import TermsPage from "./pages/terms";
import PrivacyPage from "./pages/privacy";

import Custom404 from "./pages/404";






// Layouts
import ProtectedLayout from "./layouts/ProtectedLayout";
import UnprotectedLayout from "./layouts/UnprotectedLayout";

// Miscellaneous pages
import SessionUncheckedPage from "./pages/misc/SessionUncheckedPage";

// CSS
import "./App.css";

// IDB
import { get as idb_get, set as idb_set, del as idb_delete } from 'idb-keyval'


// Client environment
import { browserName, isMobile, isDesktop } from "react-device-detect";







// Lazy load components
const PlanUpdatePaymentPage = React.lazy(() => import("./pages/dashboard/plan/update/payment"))
const PlanUpdatePaymentConfirmationPage = React.lazy(() => import("./pages/dashboard/plan/update/payment/confirmation"))








const App = () => {

  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // States


  

  // Token verified
  const [session_is_checked, set_session_is_checked] = useState(false)


  const set_to_authenticated = async (user_id, type) => {
    // Set global vars to authenticated
    global_update({
      ...global_context, 
      authenticated: true,
      user_id: user_id,
      type: type,
    })

    return
  }

  const set_to_unauthenticated = async () => {
    // Set session_token to UNSET
    await idb_set("session_token", "UNSET")

    // Set global vars to unauthenticated
    global_update({
      ...global_context,
      authenticated: false,
      user_id: "UNSET",
      type: "UNSET",
    })

    return
  }

  // Check token for authenticated state
  const check_session = async () => {

    const session_token = await idb_get("session_token")

    if (!session_token || session_token === "UNSET") {
      // Trigger cookie reset to UNSET
      await axios.delete(`/api/auth`)

      // Set to logged out
      await set_to_unauthenticated()
      set_session_is_checked(true)

      // End of the line
      return
    }

    const get_auth_res = await axios.get(`/api/auth`, {
      headers: {
        'Authorization': `session_token=${session_token}`
      }
    });

    if (!get_auth_res.data.success) {
      alert("Fatal error")

      await set_to_unauthenticated()
      set_session_is_checked(true)

      // End of the line
      return
    }

    if (get_auth_res.data.authenticated) {
      await set_to_authenticated(get_auth_res.data.user_id, get_auth_res.data.type)
      set_session_is_checked(true)

      // End of the line
      return
    } 
    else {
      await set_to_unauthenticated()
      set_session_is_checked(true)

      // End of the line
      return
    }
  }



  // Upon load
  useEffect(() => {

    // Check session
    check_session()

  }, [])

  return (
    session_is_checked
    ? <Routes>

        {/* Unprotected routes */}
        <Route element={<UnprotectedLayout />}>

          {/* Core routes */}
          <Route path="/" element={<IndexPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignupPage />} />
          <Route path="validate-email" element={<ValidateEmailPage />} />
          <Route path="set-password" element={<SetPasswordPage />} />
          <Route path="reset" element={<ResetPage />} />

          {/* Unprotected OAuth routes */}
          <Route path="/oauth/google" element={<OAuthGooglePage />} />
          

        </Route>

        {/* Protected routes */}
        <Route element={<ProtectedLayout />}>

          {/* Dashboard routes */}
          <Route path="dashboard" element={<DashboardPage />}>
            <Route index element={<Navigate to={["admin", "owner"].includes(global_context.type) ? "campaigns" : "account"} />} />

            {/* Privileged paths only available to admins & owner */}


            {/* CAMPAIGNS */}
            <Route path="campaigns" element={["admin", "owner"].includes(global_context.type) ? <CampaignsPage /> : <></>} />
            <Route path="campaigns/:campaign_id" element={["admin", "owner"].includes(global_context.type) ? <CampaignsCampaignIdPage /> : <></>} />
            <Route path="campaigns/:campaign_id/edit" element={["admin", "owner"].includes(global_context.type) ? <CampaignsCampaignIdEditPage /> : <></>} />




            {/* USERS */}
            <Route path="users" element={["admin", "owner"].includes(global_context.type) ? <UsersPage /> : <></>} />
            <Route path="users/add" element={["admin", "owner"].includes(global_context.type) ? <UsersAddPage /> : <></>} />
            <Route path="users/add/upload/csv" element={["admin", "owner"].includes(global_context.type) ? <UsersAddUploadCSVPage /> : <></>} />
            <Route path="users/add/input/profile" element={["admin", "owner"].includes(global_context.type) ? <UsersAddInputProfilePage /> : <></>} />
            <Route path="users/add/invite/emails" element={["admin", "owner"].includes(global_context.type) ? <UsersAddInviteEmailsPage /> : <></>} />
            <Route path="users/:user_id" element={["admin", "owner"].includes(global_context.type) ? <UsersUserIdPage /> : <></>} />
            <Route path="users/:user_id/edit" element={["admin", "owner"].includes(global_context.type) ? <UsersUserIdEditPage /> : <></>} />
            
            
          

            {/* USERS */}
            <Route path="groups" element={["admin", "owner"].includes(global_context.type) ? <GroupsPage /> : <></>} />
            <Route path="groups/add" element={["admin", "owner"].includes(global_context.type) ? <GroupsAddPage /> : <></>} />
            <Route path="groups/:group_id" element={["admin", "owner"].includes(global_context.type) ? <GroupsGroupIdPage /> : <></>} />
            <Route path="groups/:group_id/edit" element={["admin", "owner"].includes(global_context.type) ? <GroupsGroupIdEditPage /> : <></>} />
            




            {/* ORGANIZATION */}
            <Route path="organization" element={["admin", "owner"].includes(global_context.type) ? <OrganizationPage /> : <></>} />
            {/* Privileged paths only available to owner */}
            <Route path="organization/update/name" element={["owner"].includes(global_context.type) ? <OrganizationUpdateNamePage /> : <></>} />

          



            {/* PLAN */}
            <Route path="plan" element={["admin", "owner"].includes(global_context.type) ? <PlanPage /> : <></>} />
            {/* update */}
            <Route path="plan/update/payment" element={["admin", "owner"].includes(global_context.type) ? <React.Suspense><PlanUpdatePaymentPage /></React.Suspense> : <></>} />
            <Route path="plan/update/payment/confirmation" element={["admin", "owner"].includes(global_context.type) ? <React.Suspense><PlanUpdatePaymentConfirmationPage /></React.Suspense> : <></>} />    
            <Route path="plan/update/plan" element={["admin", "owner"].includes(global_context.type) ? <PlanUpdatePlanPage /> : <></>} />






            {/* Paths available to everyone */}

            {/* PROGRAMS */}
            <Route path="programs" element={<ProgramsPage />} />
            <Route path="programs/:program_id" element={["admin", "owner"].includes(global_context.type) ? <ProgramsProgramIdPage /> : <></>} />


            
            {/* ACCOUNT */}

            <Route path="account" element={<AccountPage />} />
            {/* update */}
            <Route path="account/update/email" element={<AccountUpdateEmailPage />} />
            <Route path="account/update/password" element={<AccountUpdatePasswordPage />} />
            <Route path="account/update/profile" element={<AccountUpdateProfilePage />} />
            
            
          </Route>

          {/* Protected OAuth routes */}
          <Route path="/oauth/vanta" element={["admin", "owner"].includes(global_context.type) ? <OAuthVantaPage /> : <></>} />

        </Route>

        {/* Universal routes */}

        <Route path="failure/programs/:program_id" element={<FailureProgramsProgramIDPage />} />

        <Route path="tracking/programs/:program_id/link" element={<TrackingProgramsProgramIDLinkPage />} />

        <Route path="docs/guides/scim" element={<DocsGuidesSCIM />} />
        <Route path="docs/guides/allowlist" element={<DocsGuidesAllowlist />} />

        <Route path="terms" element={<TermsPage />} />
        <Route path="privacy" element={<PrivacyPage />} />



        <Route path="*" element={<Custom404 />} />



      </Routes>
    : <SessionUncheckedPage />
  );
}

export default App;
