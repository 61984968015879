import { get as idb_get, set as idb_set } from 'idb-keyval';
import axios from 'axios';


export const auth_axios = {
  get: async (uri, config?) => {
    const session_token = await idb_get('session_token');

    const res = await axios.get(uri, {
      headers: {
        'Authorization': `session_token=${session_token}`
      },
      ...config
    });

    if (res.data.destroy_session) {
      await idb_set("session_token", "UNSET");
      window.location.reload();
      return;
    } 
    else if (res.data.unauthorized) {
      alert("Unauthorized API access")
      window.location.reload();
      return;
    }
    else {
      return res;
    }
  },
  post: async (uri, body, config?) => {
    const session_token = await idb_get('session_token');

    const res = await axios.post(uri, body, {
      headers: {
        'Authorization': `session_token=${session_token}`
      },
      ...config
    });

    if (res.data.destroy_session) {
      await idb_set("session_token", "UNSET");
      window.location.reload();
      return;
    } 
    else if (res.data.unauthorized) {
      alert("Unauthorized API access")
      window.location.reload();
      return;
    }
    else {
      return res;
    }
  },
  delete: async (uri, config?) => {
    const session_token = await idb_get('session_token');

    const res = await axios.delete(uri, {
      headers: {
        'Authorization': `session_token=${session_token}`
      },
      ...config
    });

    if (res.data.destroy_session) {
      await idb_set("session_token", "UNSET");
      window.location.reload();
      return;
    } 
    else if (res.data.unauthorized) {
      alert("Unauthorized API access")
      window.location.reload();
      return;
    }
    else {
      return res;
    }
  },
  put: async (uri, body, config?) => {
    const session_token = await idb_get('session_token');

    const res = await axios.put(uri, body, {
      headers: {
        'Authorization': `session_token=${session_token}`
      },
      ...config
    });

    if (res.data.destroy_session) {
      await idb_set("session_token", "UNSET");
      window.location.reload();
      return;
    } 
    else if (res.data.unauthorized) {
      alert("Unauthorized API access")
      window.location.reload();
      return;
    }
    else {
      return res;
    }
  },
  patch: async (uri, body, config?) => {
    const session_token = await idb_get('session_token');

    const res = await axios.patch(uri, body, {
      headers: {
        'Authorization': `session_token=${session_token}`
      },
      ...config
    });

    if (res.data.destroy_session) {
      await idb_set("session_token", "UNSET");
      window.location.reload();
      return;
    } 
    else if (res.data.unauthorized) {
      alert("Unauthorized API access")
      window.location.reload();
      return;
    }
    else {
      return res;
    }
  },
}