
import React, { useEffect, useState, useCallback } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../../contexts/ProtectedContext'

import { useLocation, useNavigate } from "react-router-dom"
import { Tab } from '@headlessui/react'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'


import { mixpanel_client_track } from '../../../../../../libs/mixpanelClient';
import { auth_axios } from '../../../../../../libs/authWeb'
import { get_duplicates_in_array, show_notification, classNames } from '../../../../../../libs/helpers'
import { validate_email_address } from '../../../../../../libs/validate'

import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { get_user_property_value, user_property_map } from '../../../../../../libs/formats'
import { editable_user_property_options, required_user_property_options } from '../../../../../../libs/options'
import { RESTRICT_EMAILS_TO_ORGANIZATION_DOMAIN } from '../../../../../../libs/env'





const CSVUser = ({
  user,
  email_address_error_map
} : {
  user : any
  email_address_error_map : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr 
      className={classNames("hover:bg-gray-100 cursor-pointer")}
    >
      {/* Index */}
      <td 
        className={classNames(
          'relative whitespace-nowrap px-3 py-4 text-sm text-gray-500',
          email_address_error_map[user.email_address] ? "text-red-500" : ""
        )}
      >
        {email_address_error_map[user.email_address] && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-red-600" />
        )}
        {user.index}
      </td>

      {/* User properties */}
      {editable_user_property_options.map((property, index) => <td
        key={index}
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
          email_address_error_map[user.email_address] ? "text-red-500" : ""
        )}
      >
        {get_user_property_value(user, property, true)}
      </td>)}
    </tr>
  )
}

const CSVFileUpload = ({ 
  csv_filename,
  on_csv_file_upload,
  set_is_awaiting
}) => {

  const on_drop = (accepted_files) => {

    // Set awaiting
    set_is_awaiting(true)

    // Validations
    if (accepted_files.length !== 1) {
      alert("fatal error")
      return
    }

    // Do something with the files
    const csv_file = accepted_files[0]; // Assuming only one file is uploaded

    Papa.parse(csv_file, {
      complete: (result) => {
        on_csv_file_upload(csv_file, result.data);
      },
      header: true // Set to true if your CSV has headers
    });

    // Set awaiting
    set_is_awaiting(false)
  }

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept: {'text/csv': []}, maxFiles: 1, onDrop: on_drop }); 

  return (
    <div 
      {...getRootProps()} 
      className={classNames(
        isFocused ? "" : "", 
        isDragAccept ? "bg-blue-100" : "", 
        isDragReject ? "bg-red-100" : "",
        "block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 hover:ring-1 hover:ring-blue-600 hover:ring-inset sm:text-sm sm:leading-6 h-96 flex items-center justify-center cursor-pointer")}
    >
      <input {...getInputProps()} />
      <p className="font-normal">{csv_filename || "Drag & drop or click to select CSV file"}</p>
    </div>
  );
};




const UsersAddUploadCSVPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Org data
  const [organization_data, set_organization_data] = useState({})
  const [organization_data_and_users_are_fetched, set_organization_data_and_users_are_fetched] = useState(false)
  const [users, set_users] = useState([])

  const [email_address_error_map, set_email_address_error_map] = useState({})

  // User input
  const [csv_filename, set_csv_filename] = useState("")
  const [csv_users, set_csv_users] = useState([])

  const [is_preview, set_is_preview] = useState(false)

  // Error message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [csv_is_uploaded, set_csv_is_uploaded] = useState(false)



  const get_organization_data_and_users = async () => {
    // Set is_fetched to false
    set_organization_data_and_users_are_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get user data
    const get_org_users_res = await auth_axios.get(`/api/users`)

    if (!get_org_users_res.data.success) {
      switch (get_org_users_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_users(get_org_users_res.data.users)
    set_organization_data_and_users_are_fetched(true)
  }


  const generate_preview = () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // If orgnaization data has not been fetched yet, don't do anything
    if (!organization_data_and_users_are_fetched) {
      set_is_awaiting(false)
      return
    }

    let is_invalid = false
    let error_message_draft = ""

    /////
    // If invalid, show error message and end of the line
    if (is_invalid) {
      set_error_message(error_message_draft)
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // END OF USER INPUT CHECK

    set_is_preview(true)

    // Toggle awaiting
    set_is_awaiting(false)
  }

  const on_csv_file_upload = (file, data) => {


    // Set awaiting
    set_is_awaiting(true)
    set_error_message("")

    // START OF USER INPUT CHECK

    // If orgnaization data has not been fetched yet, don't do anything
    if (!organization_data_and_users_are_fetched) {
      set_is_awaiting(false)
      return
    }

    let is_invalid = false
    let error_message_draft = ""
    let error_email_addresses = []

    // At least one user
    if (data.length < 1) {
      error_message_draft += `At least one user must be provided.\n`
      is_invalid = true
    }

    // If invalid at this point, show error message and end of the line
    if (is_invalid) {
      set_error_message(error_message_draft)
      set_is_awaiting(false)

      // End of the line
      return
    }

    // Make sure the required headers are there
    const first_datum = data[0] 
    const missing_properties = required_user_property_options.filter(option => !(option in first_datum))
    if (missing_properties.length !== 0) {
      for (const missing_property of missing_properties) {
        error_message_draft += `${missing_property} is a required property.\n`
      }
      is_invalid = true
    }

    // If invalid at this point, show error message and end of the line
    if (is_invalid) {
      set_error_message(error_message_draft)
      set_is_awaiting(false)

      // End of the line
      return
    }

    ////////////
    // Set csv_users
    ////////////

    const csv_users = data.map((datum, index) => {
      return {
        index: index + 1,
        email_address: datum.email_address,
        user_metadata: {
          first_name: datum.first_name || "UNSET",
          last_name: datum.last_name || "UNSET",
          position: datum.position || "UNSET",
          department: datum.department || "UNSET",
          division: datum.division || "UNSET",
          organization: datum.organization || "UNSET",
          manager_id: datum.manager_id || "UNSET",
          manager_name: datum.manager_name || "UNSET",
          preferred_language: datum.preferred_language || "UNSET",
          city: datum.city || "UNSET",
          country: datum.country || "UNSET",
          locale: datum.locale || "UNSET",
          timezone: datum.timezone || "UNSET",
          phone_number: datum.phone_number || "UNSET",
          social_metadata: {
            linkedin_url: datum.social_linkedin_url || "UNSET",
            instagram_url: datum.social_instagram_url || "UNSET",
            facebook_url: datum.social_facebook_url || "UNSET",
          },
        }
      }
    })

    // Set states
    set_csv_users(csv_users)
    set_csv_filename(file.path)
    set_email_address_error_map(csv_users.reduce((acc, user) => ({...acc, [user.email_address]: false}), {}))

    ////////////
    ////////////
    ////////////

  
    
    // Check email addresses
    const email_addresses = csv_users.map(user => user.email_address)

    for (const email_address of email_addresses) {
      // Validate email address
      if (!validate_email_address(email_address)) {
        error_message_draft += `${email_address} is not a valid email.\n`
        error_email_addresses.push(email_address)
        is_invalid = true
      }

      // Make sure email address and domain match
      if (RESTRICT_EMAILS_TO_ORGANIZATION_DOMAIN && email_address.split("@")[1] !== organization_data["organization_domain"] &&  !organization_data["organization_metadata"]["additional_organization_domains"].includes(email_address.split("@")[1])) {
        error_message_draft += `${email_address} does not match your organization domain (${organization_data["organization_domain"]}).\n`
        set_email_address_error_map({ ...email_address_error_map, [email_address]: true })
        error_email_addresses.push(email_address)
        is_invalid = true
      } 
      
    }

    // Make sure every email address is unique
    const duplicate_email_addresses = get_duplicates_in_array(email_addresses)
    if (duplicate_email_addresses.length !== 0) {
      for (const duplicate_email_address of duplicate_email_addresses) {
        error_message_draft += `${duplicate_email_address} is found more than once.\n`
        error_email_addresses.push(duplicate_email_address)
      }
      is_invalid = true
    }

    // Make sure user with an email does not already exist
    const users_email_addresses_set = new Set(users.map(user => user.email_address))
    const existing_email_addresses = [... new Set(email_addresses)].filter(email_address => users_email_addresses_set.has(email_address))
    if (existing_email_addresses.length !== 0) {
      for (const existing_email_address of existing_email_addresses) {
        error_message_draft += `${existing_email_address} is already in your organization.\n`
        error_email_addresses.push(existing_email_address)
      }
      is_invalid = true
    }

    // Check for invalid first_name
    const users_with_invalid_first_name = csv_users.filter(user => !user.user_metadata.first_name || user.user_metadata.first_name === "")
    if (users_with_invalid_first_name.length !== 0) {
      for (const user of users_with_invalid_first_name) {
        error_message_draft += `The first_name for ${user.email_address} is invalid.\n`
        error_email_addresses.push(user.email_address)
      }
      is_invalid = true
    }

    // Check for invalid last_name
    const users_with_invalid_last_name = csv_users.filter(user => !user.user_metadata.last_name || user.user_metadata.last_name === "")
    if (users_with_invalid_last_name.length !== 0) {
      for (const user of users_with_invalid_last_name) {
        error_message_draft += `The last_name for ${user.email_address} is invalid.\n`
        error_email_addresses.push(user.email_address)
      }
      is_invalid = true
    }

    // Check for invalid position
    const users_with_invalid_position = csv_users.filter(user => !user.user_metadata.position || user.user_metadata.position === "")
    if (users_with_invalid_position.length !== 0) {
      for (const user of users_with_invalid_position) {
        error_message_draft += `The position for ${user.email_address} is invalid.\n`
        error_email_addresses.push(user.email_address)
      }
      is_invalid = true
    }

    /////
    // If invalid, show error message and end of the line
    if (is_invalid) {
      const error_email_addresses_set = new Set(error_email_addresses)
      set_email_address_error_map(csv_users.map(user => user.email_address).reduce((acc, email_address) => ({ ...acc, [email_address]: error_email_addresses_set.has(email_address) }), {}))

      set_error_message(error_message_draft)
      set_is_preview(true)
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // END OF USER INPUT CHECK

    // Toggle
    set_is_preview(true)
    set_is_awaiting(false)
  };


  const submit = async () => {

    // Set awaiting
    set_is_awaiting(true)
    set_error_message("")

    // START OF USER INPUT CHECK

    // If orgnaization data has not been fetched yet, don't do anything
    if (!organization_data_and_users_are_fetched) {
      set_is_awaiting(false)
      return
    }

    let is_invalid = false
    let error_message_draft = ""
    let error_email_addresses = []

    // At least one user
    if (csv_users.length < 1) {
      error_message_draft += `At least one user must be provided.\n`
      is_invalid = true
    }

    // If invalid at this point, show error message and end of the line
    if (is_invalid) {
      set_error_message(error_message_draft)
      set_is_awaiting(false)

      // End of the line
      return
    }

    // Make sure the required headers are there
    const first_csv_user = csv_users[0] 
    
    is_invalid = !(first_csv_user.user_metadata.first_name && first_csv_user.email_address && first_csv_user.user_metadata.last_name && first_csv_user.user_metadata.position)

    // If invalid at this point, show error message and end of the line
    if (is_invalid) {
      error_message_draft += `Missing required properties.\n`
      set_error_message(error_message_draft)
      set_is_awaiting(false)

      // End of the line
      return
    }

    // Check email addresses
    const email_addresses = csv_users.map(user => user.email_address)

    for (const email_address of email_addresses) {
      // Validate email address
      if (!validate_email_address(email_address)) {
        error_message_draft += `${email_address} is not a valid email.\n`
        error_email_addresses.push(email_address)
        is_invalid = true
      }

      // Make sure email address and domain match
      if (RESTRICT_EMAILS_TO_ORGANIZATION_DOMAIN && email_address.split("@")[1] !== organization_data["organization_domain"] &&  !organization_data["organization_metadata"]["additional_organization_domains"].includes(email_address.split("@")[1])) {
        error_message_draft += `${email_address} does not match your organization domain (${organization_data["organization_domain"]}).\n`
        error_email_addresses.push(email_address)
        is_invalid = true
      }
    }

    // Make sure every email address is unique
    const duplicate_email_addresses = get_duplicates_in_array(email_addresses)
    if (duplicate_email_addresses.length !== 0) {
      for (const duplicate_email_address of duplicate_email_addresses) {
        error_message_draft += `${duplicate_email_address} is found more than once.\n`
        error_email_addresses.push(duplicate_email_address)
      }
      is_invalid = true
    }

    // Make sure user with an email does not already exist
    const users_email_addresses_set = new Set(users.map(user => user.email_address))
    const existing_email_addresses = [... new Set(email_addresses)].filter(email_address => users_email_addresses_set.has(email_address))
    if (existing_email_addresses.length !== 0) {
      for (const existing_email_address of existing_email_addresses) {
        error_message_draft += `${existing_email_address} is already in your organization.\n`
        error_email_addresses.push(existing_email_address)
      }
      is_invalid = true
    }

    // Check for invalid first_name
    const users_with_invalid_first_name = csv_users.filter(user => !user.user_metadata.first_name || user.user_metadata.first_name === "")
    if (users_with_invalid_first_name.length !== 0) {
      for (const user of users_with_invalid_first_name) {
        error_message_draft += `The first_name for ${user.email_address} is invalid.\n`
        error_email_addresses.push(user.email_address)
      }
      is_invalid = true
    }

    // Check for invalid last_name
    const users_with_invalid_last_name = csv_users.filter(user => !user.user_metadata.last_name || user.user_metadata.last_name === "")
    if (users_with_invalid_last_name.length !== 0) {
      for (const user of users_with_invalid_last_name) {
        error_message_draft += `The last_name for ${user.email_address} is invalid.\n`
        error_email_addresses.push(user.email_address)
      }
      is_invalid = true
    }

    // Check for invalid position
    const users_with_invalid_position = csv_users.filter(user => !user.user_metadata.position || user.user_metadata.position === "")
    if (users_with_invalid_position.length !== 0) {
      for (const user of users_with_invalid_position) {
        error_message_draft += `The position for ${user.email_address} is invalid.\n`
        error_email_addresses.push(user.email_address)
      }
      is_invalid = true
    }

    /////
    // If invalid, show error message and end of the line
    if (is_invalid) {
      const error_email_addresses_set = new Set(error_email_addresses)
      set_email_address_error_map(Object.keys(email_address_error_map).reduce((acc, email_address) => ({ ...acc, [email_address]: error_email_addresses_set.has(email_address) }), {}))
      set_error_message(error_message_draft)
      set_is_preview(true)
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // END OF USER INPUT CHECK








    // Execute add users
    const post_users_res = await auth_axios.post(`/api/users/upload`, {
      users: csv_users
    })

    if (!post_users_res.data.success) {
      switch (post_users_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/users page
          navigate(`/dashboard/users`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // React to response
    switch (post_users_res.data.status) {
      case "SUCCESS": {
        // Set success message and disable edit
        set_success_message("User(s) were successfully uploaded")
        set_csv_is_uploaded(true)

        // Toggle
        set_is_awaiting(false)

        // Show success message
        // alert("Users were successfully added")
        show_notification(protected_context, protected_update, "success", "Success", "User(s) were successfully uploaded")

        // // If adding users made the plan inactive, show and alert warning message
        // if (post_org_users_res.data.organization_status === "inactive") {
        //   set_error_message("The number of users in your organization surpasses the number of seats in your plan, and your plan is inactive. Any existing campaign will continue to operate, but you will not be able to create new campaigns until your plan is active. Please visit the Plan & Billing tab to increase the number of seats.")

        //   alert("Users were successfully added and your plan is inactive. Please add more seats to your plan.")
        // }
        // else {
        //   // Alert (in case moved away from the page)
        //   alert("Users were successfully added")
        // }

        // Always break
        break
      }
      case "VALIDATION_FAILURE": {
        // Show error message
        set_error_message(post_users_res.data.error_message)
        set_is_awaiting(false)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }



  // Renders  
  useEffect(() => {

    // Get org data
    get_organization_data_and_users()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_users_add_upload_csv_visited", global_context.user_id)

  }, [])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Upload CSV</h1>
          <p className="mt-2 text-sm text-gray-700">
            {`The CSV file header must include the following properties: ${required_user_property_options.join(", ")}`} 
          </p>
          <div className="mt-2 text-sm text-gray-700">
            {`The CSV file header may include the following additional properties: ${editable_user_property_options.filter(option => !required_user_property_options.includes(option)).join(", ")}`} 
          </div>
          <div className="mt-2 text-sm text-gray-700">
            {`Currently, uploading via CSV does not allow for individual users to be able to log into their accounts. To enable this, you can choose the invite method instead, or reach out to support@vansec.com.`} 
          </div>
        </div>
      </div>
      <div className="mt-8">
        <Tab.Group onChange={(index) => { if (index === 1) generate_preview(); else set_is_preview(false); }} selectedIndex={is_preview ? 1 : 0}>
          <Tab.List className="flex items-center">
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                    : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                  'rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                )
              }
            >
              Upload CSV
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                    : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                  'ml-2 rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                )
              }
            >
              {organization_data_and_users_are_fetched
              ? "Preview"
              : <ReactLoading
                type='spokes'
                color='#343D46'
                height={20}
                width={20}
              />}
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            {/* CSV Upload */}
            <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
              <CSVFileUpload 
                csv_filename={csv_filename}
                on_csv_file_upload={on_csv_file_upload} 
                set_is_awaiting={set_is_awaiting}
              />
            </Tab.Panel>
            {/* Preview */}
            <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
              <div className="border-b">
                {/* Table */}
                <div className="overflow-x-auto resize">
                  <div className="inline-block min-w-full align-middle h-[500px]">
                    <div className="relative">
                      <table className="min-w-full table-fixed divide-y divide-gray-300">
                        <thead className="h-14">
                          <tr className="">
                            {/* Number */}
                            <th 
                              scope="col" 
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                            >
                              <div className="flex items-center space-x-2">
                                <div className="">#</div>
                              </div>
                            </th>

                            {/* Columns */}
                            {editable_user_property_options.map((property, index) => <th 
                              key={index}
                              scope="col" 
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                            >
                              <div className="flex items-center space-x-2">
                                <div className="">{user_property_map[property]}</div>
                              </div>
                            </th>)}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {organization_data_and_users_are_fetched
                          ? csv_users
                          // Map users
                          .map((user) => (
                            <CSVUser 
                              key={user.index} 
                              user={user} 
                              email_address_error_map={email_address_error_map}
                            />
                          ))
                          : <tr>
                            <td className="py-4 px-4">
                              <ReactLoading
                                type='spokes'
                                color='#000'
                                height={20}
                                width={20}
                              />
                            </td>
                          </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        {/* Error message */}
        {error_message
        ? <div className="mt-2 flex space-x-2 items-start">
            <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
            {/* Multi-line error message */}
            <div className="text-sm font-medium text-red-400">
              {error_message.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        : <></>}

        {/* Success message */}
        {csv_is_uploaded && success_message
        ? <div className="mt-6 flex space-x-2 items-start">
            <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
            <div className="text-sm font-medium text-green-600">{success_message}</div>
          </div>
        : <></>}

        {/* Submit button */}
        <div className="mt-2 flex justify-end">
          <button
            type="submit"
            onClick={submit}
            className={classNames(is_awaiting || csv_is_uploaded ? "cursor-default bg-blue-500" : "bg-blue-600", "inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")}
            disabled={is_awaiting || csv_is_uploaded}
          >
            {is_awaiting ? (
            <ReactLoading
              type='spokes'
              color='#ffffff'
              height={20}
              width={20}
            />
          ) : (
            <span>Upload users</span>
          )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default UsersAddUploadCSVPage