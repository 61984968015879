
import { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom"

import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'

import ReactLoading from "react-loading"

import { ReactComponent as SquareLogo } from "../../../media/logos/dynamic_logo_64x64.svg"
import { v4 as uuidv4 } from 'uuid'



const OAuthVantaPage = ({
  
} : {
  
}) => {

  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Search params
  const [search_params, _] = useSearchParams()


  const [is_done, set_is_done] = useState(false)


  const initiate_vanta_oauth = async () => {

    const done = search_params.get("done")

    if (done === "true") {
      set_is_done(true)
      return
    }

    const nonce = uuidv4()

    window.location.href = `https://app.vansec.com/api/auth/vanta?state=${nonce}+${global_context.user_id}` // PROD TOGGLE
    // window.location.href = `http://localhost:5000/api/auth/vanta?state=${nonce}+${global_context.user_id}` // DEV TOGGLE
  }


  useEffect(() => {

    initiate_vanta_oauth()

  }, [])

  return (
    <div className="absolute w-full h-screen flex flex-col justify-center items-center z-10">
      {is_done
      ? <div className="flex w-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
          <SquareLogo className="h-8 w-auto" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-4">
            <div className="block text-sm font-medium leading-6 text-gray-900">
              {`Your Vansec account is now successfully integrated with Vanta.`}
            </div>

            <div className="block text-sm font-medium leading-6 text-gray-900">
              {`You can close this window now!`}
            </div>
            
          </div>

          {/* Support link */}
          <p className="mt-10 text-center text-sm text-gray-500">
            If you have any questions, please reach out to support@vansec.com.
          </p>

        </div>
      </div>
      : <ReactLoading
        type='spokes'
        color='#343D46'
        height={20}
        width={20}
      />}
    </div>
  )
}

export default OAuthVantaPage