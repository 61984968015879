import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'



import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'

import ReactLoading from "react-loading"


import { useLocation, useNavigate, Link } from "react-router-dom";
import { auth_axios } from '../../../libs/authWeb';

import { show_notification, classNames } from '../../../libs/helpers'
import { user_property_options } from '../../../libs/options'
import { user_property_map } from '../../../libs/formats'





const UpdateUserActivePropertiesModal = ({
  is_open,
  set_is_open,
  set_refresh_variable
} : {
  is_open : boolean
  set_is_open : any // todo
  set_refresh_variable : any // todo
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // Location
  const location = useLocation()

  // User input


  // User data
  const [active_user_properties, set_active_user_properties] = useState([])
  const [active_user_properties_are_fetched, set_active_user_properties_are_fetched] = useState(false)
  
  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [active_user_properties_are_updated, set_active_user_properties_are_updated] = useState(false)

  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")



  const get_organization_data = async () => {

    // Toggle false
    set_active_user_properties_are_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_active_user_properties(get_org_res.data.organization_data.organization_metadata.active_user_properties)
    set_active_user_properties_are_fetched(false)
  }


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "____": {
        // set_confirm_text(value)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const submit = async () => {
    
    // Reset error message
    set_error_message("")

    // Set is_fetched to false
    set_is_awaiting(true)

    // START OF USER INPUT CHECK



    // END OF USER INPUT CHECK

    // Execute udpate email
    const update_active_user_properties_res = await auth_axios.put(`/api/organizations/active-user-properties`, {
      active_user_properties: active_user_properties
    })

    if (!update_active_user_properties_res.data.success) {
      switch (update_active_user_properties_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // // Redirect to dashboard/organization page
          // navigate(`/dashboard/organization`) 
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (update_active_user_properties_res.data.status) {
      case "SUCCESS": {
        set_is_awaiting(false)
        set_active_user_properties_are_updated(true)
        set_success_message(`Successfully updated`)

        // Show success notification
        show_notification(protected_context, protected_update, "success", "Success", `Successfully updated`)
        
        // Re-render /dashboard/users
        set_refresh_variable(new Date())

        // Close modal
        set_is_open(false)

        // Always break
        break
      }
      case "_____": {

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  // Renders 
  useEffect(() => {

    get_organization_data()

  }, [])

  return (
    <Transition.Root show={is_open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={set_is_open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">

                
                {/* Dismiss button */}
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <div
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                    onClick={() => set_is_open(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                
                {/* Modal body */}
                <div>
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-blue-500">
                        User active properties
                      </h1>
                      <p className="mt-2 text-sm text-gray-700">
                        Update active properties for users
                      </p>
                    </div>
                  </div>

                  <div className="mt-2 flow-root">

                    
                    
                    <dl className="mt-4 space-y-6 border-t border-gray-200 text-sm">




                      {/* Description text */}
                      <div className="pt-6 font-medium text-sm text-gray-900">
                        <div>
                          Select the user properties and their order for user tables and for inclusion in campaigns.
                        </div>

                        <div className="mt-8 flex flex-col divide-y divide-gray-200 bg-white">

                          {/* Active properties */}
                          {active_user_properties.map((active_property, index) => 
                            <div 
                              key={active_property}
                              className="flex items-center py-2 justify-between"
                            >
                              {/* Left side - checkbox and data */}
                              <div className="flex space-x-4">

                                {/* Inset and checkbox */}
                                <div className="relative px-7 sm:w-12 sm:px-6">
                                  <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                    checked={true}
                                    onChange={() => {
                                      // Cannot remove first_name, last_name, email_address, position
                                      if (["first_name", "last_name", "email_address", "position"].includes(active_property)) {
                                        return
                                      }
                                      // 
                                      set_active_user_properties(active_user_properties.filter(property => property !== active_property))
                                    }}
                                  />
                                </div>

                                {/* Pretty text */}
                                <div>
                                  {user_property_map[active_property]}
                                </div>

                                {/* Variable name */}
                                <div className="text-gray-500">
                                  {active_property}
                                </div>

                                {/* Required */}
                                {["first_name", "last_name", "email_address", "position"].includes(active_property)
                                ? <div className="text-red-500">
                                  Required
                                </div>
                                : <></>}
                              </div>

                              {/* Right side - up and down buttons */}
                              <div className="flex space-x-4">
                                <ChevronDownIcon 
                                  className="h-5 w-5 cursor-pointer hover:text-gray-400"
                                  onClick={() => {
                                    // Don't do anything if last item
                                    if (index === active_user_properties.length-1) {
                                      return
                                    }
                                    // Swap places with item below
                                    set_active_user_properties([
                                      ...active_user_properties.slice(0, index),
                                      active_user_properties[index+1],
                                      active_property,
                                      ...active_user_properties.slice(index+2)
                                    ])
                                  }}
                                />
                                <ChevronUpIcon 
                                  className="h-5 w-5 cursor-pointer hover:text-gray-400"
                                  onClick={() => {
                                    // Don't do anything if first item
                                    if (index === 0) {
                                      return
                                    }
                                    // Swap places with item above
                                    set_active_user_properties([
                                      ...active_user_properties.slice(0, index-1),
                                      active_property,
                                      active_user_properties[index-1],
                                      ...active_user_properties.slice(index+1)
                                    ])
                                  }}
                                />
                              </div>

                              
                            </div> 
                          )}

                          {/* Inactive properties */}
                          {user_property_options.filter(property => !active_user_properties.includes(property)).map((property, index) => 
                            <div 
                              key={property}
                              className="flex items-center py-2 justify-between"
                            >
                              {/* Left side - checkbox and data */}
                              <div className="flex space-x-4">
                                <div className="relative px-7 sm:w-12 sm:px-6">
                                  {/* <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" /> */}
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                    checked={false}
                                    onChange={() => set_active_user_properties([...active_user_properties, property])}
                                  />
                                </div>
                                <div>
                                  {user_property_map[property]}
                                </div>
                                <div className="text-gray-500">
                                  {property}
                                </div>
                              </div>

                              {/* Right side - up and down buttons */}
                              <div className="flex">
                                {/* Empty */}
                              </div>
                            </div> 
                          )}
                        </div>
                      </div>


                      {/* Submit */}
                      <div className="sm:flex items-center justify-end">
                        <button 
                          className={classNames(
                            is_awaiting || active_user_properties_are_updated ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                            'flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                          )}
                          onClick={submit}
                          disabled={is_awaiting || active_user_properties_are_updated}
                        >
                          {!is_awaiting
                          ? <span>Update</span>
                          :<ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />}
                        </button> 
                      </div>

                      {/* Error message */}
                      {error_message
                      ? <div className="mt-6 flex space-x-2 items-start">
                          <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                          <div className="text-sm font-medium text-red-400">{error_message}</div>
                        </div>
                      : <></>}

                      {/* Success message */}
                      {active_user_properties_are_updated && success_message
                      ? <div className="mt-6 flex space-x-2 items-start">
                          <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                          <div className="text-sm font-medium text-green-600">{success_message}</div>
                        </div>
                      : <></>}

                    </dl>
                  </div>


                  

                </div>

              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UpdateUserActivePropertiesModal;
