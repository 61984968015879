import { authentication_sso_method_options, authentication_type_options, simulation_program_status_options, training_program_status_options, provision_type_options, user_type_options, job_status_options } from "./options";





// **************************************
// Dates
// **************************************


export const get_date_string = (date) => {
  const date_formatter = Intl.DateTimeFormat("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeStyle: "long",
    dateStyle: "long"
  });
  const date_string = date_formatter.format(new Date(date))

  return date_string
}

export const get_date_string_short = (date) => {
  const date_formatter = Intl.DateTimeFormat("en-US", {
    dateStyle: "long"
  });
  const date_string = date_formatter.format(new Date(date))

  return date_string
}

export const get_time_left_in_trial = (user_created_at) => {

  const now_date = new Date();
  const user_created_date = new Date(user_created_at)

  // Calculate the end date by adding 14 days to the start date
  const trial_end_date = new Date(user_created_date);
  trial_end_date.setTime(user_created_date.getTime() + (1000 * 60 * 60 * 24 * 14)); // 14 days

  // Calculate the difference in time between the end date and the current date
  const time_difference = trial_end_date.getTime() - now_date.getTime()

  return time_difference
}

export const get_days_left_in_trial = (user_created_at) => {

  // Calculate the difference in time between the end date and the current date
  const time_difference = get_time_left_in_trial(user_created_at)

  // Convert the time difference to days
  const days_left = Math.ceil(time_difference / (1000 * 60 * 60 * 24));

  return days_left
}


// **************************************
// Organization & user data
// **************************************

export const user_type_map = {
  'end_user': 'User',
  'admin': 'Admin',
  'owner': 'Organization owner',
}



export const get_organization_status_pretty = (status: string) => {
  switch(status) {
    case "inactive": {
      return "Inactive"
    }
    case "active": {
      return "Active"
    }
    default: {
      return "error"
    }
  }
}







export const filter_type_display_map = {
  includes_text: "includes",
  equals_text: "equals",
  equals_option: "equals",
  before_date: "is before",
  after_date: "is after",
  equals_number: "equals",
  lte_number: "less than or equal to",
  gte_number: "greater than or equal to",
  lt_number: "less than",
  gt_number: "greater than",
}








export const user_property_map = {
  // Profile properties
  first_name: "First name", // user_doc.user_metadata.first_name
  last_name: "Last name", // user_doc.user_metadata.last_name
  email_address: "Email address", // user_doc.email_address
  position: "Position", // user_doc.user_metadata.position
  department: "Department", // user_doc.user_metadata.department
  division: "Division", // user_doc.user_metadata.division
  organization: "Organization", // user_doc.user_metadata.organization
  type: "Type", // user_doc.type
  user_created_at: "User created at", // user_doc.user_metadata.user_created_at
  manager_id: "Manager ID", // user_doc.user_metadata.manager_id
  manager_name: "Manager name", // user_doc.user_metadata.manager_name
  preferred_language: "Preferred language", // user_doc.user_metadata.preferred_language
  city: "City", // user_doc.user_metadata.city
  country: "Country", // user_doc.user_metadata.country
  locale: "Locale", // user_doc.user_metadata.locale
  timezone: "Timezone", // user_doc.user_metadata.timezone
  phone_number: "Phone number", // user_doc.user_metadata.phone_number
  social_linkedin_url: "LinkedIn URL", // user_doc.user_metadata.social_metadata.linkedin_url
  social_instagram_url: "Instagram URL", // user_doc.user_metadata.social_metadata.instagram_url
  social_facebook_url: "Facebook URL", // user_doc.user_metadata.social_metadata.facebook_url
  authentication_type: "Login method", // user_doc.authentication_metadata.type
  authentication_email_is_validated: "Email is validated", // user_doc.authentication_metadata.email_is_validated
  authentication_password_is_set: "Password is set", // user_doc.authentication_metadata.password_is_set
  authentication_sso_method: "SSO method", // user_doc.authentication_metadata.sso_method
  provision_type: "Provision method", // user_doc.provision_metadata.type

  // Status properties
  most_recent_simulation_program_status: "Status of most recent simulation", // user_doc.most_recent_simulation_program.simulation_status
  most_recent_training_program_status: "Status of most recent training", // user_doc.most_recent_training_program.training_status
  most_recent_simulation_program_created_at: "Date of most recent simulation", // user_doc.most_recent_simulation_program.program_created_at
  most_recent_training_program_created_at: "Date of most recent training", // user_doc.most_recent_training_program.program_created_at

  most_recent_x_simulations_opened_email: "Opened email in most recent x simulations", // user_doc._______
  most_recent_x_simulations_opened_link: "Opened link in most recent x simulations", // user_doc._______
  most_recent_x_simulations_replied_email: "Replied to email in most recent x simulations", // user_doc._______
  most_recent_x_simulations_active: "Not completed most recent x simulations", // user_doc._______
  most_recent_x_simulations_failed: "Failed most recent x simulations", // user_doc._______
  most_recent_x_simulations_reported: "Reported most recent x simulations", // user_doc._______
  most_recent_x_trainings_active: "Not completed most recent x trainings", // user_doc._______
  most_recent_x_trainings_completed: "Completed most recent x trainings", // user_doc._______
}




export const campaign_user_property_placeholder_map = {
  first_name: "{{first_name}}", // user_doc.user_metadata.first_name
  last_name: "{{last_name}}", // user_doc.user_metadata.last_name
  email_address: "{{email_address}}", // user_doc.email_address
  position: "{{position}}", // user_doc.user_metadata.position
  department: "{{department}}", // user_doc.user_metadata.department
  division: "{{division}}", // user_doc.user_metadata.division
  organization: "{{organization}}", // user_doc.user_metadata.organization
  manager_id: "{{manager_id}}", // user_doc.user_metadata.manager_id
  manager_name: "{{manager_name}}", // user_doc.user_metadata.manager_name
  preferred_language: "{{preferred_language}}", // user_doc.user_metadata.preferred_language
  city: "{{city}}", // user_doc.user_metadata.city
  country: "{{country}}", // user_doc.user_metadata.country
  locale: "{{locale}}", // user_doc.user_metadata.locale
  timezone: "{{timezone}}", // user_doc.user_metadata.timezone
  phone_number: "{{phone_number}}", // user_doc.user_metadata.phone_number
  social_linkedin_url: "{{social_linkedin_url}}", // user_doc.user_metadata.social_metadata.linkedin_url
  social_instagram_url: "{{social_instagram_url}}", // user_doc.user_metadata.social_metadata.instagram_url
  social_facebook_url: "{{social_facebook_url}}", // user_doc.user_metadata.social_metadata.facebook_url
}

export const get_user_property_value = (user_doc, user_property, is_formatted) => {
  switch(user_property) {
    // Profile properties
    case "first_name": {
      return (user_doc.user_metadata.first_name && user_doc.user_metadata.first_name !== "UNSET") ? (user_doc.user_metadata.first_name) : "(Unspecified)"
    }
    case "last_name": {
      return (user_doc.user_metadata.last_name && user_doc.user_metadata.last_name !== "UNSET") ? (user_doc.user_metadata.last_name) : "(Unspecified)"
    }
    case "email_address": {
      return user_doc.email_address
    }
    case "position": {
      return (user_doc.user_metadata.position && user_doc.user_metadata.position !== "UNSET") ? (user_doc.user_metadata.position) : "(Unspecified)"
    }
    case "department": {
      return (user_doc.user_metadata.department && user_doc.user_metadata.department !== "UNSET") ? (user_doc.user_metadata.department) : "(Unspecified)"
    }
    case "division": {
      return (user_doc.user_metadata.division && user_doc.user_metadata.division !== "UNSET") ? (user_doc.user_metadata.division) : "(Unspecified)"
    }
    case "organization": {
      return (user_doc.user_metadata.organization && user_doc.user_metadata.organization !== "UNSET") ? (user_doc.user_metadata.organization) : "(Unspecified)"
    }
    case "type": {
      return is_formatted ? user_type_map[user_doc.type] : user_doc.type
    }
    case "user_created_at": {
      return is_formatted ? get_date_string_short(user_doc.user_created_at) : user_doc.user_created_at
    }
    case "manager_id": {
      return (user_doc.user_metadata.manager_id && user_doc.user_metadata.manager_id !== "UNSET") ? (user_doc.user_metadata.manager_id) : "(Unspecified)"
    }
    case "manager_name": {
      return (user_doc.user_metadata.manager_name && user_doc.user_metadata.manager_name !== "UNSET") ? (user_doc.user_metadata.manager_name) : "(Unspecified)"
    }
    case "preferred_language": {
      return (user_doc.user_metadata.preferred_language && user_doc.user_metadata.preferred_language !== "UNSET") ? (user_doc.user_metadata.preferred_language) : "(Unspecified)"
    }
    case "city": {
      return (user_doc.user_metadata.city && user_doc.user_metadata.city !== "UNSET") ? (user_doc.user_metadata.city) : "(Unspecified)"
    }
    case "country": {
      return (user_doc.user_metadata.country && user_doc.user_metadata.country !== "UNSET") ? (user_doc.user_metadata.country) : "(Unspecified)"
    }
    case "locale": {
      return (user_doc.user_metadata.locale && user_doc.user_metadata.locale !== "UNSET") ? (user_doc.user_metadata.locale) : "(Unspecified)"
    }
    case "timezone": {
      return (user_doc.user_metadata.timezone && user_doc.user_metadata.timezone !== "UNSET") ? (user_doc.user_metadata.timezone) : "(Unspecified)"
    }
    case "phone_number": {
      return (user_doc.user_metadata.phone_number && user_doc.user_metadata.phone_number !== "UNSET") ? (user_doc.user_metadata.phone_number) : "(Unspecified)"
    }
    case "social_linkedin_url": {
      return (user_doc.user_metadata.social_metadata.linkedin_url && user_doc.user_metadata.social_metadata.linkedin_url !== "UNSET") ? (user_doc.user_metadata.social_metadata.linkedin_url) : "(Unspecified)"
    }
    case "social_instagram_url": {
      return (user_doc.user_metadata.social_metadata.instagram_url && user_doc.user_metadata.social_metadata.instagram_url !== "UNSET") ? (user_doc.user_metadata.social_metadata.instagram_url) : "(Unspecified)"
    }
    case "social_facebook_url": {
      return (user_doc.user_metadata.social_metadata.facebook_url && user_doc.user_metadata.social_metadata.facebook_url !== "UNSET") ? (user_doc.user_metadata.social_metadata.facebook_url) : "(Unspecified)"
    }
    case "authentication_type": {
      return is_formatted ? authentication_type_map[user_doc.authentication_metadata.type] : user_doc.authentication_metadata.type
    }
    case "authentication_email_is_validated": {
      return is_formatted ? (user_doc.authentication_metadata.email_is_validated ? "Yes" : "No") : user_doc.authentication_metadata.email_is_validated
    }
    case "authentication_password_is_set": {
      return is_formatted ? (user_doc.authentication_metadata.password_is_set ? "Yes" : "No") : user_doc.authentication_metadata.password_is_set
    }
    case "authentication_sso_method": {
      return is_formatted ? (authentication_sso_method_map[user_doc.authentication_metadata.sso_method] || "(Unspecified)") : user_doc.authentication_metadata.sso_method
    }
    case "provision_type": {
      return is_formatted ? provision_type_map[user_doc.provision_metadata.type] : user_doc.provision_metadata.type
    }

    // Status properties
    // case "most_recent_simulation_program_status": {
    //   return user_doc.most_recent_simulation_program ? (is_formatted ? program_status_map[user_doc.most_recent_simulation_program.simulation_status] : user_doc.most_recent_simulation_program.simulation_status) : ("(Does not exist)")
    // }
    // case "most_recent_training_program_status": {
    //   return user_doc.most_recent_training_program ? (is_formatted ? program_status_map[user_doc.most_recent_training_program.training_status] : user_doc.most_recent_training_program.training_status) : ("(Does not exist)")
    // }
    // case "most_recent_simulation_program_created_at": {
    //   return user_doc.most_recent_simulation_program ? (is_formatted ? get_date_string_short(user_doc.most_recent_simulation_program.program_created_at) : user_doc.most_recent_simulation_program.program_created_at) : ("(Does not exist)")
    // }
    // case "most_recent_training_program_created_at": {
    //   return user_doc.most_recent_training_program ? (is_formatted ? get_date_string_short(user_doc.most_recent_training_program.program_created_at) : user_doc.most_recent_training_program.program_created_at) : ("(Does not exist)")
    // }


    case "most_recent_simulation_program_status": {
      return user_doc.simulation_programs[0] ? (is_formatted ? program_status_map[user_doc.simulation_programs[0].simulation_status] : user_doc.simulation_programs[0].simulation_status) : ("(Does not exist)")
    }
    case "most_recent_training_program_status": {
      return user_doc.training_programs[0] ? (is_formatted ? program_status_map[user_doc.training_programs[0].training_status] : user_doc.training_programs[0].training_status) : ("(Does not exist)")
    }
    case "most_recent_simulation_program_created_at": {
      return user_doc.simulation_programs[0] ? (is_formatted ? get_date_string_short(user_doc.simulation_programs[0].program_created_at) : user_doc.simulation_programs[0].program_created_at) : ("(Does not exist)")
    }
    case "most_recent_training_program_created_at": {
      return user_doc.training_programs[0] ? (is_formatted ? get_date_string_short(user_doc.training_programs[0].program_created_at) : user_doc.training_programs[0].program_created_at) : ("(Does not exist)")
    }

    case "most_recent_x_simulations_opened_email": {
      return null
    }
    case "most_recent_x_simulations_opened_link": {
      return null
    }
    case "most_recent_x_simulations_replied_email": {
      return null
    }
    case "most_recent_x_simulations_active": {
      const first_non_match_index = user_doc.simulation_programs.findIndex(simulation_program => simulation_program.simulation_status !== "active")
      return first_non_match_index === -1 ? user_doc.simulation_programs.length : first_non_match_index
    }
    case "most_recent_x_simulations_failed": {
      const first_non_match_index = user_doc.simulation_programs.findIndex(simulation_program => simulation_program.simulation_status !== "failed")
      return first_non_match_index === -1 ? user_doc.simulation_programs.length : first_non_match_index
    }
    case "most_recent_x_simulations_reported": {
      const first_non_match_index = user_doc.simulation_programs.findIndex(simulation_program => simulation_program.simulation_status !== "reported")
      return first_non_match_index === -1 ? user_doc.simulation_programs.length : first_non_match_index
    }
    case "most_recent_x_trainings_active": {
      const first_non_match_index = user_doc.training_programs.findIndex(training_program => training_program.training_status !== "active")
      return first_non_match_index === -1 ? user_doc.training_programs.length : first_non_match_index
    }
    case "most_recent_x_trainings_completed": {
      const first_non_match_index = user_doc.training_programs.findIndex(training_program => training_program.training_status !== "completed")
      return first_non_match_index === -1 ? user_doc.training_programs.length : first_non_match_index
    }

    // Error
    default: {
      return "Undefined"
    }
  }
}

export const get_user_property_filters = (property) => {
  switch(property) {
    case "first_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "last_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "email_address": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "position": {
      return {
        equals_text: {},
        // equals_option: {
        //   options: position_options,
        //   format: (value) => { return value }
        // },
        includes_text: {}
      }
    }
    case "department": {
      return {
        equals_text: {},
        // equals_option: {
        //   options: department_options,
        //   format: (value) => { return value }
        // },
        includes_text: {}
      }
    }
    case "division": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "organization": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "type": {
      return {
        equals_option: {
          options: user_type_options,
          format: (value) => { return user_type_map[value] }
        },
        includes_text: {}
      }
    }
    case "user_created_at": {
      return {
        before_date: {},
        after_date: {},
        equals_text: {},
        includes_text: {}
      }
    }
    case "manager_id": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "manager_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "preferred_language": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "city": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "country": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "locale": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "timezone": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "phone_number": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "social_linkedin_url": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "social_instagram_url": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "social_facebook_url": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "authentication_type": {
      return {
        equals_option: {
          options: authentication_type_options,
          format: (value) => { return authentication_type_map[value] }
        },
        includes_text: {}
      }
    }
    case "authentication_email_is_validated": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "authentication_password_is_set": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "authentication_sso_method": {
      return {
        equals_option: {
          options: authentication_sso_method_options,
          format: (value) => { return authentication_sso_method_map[value] } 
        },
        includes_text: {}
      }
    }
    case "provision_type": {
      return {
        equals_option: {
          options: provision_type_options,
          format: (value) => { return provision_type_map[value] } 
        },
        includes_text: {}
      }
    }
    case "most_recent_simulation_program_status": {
      return {
        equals_option: {
          options: simulation_program_status_options,
          format: (value) => { return program_status_map[value] } 
        },
        includes_text: {}
      }
    }
    case "most_recent_training_program_status": {
      return {
        equals_option: {
          options: training_program_status_options,
          format: (value) => { return program_status_map[value] } 
        },
        includes_text: {}
      }
    }
    case "most_recent_simulation_program_created_at": {
      return {
        before_date: {},
        after_date: {},
        equals_text: {},
        includes_text: {}
      }
    }
    case "most_recent_training_program_created_at": {
      return {
        before_date: {},
        after_date: {},
        equals_text: {},
        includes_text: {}
      }
    }
    case "most_recent_x_simulations_opened_email": {
      return {
        equals_number: {},
        lt_number: {},
        gt_number: {},
        lte_number: {},
        gte_number: {},
      }
    }
    case "most_recent_x_simulations_opened_link": {
      return {
        equals_number: {},
        lt_number: {},
        gt_number: {},
        lte_number: {},
        gte_number: {},
      }
    }
    case "most_recent_x_simulations_replied_email": {
      return {
        equals_number: {},
        lt_number: {},
        gt_number: {},
        lte_number: {},
        gte_number: {},
      }
    }
    case "most_recent_x_simulations_active": {
      return {
        equals_number: {},
        lt_number: {},
        gt_number: {},
        lte_number: {},
        gte_number: {},
      }
    }
    case "most_recent_x_simulations_failed": {
      return {
        equals_number: {},
        lt_number: {},
        gt_number: {},
        lte_number: {},
        gte_number: {},
      }
    }
    case "most_recent_x_simulations_reported": {
      return {
        equals_number: {},
        lt_number: {},
        gt_number: {},
        lte_number: {},
        gte_number: {},
      }
    }
    case "most_recent_x_trainings_active": {
      return {
        equals_number: {},
        lt_number: {},
        gt_number: {},
        lte_number: {},
        gte_number: {},
      }
    }
    case "most_recent_x_trainings_completed": {
      return {
        equals_number: {},
        lt_number: {},
        gt_number: {},
        lte_number: {},
        gte_number: {},
      }
    }
    default: {
      return {}
    }
  }
}







export const program_property_map = {
  first_name: "First name", // program_doc.user_metadata.first_name
  last_name: "Last name", // program_doc.user_metadata.last_name
  email_address: "Email address", // program_doc.email_address
  simulation_status: "Simulation status", // program_doc.simulation_status
  training_status: "Training status", // program_doc.training_status
  initial_email_is_sent: "Initial email is sent", // (complex logic)
  initial_email_status: "Initial email status", // (complex logic)
  open_email_is_done: "Opened email", // "open_email" in program_doc.actions
  report_email_is_done: "Reported email", // "report_email" in program_doc.actions
  open_link_is_done: "Opened link", // "open_link" in program_doc.actions
  submit_website_is_done: "Submitted info on website", // "submit_website" in program_doc.actions
  reply_email_is_done: "Replied to email", // "reply_email" in program_doc.actions
  reply_email_objective_is_done: "Replied to email (objective)", // "reply_email_objective" in program_doc.actions
  reply_message_is_done: "Replied to message", // "reply_message" in program_doc.actions
  reply_message_objective_is_done: "Replied to message (objective)", // "reply_message_objective" in program_doc.actions
  program_created_at: "Program created at", // program_doc.program_created_at
  name: "Program name", // program_doc.program_metadata.name
}

export const get_program_property_value = (program_doc, program_property, is_formatted) => {
  switch(program_property) {
    case "first_name": {
      return program_doc.user_metadata.first_name
    }
    case "last_name": {
      return program_doc.user_metadata.last_name
    }
    case "email_address": {
      return program_doc.email_address
    }
    case "simulation_status": {
      return is_formatted ? program_status_map[program_doc.simulation_status] : program_doc.simulation_status
    }
    case "training_status": {
      return is_formatted ? program_status_map[program_doc.training_status] : program_doc.training_status
    }
    case "initial_email_is_sent": {
      const is_done = !((!program_doc.jobs.send_email || program_doc.jobs.send_email.length === 0) || (program_doc.jobs.send_email.length === 1 && !program_doc.jobs.send_email[0].job_is_executed))
      return is_formatted ? (is_done ? "Yes" : "No") : is_done
    }
    case "initial_email_status": {
      return (program_doc.jobs.send_email && program_doc.jobs.send_email[0]) ? (is_formatted ? job_status_map[program_doc.jobs.send_email[0].job_status] : program_doc.jobs.send_email[0].job_status) : (is_formatted ? "N/A" : "n/a")
    }
    case "open_email_is_done": {
      const is_done = "open_email" in program_doc.actions
      return is_formatted ? (is_done ? "Yes" : "No") : is_done
    }
    case "report_email_is_done": {
      const is_done = "report_email" in program_doc.actions
      return is_formatted ? (is_done ? "Yes" : "No") : is_done
    }
    case "open_link_is_done": {
      const is_done = "open_link" in program_doc.actions
      return is_formatted ? (is_done ? "Yes" : "No") : is_done
    }
    case "submit_website_is_done": {
      const is_done = "submit_website" in program_doc.actions
      return is_formatted ? (is_done ? "Yes" : "No") : is_done
    }
    case "reply_email_is_done": {
      const is_done = "reply_email" in program_doc.actions
      return is_formatted ? (is_done ? "Yes" : "No") : is_done
    }
    case "reply_email_objective_is_done": {
      const is_done = "reply_email_objective" in program_doc.actions
      return is_formatted ? (is_done ? "Yes" : "No") : is_done
    }
    case "reply_message_is_done": {
      const is_done = "reply_message" in program_doc.actions
      return is_formatted ? (is_done ? "Yes" : "No") : is_done
    }
    case "reply_message_objective_is_done": {
      const is_done = "reply_message_objective" in program_doc.actions
      return is_formatted ? (is_done ? "Yes" : "No") : is_done
    }
    case "program_created_at": {
      return is_formatted ? get_date_string_short(program_doc.program_created_at) : program_doc.program_created_at
    }
    case "name": {
      return program_doc.program_metadata.name
    }

    // Error
    default: {
      return "Undefined"
    }
  }
}

export const get_program_property_filters = (property) => {
  switch(property) {
    case "first_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "last_name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "email_address": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    case "simulation_status": {
      return {
        equals_option: {
          options: simulation_program_status_options,
          format: (value) => { return program_status_map[value] }
        },
        includes_text: {}
      }
    }
    case "training_status": {
      return {
        equals_option: {
          options: training_program_status_options,
          format: (value) => { return program_status_map[value] }
        },
        includes_text: {}
      }
    }
    case "initial_email_is_sent": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "initial_email_status": {
      return {
        equals_option: {
          options: job_status_options,
          format: (value) => { return job_status_map[value] }
        },
        includes_text: {}
      }
    }
    case "open_email_is_done": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "report_email_is_done": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "open_link_is_done": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "submit_website_is_done": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "reply_email_is_done": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "reply_email_objective_is_done": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "reply_message_is_done": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "reply_message_objective_is_done": {
      return {
        equals_option: {
          options: [true, false],
          format: (value) => { return value ? "Yes" : "No" }
        },
        includes_text: {}
      }
    }
    case "program_created_at": {
      return {
        before_date: {},
        after_date: {},
        equals_text: {},
        includes_text: {}
      }
    }
    case "name": {
      return {
        equals_text: {},
        includes_text: {}
      }
    }
    default: {
      return {}
    }
  }
}





// **************************************
// Job types
// **************************************


export const job_status_map = {
  none: "None",
  email_delivered: "Email delivered",
  email_deferred: "Email deferred",
  email_bounced: "Email bounced",
  message_delivered: "Message delivered",
}





// **************************************
// Campaign types
// **************************************


export const campaign_type_map = {
  'simulation': 'Simulation',
  'training': 'Training',
}

export const campaign_delivery_type_map = {
  "window": "Space out delivery within a time window",
  "same": "Deliver all at the same time",
}


export const simulation_objective_map = {
  'open_link': 'Get user to click on a link',
  "open_link_submit_website": "Get user to open a website and submit info",
  'open_attachment': 'Get user to download and open an attachment',
  'reply_info': 'Get user to reply with information',
}


export const simulation_type_map = {
  'multi_message': 'Spear phishing',
  'single_send': 'Basic phishing',
}

export const training_module_map = {
  "simulation_debrief": "Post-simulation debrief", // post simulation debrief
  "ransomware": "Ransomware",
  "malware": "Malware",
  "phishing": "Phishing",
  "spear_phishing": "Spear phishing",
  "smishing": "Smishing",
  "vishing": "Vishing",
  "deep_fake": "Deepfake",
  "business_email_compromise": "Business email compromise",
  "digital_footprint": "Digital footprint",
  "linkedin_footprint": "LinkedIn footprint",
  "ceo_fraud": "CEO fraud",
  "passwords": "Passwords",
  "mfa": "MFA",
  "personal_data": "Personal data",
  "mobile_phone": "Mobile phone",
  "tailgating": "Tailgating",
  "out_of_office": "Out of office",
  "privacy_by_design": "Privacy by design",
  "soc2_training": "SOC2 training",
  "iso27001_training": "ISO27001 training",
  "hipaa_training": "HIPAA training",
  "pci_training": "PCI training",
  "gdpr_training": "GDPR training",
  "ccpa_training": "CCPA training",
}

export const campaign_status_map = {
  "draft": "Draft",
  "creating": "Creating",
  "active": "Active",
  "terminated": "Terminated",
}

// **************************************
// Program types
// **************************************


export const program_status_map = {
  // Universal statuses
  'none': 'None',
  'draft': 'Draft',
  'active': 'Active',
  'terminated': 'Terminated',
  // Simulation-only statuses
  'reported': 'Reported',
  'failed': 'Failed',
  // Training-only statuses
  'completed': 'Completed',
}


export const job_type_map = {
  'send_email': 'Send email',
  'send_message': '(Training) Send message',
}

export const action_type_map = {
  'open_email': 'Opened email (1st time)',
  'report_email': 'Reported email',
  'open_link': 'Opened link',
  'submit_website': 'Submitted info on website',
  'reply_email': 'Replied to email',
  'reply_email_objective': 'Replied to email (simulation objective achieved)',
  'reply_message': '(Training) Replied to message',
  'reply_message_objective': '(Training) Replied to message (training objective achieved)',
}






// **************************************
// Authentication types
// **************************************

export const authentication_type_map = {
  'disabled': 'Disabled',
  'password': 'Password',
  'sso': 'Single Sign-On',
}

export const authentication_sso_method_map = {
  'google_oauth': 'Google OAuth',
}


// **************************************
// Provision types
// **************************************

export const provision_type_map = {
  'disabled': 'Disabled',
  'native': 'Dashboard',
  'scim': 'SCIM',
}





export const submit_website_field_map = {
  first_name: "First name",
  last_name: "Last name",
  email_address: "Email address",
  password: "Password",
  credit_card_number: "Credit card number",
  credit_card_month: "Credit card month",
  credit_card_year: "Credit card year",
  credit_card_cvv: "Credit card CVV",
  credit_card_zip_code: "Credit card zip code",
}








// **************************************
// Stripe
// **************************************

export const get_invoice_status = (amount, status) => {
  if (amount === 0) {
    switch (status) {
      case "paid": {
        return "Settled"
      }
      case "void": {
        return "Voided"
      }
      case "open": {
        return "Failed"
      }
      case "draft": {
        return "Upcoming"
      }
      default: {
        return "Error occurred"
      }
    }
  }
  // Payment amount is positive (debit)
  else if (amount > 0) {
    switch (status) {
      case "paid": {
        return "Paid"
      }
      case "void": {
        return "Voided"
      }
      case "open": {
        return "Failed"
      }
      case "draft": {
        return "Upcoming"
      }
      default: {
        return "Error occurred"
      }
    }
  }
  // Payment amount is negative (credit)
  else if (amount < 0) {
    switch (status) {
      case "paid": {
        return "Credited"
      }
      case "void": {
        return "Voided"
      }
      case "open": {
        return "Failed"
      }
      case "draft": {
        return "Upcoming"
      }
      default: {
        return "Error occurred"
      }
    }
  }
}


export const beautify_usd_amount = ( usd_amount ) => {

  let res = '';

  // Zero
  if (usd_amount === 0) {
    res = '$0'
  } 

  // Positive
  else if (usd_amount > 0) {

    const usd_amount__string = usd_amount.toString();

    if (usd_amount__string.length === 1) {
      res = `$0.0${usd_amount__string}`;
    }
    else {
      if (!(usd_amount__string.slice(0, usd_amount__string.length-2))) {
        res = `$0.${usd_amount__string.slice(usd_amount__string.length-2)}`;
      } else {
        res = `$${usd_amount__string.slice(0, usd_amount__string.length-2)}.${usd_amount__string.slice(usd_amount__string.length-2)}`;
      }
    }
  }

  // Negative
  else if (usd_amount < 0) {

    const abs_usd_amount = Math.abs(usd_amount)
    const abs_usd_amount__string = abs_usd_amount.toString();
    
    if (abs_usd_amount__string.length === 1) {
      res = `-$0.0${abs_usd_amount__string}`;
    }
    else {
      if (!(abs_usd_amount__string.slice(0, abs_usd_amount__string.length-2))) {
        res = `-$0.${abs_usd_amount__string.slice(abs_usd_amount__string.length-2)}`;
      } else {
        res = `-$${abs_usd_amount__string.slice(0, abs_usd_amount__string.length-2)}.${abs_usd_amount__string.slice(abs_usd_amount__string.length-2)}`;
      }
    }
  }


  return res;
}




