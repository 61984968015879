import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ClipboardDocumentListIcon, XMarkIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'


import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'

import ReactLoading from "react-loading"


import { useLocation, useNavigate, Link } from "react-router-dom";
import { auth_axios } from '../../../libs/authWeb';

import { show_notification, sleep, classNames } from '../../../libs/helpers'
import { CSPRNG } from '../../../libs/crypto'
import { uint8_array_to_base64 } from '../../../libs/converters'


import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'







const EnableSCIMModal = ({
  is_open,
  set_is_open,
  set_refresh_variable
} : {
  is_open : boolean
  set_is_open : any // todo
  set_refresh_variable : any // todo
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // Location
  const location = useLocation()

  // Organization data
  const [organization_data, set_organization_data] = useState({})
  const [organization_data_is_fetched, set_organization_data_is_fetched] = useState(false)

  const [scim_token, set_scim_token] = useState("")

  // Clipboard copied
  const [scim_url_is_copied_to_clipboard, set_scim_url_is_copied_to_clipboard] = useState(false)
  const [scim_token_is_copied_to_clipboard, set_scim_token_is_copied_to_clipboard] = useState(false)

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [scim_is_updated, set_scim_is_updated] = useState(false)

  const [warning_message, set_warning_message] = useState("")
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      default: {
        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  // Handle copy to clipboard
  const handle_copy_to_clipboard = async (type) => {
    switch(type) {
      case "scim_url": {
        await navigator.clipboard.writeText(`https://${organization_data["organization_id"]}.vansec.com/scim/v2`)

        set_scim_token_is_copied_to_clipboard(false)
        set_scim_url_is_copied_to_clipboard(true)

        show_notification(protected_context, protected_update, "success", "Success", `Tenant / base URL is copied to clipboard`)

        await sleep(3.0)

        set_scim_url_is_copied_to_clipboard(false)

        // Always break
        break
      }
      case "scim_token": {
        await navigator.clipboard.writeText(scim_token)

        set_scim_url_is_copied_to_clipboard(false)
        set_scim_token_is_copied_to_clipboard(true)

        show_notification(protected_context, protected_update, "success", "Success", `SCIM token is copied to clipboard`)

        await sleep(3.0)

        set_scim_token_is_copied_to_clipboard(false)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const get_organization_data = async () => {
    // Set is_fetched to false
    set_organization_data_is_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_organization_data_is_fetched(true)
  }



  const generate_scim_token = () => {
    const scim_token__uint8_array = CSPRNG(32)
    const scim_token = uint8_array_to_base64(scim_token__uint8_array)
    set_scim_token(scim_token)

    // Always hide error message and reset it to empty string
    set_warning_message("SCIM token will not be shown again. Please make sure to copy this to a safe place.")
    set_error_message("")
  }


  const submit = async () => {
    
    // Reset error message
    set_error_message("")

    // Set is_fetched to false
    set_is_awaiting(true)

    // START OF USER INPUT CHECK
    

    // END OF USER INPUT CHECK

    // Execute udpate email
    const update_allowlisted_res = await auth_axios.put(`/api/organizations/enable/scim`, {
      scim_token: scim_token
    })

    if (!update_allowlisted_res.data.success) {
      switch (update_allowlisted_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // // Redirect to dashboard/organization page
          // navigate(`/dashboard/organization`) 
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (update_allowlisted_res.data.status) {
      case "SUCCESS": {
        set_is_awaiting(false)
        set_scim_is_updated(true)
        set_success_message(`Successfully updated`)

        // Show success notification
        show_notification(protected_context, protected_update, "success", "Success", `Successfully updated`)
        
        // Re-render /dashboard/users
        set_refresh_variable(new Date())

        // Always break
        break
      }
      case "_____": {

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  // Renders  
  useEffect(() => {

    get_organization_data()

  }, [])

  return (
    <Transition.Root show={is_open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={set_is_open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">

                
                {/* Dismiss button */}
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <div
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                    onClick={() => set_is_open(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                
                {/* Modal body */}
                <div>
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-blue-500">
                        {`${organization_data_is_fetched ? (organization_data["provision_metadata"]["scim_is_enabled"] ? "Update" : "Enable") : "Enable"} SCIM`}
                      </h1>
                      <p className="mt-2 text-sm text-gray-700">
                      {`${organization_data_is_fetched ? (organization_data["provision_metadata"]["scim_is_enabled"] ? "Update" : "Enable") : "Enable"} SCIM integration`}
                      </p>
                    </div>
                  </div>

                  <div className="mt-2 flow-root">

                    
                    
                    <dl className="mt-4 space-y-6 border-t border-gray-200 text-sm">




                      {/* Top row */}
                      {/* <div className="pt-6 font-medium text-sm text-gray-900">
                        Please confirm that you have allowlisted all domains available to your organization following the <Link to="#" className="text-blue-600 hover:text-blue-500">How to allowlist domains</Link> guide.
                      </div> */}
                      <div className="font-medium text-sm text-gray-900 flex space-x-2 items-center pt-6">
                        <span className="min-w-[130px]">{`Tenant / base URL: `}</span>
                        <div 
                          className={classNames(scim_url_is_copied_to_clipboard ? "" : "cursor-pointer" , "w-9/12 cursor-pointer")}
                          onClick={scim_url_is_copied_to_clipboard ? null : async () => await handle_copy_to_clipboard("scim_url")}
                        >
                          <div
                            className="block w-full rounded-md border-0 py-1.5 px-3 text-blue-500 shadow-sm ring-1 ring-inset ring-blue-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          >
                            {organization_data_is_fetched ? `https://${organization_data["organization_id"]}.vansec.com/scim/v2` : ""}
                          </div>
                        </div>
                        {scim_url_is_copied_to_clipboard
                        ? <ClipboardDocumentCheckIcon
                          className="h-6 w-6 text-green-400"
                        />
                        : <ClipboardDocumentListIcon
                          className={classNames(scim_url_is_copied_to_clipboard ? "" : "cursor-pointer" , "h-6 w-6 text-blue-400 hover:text-blue-500")}
                          onClick={scim_url_is_copied_to_clipboard ? null : async () => await handle_copy_to_clipboard("scim_url")}
                        />}
                      </div>

                      {/* Bottom row text */}
                      <div className="font-medium text-sm text-gray-900 flex space-x-2 items-center justify-between">
                        <div className="font-medium text-gray-900 pr-6 space-x-2 flex items-center">
                          <span className="min-w-[130px]">{`SCIM token:`}</span>
                            {scim_token
                            ? <div
                              className={classNames(scim_token_is_copied_to_clipboard ? "" : "cursor-pointer" , "block w-full rounded-md border-0 py-1.5 px-3 text-blue-500 shadow-sm ring-1 ring-inset ring-blue-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6")}
                              onClick={scim_token_is_copied_to_clipboard ? null : async () => await handle_copy_to_clipboard("scim_token")}
                            >
                              {scim_token}
                            </div>
                            : <button 
                              className="flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 text-white bg-blue-600 hover:bg-blue-500"
                              onClick={generate_scim_token}
                            >
                              <span>Generate SCIM Token</span>
                            </button>}
                          {scim_token_is_copied_to_clipboard
                          ? <ClipboardDocumentCheckIcon
                            className="h-6 w-6 text-green-400"
                          />
                          : <ClipboardDocumentListIcon
                            className={classNames(!scim_token ||scim_token_is_copied_to_clipboard ? "text-gray-400" : "text-blue-400 hover:text-blue-500 cursor-pointer" , "h-6 w-6")}
                            onClick={!scim_token || scim_token_is_copied_to_clipboard ? null : async () => await handle_copy_to_clipboard("scim_token")}
                          />}
                        </div>
                        <button 
                          className={classNames(
                            is_awaiting || scim_is_updated || !scim_token ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                            'flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                          )}
                          onClick={submit}
                          disabled={is_awaiting || scim_is_updated || !scim_token}
                        >
                          {!is_awaiting
                          ? <span>{organization_data_is_fetched ? (organization_data["provision_metadata"]["scim_is_enabled"] ? "Update" : "Enable") : "Enable"}</span>
                          :<ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />}
                        </button> 
                      </div>

                      {/* Warning message */}
                      {warning_message
                      ? <div className="mt-6 flex space-x-2 items-start">
                          <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-yellow-400 h-full"/>
                          <div className="text-sm font-medium text-yellow-400">{warning_message}</div>
                        </div>
                      : <></>}

                      {/* Error message */}
                      {error_message
                      ? <div className="mt-6 flex space-x-2 items-start">
                          <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                          <div className="text-sm font-medium text-red-400">{error_message}</div>
                        </div>
                      : <></>}

                      {/* Success message */}
                      {scim_is_updated && success_message
                      ? <div className="mt-6 flex space-x-2 items-start">
                          <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                          <div className="text-sm font-medium text-green-600">{success_message}</div>
                        </div>
                      : <></>}

                    </dl>
                  </div>


                  

                </div>

              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default EnableSCIMModal;
