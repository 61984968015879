
import { useEffect, useState, useRef } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../contexts/ProtectedContext'

import { useLocation, useNavigate } from "react-router-dom"


import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon, ArrowLongLeftIcon, InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'


import { auth_axios } from '../../../../../libs/authWeb';
import { mixpanel_client_track } from '../../../../../libs/mixpanelClient';
import { show_notification, classNames } from '../../../../../libs/helpers'



const billing_cycle_options = [
  // { id: 1, value: "yearly", title: 'Yearly', monthly_price: '$1.80 / user / month', yearly_price: "$21.60 / user / year", description: '20% discount' },
  { id: 1, value: "yearly", title: 'Yearly', monthly_price: '$1.80 / user / month', yearly_price: "$21.60 / user / year", description: '' },
  { id: 2, value: "monthly", title: 'Monthly', monthly_price: '$1.80 / user / month', yearly_price: "$21.60 / user / year", description: '' },
]



const PlanUpdatePlanPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Inputs
  const [num_seats, set_num_seats] = useState(0) // will update immediately to current num_seats when organization data fetches
  const [billing_cycle, set_billing_cycle] = useState(billing_cycle_options[0]) // will update immediately to current num_seats when organization data fetches

  const num_seats_ref = useRef<any>(null);

  // Organization data
  const [organization_data, set_organization_data] = useState({})
  const [organization_num_users, set_organization_num_users] = useState(0)
  const [organization_data_and_num_users_are_fetched, set_organization_data_and_num_users_are_fetched] = useState(false)

  // Status message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [is_next, set_is_next] = useState(false)
  const [is_updated, set_is_updated] = useState(false)






  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "num_seats": {
        const parsed_integer = Number(value)

        set_num_seats(parsed_integer)
  
        // Always break
        break
      }
      case "billing_cycle": {
        set_billing_cycle(value)

        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }



  const get_organization_data_and_num_users = async () => {
    // Set is_fetched to false
    set_organization_data_and_num_users_are_fetched(false)

    // Execute get organization data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get organization num users
    const get_org_num_users_res = await auth_axios.get(`/api/organizations/count/users/active`)

    if (!get_org_num_users_res.data.success) {
      switch (get_org_num_users_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // If no payment method, alert user and redirect to /plan/update/payment
    if (!get_org_res.data.organization_data.payment_method_exists) {
      // alert("Please add payment method before adding seats to your plan")
      show_notification(protected_context, protected_update, "warning", "Add payment method", "Please add payment method before adding seats to your plan")
      navigate("/dashboard/plan/update/payment")
    }

    // If no payment method, alert user and redirect to /plan
    else if (get_org_res.data.organization_data.payment_method !== "stripe") {
      // alert("Your organization uses a custom payment method. Please email support@vansec.com to modify the number of seats for your organization.")
      show_notification(protected_context, protected_update, "warning", "Custom payment method", "Your organization uses a custom payment method. Please email support@vansec.com to modify the number of seats for your organization.")
      navigate("/dashboard/plan")
    }
    
    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_num_seats(Math.max(get_org_res.data.organization_data.num_seats, get_org_num_users_res.data.num_users)) // initially set num_seats to the max of current number of seats & number of users in the org
    set_billing_cycle(get_org_res.data.organization_data.billing_cycle === "UNSET" ? billing_cycle_options[0] : billing_cycle_options.reduce((acc, option) => ({...acc, [option.value]: option}), {})[get_org_res.data.organization_data.billing_cycle]) // initially set billing_cycle to what it is currently; if currently UNSET, then set it to yearly

    set_organization_num_users(get_org_num_users_res.data.num_users)

    set_organization_data_and_num_users_are_fetched(true)
  }

  const move_to_confirm_page = () => {
    // START OF USER INPUT CHECK

    // Validate num_seats
    if (typeof num_seats !== "number") {
      // Show error message
      set_error_message("Number of seats must be a number")

      // End of the line
      return
    }

    if (num_seats < organization_num_users) {
      // Show error message
      set_error_message("Number of seats must be at least the number of users currently in your organization")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Toggle
    set_is_next(true)
  }

  const get_confirm_text = () => {

    // Status is active
    if (organization_data["status"] === "active") {
      // Sanity check validation
      if (organization_data["billing_cycle"] === "UNSET") {
        alert("An error has occurred. Please contact support@vansec.com")
        navigate(`/dashboard/plan`)
        return ""
      }

      // Current billing cycle is monthly
      if (organization_data["billing_cycle"] === "monthly") {
        // SCENARIO 1 - Update num_seats for existing monthly billing
        if (billing_cycle.value === "monthly") {
          // Number of seats is increasing
          if (num_seats > organization_data["num_seats"]) {
            return `Starting with the next billing cycle, you will be charged $${num_seats * 5} plus tax every month. There will be a surcharge applied to your upcoming invoice(s) for the prorated increase in usage from the increased number of users from now until the end of this billing cycle.`
          }
          // Number of seats is decreasing
          else {
            return `Starting with the next billing cycle, you will be charged $${num_seats * 5} plus tax every month. There will be a credit applied to your upcoming invoice(s) for the prorated decrease in usage from the decreased number of users from now until the end of this billing cycle.`
          }
        }
        // SCENARIO 2 - Update num_seats and change billing from monthly to yearly
        else if (billing_cycle.value === "yearly") {
          return `Since you are changing your billing cycle from monthly to yearly, your monthly subscription will be canceled immediately and a new yearly subscription will be created. There will be prorated credit applied to your account for the duration of time left on your current monthly billing cycle (this may take a few hours to process), which will be applied towards the invoice(s) for your yearly subscription.`
        }
      }

      // Current billing cycle is monthly
      else if (organization_data["billing_cycle"] === "yearly") {
        // SCENARIO 3 - Update num_seats and change billing from yearly to monthly
        if (billing_cycle.value === "monthly") {
          return `Since you are changing your billing cycle from yearly to monthly, your yearly subscription will be canceled immediately and a new monthly subscription will be created. There will be prorated credit applied to your account for the duration of time left on your current yearly billing cycle (this may take a few hours to process), which will be applied towards the invoice(s) for your monthly subscription.`
        }
        // SCENARIO 4 - Update num_seats for existing yearly billing
        else if (billing_cycle.value === "yearly") {
          // Number of seats is increasing
          if (num_seats > organization_data["num_seats"]) {
            return `Starting with the next billing cycle, you will be charged $${num_seats * 48} plus tax every year. There will be a surcharge applied to your upcoming invoice(s) for the prorated increase in usage from the increased number of users from now until the end of this billing cycle.`
          }
          // Number of seats is decreasing
          else {
            return `Starting with the next billing cycle, you will be charged $${num_seats * 48} plus tax every year. There will be a credit applied to your upcoming invoice(s) for the prorated decrease in usage from the decreased number of users from now until the end of this billing cycle.`
          }
        }
      }

      // This should never happen
      else {
        alert("A critical error has occurred. Please contact support@vansec.com")
        navigate(`/dashboard/plan`)
        return ""
      }
    }

    // Status is not active
    else {
      // Sanity check validation
      if (organization_data["billing_cycle"] !== "UNSET") {
        alert("An error has occurred. Please contact support@vansec.com")
        navigate(`/dashboard/plan`)
        return ""
      }

      // SCENARIO 5 - New monthly subscription
      if (billing_cycle.value === "monthly") {
        return `You will be charged $${num_seats * 5} plus tax today.`
      }

      // SCENARIO 6 - New yearly subscription
      else if (billing_cycle.value === "yearly") {
        return `You will be charged $${num_seats * 48} plus tax today.`
      }
      // This should never happen
      else {
        alert("A critical error has occurred. Please contact support@vansec.com")
        navigate(`/dashboard/plan`)
        return ""
      } 
    }
  }

  const update_num_seats = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate num_seats
    if (typeof num_seats !== "number") {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Number of seats must be a number")

      // End of the line
      return
    }

    if (num_seats < organization_num_users) {
      set_is_awaiting(false)

      // Show error message
      set_error_message("Number of seats must be at least the number of users currently in your organization")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Execute update stripe plan
    const put_org_seats = await auth_axios.put(`/api/organizations/stripe/plan`, {
      num_seats: num_seats,
      billing_cycle: billing_cycle.value
    })

    if (!put_org_seats.data.success) {
      switch (put_org_seats.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/plan page
          navigate(`/dashboard/plan`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (put_org_seats.data.status) {
      case "SUCCESS": {
        set_is_updated(true)
        set_is_awaiting(false)
        set_success_message("Your plan has been updated.")

        // Always break
        break
      }
      case "TAX_LOCATION_INVALID": {
        // alert("We could not verify the zip code of your payment method. Please update your payment method before updating the plan.")
        show_notification(protected_context, protected_update, "error", "Error", "We could not verify the zip code of your payment method. Please update your payment method before updating the plan.")

        navigate(`/dashboard/plan/update/payment`)

        // Always break
        break
      }
      case "FAILURE": {
        // alert("Error occurred while updating number of seats. Please try again")
        show_notification(protected_context, protected_update, "error", "Error", "Error occurred while updating number of seats. Please try again.")

        navigate(`/dashboard/plan`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  // Renders 
  useEffect(() => { 

    get_organization_data_and_num_users()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_plan_update_plan_visited", global_context.user_id)

  }, [])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Update plan</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
          The number of seats for your plan must be at least the number of users you have added to your organization
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex sm:flex-col max-w-[400px] justify-center items-center">
              <div className="w-full space-y-4">
                {!is_next
                ? <>
                  {/* Number of seats */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-medium">Number of seats</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          Number of seats should equal the number of users you wish to have in your organization. You won't be able to run campaigns if the number of users in your organization exceeds the number of seats.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      {organization_data_and_num_users_are_fetched
                      ? <input
                          type="number"
                          min={organization_num_users}
                          required
                          className={classNames(
                            is_awaiting || is_updated ? "bg-gray-100" : "",
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          )}
                          ref={num_seats_ref}
                          value={num_seats}
                          onChange={(e) => handle_user_input("num_seats", e.target.value)}
                          disabled={is_awaiting || is_updated}
                        />
                      : <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />}
                    </div>
                  </div>

                  {/* Billing cycle */}
                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Billing cycle
                    </label>
                    <div className="mt-2">
                      {organization_data_and_num_users_are_fetched
                      ? <RadioGroup value={billing_cycle} onChange={(e) => handle_user_input("billing_cycle", e)}>
                          <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                            {billing_cycle_options.map((billing_cycle_option) => (
                              <RadioGroup.Option
                                key={billing_cycle_option.id}
                                value={billing_cycle_option}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'border-blue-600 ring-2 ring-blue-600' : 'border-gray-300',
                                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                                  )
                                }
                              >
                                {({ checked, active }) => (
                                  <>
                                    <span className="flex flex-1">
                                      <span className="flex flex-col">
                                        <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                          {billing_cycle_option.title}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description as="span" className="mt-3 flex items-center text-sm text-gray-800">
                                          {billing_cycle_option.monthly_price}
                                        </RadioGroup.Description>
                                        <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                          {"("}{billing_cycle_option.yearly_price}{")"}
                                        </RadioGroup.Description>
                                        <RadioGroup.Description as="span" className="mt-3 text-sm font-normal text-gray-500">
                                          {billing_cycle_option.description}
                                        </RadioGroup.Description>
                                      </span>
                                    </span>
                                    <CheckCircleIcon
                                      className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-blue-600')}
                                      aria-hidden="true"
                                    />
                                    <span
                                      className={classNames(
                                        active ? 'border' : 'border-2',
                                        checked ? 'border-blue-600' : 'border-transparent',
                                        'pointer-events-none absolute -inset-px rounded-lg'
                                      )}
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      : <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />}
                    </div>
                  </div>

                  {/* Error message */}
                  {error_message
                  ? <div className="mt-6 flex space-x-2 items-start">
                      <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                      <div className="text-sm font-medium text-red-400">{error_message}</div>
                    </div>
                  : <></>}

                  {/* Next button */}
                  <button 
                    className={classNames(
                      !organization_data_and_num_users_are_fetched || is_awaiting || is_updated || (organization_data["num_seats"] === num_seats && organization_data["billing_cycle"] === billing_cycle.value) ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                      'mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    )}
                    onClick={() => move_to_confirm_page()}
                    disabled={!organization_data_and_num_users_are_fetched || is_awaiting || is_updated || (organization_data["num_seats"] === num_seats && organization_data["billing_cycle"] === billing_cycle.value)}
                  >
                    {!organization_data_and_num_users_are_fetched || is_awaiting
                    ? <ReactLoading
                        type='spokes'
                        color='#343D46'
                        height={20}
                        width={20}
                      />
                    : <span>Next</span>}
                  </button> 
                </>
                : <>
                  {/* Back button */}
                  {is_next && !is_updated
                  ? <div
                      className="inline-flex text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                      onClick={() => set_is_next(false)}
                    >
                      <ArrowLongLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                      Back
                    </div>
                  : <></>}

                  {/* Confirm details */}
                  <div>
                    <label className="block text-sm font-bold leading-6 text-gray-900">
                      Confirm details
                    </label>
                    <div className="mt-2 font-semibold text-gray-900">
                      {num_seats}{" seat(s), billed "}{billing_cycle.value}
                    </div>
                    <div className="mt-2 text-gray-500">
                      {get_confirm_text()}
                    </div>
                  </div>

                  {/* Success message */}
                  {is_updated && success_message
                  ? <div className="mt-6 flex space-x-2 items-start">
                      <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                      <div className="text-sm font-medium text-green-600">{success_message}</div>
                    </div>
                  : <></>}

                  {/* Submit button */}
                  <button 
                    className={classNames(
                      !organization_data_and_num_users_are_fetched || is_awaiting || is_updated || (organization_data["num_seats"] === num_seats && organization_data["billing_cycle"] === billing_cycle.value) ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                      'mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    )}
                    onClick={update_num_seats}
                    disabled={!organization_data_and_num_users_are_fetched || is_awaiting || is_updated || (organization_data["num_seats"] === num_seats && organization_data["billing_cycle"] === billing_cycle.value)}
                  >
                    {!organization_data_and_num_users_are_fetched || is_awaiting 
                    ? <ReactLoading
                        type='spokes'
                        color='#343D46'
                        height={20}
                        width={20}
                      />
                    : <span>Confirm & update plan</span>}
                  </button> 
                </>}

                
                
                
              </div>
            </div>
          </dl>
        </div>

      </div>
    </div>
  )
}

export default PlanUpdatePlanPage