import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'



import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'

import ReactLoading from "react-loading"


import { useLocation, useNavigate, Link } from "react-router-dom";
import { auth_axios } from '../../../libs/authWeb';

import { show_notification, classNames, timestamp_to_local_datetime, local_datetime_to_timestamp } from '../../../libs/helpers'






const GenerateReportModal = ({
  is_open,
  set_is_open,
  set_refresh_variable
} : {
  is_open : boolean
  set_is_open : any // todo
  set_refresh_variable : any // todo
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // Location
  const location = useLocation()

  // User input
  const [start_time, set_start_time] = useState((new Date()).getTime() - (1000 * 60 * 60 * 24 * (365 + 1))) // one year before
  const [end_time, set_end_time] = useState((new Date()).getTime())

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [report_is_generated, set_report_is_generated] = useState(false)

  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "start_time": {
        const new_timestamp = local_datetime_to_timestamp(value, "00:00")

        set_start_time(new_timestamp)

        // Always break
        break
      }
      case "end_time": {
        const new_timestamp = local_datetime_to_timestamp(value, "23:59")

        set_end_time(new_timestamp)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const submit = async () => {
    
    // Reset error message
    set_error_message("")

    // Set is_fetched to false
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate that start time is before end time
    if (start_time >= end_time) {
      set_is_awaiting(false)

      // Show error message
      set_error_message(`Start time must be before end time`)

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Execute udpate email
    const post_reports_evidence_res = await auth_axios.post(`/api/organizations/reports/evidence`, {
      start_time: start_time,
      end_time: end_time
    }, {
      responseType: "blob"
    })

    // Download file
    const blob = new Blob([post_reports_evidence_res.data], { type: 'application/pdf' });
    const href = URL.createObjectURL(blob);
    // const blob = post_reports_evidence_res.blob();
    // const href = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = href;
    link.download = `vansec-${timestamp_to_local_datetime((new Date()).getTime())[0]}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Render success
    set_is_awaiting(false)
    set_report_is_generated(true)
    set_success_message(`Successfully downloaded report`)

    // Show success notification
    show_notification(protected_context, protected_update, "success", "Success", `Successfully downloaded report`)
    
    // Re-render /dashboard/users
    set_refresh_variable(new Date())
  }

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <Transition.Root show={is_open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={set_is_open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">

                
                {/* Dismiss button */}
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <div
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                    onClick={() => set_is_open(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                
                {/* Modal body */}
                <div>
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-blue-500">
                        Generate evidence report
                      </h1>
                      {/* <p className="mt-2 text-sm text-gray-700">
                        Domains must be allowlisted before creating a campaign
                      </p> */}
                    </div>
                  </div>

                  <div className="mt-2 flow-root">

                    
                    
                    <dl className="mt-4 space-y-6 border-t border-gray-200 text-sm">




                      {/* Description text */}
                      {/* <div className="pt-6 font-medium text-sm text-gray-900">
                        Please confirm that you have allowlisted all domains available to your organization following the <Link to="#" className="text-blue-600 hover:text-blue-500">How to allowlist domains</Link> guide.
                      </div> */}


                      {/* Confirm text */}
                      <div className="pt-6 sm:flex items-center">


                        <dt className="font-medium text-gray-900 pr-6">Evidence report</dt>
                        <dd className="mt-1 flex space-x-2 sm:mt-0 sm:flex-auto pr-6 items-center">
                          <input
                            type="date"
                            required
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            value={timestamp_to_local_datetime(start_time)[0]}
                            onChange={(e) => handle_user_input("start_time", e.target.value)}
                            disabled={is_awaiting}
                          />

                          <span>~</span>

                          <input
                            type="date"
                            required
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            value={timestamp_to_local_datetime(end_time)[0]}
                            onChange={(e) => handle_user_input("end_time", e.target.value)}
                            disabled={is_awaiting}
                          />
                        </dd>


                        <button 
                          className={classNames(
                            is_awaiting || report_is_generated ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                            'flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                          )}
                          onClick={submit}
                          disabled={is_awaiting || report_is_generated}
                        >
                          {!is_awaiting
                          ? <span>Generate & download report</span>
                          :<ReactLoading
                            type='spokes'
                            color='#343D46'
                            height={20}
                            width={20}
                          />}
                        </button> 
                      </div>

                      {/* Error message */}
                      {error_message
                      ? <div className="mt-6 flex space-x-2 items-start">
                          <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-blue-400 h-full"/>
                          <div className="text-sm font-medium text-blue-400">{error_message}</div>
                        </div>
                      : <></>}

                      {/* Success message */}
                      {report_is_generated && success_message
                      ? <div className="mt-6 flex space-x-2 items-start">
                          <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                          <div className="text-sm font-medium text-green-600">{success_message}</div>
                        </div>
                      : <></>}

                    </dl>
                  </div>


                  

                </div>

              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default GenerateReportModal;
