import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition, Listbox } from '@headlessui/react'


import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'

import ReactLoading from "react-loading"


import { useLocation, useNavigate, Link } from "react-router-dom";
import { auth_axios } from '../../../libs/authWeb';

import { show_notification, sleep, classNames } from '../../../libs/helpers'
import { CSPRNG } from '../../../libs/crypto'
import { uint8_array_to_base64 } from '../../../libs/converters'


import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { ClipboardDocumentListIcon, XMarkIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { simulation_default_domain_options } from '../../../libs/options'






const SendTestEmailModal = ({
  is_open,
  set_is_open,
  set_refresh_variable
} : {
  is_open : boolean
  set_is_open : any // todo
  set_refresh_variable : any // todo
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // Location
  const location = useLocation()

  // Data
  const [test_domain, set_test_domain] = useState("")
  const [test_email_prefix, set_test_email_prefix] = useState("test")

  const [admin_email_address, set_admin_email_address] = useState("")
  const [custom_simulation_domains, set_custom_simulation_domains] = useState([])
  const [organization_data_is_fetched, set_user_data_and_organization_data_are_fetched] = useState(false)

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [test_email_is_sent, set_test_email_is_sent] = useState(false)

  const [warning_message, set_warning_message] = useState("")
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "test_domain": {
        set_test_domain(value)

        // Always break
        break
      }
      case "test_email_prefix": {
        set_test_email_prefix(value)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const get_organization_data = async () => {
    // Set is_fetched to false
    set_user_data_and_organization_data_are_fetched(false)

    // Execute get user data
    const get_user_data_res = await auth_axios.get(`/api/users/${global_context.user_id}`)

    if (!get_user_data_res.data.success) {
      switch (get_user_data_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get org data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }
    
    // Set states
    set_admin_email_address(get_user_data_res.data.user_data.email_address)
    set_custom_simulation_domains(get_org_res.data.organization_data.organization_metadata.custom_simulation_domains)
    set_test_domain(simulation_default_domain_options.concat(get_org_res.data.organization_data.organization_metadata.custom_simulation_domains)[0])
    set_user_data_and_organization_data_are_fetched(true)
  }


  const submit = async () => {
    
    // Reset error message
    set_error_message("")

    // Set is_fetched to false
    set_is_awaiting(true)

    // START OF USER INPUT CHECK
    

    // END OF USER INPUT CHECK

    // Execute udpate email
    const send_test_email_res = await auth_axios.post(`/api/organizations/send-test-email`, {
      sender_email_address: `${test_email_prefix}@${test_domain}`
    })

    if (!send_test_email_res.data.success) {
      switch (send_test_email_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // // Redirect to dashboard/organization page
          // navigate(`/dashboard/organization`) 
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (send_test_email_res.data.status) {
      case "SUCCESS": {
        set_is_awaiting(false)
        set_test_email_is_sent(true)
        set_success_message(`Test email has been sent`)

        // Show success notification
        show_notification(protected_context, protected_update, "success", "Success", `Test email has been sent`)
        
        // Re-render /dashboard/users
        set_refresh_variable(new Date())

        // Always break
        break
      }
      case "_____": {

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  // Renders  
  useEffect(() => {

    get_organization_data()

  }, [])

  return (
    <Transition.Root show={is_open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={set_is_open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">

                
                {/* Dismiss button */}
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <div
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                    onClick={() => set_is_open(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                
                {/* Modal body */}
                <div>
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-blue-500">
                        Send test email
                      </h1>
                      <p className="mt-2 text-sm text-gray-700">
                        Send an email to test deliverability
                      </p>
                    </div>
                  </div>

                  <div className="mt-2 flow-root">

                    
                    
                  <dl className="mt-4 space-y-6 border-t border-gray-200 text-sm">


                    {/* Description text */}
                    {/* <div className="pt-6 font-medium text-sm text-gray-900">
                      DESCRIPTION
                    </div> */}


                    {/* Select domains */}
                    <div className="pt-6 sm:flex items-center">
                      <dt className="font-medium text-gray-900">Select domain:</dt>
                      <dd className="ml-4 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto pr-6">
                        {organization_data_is_fetched
                        ? <Listbox value={test_domain} onChange={(e) => handle_user_input("test_domain", e)} >
                          {({ open }) => (
                            <>
                              <div className="relative w-64">
                                <Listbox.Button 
                                  className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                                >
                                  <span className="block truncate">{test_domain}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>
              
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {simulation_default_domain_options.concat(custom_simulation_domains).map((option) => (
                                      <Listbox.Option
                                        key={option}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                          )
                                        }
                                        value={option}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                              {option}
                                            </span>
              
                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active ? 'text-white' : 'text-blue-600',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                        : <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />}
                      </dd>
                      
                    </div>

                    {/* Send test email from */}
                    <div className="mt-2 sm:flex items-center">
                      <dt className="font-medium text-gray-900">Send test email from:</dt>
                      <dd className="ml-4 flex items-center gap-x-2 sm:mt-0 sm:flex-auto pr-6">
                        {organization_data_is_fetched
                        ? <>
                          <input
                            type="text"
                            required
                            className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            value={test_email_prefix}
                            onChange={(e) => handle_user_input("test_email_prefix", e.target.value)}
                            disabled={is_awaiting}
                          />
                          <div
                            className="text-gray-900 text-sm"
                          >
                            @{test_domain}
                          </div>
                        </>
                        : <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />}
                      </dd>
                    </div>

                    {/* Send test email to */}
                    <div className="mt-2 sm:flex items-center">
                      <dt className="font-medium text-gray-900">Send test email to:</dt>
                      <dd className="ml-4 flex items-center gap-x-2 sm:mt-0 sm:flex-auto pr-6">
                        {organization_data_is_fetched
                        ? <>
                          <input
                            type="text"
                            required
                            className="block w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 bg-gray-100"
                            value={admin_email_address}
                            disabled={true}
                          />
                        </>
                        : <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />}
                      </dd>
                    </div>

                    {/* Submit button */}
                    <div className="flex justify-end">
                      <button 
                        className={classNames(
                          is_awaiting || test_email_is_sent ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                          'flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                        )}
                        onClick={submit}
                        disabled={is_awaiting || test_email_is_sent}
                      >
                        {!is_awaiting
                        ? <span>Send test email</span>
                        : <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />}
                      </button> 
                    </div>
                    

                    {/* Error message */}
                    {error_message
                    ? <div className="mt-6 flex space-x-2 items-start">
                        <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-blue-400 h-full"/>
                        <div className="text-sm font-medium text-blue-400">{error_message}</div>
                      </div>
                    : <></>}

                    {/* Success message */}
                    {test_email_is_sent && success_message
                    ? <div className="mt-6 flex space-x-2 items-start">
                        <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                        <div className="text-sm font-medium text-green-600">{success_message}</div>
                      </div>
                    : <></>}

                    </dl>
                  </div>


                  

                </div>

              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SendTestEmailModal;
