
import React, { useEffect, useState, Fragment } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../contexts/ProtectedContext'


import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Transition, Listbox, RadioGroup } from '@headlessui/react'

import { ArrowLongLeftIcon, InformationCircleIcon, CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'

import { mixpanel_client_track } from '../../../../../libs/mixpanelClient';
import { auth_axios } from '../../../../../libs/authWeb'
import { campaign_delivery_type_map, campaign_type_map, simulation_type_map } from '../../../../../libs/formats'
import { campaign_delivery_type_options, campaign_type_options, simulation_type_options } from '../../../../../libs/options'
import { multi_message_user_count_limit, show_notification, classNames, timestamp_to_local_datetime, local_datetime_to_timestamp } from '../../../../../libs/helpers'







const CampaignsCampaignIdEditPageOnePage = ({
  is_awaiting,
  set_is_awaiting,

  error_message,
  set_error_message,

  success_message,
  set_success_message,

  campaign,
  set_campaign,

  set_edit_page,
  update_campaign,
} : {
  is_awaiting : any
  set_is_awaiting : any

  error_message : any
  set_error_message : any

  success_message : any
  set_success_message : any

  campaign : any
  set_campaign : any
  
  set_edit_page : any
  update_campaign : any
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Use params
  const { campaign_id } = useParams()  
  

  // Org data
  const [organization_data, set_organization_data] = useState({})
  const [organization_data_and_campaign_are_fetched, set_organization_data_and_campaign_are_fetched] = useState(false)

  // Today string
  const now_date = new Date()
  const now_timestamp = now_date.getTime()

  const hour_from_now_date = new Date(now_date.getTime() + 1 * 60 * 60 * 1000)
  const hour_from_now_timestamp = hour_from_now_date.getTime()
  
  const [hour_from_now_date_string, _] = timestamp_to_local_datetime(hour_from_now_timestamp)

  const end_time_timestamp = local_datetime_to_timestamp(hour_from_now_date_string, "23:59")

  const get_organization_data_and_campaign = async () => {
    // Set is_fetched to false
    set_organization_data_and_campaign_are_fetched(false)

    // Execute get organization data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get campaign
    const get_campaign_res = await auth_axios.get(`/api/campaigns/${campaign_id}`)

    if (!get_campaign_res.data.success) {
      switch (get_campaign_res.data.status) {
        case "FATAL_ERROR": {
          // alert("Could not find the campaign")
          show_notification(protected_context, protected_update, "error", "Error", "Could not find the campaign")

          // Redirect to dashboard/campaigns page
          navigate(`/dashboard/campaigns`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // If campaign has already been created, redirect to view page
    if (get_campaign_res.data.campaign.status !== "draft") {
      // alert("Campaign has already been created")
      show_notification(protected_context, protected_update, "error", "Error", "Campaign has already been created")
      navigate(`/dashboard/campaigns/${campaign_id}`)
      return
    }

    // Configure default states
    let campaign = get_campaign_res.data.campaign
    campaign = {
      ...campaign,
      campaign_metadata: {
        ...campaign.campaign_metadata,
        name: campaign.campaign_metadata.name === "UNSET" ? "" : campaign.campaign_metadata.name,
        type: campaign.campaign_metadata.type === "UNSET" ? "simulation" : campaign.campaign_metadata.type,
        simulation_type: campaign.campaign_metadata.simulation_type === "UNSET" ? "multi_message" : campaign.campaign_metadata.simulation_type,
        delivery_type: campaign.campaign_metadata.delivery_type === "UNSET" ? "window" : campaign.campaign_metadata.delivery_type,
        start_time: campaign.campaign_metadata.start_time === -1 ? hour_from_now_timestamp : campaign.campaign_metadata.start_time,
        end_time: campaign.campaign_metadata.end_time === -1 ? end_time_timestamp : campaign.campaign_metadata.end_time,
      }
    }

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_campaign(campaign)
    set_organization_data_and_campaign_are_fetched(true)
  }

  const get_multi_message_user_count_is_valid = (simulation_type) => {
    if (simulation_type === "multi_message" && campaign.campaign_metadata.user_ids.length > multi_message_user_count_limit) {
      // alert("You cannot select multi-message simulation for more than 20 users. Please select single-send and try again.")

      // Show error notification & message
      show_notification(protected_context, protected_update, "warning", "Too many users selected", "You cannot select multi-message simulation for more than 20 users. Please select single-send and try again.")
      set_error_message("You cannot select multi-message simulation for more than 20 users. Please select single-send and try again.")

      return false
    } 
    return true
  }


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "name": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            name: value
          }
        })

        // Always break
        break
      }
      case "type": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            type: value,

            // training_module: value === "training" ? "ransomware" : "simulation_debrief"
          }
        })

        // Always break
        break
      }
      case "simulation_type": {

        // Multi message 20 user limit check
        if (!get_multi_message_user_count_is_valid(value)) {
          // End of the line
          return
        } 

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_type: value,
            simulation_objective: "open_link"
          }
        })

        // Always break
        break
      }
      case "delivery_type": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            delivery_type: value
          }
        })

        // Always break
        break
      }
      case "start_time_date": {
        const [_, current_time] = timestamp_to_local_datetime(campaign["campaign_metadata"]["start_time"])
        const new_timestamp = local_datetime_to_timestamp(value, current_time)

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            start_time: new_timestamp
          }
        })

        // Always break
        break
      }
      case "start_time_time": {
        const [current_date, _] = timestamp_to_local_datetime(campaign["campaign_metadata"]["start_time"])
        const new_timestamp = local_datetime_to_timestamp(current_date, value)

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            start_time: new_timestamp
          }
        })

        // Always break
        break
      }
      case "end_time_date": {
        const [_, current_time] = timestamp_to_local_datetime(campaign["campaign_metadata"]["end_time"])
        const new_timestamp = local_datetime_to_timestamp(value, current_time)

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            end_time: new_timestamp
          }
        })

        // Always break
        break
      }
      case "end_time_time": {
        const [current_date, _] = timestamp_to_local_datetime(campaign["campaign_metadata"]["end_time"])
        const new_timestamp = local_datetime_to_timestamp(current_date, value)

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            end_time: new_timestamp
          }
        })

        // Always break
        break
      }
      
      
    }

    // Always reset error and success messages
    set_error_message("")
    set_success_message("")
  }

  const get_user_input_is_valid = () => {

    // If data has not been fetched yet, don't do anything
    if (!organization_data_and_campaign_are_fetched) {
      set_is_awaiting(false)
      
      // End of the line
      return false
    }

    // If status is not active, alert and redirect them to the plan page
    if (organization_data["status"] !== "active") {
      // Show alert
      // alert("You cannot create a campaign without an active plan")
      show_notification(protected_context, protected_update, "warning", "Inactive plan", "You cannot create a campaign without an active plan")

      // Redirect to dashboard/plan page
      navigate(`/dashboard/plan`)

      // End of the line
      return false
    }

    // If domains have not been allowlisted, redirect them to organization page
    if (!organization_data["organization_metadata"]["domains_are_allowlisted"]) {
      // Show error notification
      show_notification(protected_context, protected_update, "warning", "Domains have not been allowlisted", "You must allowlist all domains available to your organization before creating a campaign.")

      // Redirect to dashboard/organization page
      navigate(`/dashboard/organization`)

      // End of the line
      return
    }

    // If campaign name is empty, show error
    if (campaign.campaign_metadata.name === "") {
      // Toggle awaiting
      set_is_awaiting(false)

      // Show error message
      set_error_message("Please set the name for this campaign")

      // End of the line
      return false
    }

    // If campaign type is empty, show error
    if (campaign.campaign_metadata.type === "") {
      // Toggle awaiting
      set_is_awaiting(false)

      // Show error message
      set_error_message("Please select the type for this campaign")

      // End of the line
      return false
    }

    const current_date = new Date()
    const current_timestamp = current_date.getTime()

    // If start time is before now, show error
    if (campaign.campaign_metadata.start_time < current_timestamp) {
      // Toggle awaiting
      set_is_awaiting(false)

      // Show error message
      set_error_message("You cannot choose a time in the past")

      // End of the line
      return false
    }

    // If end_time is before or same as the start_time, show error
    if (campaign.campaign_metadata.end_time <= campaign.campaign_metadata.start_time) {
      // Toggle awaiting
      set_is_awaiting(false)

      // Show error message
      set_error_message("The end time must be after the start time")

      // End of the line
      return false
    }

    // SIMUALTION
    if (["simulation"].includes(campaign.campaign_metadata.type)) {
      // If simulation type is empty, show error
      if (campaign.campaign_metadata.simulation_type === "") {
        // Toggle awaiting
        set_is_awaiting(false)

        // Show error message
        set_error_message("Please select the simulation type")

        // End of the line
        return false
      }

      // Multi message 20 user limit check
      if (!get_multi_message_user_count_is_valid(campaign.campaign_metadata.simulation_type)) {
        // Toggle awaiting
        set_is_awaiting(false)

        // End of the line
        return false
      } 
    }

    return true
  }

  const save_and_exit = async () => {

    // Clear status messages
    set_error_message("")
    set_success_message("")

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (!get_user_input_is_valid()) {
      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Save the campaign
    const save_is_successful = await update_campaign()

    if (save_is_successful) {
      // Navigate to dashboard/campaigns page
      navigate(`/dashboard/campaigns`) 
    } 
    else {
      set_error_message("Error occurred while saving")
    }

    // Set awaiting
    set_is_awaiting(false)
  }

  const save_progress = async () => {

    // Clear status messages
    set_error_message("")
    set_success_message("")

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (!get_user_input_is_valid()) {
      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Save the campaign
    const save_is_successful = await update_campaign()

    if (save_is_successful) {
      set_success_message("Progress saved")
    } 
    else {
      set_error_message("Error occurred while saving")
    }

    // Set awaiting
    set_is_awaiting(false)
  }

  const save_and_continue = async () => {

    // Clear status messages
    set_error_message("")
    set_success_message("")

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (!get_user_input_is_valid()) {
      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Save the campaign
    const save_is_successful = await update_campaign()
  
    // Move to page two if successful
    if (save_is_successful) {
      // Move to page two
      set_edit_page(1) 
    }
    else {
      set_error_message("Error occurred while saving")
    }

    // Toggle awaiting
    set_is_awaiting(false)
  }


  // Renders  
  useEffect(() => {

    // Get org data
    get_organization_data_and_campaign()

  }, [])

  return (
    organization_data_and_campaign_are_fetched
    ? <div className="mt-8">



      {/* Inputs */}
      <div className="w-full space-y-6 mb-6"> 

        {/* Campaign name */}
        <div>
          <label className="block text-sm leading-6 text-gray-900 flex">
          <span className="font-semibold">Campaign name</span>
            <span className="flex relative items-center">
              <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
              <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                The name of the campaign. Campaign name will be visible to users who are included in the campaign.
              </span>
            </span>
          </label>
          <div className="mt-2">
            <input
              type="text"
              required
              className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={campaign["campaign_metadata"]["name"]}
              onChange={(e) => handle_user_input("name", e.target.value)}
              disabled={is_awaiting}
            />
          </div>
        </div>

        {/* Campaign type */}
        <div>
          <Listbox value={campaign_type_options.find(type_option => type_option === campaign["campaign_metadata"]["type"])} onChange={(e) => handle_user_input("type", e)} >
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                  <span className="font-semibold">Campaign type</span>
                  <span className="flex relative items-center">
                    <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                    <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                      For simulation + training, the training module sent to users will be individually customized to explain the scenario they received, as well as going over what happened for the user during the simulation. For training only, you must choose the training module / topic that selected users will receive.
                    </span>
                  </span>
                </Listbox.Label>
                <div className="relative mt-2 w-64">
                  <Listbox.Button 
                    className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                  >
                    <span className="block truncate">{campaign_type_map[campaign["campaign_metadata"]["type"]]}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {campaign_type_options.map((type_option) => (
                        <Listbox.Option
                          key={type_option}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-blue-600 text-white' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={type_option}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                {campaign_type_map[type_option]}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-blue-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

        {/* Simulation type */}
        {campaign.campaign_metadata.type === "simulation"
        ? <div>
          <label className="block text-sm leading-6 text-gray-900 flex">
            <span className="font-semibold">Simulation type</span>
            <span className="flex relative items-center">
              <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
              <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                The type of simulation to run. Multi-message simulation is limited to up to 20 users. Please contact support@vansec.com to increase this limit.
              </span>
            </span>
          </label>
          <div className="mt-2">
            <RadioGroup value={simulation_type_options.find(type_option => type_option === campaign["campaign_metadata"]["simulation_type"])} onChange={(e) => handle_user_input("simulation_type", e)}>
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                {simulation_type_options.map((simulation_type_option) => (
                  <RadioGroup.Option
                    key={simulation_type_option}
                    value={simulation_type_option}
                    className={({ active }) =>
                      classNames(
                        active ? 'border-blue-600 ring-2 ring-blue-600' : 'border-gray-300',
                        'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                              {simulation_type_map[simulation_type_option]}
                            </RadioGroup.Label>
                            <RadioGroup.Description as="span" className="mt-3 text-sm font-normal text-gray-500">
                              {(() => {
                                switch (simulation_type_option) {
                                  case "single_send": {
                                    return "Basic single-send phishing simulation with templated phishing emails. Basic customization such as names, department, and position can be applied."
                                  }
                                  case "multi_message": {
                                    return "Advanced multi-message phishing simulation with sophisticated personalization. The Simulation AI generates phishing emails based on the scenario that you specify, and engages in a spear-phishing email conversation customized for each user. The Simulation AI can receive and parse email replies from users as well as sending its own."
                                  }
                                  default: {
                                    return ""
                                  }
                                }
                              })()}
                            </RadioGroup.Description>
                          </span>
                        </span>
                        <CheckCircleIcon
                          className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-blue-600')}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            active ? 'border' : 'border-2',
                            checked ? 'border-blue-600' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
        : <></>}


        {/* Delivery type */}
        <div>
          <Listbox value={campaign_delivery_type_options.find(type_option => type_option === campaign["campaign_metadata"]["delivery_type"])} onChange={(e) => handle_user_input("delivery_type", e)} >
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                  <span className="font-semibold">Delivery type</span>
                  <span className="flex relative items-center">
                    <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                    <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                      _______
                    </span>
                  </span>
                </Listbox.Label>
                <div className="relative mt-2 w-96">
                  <Listbox.Button 
                    className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                  >
                    <span className="block truncate">{campaign_delivery_type_map[campaign["campaign_metadata"]["delivery_type"]]}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {campaign_delivery_type_options.map((type_option) => (
                        <Listbox.Option
                          key={type_option}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-blue-600 text-white' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={type_option}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                {campaign_delivery_type_map[type_option]}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-blue-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        

        {/* Delivery time */}
        <div>
          
          {/* Initial delivery window */}
          <label className="block text-sm leading-6 text-gray-900 flex">
            <span className="font-semibold">Initial delivery window</span>
            <span className="flex relative items-center">
              <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
              <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                Window during which the initial message to the user will be sent. For multi-message simulations, there may be correspondence to and from the user that occur after this window.
              </span>
            </span>
          </label>
          <div className="mt-2 w-full flex items-center space-x-2 text-sm font-normal">
            <span>Send {campaign.campaign_metadata && campaign.campaign_metadata.type !== "training" ? "attacks" : "training modules"}{" "}{campaign.campaign_metadata && campaign.campaign_metadata.delivery_type === "window" ? "between" : "at"}</span>
            <input
              type="date"
              required
              className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={timestamp_to_local_datetime(campaign.campaign_metadata.start_time)[0]}
              onChange={(e) => handle_user_input("start_time_date", e.target.value)}
              disabled={is_awaiting}
            />
            <input
              type="time"
              required
              className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={timestamp_to_local_datetime(campaign.campaign_metadata.start_time)[1]}
              onChange={(e) => handle_user_input("start_time_time", e.target.value)}
              disabled={is_awaiting}
            />
            {campaign.campaign_metadata && campaign.campaign_metadata.delivery_type === "window"
            ? <>
              <span>and</span>
              <input
                type="date"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={timestamp_to_local_datetime(campaign.campaign_metadata.end_time)[0]}
                onChange={(e) => handle_user_input("end_time_date", e.target.value)}
                disabled={is_awaiting}
              />
              <input
                type="time"
                required
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={timestamp_to_local_datetime(campaign.campaign_metadata.end_time)[1]}
                onChange={(e) => handle_user_input("end_time_time", e.target.value)}
                disabled={is_awaiting}
              />
            </>
            : <></>}
          </div>
          
        </div>
        

      </div>
      



      {/* Error message */}
      {error_message
      ? <div className="mt-6 flex space-x-2 items-start">
          <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
          {/* Multi-line error message */}
          <div className="text-sm font-medium text-red-400">
            {error_message.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
      : <></>}

      {/* Success message */}
      {success_message
      ? <div className="mt-6 flex space-x-2 items-start">
          <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
          <div className="text-sm font-medium text-green-600">{success_message}</div>
        </div>
      : <></>}

      {/* Loading wheel */}
      {is_awaiting
      ? <div className="mt-6 flex space-x-2 items-start">
        <ReactLoading
          type='spokes'
          color='#000000'
          height={20}
          width={20}
        />
      </div>
      : <></>}

      {/* Buttons */}
      <div className="mt-12 flex justify-between">
        <div
          className="flex items-center text-sm font-medium text-gray-900 hover:text-gray-700 cursor-pointer"
          onClick={save_and_exit}
        >
          <ArrowLongLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
          Save & exit
        </div>

        <button
          type="submit"
          onClick={save_progress}
          className={classNames(is_awaiting ? "cursor-default bg-gray-50" : "", "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50")}
          disabled={is_awaiting}
        >
          Save progress
        </button>

        <button
          type="submit"
          onClick={save_and_continue}
          className={classNames(is_awaiting ? "cursor-default bg-blue-500" : "bg-blue-600", "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")}
          disabled={is_awaiting}
        >
          Save & continue
        </button>
      </div>
    </div>
    : <div className="mt-8">
      <ReactLoading
        type='spokes'
        color='#000000'
        height={20}
        width={20}
      />
    </div>
  )
}

export default CampaignsCampaignIdEditPageOnePage