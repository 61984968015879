
import { useProtected, useProtectedUpdate } from '../contexts/ProtectedContext'
import { XMarkIcon } from '@heroicons/react/20/solid'




const Banners = ({

}: { 

}) => {

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  return (
    protected_context.banners.length > 0
    ? protected_context.banners.map(banner => <div key={banner.id} className="absolute w-full flex items-center gap-x-6 bg-gray-900 px-6 sm:px-3.5 py-0.5 sm:before:flex-1 z-[51] h-6 overflow-clip">
      <div className="text-xs leading-2 text-white overflow-clip">
        <div className="overflow-clip">
          <strong className="font-semibold">{banner.header}</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          {banner.body}
        </div>
      </div>
      <div className="flex flex-1 justify-end cursor-pointer ">
        <button 
          type="button" 
          className="-m-3 p-3 focus-visible:outline-offset-[-4px] cursor-pointer hover:text-gray-100" 
          onClick={() => protected_update({ ...protected_context, banners: protected_context.banners.filter(existing_banner => existing_banner.id !== banner.id) })}
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div> 
    </div>)
  : <></>
  )
}


export default Banners;