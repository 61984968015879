
import React, { useEffect, useState, useCallback } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../../contexts/ProtectedContext'

import { useLocation, useNavigate } from "react-router-dom"
import { Disclosure } from '@headlessui/react'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'


import { mixpanel_client_track } from '../../../../../../libs/mixpanelClient';
import { auth_axios } from '../../../../../../libs/authWeb'
import { show_notification, classNames } from '../../../../../../libs/helpers'
import { validate_email_address } from '../../../../../../libs/validate'
import { RESTRICT_EMAILS_TO_ORGANIZATION_DOMAIN } from '../../../../../../libs/env'




const UsersAddInputProfilePage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Org data
  const [organization_data, set_organization_data] = useState({})
  const [organization_data_and_users_are_fetched, set_organization_data_and_users_are_fetched] = useState(false)
  const [users, set_users] = useState([])

  // User input
  const [input_user, set_input_user] = useState({
    email_address: "",
    user_metadata: {
      first_name: "",
      last_name: "",
      position: "",
      department: "",
      division: "",
      organization: "",
      manager_id: "",
      manager_name: "",
      preferred_language: "",
      city: "",
      country: "",
      locale: "",
      timezone: "",
      phone_number: "",
      social_metadata: {
        linkedin_url: "",
        instagram_url: "",
        facebook_url: "",
      },
    }
  })

  // Error message
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [is_added, set_is_added] = useState(false)



  const get_organization_data_and_users = async () => {
    // Set is_fetched to false
    set_organization_data_and_users_are_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get user data
    const get_org_users_res = await auth_axios.get(`/api/users`)

    if (!get_org_users_res.data.success) {
      switch (get_org_users_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_users(get_org_users_res.data.users)
    set_organization_data_and_users_are_fetched(true)
  }

  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "first_name": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            first_name: value
          }
        })
  
        // Always break
        break
      }
      case "last_name": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            last_name: value
          }
        })
  
        // Always break
        break
      }
      case "email_address": {
        set_input_user({
          ...input_user,
          email_address: value
        })
  
        // Always break
        break
      }
      case "position": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            position: value
          }
        })
  
        // Always break
        break
      }
      case "department": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            department: value
          }
        })
  
        // Always break
        break
      }
      case "division": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            division: value
          }
        })
  
        // Always break
        break
      }
      case "organization": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            organization: value
          }
        })
  
        // Always break
        break
      }
      case "manager_id": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            manager_id: value
          }
        })
  
        // Always break
        break
      }
      case "manager_name": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            manager_name: value
          }
        })
  
        // Always break
        break
      }
      case "preferred_language": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            preferred_language: value
          }
        })
  
        // Always break
        break
      }
      case "city": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            city: value
          }
        })
  
        // Always break
        break
      }
      case "country": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            country: value
          }
        })
  
        // Always break
        break
      }
      case "locale": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            locale: value
          }
        })
  
        // Always break
        break
      }
      case "timezone": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            timezone: value
          }
        })
  
        // Always break
        break
      }
      case "phone_number": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            phone_number: value
          }
        })
  
        // Always break
        break
      }
      case "linkedin_url": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            social_metadata: {
              ...input_user.user_metadata.social_metadata,
              linkedin_url: value
            } 
          }
        })
  
        // Always break
        break
      }
      case "instagram_url": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            social_metadata: {
              ...input_user.user_metadata.social_metadata,
              instagram_url: value
            } 
          }
        })
  
        // Always break
        break
      }
      case "facebook_url": {
        set_input_user({
          ...input_user,
          user_metadata: {
            ...input_user.user_metadata,
            social_metadata: {
              ...input_user.user_metadata.social_metadata,
              facebook_url: value
            } 
          }
        })
  
        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const submit = async () => {

    // Set awaiting
    set_is_awaiting(true)
    set_error_message("")

    // START OF USER INPUT CHECK

    // If orgnaization data has not been fetched yet, don't do anything
    if (!organization_data_and_users_are_fetched) {
      set_is_awaiting(false)
      return
    }

    let is_invalid = false
    let error_message_draft = ""

    // Check for invalid first_name
    if (!input_user.user_metadata.first_name || input_user.user_metadata.first_name === "") {
      error_message_draft += `Invalid first name.\n`
      is_invalid = true
    }

    // Check for invalid last_name
    if (!input_user.user_metadata.last_name || input_user.user_metadata.last_name === "") {
      error_message_draft += `Invalid last name.\n`
      is_invalid = true
    }

    // Validate email address
    if (!validate_email_address(input_user.email_address)) {
      error_message_draft += `${input_user.email_address || `<empty string>`} is not a valid email.\n`
      is_invalid = true
    }

    // Make sure email address and domain match
    if (RESTRICT_EMAILS_TO_ORGANIZATION_DOMAIN && input_user.email_address.split("@")[1] !== organization_data["organization_domain"] &&  !organization_data["organization_metadata"]["additional_organization_domains"].includes(input_user.email_address.split("@")[1])) {
      error_message_draft += `${input_user.email_address || `<empty string>`} does not match your organization domain (${organization_data["organization_domain"]}).\n`
      is_invalid = true
    } 
    

    // Make sure user with an email does not already exist
    const users_email_addresses_set = new Set(users.map(user => user.email_address))
    if (users_email_addresses_set.has(input_user.email_address)) {
      error_message_draft += `${input_user.email_address || `<empty string>`} is already in your organization.\n`
      is_invalid = true
    }

    // Check for invalid position
    if (!input_user.user_metadata.position || input_user.user_metadata.position === "") {
      error_message_draft += `Invalid position.\n`
      is_invalid = true
    }

    /////
    // If invalid, show error message and end of the line
    if (is_invalid) {
      set_error_message(error_message_draft)
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // END OF USER INPUT CHECK








    // Execute add users
    const post_users_res = await auth_axios.post(`/api/users/upload`, {
      users: [input_user]
    })

    if (!post_users_res.data.success) {
      switch (post_users_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/users page
          navigate(`/dashboard/users`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // React to response
    switch (post_users_res.data.status) {
      case "SUCCESS": {
        // Set success message and disable edit
        set_success_message("User was successfully uploaded")
        set_is_added(true)

        // Toggle
        set_is_awaiting(false)

        // Show success message
        // alert("Users were successfully added")
        show_notification(protected_context, protected_update, "success", "Success", "User was successfully uploaded")

        // // If adding users made the plan inactive, show and alert warning message
        // if (post_org_users_res.data.organization_status === "inactive") {
        //   set_error_message("The number of users in your organization surpasses the number of seats in your plan, and your plan is inactive. Any existing campaign will continue to operate, but you will not be able to create new campaigns until your plan is active. Please visit the Plan & Billing tab to increase the number of seats.")

        //   alert("Users were successfully added and your plan is inactive. Please add more seats to your plan.")
        // }
        // else {
        //   // Alert (in case moved away from the page)
        //   alert("Users were successfully added")
        // }

        // Always break
        break
      }
      case "VALIDATION_FAILURE": {
        // Show error message
        set_error_message(post_users_res.data.error_message)
        set_is_awaiting(false)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }



  // Renders  
  useEffect(() => {

    // Get org data
    get_organization_data_and_users()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_users_add_input_profile_visited", global_context.user_id)

  }, [])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Add a single profile</h1>
          <p className="mt-2 text-sm text-gray-700">
            Manually input a single profile
          </p>
          <div className="mt-2 text-sm text-gray-700">
            {`Currently, adding a single profile does not allow for the added user to be able to log into their account. To enable this, you can choose the invite method instead, or reach out to support@vansec.com.`} 
          </div>
        </div>
      </div>
      <div className="mt-8 w-1/2 space-y-4">

        {/* First name */}
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900 space-x-2">
            <span>First name</span>
            <span className="text-red-500">required</span>
          </label>
          <div className="mt-2">
            <input
              type="text"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={input_user.user_metadata.first_name}
              onChange={(e) => handle_user_input("first_name", e.target.value)}
              disabled={is_awaiting || is_added}
            />
          </div>
        </div>

        {/* Last name */}
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900 space-x-2">
            <span>Last name</span>
            <span className="text-red-500">required</span>
          </label>
          <div className="mt-2">
            <input
              type="text"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={input_user.user_metadata.last_name}
              onChange={(e) => handle_user_input("last_name", e.target.value)}
              disabled={is_awaiting || is_added}
            />
          </div>
        </div>

        {/* Email address */}
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900 space-x-2">
            <span>Email address</span>
            <span className="text-red-500">required</span>
          </label>
          <div className="mt-2">
            <input
              type="text"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={input_user.email_address}
              onChange={(e) => handle_user_input("email_address", e.target.value)}
              disabled={is_awaiting || is_added}
            />
          </div>
        </div>

        {/* Position */}
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900 space-x-2">
            <span>Position</span>
            <span className="text-red-500">required</span>
          </label>
          <div className="mt-2">
            <input
              type="text"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={input_user.user_metadata.position}
              onChange={(e) => handle_user_input("position", e.target.value)}
              disabled={is_awaiting || is_added}
            />
          </div>
        </div>

        <Disclosure as="div" className="pt-6">
          {({ open }) => (
            <>
              <dt>
                <Disclosure.Button className="flex w-full items-center text-left text-gray-900">
                  <span className="font-medium text-base">Additional properties</span>
                  <span className="ml-6 flex h-7 items-center">
                    {open ? (
                      <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                    ) : (
                      <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="div" className="mt-8 space-y-4">

                {/* Department */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Department
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.department}
                      onChange={(e) => handle_user_input("department", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Division */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Division
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.division}
                      onChange={(e) => handle_user_input("division", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Organization */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Organization
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.organization}
                      onChange={(e) => handle_user_input("organization", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Manager ID */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Manager ID
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.manager_id}
                      onChange={(e) => handle_user_input("manager_id", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Manager name */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Manager name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.manager_name}
                      onChange={(e) => handle_user_input("manager_name", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Preferred language */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Preferred language
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.preferred_language}
                      onChange={(e) => handle_user_input("preferred_language", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* City */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    City
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.city}
                      onChange={(e) => handle_user_input("city", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Country */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Country
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.country}
                      onChange={(e) => handle_user_input("country", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Locale */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Locale
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.locale}
                      onChange={(e) => handle_user_input("locale", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Timezone */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Timezone
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.timezone}
                      onChange={(e) => handle_user_input("timezone", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Phone number */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Phone number
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.phone_number}
                      onChange={(e) => handle_user_input("phone_number", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* LinkedIn URL */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    LinkedIn URL
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.social_metadata.linkedin_url}
                      onChange={(e) => handle_user_input("linkedin_url", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Instagram URL */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Instagram URL
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.social_metadata.instagram_url}
                      onChange={(e) => handle_user_input("instagram_url", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>

                {/* Facebook URL */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Facebook URL
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={input_user.user_metadata.social_metadata.facebook_url}
                      onChange={(e) => handle_user_input("facebook_url", e.target.value)}
                      disabled={is_awaiting || is_added}
                    />
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        

        

        {/* Error message */}
        {error_message
        ? <div className="mt-2 flex space-x-2 items-start">
            <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
            {/* Multi-line error message */}
            <div className="text-sm font-medium text-red-400">
              {error_message.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        : <></>}

        {/* Success message */}
        {is_added && success_message
        ? <div className="mt-6 flex space-x-2 items-start">
            <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
            <div className="text-sm font-medium text-green-600">{success_message}</div>
          </div>
        : <></>}

        {/* Submit button */}
        <div className="pt-4 flex justify-end">
          <button
            type="submit"
            onClick={submit}
            className={classNames(is_awaiting || is_added ? "cursor-default bg-blue-500" : "bg-blue-600", "inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")}
            disabled={is_awaiting || is_added}
          >
            {is_awaiting ? (
            <ReactLoading
              type='spokes'
              color='#ffffff'
              height={20}
              width={20}
            />
          ) : (
            <span>Upload user</span>
          )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default UsersAddInputProfilePage