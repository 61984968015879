
import { useEffect, useState, useRef, Fragment } from 'react'
import { useLocation, useNavigate, Link, useSearchParams } from "react-router-dom"


import { Transition, Listbox, Switch } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { ArrowLongLeftIcon, InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import axios from "axios"

import { set as idb_set } from 'idb-keyval'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'

import { handle_enter_keydown, classNames } from '../../../libs/helpers'
import { validate_email_address, validate_organization_domain } from '../../../libs/validate'

import { isDesktop } from "react-device-detect";

import { ReactComponent as SquareLogo } from "../../../media/logos/dynamic_logo_64x64.svg"
import { mixpanel_client_track } from '../../../libs/mixpanelClient'
import { RESTRICT_EMAILS_TO_ORGANIZATION_DOMAIN } from '../../../libs/env'








// const num_employees_options = [
//   { id: 1, value: '0-10' },
//   { id: 2, value: '10-50' },
//   { id: 3, value: '50-200' },
//   { id: 4, value: '200-1000' },
//   { id: 5, value: '1000-5000' },
//   { id: 6, value: '5000+' },
// ]

const num_employees_options = [
  "0-100",
  "101-500",
  "501-1000",
  "1001-2000",
  "2001-3000",
  "3001-5000",
  "5000+"
]

const OAuthGooglePage = ({
  
} : {
  
}) => {

  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Search params
  const [search_params, _] = useSearchParams()
  
  // User input
  const [organization_name, set_organization_name] = useState("")
  const [organization_domain, set_organization_domain] = useState("")
  const [num_employees, set_num_employees] = useState(num_employees_options[1])
  const [email_address, set_email_address] = useState("")
  const [first_name, set_first_name] = useState("")
  const [last_name, set_last_name] = useState("")
  const [position, set_position] = useState("")
  const [is_agreed, set_is_agreed] = useState(false)

  
  
  // Sign up user type
  const [allowed_domain, set_allowed_domain] = useState("")
  const [is_owner_signup, set_is_owner_signup] = useState(true)

  // Autofocus on organization name and email address field
  const email_address_ref = useRef<any>(null)
  const organization_name_ref = useRef<any>(null)


  // Error message
  const [error_message, set_error_message] = useState("")

  // Statuses
  const [is_validated, set_is_validated] = useState(false)
  const [is_awaiting, set_is_awaiting] = useState(false)

  // Reset variables
  const reset = () => {
    set_is_awaiting(false)
  }

  // Validate search parameters
  const validate_params = async () => {
    const email_validation_token_id = search_params.get("email_validation_token_id")
    const code = search_params.get("code")
    const nonce = search_params.get("state")
    const referral_id = search_params.get("referral_id")

    console.log("nonce", nonce)
    console.log("referral_id", referral_id)

    

    // Execute validate email_validation_token
    const post_validate_email_validation_token_res = await axios.post(`/api/email-validation-tokens/${email_validation_token_id}/validate`, {
      code: code,
      type: "google_oauth",
      referral_id: referral_id
    })

    if (!post_validate_email_validation_token_res.data.success) {
      switch (post_validate_email_validation_token_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to login page
          navigate(`/login`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_validate_email_validation_token_res.data.status) {
      case "VALID": {
        
        set_email_address(post_validate_email_validation_token_res.data.email_address)
        set_organization_domain(post_validate_email_validation_token_res.data.organization_domain)
        set_is_owner_signup(post_validate_email_validation_token_res.data.is_owner_signup)
        set_allowed_domain(post_validate_email_validation_token_res.data.allowed_domain)

        // Always break
        break
      }
      case "AUTHENTICATED": {

        // Save JWT in idb-keyval
        await idb_set("session_token", post_validate_email_validation_token_res.data.session_token)

        // Global variables
        global_update({ 
          ...global_context, 
          authenticated: true,
          user_id: post_validate_email_validation_token_res.data.user_id,
          type: post_validate_email_validation_token_res.data.type,
        })
  
        // If owner or admin, navigate to campaigns page
        if (["owner", "admin"].includes(post_validate_email_validation_token_res.data.type)) {
          navigate(location.state ? location.state.from : `/dashboard/campaigns`)
        }
        // If not owner or admin, navigate to account page
        else {
          navigate(location.state ? location.state.from : `/dashboard/account`)
        }

        // Always break
        break
      }
      case "INACTIVE_USER": {
        alert("Your account is inactive. Please contact your admin.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "AUTHENTICATION_TYPE_IS_WRONG": {
        alert("You cannot use SSO on this account.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "SSO_METHOD_IS_WRONG": {
        alert("SSO method is wrong.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "EMAIL_ADDRESS_IS_INVALID": {
        alert("You must use your work email. Please try again.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "REFERRAL_ID_IS_INVALID": {
        alert("Invalid link. Please try again.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "INVALID": {
        alert("Invalid link. Please try again.")

        // Redirect to vanilla reset page
        navigate(`/login`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Toggle
    set_is_validated(true)

  }

  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "organization_name": {
        set_organization_name(value)

        // Always break
        break
      }
      case "organization_domain": {
        set_organization_domain(value)

        // Always break
        break
      }
      case "num_employees": {
        set_num_employees(value)

        // Always break
        break
      }
      case "email_address": {
        set_email_address(value)

        // Always break
        break
      }
      case "first_name": {
        set_first_name(value)

        // Always break
        break
      }
      case "last_name": {
        set_last_name(value)

        // Always break
        break
      }
      case "position": {
        set_position(value)

        // Always break
        break
      }
      case "is_agreed": {
        set_is_agreed(value)

        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }


  // Submit function
  const submit = async () => {

    // Only allow valid link
    const signup_nonce = search_params.get("referral_id")

    // if (signup_nonce !== "eb64c439-cf70-4c62-8df8-501030966a50") {
    //   // Show error message
    //   set_error_message("Your signup link is invalid. You can only sign up using a valid signup link provided to you by our onboarding team. Please reach out to us at support@vansec.com and we will get you set up within 12 hours.")

    //   // End of the line
    //   return
    // }

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (is_owner_signup) {
      // Validate organization name
      if (!organization_name) {
        reset()

        // Show error message
        set_error_message("Please provide the company name")

        // End of the line
        return
      }

      if (!num_employees) {
        reset()
  
        // Show error message
        set_error_message("Please select the number of employees")
      }
    }

    // Validate organization domain
    if (!validate_organization_domain(organization_domain)) {
      reset()

      // Show error message
      set_error_message("Invalid company domain")

      // End of the line
      return
    }

    // Validate email address
    if (!validate_email_address(email_address)) {
      reset()

      // Show error message
      set_error_message("Invalid email address")

      // End of the line
      return
    }

    // Make sure email address and domain match
    // if (RESTRICT_EMAILS_TO_ORGANIZATION_DOMAIN && email_address.split("@")[1] !== organization_domain) {

    if ((is_owner_signup || RESTRICT_EMAILS_TO_ORGANIZATION_DOMAIN) && email_address.split("@")[1] !== organization_domain && email_address.split("@")[1] !== allowed_domain) {

      reset()

      // Show error message
      set_error_message("Please use your company email")

      // End of the line
      return
    }

    // Validate first name
    if (!first_name) {
      reset()

      // Show error message
      set_error_message("Please enter your first name")

      // End of the line
      return
    }

    // Validate last name
    if (!last_name) {
      reset()

      // Show error message
      set_error_message("Please enter your last name")

      // End of the line
      return
    }

    // Validate position
    if (!position) {
      reset()

      // Show error message
      set_error_message("Please enter your title")

      // End of the line
      return
    }

    // Validate is_agreed
    if (!is_agreed) {
      reset()

      // Show error message
      set_error_message("Please agree to the Terms of Service and Privacy Policy")

      // End of the line
      return
    }

    // END OF USER INPUT CHECK


    // Execute signup
    const post_auth_google_signup_res = await axios.post(`/api/auth/google/signup`, {
      organization_name: organization_name,
      organization_domain: organization_domain,
      num_employees: num_employees,
      email_address: email_address,
      first_name: first_name,
      last_name: last_name,
      position: position,

      sso_method: "google_oauth",

      referral_id: signup_nonce,
    })

    if (!post_auth_google_signup_res.data.success) {
      console.log(post_auth_google_signup_res.data)
      switch (post_auth_google_signup_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to signup
          navigate(`/signup`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_auth_google_signup_res.data.status) {
      case "AUTHENTICATED": {
        // Save JWT in idb-keyval
        await idb_set("session_token", post_auth_google_signup_res.data.session_token)

        // Global variables
        global_update({ 
          ...global_context, 
          authenticated: true,
          user_id: post_auth_google_signup_res.data.user_id,
          type: post_auth_google_signup_res.data.type
        })
  
        // ___??___ This doesn't appear to be necessary, but just having it here for safety and for code readability
        // navigate(location.state ? location.state.from : `/onboarding`)

        // If owner, navigate to add users page
        if (post_auth_google_signup_res.data.type === "owner") {
          navigate(`/dashboard/users/add`)
        }
        // If admin, navigate to the campaigns page
        else if (post_auth_google_signup_res.data.type === "admin") {
          navigate(`/dashboard/campaigns`)
        }
        // If not owner or admin, navigate to the account page
        else {
          navigate(`/dashboard/account`)
        }
        
        // Always break
        break
      }
      case "ORGANIZATION_AND_USER_EXISTS": {
        alert("You already have an account. Please login.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "ORGANIZATION_EXISTS_AND_UNWELCOME_EMAIL_ADDRESS": {
        alert("Your organization already has an account. Please contact your admin.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "WRONG_AUTHENTICATION_TYPE": {
        alert("You chose the wrong authentication method. Please try again")

        // // Redirect to login page
        // navigate(`/login`)
        // Toggle is_awaiting
        set_is_awaiting(false)

        // Always break
        break
      }
      case "REFERRAL_ID_IS_INVALID": {
        // Show error message
        set_error_message("Your signup link is invalid. You can only sign up using a valid signup link provided to you by our onboarding team. Please reach out to us at support@vansec.com and we will get you set up within 12 hours.")

        // Toggle is_awaiting
        set_is_awaiting(false)
        
        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }





  // Renders
  useEffect(() => {

    // // Focus on organization name field upon load
    // organization_name_ref.current.focus()

    // Validate search params
    validate_params()
    
    // Mixpanel tracking
    mixpanel_client_track("app_google_visited", null)
    
  }, [])

  return (
    <div className="absolute w-full h-screen flex flex-col justify-center items-center z-10">
      {isDesktop
      ? is_validated 
        ? <div className="flex w-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
              <SquareLogo className="h-8 w-auto" />
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

              <div className="space-y-4">
                
                {/* Organization domain */}
                {is_owner_signup || RESTRICT_EMAILS_TO_ORGANIZATION_DOMAIN
                ? <div>
                  <label className="block text-sm leading-6 text-gray-900 flex">
                    <span className="font-medium">Company domain</span>
                    <span className="flex relative items-center">
                      {/* <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        The domain of your company. Only those with email address belonging to this domain are allowed to be added to your organization. This cannot be changed later.
                      </span> */}
                    </span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 bg-gray-100"
                      value={organization_domain}
                      disabled
                    />
                  </div>
                </div>
                : <></>}
                
                {/* Organization name */}
                {is_owner_signup
                ? <div>
                  <label className="block text-sm leading-6 text-gray-900 flex">
                    <span className="font-medium">Company name</span>
                    <span className="flex relative items-center">
                      <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        The legal name of your company. This can be changed later.
                      </span>
                    </span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      ref={organization_name_ref}
                      value={organization_name}
                      onChange={(e) => handle_user_input("organization_name", e.target.value)}
                    />
                  </div>
                </div>
                : <></>}

                {/* Number of employees */}
                {is_owner_signup
                ? <div>
                  <Listbox value={num_employees} onChange={(e) => handle_user_input("num_employees", e)}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Number of employees</Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button 
                            className="relative w-full cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 bg-white"
                          >
                            <span className="block truncate">{num_employees}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {num_employees_options.map((num_employees_option, index) => (
                                <Listbox.Option
                                  key={index}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={num_employees_option}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        {num_employees_option}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active ? 'text-white' : 'text-blue-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
                : <></>}

                {/* Email address */}
                <div>
                  <label className="block text-sm leading-6 text-gray-900 flex">
                    <span className="font-medium">Email address</span>
                    <span className="flex relative items-center">
                      {/* <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        Email address for your account that will be used for owner (admin) privileges. Your email must belong to your company domain.
                      </span> */}
                    </span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 bg-gray-100"
                      ref={email_address_ref}
                      value={email_address}
                      disabled
                    />
                  </div>
                </div>

                {/* First name */}
                <div>
                  <label className="block text-sm leading-6 text-gray-900 flex">
                    <span className="font-medium">First name</span>
                    <span className="flex relative items-center">
                      {/* <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        The domain of your company. Only those with email address belonging to this domain are allowed to be added to your organization. This cannot be changed later.
                      </span> */}
                    </span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={first_name}
                      onChange={(e) => handle_user_input("first_name", e.target.value)}
                      onKeyDown={async (e) => handle_enter_keydown(e, submit)}
                    />
                  </div>
                </div>

                {/* Last name */}
                <div>
                  <label className="block text-sm leading-6 text-gray-900 flex">
                    <span className="font-medium">Last name</span>
                    <span className="flex relative items-center">
                      {/* <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        The domain of your company. Only those with email address belonging to this domain are allowed to be added to your organization. This cannot be changed later.
                      </span> */}
                    </span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={last_name}
                      onChange={(e) => handle_user_input("last_name", e.target.value)}
                      onKeyDown={async (e) => handle_enter_keydown(e, submit)}
                    />
                  </div>
                </div>

                {/* Position */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Title
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={position}
                      onChange={(e) => handle_user_input("position", e.target.value)}
                      onKeyDown={async (e) => handle_enter_keydown(e, submit)}
                    />
                  </div>
                </div>
                
                {/* Agree */}
                <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                  <div className="flex h-6 items-center">
                    <Switch
                      checked={is_agreed}
                      onChange={(e) => handle_user_input("is_agreed", e)}
                      className={classNames(
                        is_agreed ? 'bg-blue-600' : 'bg-gray-200',
                        'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                      )}
                    >
                      <span className="sr-only">Agree to policies</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          is_agreed ? 'translate-x-3.5' : 'translate-x-0',
                          'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </div>
                  <div>
                    <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                      By selecting this, you agree to our{' '}
                    </Switch.Label>
                    <Link to="https://app.vansec.com/terms" target="_blank" className="font-semibold text-blue-600 text-sm leading-6">
                      Terms&nbsp;of&nbsp;Service
                    </Link>
                    <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                      {" "}and{" "}
                    </Switch.Label>
                    <Link to="https://app.vansec.com/privacy" target="_blank" className="font-semibold text-blue-600 text-sm leading-6">
                      Privacy&nbsp;Policy
                    </Link>
                    <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                      .
                    </Switch.Label>
                  </div>
                  
                </Switch.Group>

                {/* Error message */}
                {error_message
                ? <div className="flex space-x-2 items-start">
                    <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                    <div className="text-sm font-medium text-red-400">{error_message}</div>
                  </div>
                : <></>}

                {/* Submit button */}
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={submit}
                  >
                    {is_awaiting ? (
                      <ReactLoading
                        type='spokes'
                        color='#ffffff'
                        height={20}
                        width={20}
                      />
                    ) : (
                      <span>Create account</span>
                    )}
                  </button>
                </div>

              </div>

              {/* <p className="mt-10 text-center text-sm text-gray-500">
                Already have an account?{" "}
                <Link to="/login" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                  Log in
                </Link>
              </p> */}
            </div>
          </div>
        : <ReactLoading
          type='spokes'
          color='#343D46'
          height={20}
          width={20}
        />
      : <div className="flex min-h-full flex-col bg-transparent pt-16 pb-12">
          <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="text-base font-semibold text-gray-600">Error</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Unsupported access.</h1>
                <p className="mt-2 text-base text-gray-500">This page can only be accessed from a desktop.</p>
                <div className="mt-6">
                  <Link to='/'>
                    <span className="text-base font-medium text-gray-600 hover:text-gray-500">
                      Go back home
                      <span aria-hidden="true"> &rarr;</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </main>
          <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-center space-x-4">
              <Link to="mailto:support@vansec.com" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Contact Support
              </Link>
            </nav>
          </footer>
        </div>
      }
    </div>
  )
}

export default OAuthGooglePage