import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition, Listbox } from '@headlessui/react'


import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'

import ReactLoading from "react-loading"


import { useLocation, useNavigate, Link } from "react-router-dom";
import { auth_axios } from '../../../libs/authWeb';

import { show_notification, sleep, classNames } from '../../../libs/helpers'
import { CSPRNG } from '../../../libs/crypto'
import { uint8_array_to_base64 } from '../../../libs/converters'


import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { ClipboardDocumentListIcon, XMarkIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'







const ContentVisibilityModal = ({
  is_open,
  set_is_open,
  set_refresh_variable
} : {
  is_open : boolean
  set_is_open : any // todo
  set_refresh_variable : any // todo
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // Location
  const location = useLocation()

  // Data
  const [simulation_content_is_visible, set_simulation_content_is_visible] = useState(false)
  const [training_content_is_visible, set_training_content_is_visible] = useState(false)
  const [organization_data_is_fetched, set_organization_data_is_fetched] = useState(false)

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [content_visibility_is_updated, set_content_visibility_is_updated] = useState(false)

  const [warning_message, set_warning_message] = useState("")
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "simulation_content_is_visible": {
        set_simulation_content_is_visible(value === "visible")

        // Always break
        break
      }
      case "training_content_is_visible": {
        set_training_content_is_visible(value === "visible")

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const get_organization_data = async () => {
    // Set is_fetched to false
    set_organization_data_is_fetched(false)

    // Execute get user data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_simulation_content_is_visible(get_org_res.data.organization_data.organization_metadata.simulation_content_is_visible)
    set_training_content_is_visible(get_org_res.data.organization_data.organization_metadata.training_content_is_visible)
    set_organization_data_is_fetched(true)
  }


  const submit = async () => {
    
    // Reset error message
    set_error_message("")

    // Set is_fetched to false
    set_is_awaiting(true)

    // START OF USER INPUT CHECK
    

    // END OF USER INPUT CHECK

    // Execute udpate email
    const update_contents_visibility_res = await auth_axios.put(`/api/organizations/content/visibility`, {
      simulation_content_is_visible: simulation_content_is_visible,
      training_content_is_visible: training_content_is_visible,
    })

    if (!update_contents_visibility_res.data.success) {
      switch (update_contents_visibility_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // // Redirect to dashboard/organization page
          // navigate(`/dashboard/organization`) 
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (update_contents_visibility_res.data.status) {
      case "SUCCESS": {
        set_is_awaiting(false)
        set_content_visibility_is_updated(true)
        set_success_message(`Successfully updated`)

        // Show success notification
        show_notification(protected_context, protected_update, "success", "Success", `Successfully updated`)
        
        // Re-render /dashboard/users
        set_refresh_variable(new Date())

        // Always break
        break
      }
      case "_____": {

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  // Renders  
  useEffect(() => {

    get_organization_data()

  }, [])

  return (
    <Transition.Root show={is_open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={set_is_open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">

                
                {/* Dismiss button */}
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <div
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                    onClick={() => set_is_open(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                
                {/* Modal body */}
                <div>
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-blue-500">
                        Update content visibility settings
                      </h1>
                      <p className="mt-2 text-sm text-gray-700">
                        Configure whether content will be visible to admins
                      </p>
                    </div>
                  </div>

                  <div className="mt-2 flow-root">

                    
                    
                  <dl className="mt-4 space-y-6 border-t border-gray-200 text-sm">


                    {/* Description text */}
                    {/* <div className="pt-6 font-medium text-sm text-gray-900">
                      DESCRIPTION
                    </div> */}


                    {/* Simulation */}
                    <div className="pt-6 sm:flex items-center">
                      <dt className="font-medium text-gray-900">Simulation content:</dt>
                      <dd className="ml-4 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto pr-6">
                        {organization_data_is_fetched
                        ? <Listbox value={simulation_content_is_visible ? "visible" : "hidden"} onChange={(e) => handle_user_input("simulation_content_is_visible", e)} >
                          {({ open }) => (
                            <>
                              <div className="relative w-32">
                                <Listbox.Button 
                                  className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                                >
                                  <span className="block truncate">{simulation_content_is_visible ? "Visible" : "Hidden"}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>
              
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {["visible", "hidden"].map((option) => (
                                      <Listbox.Option
                                        key={option}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                          )
                                        }
                                        value={option}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                              {option === "visible" ? "Visible" : "Hidden"}
                                            </span>
              
                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active ? 'text-white' : 'text-blue-600',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                        : <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />}
                      </dd>
                      
                    </div>

                    {/* Training */}
                    <div className="mt-2 sm:flex items-center">
                      <dt className="font-medium text-gray-900">Training content:</dt>
                      <dd className="ml-4 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto pr-6">
                        {organization_data_is_fetched
                        ? <Listbox value={training_content_is_visible ? "visible" : "hidden"} onChange={(e) => handle_user_input("training_content_is_visible", e)} >
                          {({ open }) => (
                            <>
                              <div className="relative w-32">
                                <Listbox.Button 
                                  className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                                >
                                  <span className="block truncate">{training_content_is_visible ? "Visible" : "Hidden"}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>
              
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {["visible", "hidden"].map((option) => (
                                      <Listbox.Option
                                        key={option}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                          )
                                        }
                                        value={option}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                              {option === "visible" ? "Visible" : "Hidden"}
                                            </span>
              
                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active ? 'text-white' : 'text-blue-600',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                        : <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />}
                      </dd>
                    </div>

                    {/* Submit button */}
                    <div className="flex justify-end">
                      <button 
                        className={classNames(
                          is_awaiting || content_visibility_is_updated ? 'text-white bg-blue-300' : 'text-white bg-blue-600 hover:bg-blue-500',
                          'flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                        )}
                        onClick={submit}
                        disabled={is_awaiting || content_visibility_is_updated}
                      >
                        {!is_awaiting
                        ? <span>Update</span>
                        : <ReactLoading
                          type='spokes'
                          color='#343D46'
                          height={20}
                          width={20}
                        />}
                      </button> 
                    </div>
                    

                    {/* Error message */}
                    {error_message
                    ? <div className="mt-6 flex space-x-2 items-start">
                        <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-blue-400 h-full"/>
                        <div className="text-sm font-medium text-blue-400">{error_message}</div>
                      </div>
                    : <></>}

                    {/* Success message */}
                    {content_visibility_is_updated && success_message
                    ? <div className="mt-6 flex space-x-2 items-start">
                        <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
                        <div className="text-sm font-medium text-green-600">{success_message}</div>
                      </div>
                    : <></>}

                    </dl>
                  </div>


                  

                </div>

              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ContentVisibilityModal;
