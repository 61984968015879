

// **************************************
// Options
// **************************************

export const user_type_options = [
  "end_user",
  "admin",
  "owner"
]

export const campaign_type_options = [
  "simulation",
  "training"
]

export const campaign_delivery_type_options = [
  "window",
  "same",
]

export const simulation_program_status_options = [
  "none",
  "draft",
  "active",
  "reported",
  "failed",
  "terminated",
]

export const training_program_status_options = [
  "none",
  "draft",
  "active",
  "completed",
  "terminated",
]


// export const department_options = [
//   "C-Suite",
//   "IT/Security",
//   "Sales",
//   "Marketing",
//   "Engineering",
//   "Procurement",
//   "Finance",
//   "Legal",
//   "Accounting",
//   "Product",
//   "Design",
//   "Human Resources",
//   "Public Relations",
//   "Investor Relations",
//   "Business Development",
//   "Operations",
//   "Quality Assurance",
//   "Research & Development",
//   "Other",
// ]

// export const position_options = [
//   "CISO",
//   "CTO",
//   "CEO",
//   "COO",
//   "Security Engineer",
//   "IT Engineer",
//   "Product Manager",
//   "Software Engineer",
//   "Other",
// ]



export const user_property_options = [
  // Profile properties
  "first_name", // user_doc.user_metadata.first_name
  "last_name", // user_doc.user_metadata.last_name
  "email_address", // user_doc.email_address
  "position", // user_doc.user_metadata.position
  "department", // user_doc.user_metadata.department
  "division", // user_doc.user_metadata.division
  "organization", // user_doc.user_metadata.organization
  "type", // NON-EDIT user_doc.type
  "user_created_at", // NON-EDIT user_doc.user_created_at
  "manager_id", // user_doc.user_metadata.manager_id
  "manager_name", // user_doc.user_metadata.manager_name
  "preferred_language", // user_doc.user_metadata.preferred_language
  "city", // user_doc.user_metadata.city
  "country", // user_doc.user_metadata.country
  "locale", // user_doc.user_metadata.locale
  "timezone", // user_doc.user_metadata.timezone
  "phone_number", // user_doc.user_metadata.phone_number
  "social_linkedin_url", // user_doc.user_metadata.linkedin_url
  "social_instagram_url", // user_doc.user_metadata.instagram_url
  "social_facebook_url", // user_doc.user_metadata.facebook_url
  "authentication_type", // NON-EDIT user_doc.authentication_metadata.type
  "authentication_email_is_validated", // NON-EDIT user_doc.authentication_metadata.email_is_validated
  "authentication_password_is_set", // NON-EDIT user_doc.authentication_metadata.password_is_set
  "authentication_sso_method", // NON-EDIT user_doc.authentication_metadata.sso_method
  "provision_type", // NON-EDIT user_doc.provision_metadata.type

  // Status properties
  "most_recent_simulation_program_status", // user_doc.most_recent_simulation_program.simulation_status
  "most_recent_training_program_status", // user_doc.most_recent_training_program.training_status
  "most_recent_simulation_program_created_at", // user_doc.most_recent_simulation_program.program_created_at
  "most_recent_training_program_created_at", // user_doc.most_recent_training_program.program_created_at

  // "most_recent_x_simulations_opened_email", // user_doc.______
  // "most_recent_x_simulations_opened_link", // user_doc.______
  // "most_recent_x_simulations_replied_email", // user_doc.______
  "most_recent_x_simulations_active", // user_doc.______
  "most_recent_x_simulations_failed", // user_doc.______
  "most_recent_x_simulations_reported", // user_doc.______
  "most_recent_x_trainings_active", // user_doc.______
  "most_recent_x_trainings_completed", // user_doc.______
]

export const required_user_property_options = [
  "first_name",
  "last_name",
  "email_address",
  "position",
]


export const editable_user_property_options = [
  "first_name",
  "last_name", 
  "email_address",
  "position", 
  "department", 
  "division", 
  "organization", 
  "manager_id", 
  "manager_name", 
  "preferred_language",
  "city", 
  "country", 
  "locale", 
  "timezone", 
  "phone_number",
  "social_linkedin_url",
  "social_instagram_url", 
  "social_facebook_url", 
]

export const campaign_user_property_options = [
  "first_name",
  "last_name", 
  "email_address",
  "position", 
  "department", 
  "division", 
  "organization", 
  "manager_id",
  "manager_name", 
  "preferred_language",
  "city", 
  "country", 
  "locale", 
  "timezone", 
  "phone_number",
  "social_linkedin_url",
  "social_instagram_url", 
  "social_facebook_url", 
]






export const program_property_options = [
  "first_name", // program_doc.user_metadata.first_name
  "last_name", // program_doc.user_metadata.last_name
  "email_address", // program_doc.email_address
  "simulation_status", // program_doc.simulation_status
  "training_status", // program_doc.training_status
  "initial_email_is_sent", // (complex logic)
  "initial_email_status", // (complex logic)
  "open_email_is_done", // "open_email" in program_doc.actions
  "report_email_is_done", // "report_email" in program_doc.actions
  "open_link_is_done", // "open_link" in program_doc.actions
  "submit_website_is_done", // "submit_website" in program_doc.actions
  "reply_email_is_done", // "reply_email" in program_doc.actions
  "reply_email_objective_is_done", // "reply_email_objective" in program_doc.actions
  "reply_message_is_done", // "reply_message" in program_doc.actions
  "reply_message_objective_is_done", // "reply_message_objective" in program_doc.actions
  "program_created_at", // program_doc.program_created_at
  "name", // program_doc.program_metadata.name
]



export const job_status_options = [
  "none",
  "email_delivered",
  "email_deferred",
  "email_bounced",
  "message_delivered",
]





export const filter_options = [
  "includes_text",
  "equals_text",
  "equals_option",
  "before_date",
  "after_date",
  "equals_number",
  "lte_number",
  "gte_number",
  "lt_number",
  "gt_number",
]



export const provision_type_options = [
  "disabled",
  "native",
  "scim",
]

export const authentication_type_options = [
  "disabled",
  "password",
  "sso",
]


export const authentication_sso_method_options = [
  "google_oauth",
]





export const simulation_type_options = [
  "multi_message",
  "single_send"
]

export const simulation_single_send_objective_options = [
  "open_link",
  "open_link_submit_website",
  "open_attachment",
]

export const simulation_multi_message_objective_options = [
  "open_link",
  "open_link_submit_website",
  "open_attachment", 
  "reply_info", 
]


export const simulation_default_domain_options = [
  "admin-settings.com",
  "google-homepage.com"
]


export const training_module_options = [
  "ransomware",
  "malware",
  "phishing",
  "spear_phishing",
  // "smishing",
  // "vishing",
  // "deep_fake",
  // "business_email_compromise",
  // "digital_footprint",
  // "linkedin_footprint",
  // "ceo_fraud",
  // "passwords",
  // "mfa",
  // "personal_data",
  // "mobile_phone",
  // "tailgating",
  // "out_of_office",
  // "privacy_by_design",
  "soc2_training",
  "iso27001_training",
  "hipaa_training",
  "pci_training",
  "gdpr_training", 
  "ccpa_training",
]



export const simulation_website_templates = [
  // self_hosted
  {
    id: "self_hosted",
    name: "Custom | Self-hosted",
    fields: {
      simulation_website_url: "",
      simulation_website_open_link_tracking_is_enabled: false,
      simulation_website_submit_website_capture_is_enabled: false,
    }
  },
  // google_login
  {
    id: "google_login",
    name: "Google login | Vansec-hosted",
    fields: {
      simulation_website_url: "https://www.google-homepage.com",
      simulation_website_open_link_tracking_is_enabled: true,
      simulation_website_submit_website_capture_is_enabled: false,
    }
  },
]

export const simulation_attachment_extension_options = [
  "pdf",
  "exe"
]






export const simulation_single_send_templates = [
  // amazon_you_won_gift_card
  {
    id: 'amazon_you_won_gift_card',
    img_src: 'https://vansec-assets.s3.amazonaws.com/simulation/images/template-images/amazon_you_won_gift_card.png',
    name: 'Amazon - you won a gift card',
    difficulty: '2/5',
    fields: {
      // general 
      simulation_type: "single_send",
      simulation_objective: "open_link",
      simulation_user_properties: ["first_name"],

      // objective dependent
      simulation_domain: "admin-settings.com",
      simulation_email_prefix: "amazon-gift-card",
      simulation_display_name: "Amazon Gift Cards",
      simulation_subject_line: "Your $100 Amazon gift card is here",

      // single send
      simulation_single_send_email_html: `<html lang="en"><head>
      <title>You won an Amazon gift card!</title>
      <style type="text/css">
        
        body {
          font-family: Helvetica, sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
          line-height: 1.3;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }
        
        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }
        
        table td {
          font-family: Helvetica, sans-serif;
          font-size: 16px;
          vertical-align: top;
        }
        
        body {
          background-color: #F2F3F5;
          margin: 0;
          padding: 0;
        }
        
        .body {
          background-color: #F2F3F5;
          width: 100%;
        }
        
        .container {
          margin: 0 auto !important;
          max-width: 600px;
          padding: 0;
          padding-top: 24px;
          width: 600px;
        }
        
        .content {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          max-width: 600px;
          padding: 0;
        }
        
        .main {
          background: #ffffff;
          border: 1px solid #eaebed;
          border-radius: 16px;
          width: 100%;
        }
        
        .wrapper {
          box-sizing: border-box;
          padding: 24px;
        }
        
        .footer {
          clear: both;
          padding-top: 24px;
          text-align: center;
          width: 100%;
        }
        
        .footer td,
        .footer p,
        .footer span,
        .footer a {
          color: #9a9ea6;
          font-size: 16px;
          text-align: center;
        }
        
        p {
          font-family: Helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          margin: 20px 0px 20px 0px;
        }
        
        a {
          color: #eebd43;
          text-decoration: underline;
        }
        
        .btn {
          box-sizing: border-box;
          min-width: 100% !important;
          width: 100%;
        }
        
        .btn > tbody > tr > td {
          padding-bottom: 16px;
        }
        
        .btn table {
          width: auto;
        }
        
        .btn table td {
          background-color: #ffffff;
          border-radius: 4px;
          text-align: center;
        }
        
        .btn a {
          background-color: #ffffff;
          border: solid 2px #eebd43;
          border-radius: 4px;
          box-sizing: border-box;
          color: #eebd43;
          cursor: pointer;
          display: inline-block;
          font-size: 16px;
          font-weight: medium;
          margin: 0;
          padding: 12px 24px;
          text-decoration: none;
          text-transform: capitalize;
        }
        
        .btn-primary table td {
          background-color: #eebd43;
        }
        
        .btn-primary a {
          background-color: #eebd43;
          border-color: #eebd43;
          color: #000000;
        }
        
        @media all {
          .btn-primary table td:hover {
            background-color: #bb922b !important;
          }
          .btn-primary a:hover {
            background-color: #bb922b !important;
            border-color: #bb922b !important;
          }
        }
        
        .last {
          margin-bottom: 0;
        }
        
        .first {
          margin-top: 0;
        }
        
        .align-center {
          text-align: center;
        }
        
        .align-right {
          text-align: right;
        }
        
        .align-left {
          text-align: left;
        }
        
        .text-link {
          color: #eebd43 !important;
          text-decoration: underline !important;
        }
        
        .clear {
          clear: both;
        }
        
        .mt0 {
          margin-top: 0;
        }
        
        .mb0 {
          margin-bottom: 0;
        }
        
        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0;
        }
        
        .powered-by a {
          text-decoration: none;
        }
        
        @media only screen and (max-width: 640px) {
          .main p,
          .main td,
          .main span {
            font-size: 16px !important;
          }
          .wrapper {
            padding: 8px !important;
          }
          .content {
            padding: 0 !important;
          }
          .container {
            padding: 0 !important;
            padding-top: 8px !important;
            width: 100% !important;
          }
          .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }
          .btn table {
            max-width: 100% !important;
            width: 100% !important;
          }
          .btn a {
            font-size: 16px !important;
            max-width: 100% !important;
            width: 100% !important;
          }
        }
        
        @media all {
          .ExternalClass {
            width: 100%;
          }
          .ExternalClass,
          .ExternalClass p,
          .ExternalClass span,
          .ExternalClass font,
          .ExternalClass td,
          .ExternalClass div {
            line-height: 100%;
          }
          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }
          #MessageViewBody a {
            color: inherit;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
        }
        </style>
      </head>
      <body>
        <table class="body" cellspacing="0" cellpadding="0" border="0" role="presentation">
          <tbody><tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
    
                
                <span class="preheader">You just won a $100 Amazon gift card! Click here to redeem.</span>
                <table class="main" cellspacing="0" cellpadding="0" border="0" role="presentation">
    
                  
                  <tbody>
                    <tr>
                      <td class="wrapper">
                        <div style="display: flex; flex-direction: column; align-items: center;">
                          <img src="https://vansec-assets.s3.amazonaws.com/simulation/images/email-images/amazon_logo.png" height="160px">
                        </div>
                        <p>Hi {{first_name}},</p>
                        <p>Congratulations! You just won a $100 gift card from Amazon, available for purchases across the entire Amazon website. Click the button below to redeem the gift card to your account.</p>
                        <table class="btn btn-primary" cellspacing="0" cellpadding="0" border="0" role="presentation">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation">
                                  <tbody>
                                    <tr>
                                      <td> <a href="{{objective_link}}">Redeem your gift card</a> </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p>Amazon Gift Card</p>
                      </td>
                    </tr>
    
                  
                  </tbody>
                </table>
    
                
                <div class="footer">
                  <table cellspacing="0" cellpadding="0" border="0" role="presentation">
                    <tbody>
                      <tr>
                        <td class="content-block">
                          <span class="apple-link">Once applied to your Amazon account, the entire amount will be added to your gift card balance. Your gift card balance can't be transferred to other accounts, used to buy other gift cards, or, except as required by law, redeemed for cash.</span>
                        </td>
                      </tr>
                      <tr>
                      </tr>
                    </tbody>
                  </table>
                </div>
    
                
                
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
      
    </body></html>`
    }
  },
  // zoom_attendees_are_waiting
  {
    id: 'zoom_attendees_are_waiting',
    img_src: 'https://vansec-assets.s3.amazonaws.com/simulation/images/template-images/zoom_attendees_are_waiting.png',
    name: 'Zoom - your meeting attendees are waiting',
    difficulty: '3/5',
    fields: {
      // general 
      simulation_type: "single_send",
      simulation_objective: "open_link",
      simulation_user_properties: ["first_name", "last_name"],

      // objective dependent
      simulation_domain: "admin-settings.com",
      simulation_email_prefix: "zoom-meetings",
      simulation_display_name: "Zoom Video Communications Inc",
      simulation_subject_line: "Your meeting attendees are waiting!",

      // single send
      simulation_single_send_email_html: `<html lang="en"><head>
      <title>Your meeting attendees are waiting!</title>
      <style type="text/css">
        
        body {
          font-family: Helvetica, sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
          line-height: 1.3;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }
        
        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }
        
        table td {
          font-family: Helvetica, sans-serif;
          font-size: 16px;
          vertical-align: top;
        }
        
        body {
          background-color: #F2F3F5;
          margin: 0;
          padding: 0;
        }
        
        .body {
          background-color: #F2F3F5;
          width: 100%;
        }
        
        .container {
          margin: 0 auto !important;
          max-width: 600px;
          padding: 0;
          padding-top: 24px;
          width: 600px;
        }
        
        .content {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          max-width: 600px;
          padding: 0;
        }
        
        .main {
          background: #ffffff;
          border: 1px solid #eaebed;
          border-radius: 16px;
          width: 100%;
        }
        
        .wrapper {
          box-sizing: border-box;
          padding: 24px;
        }
        
        .footer {
          clear: both;
          padding-top: 24px;
          text-align: center;
          width: 100%;
        }
        
        .footer td,
        .footer p,
        .footer span,
        .footer a {
          color: #9a9ea6;
          font-size: 16px;
          text-align: center;
        }
        
        p {
          font-family: Helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          margin: 20px 0px 20px 0px;
        }
        
        a {
          color: #eebd43;
          text-decoration: underline;
        }
        
        .btn {
          box-sizing: border-box;
          min-width: 100% !important;
          width: 100%;
        }
        
        .btn > tbody > tr > td {
          padding-bottom: 16px;
        }
        
        .btn table {
          width: auto;
        }
        
        .btn table td {
          background-color: #ffffff;
          border-radius: 4px;
          text-align: center;
        }
        
        .btn a {
          background-color: #ffffff;
          border: solid 2px #eebd43;
          border-radius: 4px;
          box-sizing: border-box;
          color: #eebd43;
          cursor: pointer;
          display: inline-block;
          font-size: 16px;
          font-weight: medium;
          margin: 0;
          padding: 12px 24px;
          text-decoration: none;
          text-transform: capitalize;
        }
        
        .btn-primary table td {
          background-color: #ffffff;
        }
        
        .btn-primary a {
          background-color: #3A86FC;
          border-color: #3A86FC;
          color: #ffffff;
        }
        
        @media all {
          .btn-primary table td:hover {
            background-color: #bb922b !important;
          }
          .btn-primary a:hover {
            background-color: #bb922b !important;
            border-color: #bb922b !important;
          }
        }
        
        .last {
          margin-bottom: 0;
        }
        
        .first {
          margin-top: 0;
        }
        
        .align-center {
          text-align: center;
        }
        
        .align-right {
          text-align: right;
        }
        
        .align-left {
          text-align: left;
        }
        
        .text-link {
          color: #eebd43 !important;
          text-decoration: underline !important;
        }
        
        .clear {
          clear: both;
        }
        
        .mt0 {
          margin-top: 0;
        }
        
        .mb0 {
          margin-bottom: 0;
        }
        
        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0;
        }
        
        .powered-by a {
          text-decoration: none;
        }
        
        @media only screen and (max-width: 640px) {
          .main p,
          .main td,
          .main span {
            font-size: 16px !important;
          }
          .wrapper {
            padding: 8px !important;
          }
          .content {
            padding: 0 !important;
          }
          .container {
            padding: 0 !important;
            padding-top: 8px !important;
            width: 100% !important;
          }
          .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }
          .btn table {
            max-width: 100% !important;
            width: 100% !important;
          }
          .btn a {
            font-size: 16px !important;
            max-width: 100% !important;
            width: 100% !important;
          }
        }
        
        @media all {
          .ExternalClass {
            width: 100%;
          }
          .ExternalClass,
          .ExternalClass p,
          .ExternalClass span,
          .ExternalClass font,
          .ExternalClass td,
          .ExternalClass div {
            line-height: 100%;
          }
          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }
          #MessageViewBody a {
            color: inherit;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
        }
        </style>
      </head>
      <body>
        <table class="body" cellspacing="0" cellpadding="0" border="0" role="presentation">
          <tbody><tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
    
                
                <span class="preheader">Your meeting attendees are waiting!</span>
                <table class="main" cellspacing="0" cellpadding="0" border="0" role="presentation">
    
                  
                  <tbody>
                    <tr>
                      <td class="wrapper">
                        <div style="display: flex; flex-direction: column; align-items: center;">
                          <img src="https://vansec-assets.s3.amazonaws.com/simulation/images/email-images/zoom_logo.png" height="160px">
                        </div>
                        <p>Hello {{first_name}}</p>
                        <p>You've been invited to join a meeting in progress. Click here to join.</p>
                        <table class="btn btn-primary" cellspacing="0" cellpadding="0" border="0" role="presentation">
                          <tbody>
                            <tr>
                              <td align="center">
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation">
                                  <tbody>
                                    <tr>
                                      <td> <a href="{{objective_link}}">Join the meeting</a> </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p>Host: {{first_name}} {{last_name}}</p>
                        <p>Meeting name: {{first_name}}'s meeting</p>
                        <p>Meeting ID: 235 9283 2910</p>
                        <p>Password: T3vHl9cu2m</p>
                        <p>Zoom Video Communications</p>
                      </td>
                    </tr>
    
                  
                  </tbody>
                </table>
    
                
                <div class="footer">
                  <table cellspacing="0" cellpadding="0" border="0" role="presentation">
                    <tbody>
                      <tr>
                        <td class="content-block">
                          <span class="apple-link">© 2024 Zoom Video Communications - All Rights Reserved</span>
                        </td>
                      </tr>
                      <tr>
                      </tr>
                    </tbody>
                  </table>
                </div>
    
                
                
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
      
    </body></html>`
    }
  },
  // google_security_alert
  {
    id: 'google_security_alert',
    img_src: 'https://vansec-assets.s3.amazonaws.com/simulation/images/template-images/google_security_alert.png',
    name: 'Google - security alert',
    difficulty: '3/5',
    fields: {
      // general 
      simulation_type: "single_send",
      simulation_objective: "open_link_submit_website",
      simulation_user_properties: ["email_address"],

      // objective dependent
      simulation_domain: "google-homepage.com",
      simulation_email_prefix: "alerts",
      simulation_display_name: "Google",
      simulation_subject_line: "Security alert",
      simulation_website: "google_login",
      simulation_website_url: "https://www.google-homepage.com",
      simulation_website_open_link_tracking_is_enabled: true,
      simulation_website_submit_website_capture_is_enabled: false,

      // single send
      simulation_single_send_email_html: `<html lang="en"><head>
      <title>Security alert</title>
      <style type="text/css">
        
        body {
          font-family: Helvetica, sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
          line-height: 1.3;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }
        
        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }
        
        table td {
          font-family: Helvetica, sans-serif;
          font-size: 16px;
          vertical-align: top;
        }
        
        body {
          background-color: #F2F3F5;
          margin: 0;
          padding: 0;
        }
        
        .body {
          background-color: #F2F3F5;
          width: 100%;
        }
        
        .container {
          margin: 0 auto !important;
          max-width: 600px;
          padding: 0;
          padding-top: 24px;
          width: 600px;
        }
        
        .content {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          max-width: 600px;
          padding: 0;
        }
        
        .main {
          background: #ffffff;
          border: 1px solid #eaebed;
          border-radius: 16px;
          width: 100%;
        }
        
        .wrapper {
          box-sizing: border-box;
          padding: 24px;
        }
        
        .footer {
          clear: both;
          padding-top: 24px;
          text-align: center;
          width: 100%;
        }
        
        .footer td,
        .footer p,
        .footer span,
        .footer a {
          color: #9a9ea6;
          font-size: 16px;
          text-align: center;
        }
        
        p {
          font-family: Helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          margin: 20px 0px 20px 0px;
        }
        
        a {
          color: #eebd43;
          text-decoration: underline;
        }
        
        .btn {
          box-sizing: border-box;
          min-width: 100% !important;
          width: 100%;
        }
        
        .btn > tbody > tr > td {
          padding-bottom: 16px;
        }
        
        .btn table {
          width: auto;
        }
        
        .btn table td {
          background-color: #ffffff;
          border-radius: 4px;
          text-align: center;
        }
        
        .btn a {
          background-color: #ffffff;
          border: solid 2px #eebd43;
          border-radius: 4px;
          box-sizing: border-box;
          color: #eebd43;
          cursor: pointer;
          display: inline-block;
          font-size: 16px;
          font-weight: medium;
          margin: 0;
          padding: 12px 24px;
          text-decoration: none;
          text-transform: capitalize;
        }
        
        .btn-primary table td {
          background-color: #ffffff;
        }
        
        .btn-primary a {
          background-color: #3A86FC;
          border-color: #3A86FC;
          color: #ffffff;
        }
        
        @media all {
          .btn-primary table td:hover {
            background-color: #bb922b !important;
          }
          .btn-primary a:hover {
            background-color: #bb922b !important;
            border-color: #bb922b !important;
          }
        }
        
        .last {
          margin-bottom: 0;
        }
        
        .first {
          margin-top: 0;
        }
        
        .align-center {
          text-align: center;
        }
        
        .align-right {
          text-align: right;
        }
        
        .align-left {
          text-align: left;
        }
        
        .text-link {
          color: #eebd43 !important;
          text-decoration: underline !important;
        }
        
        .clear {
          clear: both;
        }
        
        .mt0 {
          margin-top: 0;
        }
        
        .mb0 {
          margin-bottom: 0;
        }
        
        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0;
        }
        
        .powered-by a {
          text-decoration: none;
        }
        
        @media only screen and (max-width: 640px) {
          .main p,
          .main td,
          .main span {
            font-size: 16px !important;
          }
          .wrapper {
            padding: 8px !important;
          }
          .content {
            padding: 0 !important;
          }
          .container {
            padding: 0 !important;
            padding-top: 8px !important;
            width: 100% !important;
          }
          .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }
          .btn table {
            max-width: 100% !important;
            width: 100% !important;
          }
          .btn a {
            font-size: 16px !important;
            max-width: 100% !important;
            width: 100% !important;
          }
        }
        
        @media all {
          .ExternalClass {
            width: 100%;
          }
          .ExternalClass,
          .ExternalClass p,
          .ExternalClass span,
          .ExternalClass font,
          .ExternalClass td,
          .ExternalClass div {
            line-height: 100%;
          }
          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }
          #MessageViewBody a {
            color: inherit;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
        }
        </style>
      </head>
      <body>
        <table class="body" cellspacing="0" cellpadding="0" border="0" role="presentation">
          <tbody><tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
    
                
                <span class="preheader">Security alert</span>
                <table class="main" cellspacing="0" cellpadding="0" border="0" role="presentation">
    
                  
                  <tbody>
                    <tr>
                      <td class="wrapper">
                        <div style="display: flex; flex-direction: column; align-items: center;">
                          <img src="https://vansec-assets.s3.amazonaws.com/simulation/images/email-images/google_logo.png" height="60px">
                        </div>
                        <p style="font-size: 28px; text-align: center;">A new sign-in</p>
                        <p style="text-align: center;">{{email_address}}</p>
                        <p>We noticed a new sign-in to your Google Account that is suspicious. If this was you, you don’t need to do anything. If not, we’ll help you secure your account.</p>
                        <table class="btn btn-primary" cellspacing="0" cellpadding="0" border="0" role="presentation">
                          <tbody>
                            <tr>
                              <td align="center">
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation">
                                  <tbody>
                                    <tr>
                                      <td> <a href="{{website_link}}">Check activity</a> </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p style="font-size: 14px;">You can also see security activity at https://myaccount.google.com/notifications.</p>
                      </td>
                    </tr>
    
                  
                  </tbody>
                </table>
    
                
                <div class="footer">
                  <table cellspacing="0" cellpadding="0" border="0" role="presentation">
                    <tbody>
                      <tr>
                        <td class="content-block">
                          <span class="apple-link" style="font-size: 14px;">You received this email to let you know about important changes to your Google Account and services. © 2024 Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</span>
                        </td>
                      </tr>
                      <tr>
                      </tr>
                    </tbody>
                  </table>
                </div>
    
                
                
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
      
    </body></html>`
    }
  },
  // // google_security_alert
  // {
  //   id: 'google_security_alert',
  //   img_src: 'https://vansec-assets.s3.amazonaws.com/simulation/images/template-images/google_security_alert.png',
  //   name: 'Google - security alert',
  //   difficulty: '2/5',
  //   fields: {
  //     // general 
  //     simulation_type: "single_send",
  //     simulation_objective: "open_link",
  //     simulation_domain: "admin-settings.com",
  //     simulation_email_prefix: "google-alerts",
  //     simulation_display_name: "Google",
  //     simulation_subject_line: "Security alert",
  //     simulation_user_properties: ["email_address"],

  //     // single send
  //     simulation_single_send_email_html: `<html lang="en"><head>
  //     <title>Security alert</title>
  //     <style type="text/css">
        
  //       body {
  //         font-family: Helvetica, sans-serif;
  //         -webkit-font-smoothing: antialiased;
  //         font-size: 16px;
  //         line-height: 1.3;
  //         -ms-text-size-adjust: 100%;
  //         -webkit-text-size-adjust: 100%;
  //       }
        
  //       table {
  //         border-collapse: separate;
  //         mso-table-lspace: 0pt;
  //         mso-table-rspace: 0pt;
  //         width: 100%;
  //       }
        
  //       table td {
  //         font-family: Helvetica, sans-serif;
  //         font-size: 16px;
  //         vertical-align: top;
  //       }
        
  //       body {
  //         background-color: #F2F3F5;
  //         margin: 0;
  //         padding: 0;
  //       }
        
  //       .body {
  //         background-color: #F2F3F5;
  //         width: 100%;
  //       }
        
  //       .container {
  //         margin: 0 auto !important;
  //         max-width: 600px;
  //         padding: 0;
  //         padding-top: 24px;
  //         width: 600px;
  //       }
        
  //       .content {
  //         box-sizing: border-box;
  //         display: block;
  //         margin: 0 auto;
  //         max-width: 600px;
  //         padding: 0;
  //       }
        
  //       .main {
  //         background: #ffffff;
  //         border: 1px solid #eaebed;
  //         border-radius: 16px;
  //         width: 100%;
  //       }
        
  //       .wrapper {
  //         box-sizing: border-box;
  //         padding: 24px;
  //       }
        
  //       .footer {
  //         clear: both;
  //         padding-top: 24px;
  //         text-align: center;
  //         width: 100%;
  //       }
        
  //       .footer td,
  //       .footer p,
  //       .footer span,
  //       .footer a {
  //         color: #9a9ea6;
  //         font-size: 16px;
  //         text-align: center;
  //       }
        
  //       p {
  //         font-family: Helvetica, sans-serif;
  //         font-size: 16px;
  //         font-weight: normal;
  //         margin: 20px 0px 20px 0px;
  //       }
        
  //       a {
  //         color: #eebd43;
  //         text-decoration: underline;
  //       }
        
  //       .btn {
  //         box-sizing: border-box;
  //         min-width: 100% !important;
  //         width: 100%;
  //       }
        
  //       .btn > tbody > tr > td {
  //         padding-bottom: 16px;
  //       }
        
  //       .btn table {
  //         width: auto;
  //       }
        
  //       .btn table td {
  //         background-color: #ffffff;
  //         border-radius: 4px;
  //         text-align: center;
  //       }
        
  //       .btn a {
  //         background-color: #ffffff;
  //         border: solid 2px #eebd43;
  //         border-radius: 4px;
  //         box-sizing: border-box;
  //         color: #eebd43;
  //         cursor: pointer;
  //         display: inline-block;
  //         font-size: 16px;
  //         font-weight: medium;
  //         margin: 0;
  //         padding: 12px 24px;
  //         text-decoration: none;
  //         text-transform: capitalize;
  //       }
        
  //       .btn-primary table td {
  //         background-color: #ffffff;
  //       }
        
  //       .btn-primary a {
  //         background-color: #3A86FC;
  //         border-color: #3A86FC;
  //         color: #ffffff;
  //       }
        
  //       @media all {
  //         .btn-primary table td:hover {
  //           background-color: #bb922b !important;
  //         }
  //         .btn-primary a:hover {
  //           background-color: #bb922b !important;
  //           border-color: #bb922b !important;
  //         }
  //       }
        
  //       .last {
  //         margin-bottom: 0;
  //       }
        
  //       .first {
  //         margin-top: 0;
  //       }
        
  //       .align-center {
  //         text-align: center;
  //       }
        
  //       .align-right {
  //         text-align: right;
  //       }
        
  //       .align-left {
  //         text-align: left;
  //       }
        
  //       .text-link {
  //         color: #eebd43 !important;
  //         text-decoration: underline !important;
  //       }
        
  //       .clear {
  //         clear: both;
  //       }
        
  //       .mt0 {
  //         margin-top: 0;
  //       }
        
  //       .mb0 {
  //         margin-bottom: 0;
  //       }
        
  //       .preheader {
  //         color: transparent;
  //         display: none;
  //         height: 0;
  //         max-height: 0;
  //         max-width: 0;
  //         opacity: 0;
  //         overflow: hidden;
  //         mso-hide: all;
  //         visibility: hidden;
  //         width: 0;
  //       }
        
  //       .powered-by a {
  //         text-decoration: none;
  //       }
        
  //       @media only screen and (max-width: 640px) {
  //         .main p,
  //         .main td,
  //         .main span {
  //           font-size: 16px !important;
  //         }
  //         .wrapper {
  //           padding: 8px !important;
  //         }
  //         .content {
  //           padding: 0 !important;
  //         }
  //         .container {
  //           padding: 0 !important;
  //           padding-top: 8px !important;
  //           width: 100% !important;
  //         }
  //         .main {
  //           border-left-width: 0 !important;
  //           border-radius: 0 !important;
  //           border-right-width: 0 !important;
  //         }
  //         .btn table {
  //           max-width: 100% !important;
  //           width: 100% !important;
  //         }
  //         .btn a {
  //           font-size: 16px !important;
  //           max-width: 100% !important;
  //           width: 100% !important;
  //         }
  //       }
        
  //       @media all {
  //         .ExternalClass {
  //           width: 100%;
  //         }
  //         .ExternalClass,
  //         .ExternalClass p,
  //         .ExternalClass span,
  //         .ExternalClass font,
  //         .ExternalClass td,
  //         .ExternalClass div {
  //           line-height: 100%;
  //         }
  //         .apple-link a {
  //           color: inherit !important;
  //           font-family: inherit !important;
  //           font-size: inherit !important;
  //           font-weight: inherit !important;
  //           line-height: inherit !important;
  //           text-decoration: none !important;
  //         }
  //         #MessageViewBody a {
  //           color: inherit;
  //           text-decoration: none;
  //           font-size: inherit;
  //           font-family: inherit;
  //           font-weight: inherit;
  //           line-height: inherit;
  //         }
  //       }
  //       </style>
  //     </head>
  //     <body>
  //       <table class="body" cellspacing="0" cellpadding="0" border="0" role="presentation">
  //         <tbody><tr>
  //           <td>&nbsp;</td>
  //           <td class="container">
  //             <div class="content">
    
                
  //               <span class="preheader">Security alert</span>
  //               <table class="main" cellspacing="0" cellpadding="0" border="0" role="presentation">
    
                  
  //                 <tbody>
  //                   <tr>
  //                     <td class="wrapper">
  //                       <div style="display: flex; flex-direction: column; align-items: center;">
  //                         <img src="https://vansec-assets.s3.amazonaws.com/simulation/images/email-images/google_logo.png" height="60px">
  //                       </div>
  //                       <p style="font-size: 28px; text-align: center;">A new sign-in</p>
  //                       <p style="text-align: center;">{{email_address}}</p>
  //                       <p>We noticed a new sign-in to your Google Account that is suspicious. If this was you, you don’t need to do anything. If not, we’ll help you secure your account.</p>
  //                       <table class="btn btn-primary" cellspacing="0" cellpadding="0" border="0" role="presentation">
  //                         <tbody>
  //                           <tr>
  //                             <td align="center">
  //                               <table cellspacing="0" cellpadding="0" border="0" role="presentation">
  //                                 <tbody>
  //                                   <tr>
  //                                     <td> <a href="{{objective_link}}">Check activity</a> </td>
  //                                   </tr>
  //                                 </tbody>
  //                               </table>
  //                             </td>
  //                           </tr>
  //                         </tbody>
  //                       </table>
  //                       <p style="font-size: 14px;">You can also see security activity at https://myaccount.google.com/notifications.</p>
  //                     </td>
  //                   </tr>
    
                  
  //                 </tbody>
  //               </table>
    
                
  //               <div class="footer">
  //                 <table cellspacing="0" cellpadding="0" border="0" role="presentation">
  //                   <tbody>
  //                     <tr>
  //                       <td class="content-block">
  //                         <span class="apple-link" style="font-size: 14px;">You received this email to let you know about important changes to your Google Account and services. © 2024 Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</span>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                     </tr>
  //                   </tbody>
  //                 </table>
  //               </div>
    
                
                
  //             </div>
  //           </td>
  //           <td>&nbsp;</td>
  //         </tr>
  //       </tbody></table>
      
  //   </body></html>`
  //   }
  // },
]

export const simulation_multi_message_templates = [
  // vendor_account_number_changed
  {
    id: 'vendor_account_number_changed',
    img_src: 'https://vansec-assets.s3.amazonaws.com/simulation/images/template-images/vendor_account_number_changed.png',
    name: 'Vendor (dynamically selected for each user) - account number changed',
    difficulty: '5/5',
    fields: {
      // general
      simulation_type: "multi_message",
      simulation_objective: "reply_info",
      simulation_user_properties: ["email_address", "first_name", "last_name", "position"],

      // objective dependent
      simulation_domain: "admin-settings.com",
      simulation_email_prefix: "support",
      simulation_display_name: "Accounts",
      simulation_subject_line: "[Action required] Our account number has changed",
      simulation_reply_info_objective: "Confirmation from the user that the account number has been updated",

      // multi message
      simulation_multi_message_instructions: "Based on the user's position within the company, you will be impersonating an account executive of a vendor that this user works with. Choose the most appropriate real-life vendor for this. Your bank account number has changed recently, so you need to have the user contact their accounts payable department to update the bank account number. First, explain the situation to the user. Once the user responds back acknowleding the situation, send the user the following bank account details: generate a dummy invalid bank account details (make sure that the account number is too short or long to be valid) but do not say that this is invalid. Continue the email conversation until the user confirms that the account number has been updated.",
    }
  },
  // hr_employee_info_missing
  {
    id: 'hr_employee_info_missing',
    img_src: 'https://vansec-assets.s3.amazonaws.com/simulation/images/template-images/hr_employee_info_missing.png',
    name: 'HR - employee info missing',
    difficulty: '5/5',
    fields: {
      // general
      simulation_type: "multi_message",
      simulation_objective: "reply_info",
      simulation_user_properties: ["email_address", "first_name", "last_name", "position"],

      // objective dependent
      simulation_domain: "admin-settings.com",
      simulation_email_prefix: "hr",
      simulation_display_name: "HR",
      simulation_subject_line: "[Action required] Information missing",
      simulation_reply_info_objective: "Social security number and bank account number",

      // multi message
      simulation_multi_message_instructions: "You will be impersonating someone from the HR department of the company (do not disclose your own name). You are currently missing the employee's social security number and bank account number for payroll. First, explain to the employee that your files are missing some information about the employee, and send them their full name and position and ask them to confirm them. Once they confirm, ask them for their social security number and their bank account number. If they do not share the details, keep asking until they share.",
    }
  },
  // ceo_fraud_urgent_wire
  {
    id: 'ceo_fraud_urgent_wire',
    img_src: 'https://vansec-assets.s3.amazonaws.com/simulation/images/template-images/ceo_fraud_urgent_wire.png',
    name: 'CEO fraud - urgent wire',
    difficulty: '5/5',
    fields: {
      // general
      simulation_type: "multi_message",
      simulation_objective: "open_link",
      simulation_user_properties: ["email_address", "first_name", "last_name", "position"],

      // objective dependent
      simulation_domain: "admin-settings.com",
      simulation_email_prefix: "ceo",
      simulation_display_name: "CEO",
      simulation_subject_line: "[Urgent] Deal closure help",

      // multi message
      simulation_multi_message_instructions: "You will be impersonating the CEO of the company and you are about to close a time-sensitive $3.5M acquisition of another organization. It is assumed that the employee (the user) has the ability to make wire transfers on behalf of the company. You need to first get a hold of the attention your employee, second get them on standby so that they can send the wire immediately when the time comes, third send the wire information as a link, and fourth get them to click on the link to view the wire info.",
    }
  },
  // fedex_address_info_missing
  {
    id: 'fedex_address_info_missing',
    img_src: 'https://vansec-assets.s3.amazonaws.com/simulation/images/template-images/fedex_address_info_missing.png',
    name: 'Fedex - address information missing',
    difficulty: '4/5',
    fields: {
      // general
      simulation_type: "multi_message",
      simulation_objective: "open_link",
      simulation_user_properties: ["email_address", "first_name", "last_name", "position"],

      // objective dependent
      simulation_domain: "admin-settings.com",
      simulation_email_prefix: "fedex",
      simulation_display_name: "Fedex Customer Service",
      simulation_subject_line: "[Action required] Fedex - address info missing",

      // multi message
      simulation_multi_message_instructions: "You will be impersonating a Fedex employee named Joe. You are missing some address information that is needed to complete the delivery of an important package that is being sent to the company. You need someone from the company to visit a secure link to confirm the company address details. Before you send out the secure link, you need the user to confirm their name and position in the company.",
    }
  },
  // it_strange_login_behavior
  {
    id: 'it_strange_login_behavior',
    img_src: 'https://vansec-assets.s3.amazonaws.com/simulation/images/template-images/it_strange_login_behavior.png',
    name: 'IT - Strange login behavior',
    difficulty: '4/5',
    fields: {
      // general
      simulation_type: "multi_message",
      simulation_objective: "open_link",
      simulation_user_properties: ["email_address", "first_name", "last_name", "position"],

      // objective dependent
      simulation_domain: "admin-settings.com",
      simulation_email_prefix: "it",
      simulation_display_name: "IT Alerts",
      simulation_subject_line: "[Action required] Strange login behavior",
      
      // multi message
      simulation_multi_message_instructions: `In your first email, write the following:
      
"We received an alert late last night informing IT of some strange login behavior on your account between approximately 12:00 AM and 2:00 AM. We would like to have you review the list of locations and devices logged in during that time frame and verify that they all belong to you.

Please confirm your full name and position in the company and we will send you a secure link for you to access the ticket."

After the user confirms with their full name and position, send the link to the user.`,
    }
  },
  // vendor_some_info_missing
  {
    id: 'vendor_some_info_missing',
    img_src: 'https://vansec-assets.s3.amazonaws.com/simulation/images/template-images/vendor_some_info_missing.png',
    name: 'Vendor (dynamically selected for each user) - some information missing',
    difficulty: '4/5',
    fields: {
      // general
      simulation_type: "multi_message",
      simulation_objective: "open_link",
      simulation_domain: "admin-settings.com",
      simulation_email_prefix: "support",
      simulation_display_name: "Accounts",
      simulation_subject_line: "[Action required] Important information missing",
      simulation_user_properties: ["email_address", "first_name", "last_name", "position"],

      // multi message
      simulation_multi_message_instructions: "Based on the user's position within the company, you will be impersonating an account executive of a vendor that this user works with. Choose the most appropriate real-life vendor for this. You are missing some important information about the user that is needed for the service that you provide as a vendor. Come up with a reason for needing the info. You need the user to visit a secure link to enter in the information details. Before you send out the secure link, you need the user to confirm their name and position in the company.",
    }
  },
]

