
import { useEffect, useState, useRef, Fragment } from 'react'
import { useLocation, useNavigate, useSearchParams, Link } from "react-router-dom"


import { Transition, Listbox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'


import axios from "axios"
import { set as idb_set } from 'idb-keyval'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../contexts/GlobalContext'

import { HASH } from '../../libs/crypto'
import { string_to_uint8_array, uint8_array_to_base64 } from '../../libs/converters'
import { handle_enter_keydown, classNames } from '../../libs/helpers'
import { validate_password } from '../../libs/validate'



import { isDesktop } from "react-device-detect";

import { ReactComponent as SquareLogo } from "../../media/logos/dynamic_logo_64x64.svg"
import { mixpanel_client_track } from '../../libs/mixpanelClient'





const SetPasswordPage = ({
  
} : {
  
}) => {

  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // Search params
  const [search_params, set_search_params] = useSearchParams()

  // Navigate
  const navigate = useNavigate()

  

  const user_id = search_params.get("user_id")


  // User input
  const [first_name, set_first_name] = useState("")
  const [last_name, set_last_name] = useState("")

  // const [department, set_department] = useState(department_options[0])
  const [position, set_position] = useState("")

  const [password, set_password] = useState("")
  const [password_confirm, set_password_confirm] = useState("")
  

  // Autofocus on first name field
  const first_name_ref = useRef<any>(null)


  // Error message
  const [error_message, set_error_message] = useState("")
  const [error_message_is_displayed, set_error_message_is_displayed] = useState(false)

  // Awaiting
  const [is_awaiting, set_is_awaiting] = useState(false)





  // Reset variables
  const reset = () => {
    set_is_awaiting(false)
  }

  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "first_name": {
        set_first_name(value)

        // Always break
        break
      }
      case "last_name": {
        set_last_name(value)

        // Always break
        break
      }
      // case "department": {
      //   set_department(value)

      //   // Always break
      //   break
      // }
      case "position": {
        set_position(value)

        // Always break
        break
      }
      case "password": {
        set_password(value)

        // Always break
        break
      }
      case "password_confirm": {
        set_password_confirm(value)

        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
    set_error_message_is_displayed(false)
  }

  const submit = async () => {

    // // Sanity check to disallow login from mobile (wouldn't happen, because cannot submit from mobile anyways)
    // if (isMobile) {
    //   alert("Please try again from a desktop")

    //   // Redirect to index page
    //   navigate("/")

    //   return
    // }

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate first name
    if (first_name.length === 0) {
      reset()

      // Show error message
      set_error_message("Please enter your first name")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }

    // Validate last name
    if (last_name.length === 0) {
      reset()

      // Show error message
      set_error_message("Please enter your last name")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }

    // // Validate department
    // if (!department) {
    //   reset()

    //   // Show error message
    //   set_error_message("Please select the department")
    // }

    // Validate position
    if (!position) {
      reset()

      // Show error message
      set_error_message("Please select the position")
    }

    // Validate password
    if (!validate_password(password)) {
      reset()

      // Show error message
      set_error_message("Password must be at least 8 characters long and must include a lowercase alphabet, an uppercase alphabet, a number, and a special character")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }

    // Validate password confirm
    if (password !== password_confirm) {
      reset()

      // Show error message
      set_error_message("Passwords do not match")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }


    // END OF USER INPUT CHECK



    // Hash master password prepare for API call
    const password__unenc__uint8_array = string_to_uint8_array(password)
    const password__hash__uint8_array = await HASH(password__unenc__uint8_array)
    const password__hash__base64 = uint8_array_to_base64(password__hash__uint8_array)

    // Execute signup
    const post_auth_signup_password_res = await axios.post(`/api/auth/signup/password`, {
      user_id: user_id,
      first_name: first_name,
      last_name: last_name,
      // department: department,
      position: position,
      password__hash: password__hash__base64,
    })

    if (!post_auth_signup_password_res.data.success) {
      switch (post_auth_signup_password_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to signup
          navigate(`/signup`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_auth_signup_password_res.data.status) {
      case "AUTHENTICATED": {
        // Save JWT in idb-keyval
        await idb_set("session_token", post_auth_signup_password_res.data.session_token)

        // Global variables
        global_update({ 
          ...global_context, 
          authenticated: true,
          user_id: post_auth_signup_password_res.data.user_id,
          type: post_auth_signup_password_res.data.type
        })
  
        // ___??___ This doesn't appear to be necessary, but just having it here for safety and for code readability
        // navigate(location.state ? location.state.from : `/onboarding`)

        // If owner, navigate to add users page
        if (post_auth_signup_password_res.data.type === "owner") {
          navigate(`/dashboard/users/add`)
        }
        // If admin, navigate to the campaigns page
        else if (post_auth_signup_password_res.data.type === "admin") {
          navigate(`/dashboard/campaigns`)
        }
        // If not owner or admin, navigate to the account page
        else {
          navigate(`/dashboard/account`)
        }
        
        // Always break
        break
      }
      case "USER_DOES_NOT_EXIST": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      case "USER_EXISTS_AND_EMAIL_NOT_VALIDATED": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      case "USER_EXISTS_AND_ALREADY_COMPLETED_SIGNUP": {
        alert("This account had already completed signup previously. Redirecting to login page.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "ORGANIZATION_DOES_NOT_EXIST": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      case "OWNER_SIGNUP_ORGANIZATION_IS_ALREADY_CREATED": {
        alert("Someone else has completed the setup for your organization. Please try again.")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      case "NON_OWNER_SIGNUP_ORGANIZATION_IS_NOT_CREATED": {
        alert("Fatal error occurred. Please reach out to support@vansec.com")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }
  
  // Renders
  useEffect(() => {
    // Focus on first name field upon load
    first_name_ref.current.focus()

    // Mixpanel tracking
    mixpanel_client_track("app_set_password_visited", null)
  }, [])

  return (
    <div className="absolute w-full h-screen flex flex-col justify-center items-center z-10">
      {isDesktop
      ? <div className="flex w-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
            <SquareLogo className="h-8 w-auto" />
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  First name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    ref={first_name_ref}
                    value={first_name}
                    onChange={(e) => handle_user_input("first_name", e.target.value)}
                    onKeyDown={async (e) => handle_enter_keydown(e, submit)}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    value={last_name}
                    onChange={(e) => handle_user_input("last_name", e.target.value)}
                    onKeyDown={async (e) => handle_enter_keydown(e, submit)}
                  />
                </div>
              </div>

              {/* Department */}
              {/* <div>
                <Listbox value={department} onChange={(e) => handle_user_input("department", e)}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Department</Listbox.Label>
                      <div className="relative mt-2">
                        <Listbox.Button 
                          className="relative w-full cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        >
                          <span className="block truncate">{department}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {department_options.map((department_option) => (
                              <Listbox.Option
                                key={department_option}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                  )
                                }
                                value={department_option}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {department_option}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-blue-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div> */}

              {/* Position */}
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Position
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    value={position}
                    onChange={(e) => handle_user_input("position", e.target.value)}
                    onKeyDown={async (e) => handle_enter_keydown(e, submit)}
                  />
                </div>
                {/* <Listbox value={position} onChange={(e) => handle_user_input("position", e)}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Position</Listbox.Label>
                      <div className="relative mt-2">
                        <Listbox.Button 
                          className="relative w-full cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        >
                          <span className="block truncate">{position}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {position_options.map((position_option) => (
                              <Listbox.Option
                                key={position_option}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                  )
                                }
                                value={position_option}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {position_option}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-blue-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox> */}
              </div>

              <div>
                <label className="block text-sm leading-6 text-gray-900 flex">
                  <span className="font-medium">Password</span>
                  <span className="flex relative items-center">
                    <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                    <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                      Password must be at least 8 characters long and must include a lowercase alphabet, an uppercase alphabet, a number, and a special character.
                    </span>
                  </span>
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    value={password}
                    onChange={(e) => handle_user_input("password", e.target.value)}
                    onKeyDown={async (e) => handle_enter_keydown(e, submit)}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Confirm password
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    value={password_confirm}
                    onChange={(e) => handle_user_input("password_confirm", e.target.value)}
                    onKeyDown={async (e) => handle_enter_keydown(e, submit)}
                  />
                </div>
              </div>

              {error_message_is_displayed
              ? <div className="flex space-x-2 items-start">
                  <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                  <div className="text-sm font-medium text-red-400">{error_message}</div>
                </div>
              : <></>}

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={submit}
                >
                  {is_awaiting ? (
                    <ReactLoading
                      type='spokes'
                      color='#ffffff'
                      height={20}
                      width={20}
                    />
                  ) : (
                    <span>Create account</span>
                  )}
                </button>
              </div>
            </div>

            <p className="mt-10 text-center text-sm text-gray-500">
              Already have an account?{" "}
              <Link to="/login" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                Log in
              </Link>
            </p>
          </div>
        </div>
      : <div className="flex min-h-full flex-col bg-transparent pt-16 pb-12">
          <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="text-base font-semibold text-gray-600">Error</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Unsupported access.</h1>
                <p className="mt-2 text-base text-gray-500">This page can only be accessed from a desktop.</p>
                <div className="mt-6">
                  <Link to='/'>
                    <span className="text-base font-medium text-gray-600 hover:text-gray-500">
                      Go back home
                      <span aria-hidden="true"> &rarr;</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </main>
          <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-center space-x-4">
              <Link to="mailto:support@vansec.com" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Contact Support
              </Link>
            </nav>
          </footer>
        </div>
      }
    </div>
  )
}

export default SetPasswordPage