import { campaign_status_map, campaign_type_map, get_program_property_value, get_user_property_value, provision_type_map } from "./formats"
import { filter_regex, timestamp_to_local_datetime } from "./helpers"
import { program_property_options, user_property_options } from "./options"




export const user_search_filter_fn = (user, search_text) => {

  const search_text__trimmed_lowercase = search_text.trim().toLowerCase()

  // If search text is empty, return all
  if (search_text__trimmed_lowercase === "") {
    return true
  }

  // Search text is not empty
  else {
    // If any property matches, return true
    for (const property of user_property_options) {
      const user_value = get_user_property_value(user, property, true)
      if (user_value.toLowerCase().includes(search_text__trimmed_lowercase)) {
        return true
      }
    }

    // If nothing, return false
    return false
  }
}

export const group_search_filter_fn = (group, search_text) => {

  const search_text__trimmed_lowercase = search_text.trim().toLowerCase()

  // If search text is empty, return all
  if (search_text__trimmed_lowercase === "") {
    return true
  }

  // Search text is not empty
  else {
    // See if search_text is included in name
    if ((group.group_metadata.name === "UNSET" ? "(Unspecified)" : `${group.group_metadata.name}`).toLowerCase().includes(search_text__trimmed_lowercase)) {
      return true
    }

    // See if search_text is included in number of users
    else if (group.active_user_ids.length.toString().toLowerCase().includes(search_text__trimmed_lowercase)) {
      return true
    }

    // See if search_text is included in campaign status
    else if (provision_type_map[group.provision_metadata.type].toLowerCase().includes(search_text__trimmed_lowercase)) {
      return true
    }    

    // If nothing, return false
    else {
      return false
    }
  }
}


export const campaign_search_filter_fn = (campaign, search_text) => {

  const search_text__trimmed_lowercase = search_text.trim().toLowerCase()

  // If search text is empty, return all
  if (search_text__trimmed_lowercase === "") {
    return true
  }

  // Search text is not empty
  else {
    // See if search_text is included in name
    if ((campaign.campaign_metadata.name === "UNSET" ? "(Unspecified)" : `${campaign.campaign_metadata.name}`).toLowerCase().includes(search_text__trimmed_lowercase)) {
      return true
    }

    // See if search_text is included in campaign status
    else if (campaign_status_map[campaign.status].toLowerCase().includes(search_text__trimmed_lowercase)) {
      return true
    }
    
    // See if search_text is included in campaign type
    else if ((campaign.campaign_metadata.type === "UNSET" ? "(Unspecified)" : campaign_type_map[campaign.campaign_metadata.type]).toLowerCase().includes(search_text__trimmed_lowercase)) {
      return true
    }
    
    // See if search_text is included in start date
    else if ((campaign.campaign_metadata.start_time === -1 ? "(Unspecified)" : `${timestamp_to_local_datetime(campaign.campaign_metadata.start_time)[0]} ${timestamp_to_local_datetime(campaign.campaign_metadata.start_time)[1]}`).toLowerCase().includes(search_text__trimmed_lowercase)) {
      return true
    }

    // See if search_text is included in number of users
    else if (campaign.campaign_metadata.user_ids.length.toString().toLowerCase().includes(search_text__trimmed_lowercase)) {
      return true
    }

    // If nothing, return false
    else {
      return false
    }
  }
}


export const program_search_filter_fn = (program, search_text) => {

  const search_text__trimmed_lowercase = search_text.trim().toLowerCase()

  // If search text is empty, return all
  if (search_text__trimmed_lowercase === "") {
    return true
  }

  // Search text is not empty
  else {
    // If any property matches, return true
    for (const property of program_property_options) {
      const user_value = get_program_property_value(program, property, true)
      if (user_value.toLowerCase().includes(search_text__trimmed_lowercase)) {
        return true
      }
    }

    // If nothing, return false
    return false
  }
}









export const user_filters_filter_fn = (user, filters) => {

  let is_valid = true

  for (const filter of filters) {
    const property = filter.match(filter_regex)[1]
    const filter_type = filter.match(filter_regex)[2]
    const filter_value = filter.match(filter_regex)[3]

    switch(filter_type) {
      case "includes_text": {
        const user_value = get_user_property_value(user, property, true)
        if (!user_value.toLowerCase().includes(filter_value)) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_text": {
        const user_value = get_user_property_value(user, property, true)
        if (filter_value !== user_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_option": {
        let user_value = get_user_property_value(user, property, false)

        switch(typeof user_value) {
          case "string": {
            user_value = user_value
            // Always break
            break
          }
          case "boolean": {
            user_value = user_value ? "true" : "false"
            // Always break
            break
          }
          default: {
            // Always break
            break
          }
        }

        if (filter_value !== user_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "before_date": {
        const user_value = get_user_property_value(user, property, false)
        const user_value_date = new Date(user_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() < user_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "after_date": {
        const user_value = get_user_property_value(user, property, false)
        const user_value_date = new Date(user_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() > user_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value === filter_value
        if (user_value !== filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lte_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value <= filter_value
        if (user_value > filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gte_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value >= filter_value
        if (user_value < filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "lt_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value < filter_value
        if (user_value >= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      case "gt_number": {
        const user_value = get_user_property_value(user, property, false)
        const filter_value_number = Number(filter_value)
        
        // user_value > filter_value
        if (user_value <= filter_value_number) {
          is_valid = false
        }
        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  return is_valid
}


export const program_filters_filter_fn = (program, filters) => {

  let is_valid = true

  for (const filter of filters) {
    const property = filter.match(filter_regex)[1]
    const filter_type = filter.match(filter_regex)[2]
    const filter_value = filter.match(filter_regex)[3]

    switch(filter_type) {
      case "includes_text": {
        const program_value = get_program_property_value(program, property, true)
        if (!program_value.toLowerCase().includes(filter_value)) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_text": {
        const program_value = get_program_property_value(program, property, true)
        if (filter_value !== program_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "equals_option": {
        let program_value = get_program_property_value(program, property, false)

        switch(typeof program_value) {
          case "string": {
            program_value = program_value
            // Always break
            break
          }
          case "boolean": {
            program_value = program_value ? "true" : "false"
            // Always break
            break
          }
          default: {
            // Always break
            break
          }
        }

        if (filter_value !== program_value) {
          is_valid = false
        }
        // Always break
        break
      }
      case "before_date": {
        const program_value = get_program_property_value(program, property, false)
        const program_value_date = new Date(program_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() < program_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      case "after_date": {
        const program_value = get_program_property_value(program, property, false)
        const program_value_date = new Date(program_value)
        const filter_value_date = new Date(filter_value)
        if (filter_value_date.getTime() > program_value_date.getTime()) {
          is_valid = false
        }
        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }

  return is_valid
}