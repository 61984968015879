




const card_brand_map = {
  'visa': 'Visa',
  'mastercard': 'Mastercard',
  'amex': 'American Express',
  'cartes_bancaires': 'Cartes Bancaires',
  'diners_club': 'Diners Club',
  'discover': 'Discover',
  'jcb': 'JCB',
  'unionpay': 'UnionPay'
}

const format_month = (month_int) => {
  const month_str = month_int.toString()
  if (month_str.length === 2) {
    return month_str;
  }
  else if (month_str.length === 1) {
    return ('0' + month_str);
  }
}

const format_year = (year_int) => {
  const year_str = year_int.toString()
  if (year_str.length === 2) {
    return year_str;
  }
  else if (year_str.length === 4) {
    return year_str.slice(2);
  }
}

// Convert payment method
export const get_payment_method_pretty = (organization_data: any) => {
  switch(organization_data["payment_method"]) {
    case "stripe": {
      if (organization_data["payment_method_exists"]) {
        return <div>
        <div>
          {`${card_brand_map[organization_data["payment_method_info"]["brand"]]} ending in ${organization_data["payment_method_info"]["last4"]}`}
        </div>
        <div>
          {`Expires on ${format_month(organization_data["payment_method_info"]["exp_month"])}/${format_year(organization_data["payment_method_info"]["exp_year"])}`}
        </div>
      </div>
      }
      else {
        return "Your online payment method was not found. Please update your payment method or  contact support@vansec.com."
      }
    }
    case "contract": {
      return "Custom contract"
    }
    case "UNSET": {
      return "Payment method not found"
    }
    default: {
      return "error"
    }
  }
}