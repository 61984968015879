
import React, { useEffect, useState, Fragment } from 'react'


import ReactLoading from "react-loading"
import { useGlobal, useGlobalUpdate } from '../../../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../../../contexts/ProtectedContext'

import { useLocation, useNavigate, useParams } from "react-router-dom"


import { Transition, Listbox, Tab, Disclosure } from '@headlessui/react'
import { ArrowLongLeftIcon, InformationCircleIcon, CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import { CheckIcon, ChevronUpDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'


import { mixpanel_client_track } from '../../../../../libs/mixpanelClient';
import { auth_axios } from '../../../../../libs/authWeb'

import { campaign_user_property_placeholder_map, get_user_property_value, simulation_objective_map, training_module_map, user_property_map } from '../../../../../libs/formats'
import { simulation_default_domain_options, simulation_multi_message_templates, simulation_single_send_objective_options, simulation_multi_message_objective_options, simulation_single_send_templates, training_module_options, simulation_website_templates, simulation_attachment_extension_options } from '../../../../../libs/options'
import { get_prompt_text, show_notification, classNames } from '../../../../../libs/helpers'


import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import DOMPurify from 'dompurify';


import { Popover } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import SelectSimulationUserPropertiesModal from './SelectSimulationUserPropertiesModal'
import { validate_url } from '../../../../../libs/validate'











const CampaignsCampaignIdEditPageThreePage = ({
  is_awaiting,
  set_is_awaiting,

  error_message,
  set_error_message,

  success_message,
  set_success_message,

  campaign,
  set_campaign,

  set_edit_page,
  update_campaign,
} : {
  is_awaiting : any
  set_is_awaiting : any

  error_message : any
  set_error_message : any

  success_message : any
  set_success_message : any

  campaign : any
  set_campaign : any

  set_edit_page : any
  update_campaign : any
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()
  
  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Use params
  const { campaign_id } = useParams() 

  // Org data
  const [organization_data, set_organization_data] = useState({})
  const [campaign_users, set_campaign_users] = useState([])
  const [organization_data_and_campaign_are_fetched, set_organization_data_and_campaign_are_fetched] = useState(false)




  // Preview states
  const [is_email_preview, set_is_email_preview] = useState(false)
  const [is_prompt_preview, set_is_prompt_preview] = useState(false)

  // Modal
  const [select_simulation_user_properties_modal, set_select_simulation_user_properties_modal] = useState(false)
  


  const get_organization_data_and_campaign_and_users = async () => {
    // Set is_fetched to false
    set_organization_data_and_campaign_are_fetched(false)

    // Execute get organization data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get campaign
    const get_campaign_res = await auth_axios.get(`/api/campaigns/${campaign_id}`)

    if (!get_campaign_res.data.success) {
      switch (get_campaign_res.data.status) {
        case "FATAL_ERROR": {
          // alert("Could not find the campaign")
          show_notification(protected_context, protected_update, "error", "Error", "Could not find the campaign")

          // Redirect to dashboard/campaigns page
          navigate(`/dashboard/campaigns`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get org users data
    const get_org_users_res = await auth_axios.get(`/api/users`)

    if (!get_org_users_res.data.success) {
      switch (get_org_users_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // If campaign has already been created, redirect to view page
    if (get_campaign_res.data.campaign.status !== "draft") {
      // alert("Campaign has already been created")
      show_notification(protected_context, protected_update, "error", "Error", "Campaign has already been created")
      navigate(`/dashboard/campaigns/${campaign_id}`)
      return
    }

    // Configure default states for the campaign
    let campaign = get_campaign_res.data.campaign
    campaign = {
      ...campaign,
      campaign_metadata: {
        ...campaign.campaign_metadata,

        // SIMULATION
        simulation_channels: campaign.campaign_metadata.simulation_channels.length === 0 ? ["email"] : campaign.campaign_metadata.simulation_channels,
        simulation_objective: campaign.campaign_metadata.simulation_objective === "UNSET" ? "open_link" : campaign.campaign_metadata.simulation_objective,
        simulation_user_properties: campaign.campaign_metadata.simulation_user_properties,
        // simulation_failure_training_is_enabled: false // this is always set upon campaign doc creation,
        // simulation_failure_redirect_is_enabled: false, // this is always set upon campaign doc creation
        simulation_failure_redirect_url: campaign.campaign_metadata.simulation_failure_redirect_url === "UNSET" ? "" : campaign.campaign_metadata.simulation_failure_redirect_url,

        simulation_domain: campaign.campaign_metadata.simulation_domain === "UNSET" ? "admin-settings.com" : campaign.campaign_metadata.simulation_domain,
        simulation_email_prefix: campaign.campaign_metadata.simulation_email_prefix === "UNSET" ? "" : campaign.campaign_metadata.simulation_email_prefix,
        simulation_display_name: campaign.campaign_metadata.simulation_display_name === "UNSET" ? "" : campaign.campaign_metadata.simulation_display_name,
        simulation_subject_line: campaign.campaign_metadata.simulation_subject_line === "UNSET" ? "" : campaign.campaign_metadata.simulation_subject_line,
        simulation_attachment_name: campaign.campaign_metadata.simulation_attachment_name === "UNSET" ? "" : campaign.campaign_metadata.simulation_attachment_name,
        simulation_attachment_extension: campaign.campaign_metadata.simulation_attachment_extension === "UNSET" ? "pdf" : campaign.campaign_metadata.simulation_attachment_extension,
        simulation_website: campaign.campaign_metadata.simulation_website === "UNSET" ? "self_hosted" : campaign.campaign_metadata.simulation_website,
        simulation_website_url: campaign.campaign_metadata.simulation_website_url === "UNSET" ? "" : campaign.campaign_metadata.simulation_website_url,
        // simulation_website_open_link_tracking_is_enabled: false, // this is always set upon campaign doc creation
        // simulation_website_submit_website_capture_is_enabled: false, // this is always set upon campaign doc creation
        simulation_reply_info_objective: campaign.campaign_metadata.simulation_reply_info_objective === "UNSET" ? "" : campaign.campaign_metadata.simulation_reply_info_objective,

        // Simulation - single send
        simulation_single_send_email_html: campaign.campaign_metadata.simulation_single_send_email_html === "UNSET" ? `<html><head></head><body><div>Hello World!</div></body></html>` : campaign.campaign_metadata.simulation_single_send_email_html,

        // Simulation - multi message
        simulation_multi_message_instructions: campaign.campaign_metadata.simulation_multi_message_instructions === "UNSET" ? "" : campaign.campaign_metadata.simulation_multi_message_instructions,
        simulation_multi_message_excerpt: campaign.campaign_metadata.simulation_multi_message_excerpt === "UNSET" ? "" : campaign.campaign_metadata.simulation_multi_message_excerpt,

        // TRAINING
        training_module: campaign.campaign_metadata.training_module === "UNSET" ? (campaign.campaign_metadata.type === "simulation" ? "simulation_debrief" : "ransomware") : campaign.campaign_metadata.training_module,
        // training_skip_completed_users_is_enabled: true // this is always set upon campaign doc creation
      }
    }

    const campaign_user_ids_set = new Set(campaign.campaign_metadata.user_ids)

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_campaign(campaign)
    set_campaign_users(get_org_users_res.data.users.filter(user => campaign_user_ids_set.has(user.user_id)))
    set_organization_data_and_campaign_are_fetched(true)
  }

  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {


      case "simulation_template": {

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            ...value.fields
          }
        })

        if ("simulation_single_send_email_html" in value.fields) set_is_email_preview(true)

        show_notification(protected_context, protected_update, "success", "Fields auto-populated", "Fields were auto-populated using the chosen template. You can further customize by editing each field.")

        // Always break
        break
      }






      case "simulation_objective": {

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_objective: value
          }
        })

        // Always break
        break
      }
      case "simulation_user_properties": {

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_user_properties: value
          }
        })

        // Always break
        break
      }
      case "simulation_failure_training_is_enabled": {

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_failure_training_is_enabled: !campaign["campaign_metadata"]["simulation_failure_training_is_enabled"],

            // training_module: !campaign["campaign_metadata"]["simulation_failure_training_is_enabled"] ? "simulation_debrief" : "ransomware" // TODO if being enabled, set training_module to simulation_debrief
          }
        })

        // Always break
        break
      }
      case "simulation_failure_redirect_is_enabled": {

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_failure_redirect_is_enabled: !campaign["campaign_metadata"]["simulation_failure_redirect_is_enabled"],
          }
        })

        // Always break
        break
      }
      case "simulation_failure_redirect_url": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_failure_redirect_url: value.trim() // trim to prevent outer spaces
          }
        })

        // Always break
        break
      }





      case "simulation_domain": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_domain: value
          }
        })

        // Always break
        break
      }
      case "simulation_email_prefix": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_email_prefix: value.trim() // trim to prevent outer spaces
          }
        })

        // Always break
        break
      }
      case "simulation_display_name": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_display_name: value
          }
        })

        // Always break
        break
      }
      case "simulation_subject_line": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_subject_line: value
          }
        })

        // Always break
        break
      }

      case "simulation_attachment_name": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_attachment_name: value.trim() // trim to prevent outer spaces
          }
        })

        // Always break
        break
      }
      case "simulation_attachment_extension": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_attachment_extension: value
          }
        })

        // Always break
        break
      }
      case "simulation_website_template": {

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_website: value.id,
            ...value.fields
          }
        })

        // Always break
        break
      }
      case "simulation_website_url": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_website_url: value.trim() // trim to prevent outer spaces
          }
        })

        // Always break
        break
      }
      case "simulation_website_open_link_tracking_is_enabled": {

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_website_open_link_tracking_is_enabled: !campaign["campaign_metadata"]["simulation_website_open_link_tracking_is_enabled"],
          }
        })

        // Always break
        break
      }
      case "simulation_website_submit_website_capture_is_enabled": {

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_website_submit_website_capture_is_enabled: !campaign["campaign_metadata"]["simulation_website_submit_website_capture_is_enabled"],
          }
        })

        // Always break
        break
      }
      case "simulation_reply_info_objective": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_reply_info_objective: value
          }
        })

        // Always break
        break
      }



      case "simulation_single_send_email_html": {
        if (value.length > 100000) { // If html is longer than 100,000 chars, alert and don't do anything
          // alert("Email HTML cannot be longer than 100,000 characters in total")
          show_notification(protected_context, protected_update, "error", "Error", "Email HTML cannot be longer than 100,000 characters in total")
          return
        }

        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_single_send_email_html: value
            // simulation_single_send_email_html: DOMPurify.sanitize(value, { WHOLE_DOCUMENT: true })
          }
        })

        // Always break
        break
      }
      case "simulation_multi_message_instructions": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_multi_message_instructions: value
          }
        })

        // Always break
        break
      } 
      case "simulation_multi_message_excerpt": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            simulation_multi_message_excerpt: value
          }
        })

        // Always break
        break
      }
      case "training_module": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            training_module: value
          }
        })

        // Always break
        break
      }
      case "training_skip_completed_users_is_enabled": {
        set_campaign({
          ...campaign,
          campaign_metadata: {
            ...campaign["campaign_metadata"],
            training_skip_completed_users_is_enabled: !campaign["campaign_metadata"]["training_skip_completed_users_is_enabled"]
          }
        })

        // Always break
        break
      }
    }

    // Always reset error and success messages
    set_error_message("")
    set_success_message("")
  }

  // Single send email preview

  const go_back_to_email_html_editor = () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // If orgnaization data has not been fetched yet, don't do anything
    if (!organization_data_and_campaign_are_fetched) {
      set_is_awaiting(false)
      return
    }

    /////
    // If invalid, show error message and end of the line
    if (false) {
      set_error_message("error_message_draft")
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // END OF USER INPUT CHECK

    // Sanitize HTML to be safe
    set_campaign({
      ...campaign,
      campaign_metadata: {
        ...campaign["campaign_metadata"],
        simulation_single_send_email_html: DOMPurify.sanitize(campaign["campaign_metadata"]["simulation_single_send_email_html"], { WHOLE_DOCUMENT: true })
      }
    })


    // Show preview
    set_is_email_preview(false)

    // Toggle awaiting
    set_is_awaiting(false)
  }

  const generate_email_preview = () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // If orgnaization data has not been fetched yet, don't do anything
    if (!organization_data_and_campaign_are_fetched) {
      set_is_awaiting(false)
      return
    }



    /////
    // If invalid, show error message and end of the line
    if (false) {
      set_error_message("error_message_draft")
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // END OF USER INPUT CHECK


    // Show preview
    set_is_email_preview(true)

    // Toggle awaiting
    set_is_awaiting(false)
  }

  // Multi message preview

  const go_back_to_prompt_editor = () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // If orgnaization data has not been fetched yet, don't do anything
    if (!organization_data_and_campaign_are_fetched) {
      set_is_awaiting(false)
      return
    }

    /////
    // If invalid, show error message and end of the line
    if (false) {
      set_error_message("error_message_draft")
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // END OF USER INPUT CHECK

    // Sanitize HTML to be safe
    set_campaign({
      ...campaign,
      campaign_metadata: {
        ...campaign["campaign_metadata"],
        simulation_single_send_email_html: DOMPurify.sanitize(campaign["campaign_metadata"]["simulation_single_send_email_html"], { WHOLE_DOCUMENT: true })
      }
    })


    // Show preview
    set_is_prompt_preview(false)

    // Toggle awaiting
    set_is_awaiting(false)
  }

  const generate_prompt_preview = () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // If orgnaization data has not been fetched yet, don't do anything
    if (!organization_data_and_campaign_are_fetched) {
      set_is_awaiting(false)
      return
    }



    /////
    // If invalid, show error message and end of the line
    if (false) {
      set_error_message("error_message_draft")
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // END OF USER INPUT CHECK


    // Show preview
    set_is_prompt_preview(true)

    // Toggle awaiting
    set_is_awaiting(false)
  }


  // Save functions

  const get_user_input_is_valid = () => {

    // If data has not been fetched yet, don't do anything
    if (!organization_data_and_campaign_are_fetched) {
      set_is_awaiting(false)
      
      // End of the line
      return false
    }

    // If status is not active, alert and redirect them to the plan page
    if (organization_data["status"] !== "active") {
      // Show alert
      // alert("You cannot create a campaign without an active plan")
      show_notification(protected_context, protected_update, "warning", "Inactive plan", "You cannot create a campaign without an active plan")

      // Redirect to dashboard/plan page
      navigate(`/dashboard/plan`)

      // End of the line
      return false
    }

    // If domains have not been allowlisted, redirect them to organization page
    if (!organization_data["organization_metadata"]["domains_are_allowlisted"]) {
      // Show error notification
      show_notification(protected_context, protected_update, "warning", "Domains have not been allowlisted", "You must allowlist all domains available to your organization before creating a campaign.")

      // Redirect to dashboard/organization page
      navigate(`/dashboard/organization`)

      // End of the line
      return
    }

    // Date checking just in case
    const current_date = new Date()
    const current_timestamp = current_date.getTime()

    // If start time is before now, show error
    if (campaign.campaign_metadata.start_time < current_timestamp) {
      // Toggle awaiting
      set_is_awaiting(false)

      // Show error notification
      show_notification(protected_context, protected_update, "error", "Delivery time error", "The delivery time is set to before now.")

      // Go back to first page
      set_edit_page(0) 

      // End of the line
      return
    }

    // Two branches

    // SIMUALTION
    if (["simulation"].includes(campaign.campaign_metadata.type)) {
      // If simulation objective is empty, show error
      if (campaign.campaign_metadata.simulation_objective === "") {
        // Toggle awaiting
        set_is_awaiting(false)

        // Show error message
        set_error_message("Please select the simulation objective")

        // End of the line
        return false
      }

      // Make sure every campaign user has valid simulation_user_properties
      const invalid_campaign_users = campaign_users.filter(campaign_user => {
        for (const property of campaign.campaign_metadata.simulation_user_properties) {
          const user_value = get_user_property_value(campaign_user, property, false)
          if (user_value === "" || user_value === "UNSET" || user_value === "(Unspecified)") {
            return true
          }
        }
        return false
      }) 

      if (invalid_campaign_users.length > 0) {
        // Toggle awaiting
        set_is_awaiting(false)

        // Show error message
        set_error_message(`User properties to personalize the simulation with is missing for the following users: ${invalid_campaign_users.map(user => user.email_address).join(", ")}`)

        // End of the line
        return false
      }

      // Validate failure redirect URL
      if (campaign.campaign_metadata.simulation_failure_redirect_is_enabled && !validate_url(campaign.campaign_metadata.simulation_failure_redirect_url)) {
        // Toggle awaiting
        set_is_awaiting(false)

        // Show error message
        set_error_message("Please enter a valid URL for the failure redirect")

        // End of the line
        return false
      }

      // Simulation objective dependent fields
      switch(campaign.campaign_metadata.simulation_objective) {

        case "open_link": {

          // If simulation objective is empty, show error
          if (campaign.campaign_metadata.simulation_domain === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please select the email domain for the phishing simulation")

            // End of the line
            return false
          }

          // If simulation_email_prefix is empty, show error
          if (campaign.campaign_metadata.simulation_email_prefix === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the from email address")

            // End of the line
            return false
          }

          // If simulation_display_name is empty, show error
          if (campaign.campaign_metadata.simulation_display_name === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the from display name")

            // End of the line
            return false
          }

          // If simulation_subject_line is empty, show error
          if (campaign.campaign_metadata.simulation_subject_line === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the email subject line")

            // End of the line
            return false
          }
          
          // Always break
          break
        }
        case "open_link_submit_website": {

          // If simulation objective is empty, show error
          if (campaign.campaign_metadata.simulation_domain === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please select the email domain for the phishing simulation")

            // End of the line
            return false
          }

          // If simulation_email_prefix is empty, show error
          if (campaign.campaign_metadata.simulation_email_prefix === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the from email address")

            // End of the line
            return false
          }

          // If simulation_display_name is empty, show error
          if (campaign.campaign_metadata.simulation_display_name === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the from display name")

            // End of the line
            return false
          }

          // If simulation_subject_line is empty, show error
          if (campaign.campaign_metadata.simulation_subject_line === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the email subject line")

            // End of the line
            return false
          }

          // Validate simulation website URL
          if (!validate_url(campaign.campaign_metadata.simulation_website_url)) {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter a valid URL for the simulation website")

            // End of the line
            return false
          }
          
          // Always break
          break
        }
        case "open_attachment": {

          // If simulation objective is empty, show error
          if (campaign.campaign_metadata.simulation_domain === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please select the email domain for the phishing simulation")

            // End of the line
            return false
          }

          // If simulation_email_prefix is empty, show error
          if (campaign.campaign_metadata.simulation_email_prefix === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the from email address")

            // End of the line
            return false
          }

          // If simulation_display_name is empty, show error
          if (campaign.campaign_metadata.simulation_display_name === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the from display name")

            // End of the line
            return false
          }

          // If simulation_subject_line is empty, show error
          if (campaign.campaign_metadata.simulation_subject_line === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the email subject line")

            // End of the line
            return false
          }

          // If simulation_attachment_name is empty, show error
          if (campaign.campaign_metadata.simulation_attachment_name === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the attachment name")

            // End of the line
            return false
          }

          // If simulation_attachment_extension is empty, show error
          if (campaign.campaign_metadata.simulation_attachment_extension === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please select the attachment filetype")

            // End of the line
            return false
          }

          // Always break
          break
        }
        case "reply_info": {
          
          // If simulation objective is empty, show error
          if (campaign.campaign_metadata.simulation_domain === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please select the email domain for the phishing simulation")

            // End of the line
            return false
          }

          // If simulation_email_prefix is empty, show error
          if (campaign.campaign_metadata.simulation_email_prefix === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the from email address")

            // End of the line
            return false
          }

          // If simulation_display_name is empty, show error
          if (campaign.campaign_metadata.simulation_display_name === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the from display name")

            // End of the line
            return false
          }

          // If simulation_subject_line is empty, show error
          if (campaign.campaign_metadata.simulation_subject_line === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the email subject line")

            // End of the line
            return false
          }

          // If simulation_reply_info_objective is empty, show error
          if (campaign.campaign_metadata.simulation_reply_info_objective === "") {
            // Toggle awaiting
            set_is_awaiting(false)

            // Show error message
            set_error_message("Please enter the description of the data to try to elicit from the user")

            // End of the line
            return false
          }

          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      // If website URL is not valid, show error
      if (campaign.campaign_metadata.simulation_subject_line === "") {
        // Toggle awaiting
        set_is_awaiting(false)

        // Show error message
        set_error_message("Please enter the email subject line")

        // End of the line
        return false
      }

      // SINGLE SEND
      if (["single_send"].includes(campaign.campaign_metadata.simulation_type)) {
        // If simulation_single_send_email_html is empty, show error
        if (campaign.campaign_metadata.simulation_single_send_email_html === "") {
          // Toggle awaiting
          set_is_awaiting(false)

          // Show error message
          set_error_message("Email HTML cannot be empty")

          // End of the line
          return false
        }

        // Make sure placeholders for every simulation_user_properties are included 
        const missing_placeholders = campaign.campaign_metadata.simulation_user_properties.filter(property => !campaign.campaign_metadata.simulation_single_send_email_html.includes(campaign_user_property_placeholder_map[property])).map(property => campaign_user_property_placeholder_map[property])
        if (missing_placeholders.length > 0) {
          // Toggle awaiting
          set_is_awaiting(false)

          // Show error message
          set_error_message(`You are missing the following placeholders: ${missing_placeholders.join(", ")}`)

          // End of the line
          return false
        }


        // If simulation_objective is open_link, the simulation_single_send_email_html must include {{objective_link}}
        if (["open_link"].includes(campaign.campaign_metadata.simulation_objective) && !campaign.campaign_metadata.simulation_single_send_email_html.includes("{{objective_link}}")) {
          // Toggle awaiting
          set_is_awaiting(false)

          // Show error message
          set_error_message("For the 'Get user to click on a link' objective, you must include at least one {{objective_link}} placeholder")

          // End of the line
          return false
        }

        // If simulation_objective is open_link_submit_website, the simulation_single_send_email_html must include {{website_link}}
        if (["open_link_submit_website"].includes(campaign.campaign_metadata.simulation_objective) && !campaign.campaign_metadata.simulation_single_send_email_html.includes("{{website_link}}")) {
          // Toggle awaiting
          set_is_awaiting(false)

          // Show error message
          set_error_message("For the 'Get user to click on a link' objective, you must include at least one {{website_link}} placeholder")

          // End of the line
          return false
        }
      }

      // MULTI MESSAGE
      if (["multi_message"].includes(campaign.campaign_metadata.simulation_type)) {
        // If simulation_multi_message_instructions is empty, show error
        if (campaign.campaign_metadata.simulation_multi_message_instructions === "") {
          // Toggle awaiting
          set_is_awaiting(false)

          // Show error message
          set_error_message("Please enter the instructions for the AI")

          // End of the line
          return false
        }
      }
    }

    // TRAINING
    if (["training"].includes(campaign.campaign_metadata.type)) {

      // If training_module is empty, show error
      if (campaign.campaign_metadata.training_module === "") {
        // Toggle awaiting
        set_is_awaiting(false)

        // Show error message
        set_error_message("Please select the training module")

        // End of the line
        return false
      }
    }
    

    return true
  }

  const save_and_go_back = async () => {

    // Clear status messages
    set_error_message("")
    set_success_message("")

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (!get_user_input_is_valid()) {
      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Save the campaign
    const save_is_successful = await update_campaign()

    if (save_is_successful) {
      // Go back to page two
      set_edit_page(1) 
    } 
    else {
      set_error_message("Error occurred while saving")
    }

    // Set awaiting
    set_is_awaiting(false)
  }

  const save_progress = async () => {

    // Clear status messages
    set_error_message("")
    set_success_message("")

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (!get_user_input_is_valid()) {
      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Save the campaign
    const save_is_successful = await update_campaign()

    if (save_is_successful) {
      set_success_message("Progress saved")
    } 
    else {
      set_error_message("Error occurred while saving")
    }

    // Set awaiting
    set_is_awaiting(false)
  }

  const set_campaign_to_created = async () => {

    // Clear status messages
    set_error_message("")
    set_success_message("")

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    if (!get_user_input_is_valid()) {
      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Update campaign status to "active" <= this will trigger the useEffect below
    
    set_campaign({
      ...campaign,
      status: "creating"
    })

    // The actual submission will be triggered by useEffect => handle_submit below
    // handle_submit
  }

  const save_and_submit = async () => {
    // Save the campaign
    const save_is_successful = await update_campaign()
        
    // Move to page two if successful
    if (save_is_successful) {
      // Show alert
      // alert("Campaign is now published. Please allow up to 10 minutes for all the programs in the campaign to finish creating.")
      show_notification(protected_context, protected_update, "success", "Campaign created", "The campaign has been successfully created.")

      // Navigate to dashboard/campaigns page
      navigate(`/dashboard/campaigns`) 
    }
    else {
      set_error_message("Error occurred while saving")
    }

    // Toggle awaiting
    set_is_awaiting(false)
  }

  // Submit campaign
  useEffect(() => {
    if (campaign.status === "creating") {
      save_and_submit()
    }
  }, [campaign])



  // Renders  
  useEffect(() => {

    // Get org data
    get_organization_data_and_campaign_and_users()

  }, [])

  return (
    organization_data_and_campaign_are_fetched
    ? <div className="mt-8">

      {/* Inputs */}
      <div className="w-full space-y-6 mb-6"> 

        {/* SIMULATION INPUTS */}
        {["simulation"].includes(campaign["campaign_metadata"]["type"])
        ? <>

          {/* Templates section */}
          <Popover className="relative isolate z-50">
            {({ open }) => (<>
              <div className="bg-white">
                <div className="">
                  <Popover.Button className="flex flex-col space-y-4 text-sm text-gray-900 rounded-lg px-6 py-4 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <div className="flex items-center gap-x-1 text-sm z-50">
                      <span className="font-bold">
                        {(() => { 
                          switch (campaign.campaign_metadata.simulation_type) {
                            case "single_send": {
                              return "Choose from a template"
                            }
                            case "multi_message": {
                              return "Choose from a scenario"
                            }
                            default: {
                              return "Choose from a template"
                            }
                          }
                        })()}
                      </span> 
                      <ChevronDownIcon 
                        className={classNames(open ? "" : "-rotate-90 transform" , "h-5 w-5 transition ease-in-out duration-200")} 
                        aria-hidden="true" 
                      />
                      <span className="flex relative items-center text-left z-50">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          Examples to auto-populate the fields below.
                        </span>
                      </span>
                    </div>
                    <div className="z-20">
                      {open ? "Choose and further customize below" : "Click here to choose from any of the following"}
                    </div>
                    
                  </Popover.Button>
                </div>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 -translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1"
              >
                <Popover.Panel className="absolute top-0 -left-0 z-10 pt-16 shadow-md rounded-lg border border-gray-200 bg-white">
                  {({ close }) => (
                    <div className="mx-auto grid grid-cols-1 gap-2 px-6 py-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 sm:py-10 lg:grid-cols-4 lg:gap-4 lg:px-8 xl:gap-8">
                      {((() => { 
                        switch (campaign.campaign_metadata.simulation_type) {
                          case "single_send": {
                            return simulation_single_send_templates
                          }
                          case "multi_message": {
                            return simulation_multi_message_templates
                          }
                          default: {
                            return []
                          }
                        }
                      })()).map((template) => (
                        <div 
                          key={template.id} 
                          className="relative cursor-pointer" 
                          onClick={() => {
                            handle_user_input("simulation_template", template)
                            close()
                          }}
                        >
                          <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 h-[200px]">
                            <img src={template.img_src} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                          </div>
                          <p className="pointer-events-none mt-2 block text-sm font-medium text-gray-900">{template.name}</p>
                          <p className="pointer-events-none block text-sm font-medium text-gray-500">Difficulty: {template.difficulty}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </>)}
          </Popover>

          {/* Simulation channel */}
          {/* TODO */}

          {/* Simulation objective */}
          <div>
            <Listbox value={(campaign["campaign_metadata"]["simulation_type"] === "single_send" ? simulation_single_send_objective_options : simulation_multi_message_objective_options).find(type_option => type_option === campaign["campaign_metadata"]["simulation_objective"])} onChange={(e) => handle_user_input("simulation_objective", e)} >
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                    <span className="font-semibold">Threat actor objective</span>
                    <span className="flex relative items-center">
                      <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        The objective of the simulated phishing attack.
                      </span>
                    </span>
                  </Listbox.Label>
                  <div className="relative mt-2 w-96">
                    <Listbox.Button 
                      className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                    >
                      <span className="block truncate">{simulation_objective_map[campaign["campaign_metadata"]["simulation_objective"]]}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {(campaign["campaign_metadata"]["simulation_type"] === "single_send" ? simulation_single_send_objective_options : simulation_multi_message_objective_options).map((type_option) => (
                          <Listbox.Option
                            key={type_option}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={type_option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                  {simulation_objective_map[type_option]}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-blue-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          {/* Simulation user properties  */}
          <div>
            <label className="block text-sm leading-6 text-gray-900 flex">
              <span className="font-semibold">User properties to personalize the simulation with</span>
              <span className="flex relative items-center">
                <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                  {`The user properties that will be used to personalize the simulation. All users included in the campaign must have the listed properties populated.${campaign["campaign_metadata"]["simulation_type"] === "single_send" ? " The corresponding placeholders must be included in the email HTML as well." : ""}`}
                </span>
              </span>
            </label>
            <div className="mt-2 flex items-center space-x-2 justify-between">
              <div
                className="text-gray-900 text-sm"
              >
                {campaign["campaign_metadata"]["simulation_user_properties"].length > 0 ? campaign["campaign_metadata"]["simulation_user_properties"].map(property => user_property_map[property]).join(", ") : "(Not selected)"}
              </div>
              <div 
                className="text-sm font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                onClick={() => set_select_simulation_user_properties_modal(true)}
              >
                Select
              </div>                        
            </div>
          </div>

          {/* Simulation failure training enabled */}
          <div 
            className="relative flex items-start cursor-pointer" 
            onClick={() => handle_user_input("simulation_failure_training_is_enabled", null)}
          >
            <div className="flex h-6 items-center">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                checked={campaign["campaign_metadata"]["simulation_failure_training_is_enabled"]}
                onChange={() => handle_user_input("simulation_failure_training_is_enabled", null)}
              />
            </div>
            <div className="ml-3 text-sm leading-6 text-gray-900 flex">
              <span className="font-semibold">Require failed users to complete a personalized post-simulation training module</span>
              <span className="flex relative items-center">
                <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                  If turned on, failed users will be required to complete a post-simulation training module that goes over what happened in their specific simulation and how best to improve their security posture.
                </span>
              </span>
            </div>
          </div>

          {/* Simulation failure redirect is enabled */}
          <div 
            className="relative flex items-start cursor-pointer" 
            onClick={() => handle_user_input("simulation_failure_redirect_is_enabled", null)}
          >
            <div className="flex h-6 items-center">
              <input
                type="checkbox"
                className="text-blue-600 h-4 w-4 rounded border-gray-300 focus:ring-blue-600"
                checked={campaign["campaign_metadata"]["simulation_failure_redirect_is_enabled"]}
                onChange={() => handle_user_input("simulation_failure_redirect_is_enabled", null)}
                disabled={is_awaiting}
              />
            </div>
            <div className="ml-3 text-sm leading-6 text-gray-900 flex">
              <span className="font-semibold">Redirect failed users to a custom URL</span>
              <span className="flex relative items-center">
                <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                  If turned on, Vansec will redirect users to a specified URL upon failure. If turned off, users will be shown the default Vansec failure page.
                </span>
              </span>
            </div>
          </div>

          {/* Simulation failure redirect URL */}
          {campaign["campaign_metadata"]["simulation_failure_redirect_is_enabled"]
          ? <div>
            <label className="block text-sm leading-6 text-gray-900 flex">
              <span className="font-semibold">Simulation failure redirect URL</span>
              <span className="flex relative items-center">
                <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                  URL where users who fail this simulation will be redirected to.
                </span>
              </span>
            </label>
            <div className="mt-2">
              <input
                type="text"
                required
                className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={campaign["campaign_metadata"]["simulation_failure_redirect_url"]}
                onChange={(e) => handle_user_input("simulation_failure_redirect_url", e.target.value)}
                disabled={is_awaiting}
              />
            </div>
          </div>
          : <></>}

          {/* Simulation objective dependent fields */}
          {(() => {
            switch(campaign["campaign_metadata"]["simulation_objective"]) {
              case "open_link": {
                return <div className="space-y-6">

                  {/* Simulation domain */}
                  <div>
                    <Listbox value={campaign["campaign_metadata"]["simulation_domain"]} onChange={(e) => handle_user_input("simulation_domain", e)} >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                            <span className="font-semibold">Email domain</span>
                            <span className="flex relative items-center">
                              <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                              <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                                The domain that the simulation will use for the simulated phishing emails.
                              </span>
                            </span>
                          </Listbox.Label>
                          <div className="relative mt-2 w-96">
                            <Listbox.Button 
                              className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                            >
                              <span className="block truncate">{campaign["campaign_metadata"]["simulation_domain"]}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {simulation_default_domain_options.concat(organization_data["organization_metadata"]["custom_simulation_domains"]).map((type_option) => (
                                  <Listbox.Option
                                    key={type_option}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                      )
                                    }
                                    value={type_option}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                          {type_option}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-blue-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  {/* Simulation email prefix */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">From email address</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The email address from which the simulated phishing email will be sent.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2 flex items-center space-x-2">
                      <input
                        type="text"
                        required
                        className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_email_prefix"]}
                        onChange={(e) => handle_user_input("simulation_email_prefix", e.target.value)}
                        disabled={is_awaiting}
                      />
                      <div
                        className="text-gray-900 text-sm"
                      >
                        @{campaign["campaign_metadata"]["simulation_domain"]}
                      </div>
                    </div>
                  </div>

                  {/* Simulation display name */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">From display name</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The display name to be shown on the simulated phishing email.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        required
                        className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_display_name"]}
                        onChange={(e) => handle_user_input("simulation_display_name", e.target.value)}
                        disabled={is_awaiting}
                      />
                    </div>
                  </div>

                  {/* Simulation subject line  */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">Email subject line</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The subject line to be used for the simulated phishing email. For multi-message simulation, this is the subject line of the initial email sent to the user.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        required
                        className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_subject_line"]}
                        onChange={(e) => handle_user_input("simulation_subject_line", e.target.value)}
                        disabled={is_awaiting}
                      />
                    </div>
                  </div>

                </div>
              }
              case "open_link_submit_website": {
                return <div className="space-y-6">

                  {/* Simulation domain */}
                  <div>
                    <Listbox value={campaign["campaign_metadata"]["simulation_domain"]} onChange={(e) => handle_user_input("simulation_domain", e)} >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                            <span className="font-semibold">Email domain</span>
                            <span className="flex relative items-center">
                              <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                              <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                                The domain that the simulation will use for the simulated phishing emails.
                              </span>
                            </span>
                          </Listbox.Label>
                          <div className="relative mt-2 w-96">
                            <Listbox.Button 
                              className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                            >
                              <span className="block truncate">{campaign["campaign_metadata"]["simulation_domain"]}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {simulation_default_domain_options.concat(organization_data["organization_metadata"]["custom_simulation_domains"]).map((type_option) => (
                                  <Listbox.Option
                                    key={type_option}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                      )
                                    }
                                    value={type_option}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                          {type_option}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-blue-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  {/* Simulation email prefix */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">From email address</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The email address from which the simulated phishing email will be sent.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2 flex items-center space-x-2">
                      <input
                        type="text"
                        required
                        className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_email_prefix"]}
                        onChange={(e) => handle_user_input("simulation_email_prefix", e.target.value)}
                        disabled={is_awaiting}
                      />
                      <div
                        className="text-gray-900 text-sm"
                      >
                        @{campaign["campaign_metadata"]["simulation_domain"]}
                      </div>
                    </div>
                  </div>

                  {/* Simulation display name */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">From display name</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The display name to be shown on the simulated phishing email.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        required
                        className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_display_name"]}
                        onChange={(e) => handle_user_input("simulation_display_name", e.target.value)}
                        disabled={is_awaiting}
                      />
                    </div>
                  </div>

                  {/* Simulation subject line  */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">Email subject line</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The subject line to be used for the simulated phishing email. For multi-message simulation, this is the subject line of the initial email sent to the user.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        required
                        className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_subject_line"]}
                        onChange={(e) => handle_user_input("simulation_subject_line", e.target.value)}
                        disabled={is_awaiting}
                      />
                    </div>
                  </div>

                  {/* Simulation website */}
                  <div>
                    <Listbox value={simulation_website_templates.find(template_option => template_option.id === campaign["campaign_metadata"]["simulation_website"])} onChange={(e) => handle_user_input("simulation_website_template", e)} >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                            <span className="font-semibold">Simulation website</span>
                            <span className="flex relative items-center">
                              <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                              <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                                The website where users will be directed to enter and submit info.
                              </span>
                            </span>
                          </Listbox.Label>
                          <div className="relative mt-2 w-96">
                            <Listbox.Button 
                              className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                            >
                              <span className="block truncate">{simulation_website_templates.find(template_option => template_option.id === campaign["campaign_metadata"]["simulation_website"]).name}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {simulation_website_templates.map((template_option) => (
                                  <Listbox.Option
                                    key={template_option.id}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                      )
                                    }
                                    value={template_option}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                          {template_option.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-blue-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  {/* Simulation website URL */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">Simulation website URL</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          URL of the website for the simulation in the form of https://subdomain.domain.com. For the self-hosted option, you must enter in a valid URL pointing to your self-hosted website.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        required
                        className={classNames(campaign["campaign_metadata"]["simulation_website"] !== "self_hosted" ? "bg-gray-50" : "", "block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6")}
                        value={campaign["campaign_metadata"]["simulation_website_url"]}
                        onChange={(e) => handle_user_input("simulation_website_url", e.target.value)}
                        disabled={campaign["campaign_metadata"]["simulation_website"] !== "self_hosted" || is_awaiting}
                      />
                    </div>
                  </div>

                  {/* Simulation website open tracking is enabled */}
                  <div 
                    className="relative flex items-start cursor-pointer" 
                    onClick={() => {
                      if (false) { // TEMP
                      // if (!(campaign["campaign_metadata"]["simulation_website"] !== "self_hosted" || is_awaiting)) {
                        handle_user_input("simulation_website_open_link_tracking_is_enabled", null)
                      }
                    }}
                  >
                    <div className="flex h-6 items-center">
                      <input
                        type="checkbox"
                        className={classNames(campaign["campaign_metadata"]["simulation_website"] !== "self_hosted" ? "text-gray-500" : "text-blue-600", "h-4 w-4 rounded border-gray-300 focus:ring-blue-600")}
                        checked={campaign["campaign_metadata"]["simulation_website_open_link_tracking_is_enabled"]}
                        onChange={() => handle_user_input("simulation_website_open_link_tracking_is_enabled", null)}
                        disabled={true} // TEMP
                        // disabled={campaign["campaign_metadata"]["simulation_website"] !== "self_hosted" || is_awaiting}
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">Track whther the simulation website is opened</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          If turned on, Vansec will track whether user visited the simulation website. For self hosted websites, the email will contain a URL with the app.vansec.com that will track the website open, which will then automatically redirect the user to the simulation website URL.
                        </span>
                      </span>
                    </div>
                  </div>

                  {/* Simulation submit website capture is enabled */}
                  <div 
                    className="relative flex items-start cursor-pointer" 
                    onClick={() => {
                      if (!(campaign["campaign_metadata"]["simulation_website"] === "self_hosted" || is_awaiting)) {
                        handle_user_input("simulation_website_submit_website_capture_is_enabled", null)}
                      }
                    } 
                  >
                    <div className="flex h-6 items-center">
                      <input
                        type="checkbox"
                        className="text-blue-600 h-4 w-4 rounded border-gray-300 focus:ring-blue-600"
                        checked={campaign["campaign_metadata"]["simulation_website_submit_website_capture_is_enabled"]}
                        onChange={() => {
                          if (!(campaign["campaign_metadata"]["simulation_website"] === "self_hosted" || is_awaiting)) {
                            handle_user_input("simulation_website_submit_website_capture_is_enabled", null)}
                          }
                        }
                        disabled={is_awaiting}
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">Capture the submitted info on the website</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          Warning: if turned on, Vansec will capture the info that is submitted on the website. Please ensure that only authorized admins are able to access the sensitive data collected in this campaign.
                        </span>
                      </span>
                    </div>
                  </div>

                </div>
              }
              case "open_attachment": {
                return <div className="space-y-6">

                  {/* Simulation domain */}
                  <div>
                    <Listbox value={campaign["campaign_metadata"]["simulation_domain"]} onChange={(e) => handle_user_input("simulation_domain", e)} >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                            <span className="font-semibold">Email domain</span>
                            <span className="flex relative items-center">
                              <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                              <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                                The domain that the simulation will use for the simulated phishing emails.
                              </span>
                            </span>
                          </Listbox.Label>
                          <div className="relative mt-2 w-96">
                            <Listbox.Button 
                              className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                            >
                              <span className="block truncate">{campaign["campaign_metadata"]["simulation_domain"]}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {simulation_default_domain_options.concat(organization_data["organization_metadata"]["custom_simulation_domains"]).map((type_option) => (
                                  <Listbox.Option
                                    key={type_option}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                      )
                                    }
                                    value={type_option}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                          {type_option}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-blue-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  {/* Simulation email prefix */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">From email address</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The email address from which the simulated phishing email will be sent.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2 flex items-center space-x-2">
                      <input
                        type="text"
                        required
                        className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_email_prefix"]}
                        onChange={(e) => handle_user_input("simulation_email_prefix", e.target.value)}
                        disabled={is_awaiting}
                      />
                      <div
                        className="text-gray-900 text-sm"
                      >
                        @{campaign["campaign_metadata"]["simulation_domain"]}
                      </div>
                    </div>
                  </div>

                  {/* Simulation display name */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">From display name</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The display name to be shown on the simulated phishing email.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        required
                        className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_display_name"]}
                        onChange={(e) => handle_user_input("simulation_display_name", e.target.value)}
                        disabled={is_awaiting}
                      />
                    </div>
                  </div>

                  {/* Simulation subject line  */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">Email subject line</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The subject line to be used for the simulated phishing email. For multi-message simulation, this is the subject line of the initial email sent to the user.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        required
                        className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_subject_line"]}
                        onChange={(e) => handle_user_input("simulation_subject_line", e.target.value)}
                        disabled={is_awaiting}
                      />
                    </div>
                  </div>

                  {/* Simulation attachment extension */}
                  <div>
                    <Listbox value={campaign["campaign_metadata"]["simulation_attachment_extension"]} onChange={(e) => handle_user_input("simulation_attachment_extension", e)} >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                            <span className="font-semibold">Attachment type</span>
                            <span className="flex relative items-center">
                              <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                              <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                                File type of the attachment.
                              </span>
                            </span>
                          </Listbox.Label>
                          <div className="relative mt-2 w-24">
                            <Listbox.Button 
                              className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                            >
                              <span className="block truncate">{campaign["campaign_metadata"]["simulation_attachment_extension"]}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {simulation_attachment_extension_options.map((option) => (
                                  <Listbox.Option
                                    key={option}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                      )
                                    }
                                    value={option}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                          {option}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-blue-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  
                  {/* Simulation attachment name */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">Name of the attachment</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          Name of the attachment.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2 flex items-center space-x-2">
                      <input
                        type="text"
                        required
                        className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_attachment_name"]}
                        onChange={(e) => handle_user_input("simulation_attachment_name", e.target.value)}
                        disabled={is_awaiting}
                      />
                      <div
                        className="text-gray-900 text-sm"
                      >
                        .{campaign["campaign_metadata"]["simulation_attachment_extension"]}
                      </div>
                    </div>
                  </div>

                </div>
              }
              case "reply_info": {
                return <div className="space-y-6">

                  {/* Simulation domain */}
                  <div>
                    <Listbox value={campaign["campaign_metadata"]["simulation_domain"]} onChange={(e) => handle_user_input("simulation_domain", e)} >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                            <span className="font-semibold">Email domain</span>
                            <span className="flex relative items-center">
                              <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                              <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                                The domain that the simulation will use for the simulated phishing emails.
                              </span>
                            </span>
                          </Listbox.Label>
                          <div className="relative mt-2 w-96">
                            <Listbox.Button 
                              className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                            >
                              <span className="block truncate">{campaign["campaign_metadata"]["simulation_domain"]}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {simulation_default_domain_options.concat(organization_data["organization_metadata"]["custom_simulation_domains"]).map((type_option) => (
                                  <Listbox.Option
                                    key={type_option}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                      )
                                    }
                                    value={type_option}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                          {type_option}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-blue-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  {/* Simulation email prefix */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">From email address</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The email address from which the simulated phishing email will be sent.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2 flex items-center space-x-2">
                      <input
                        type="text"
                        required
                        className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_email_prefix"]}
                        onChange={(e) => handle_user_input("simulation_email_prefix", e.target.value)}
                        disabled={is_awaiting}
                      />
                      <div
                        className="text-gray-900 text-sm"
                      >
                        @{campaign["campaign_metadata"]["simulation_domain"]}
                      </div>
                    </div>
                  </div>

                  {/* Simulation display name */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">From display name</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The display name to be shown on the simulated phishing email.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        required
                        className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_display_name"]}
                        onChange={(e) => handle_user_input("simulation_display_name", e.target.value)}
                        disabled={is_awaiting}
                      />
                    </div>
                  </div>

                  {/* Simulation subject line  */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">Email subject line</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          The subject line to be used for the simulated phishing email. For multi-message simulation, this is the subject line of the initial email sent to the user.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        required
                        className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={campaign["campaign_metadata"]["simulation_subject_line"]}
                        onChange={(e) => handle_user_input("simulation_subject_line", e.target.value)}
                        disabled={is_awaiting}
                      />
                    </div>
                  </div>

                  {/* Simulation reply info objective */}
                  <div>
                    <label className="block text-sm leading-6 text-gray-900 flex">
                      <span className="font-semibold">Description of data to try to elicit from the user</span>
                      <span className="flex relative items-center">
                        <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                        <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                          Warning: for sensitive data, we recommend that you turn off simulation content visibility for admins to prevent unintended data exposure.
                        </span>
                      </span>
                    </label>
                    <div className="mt-2">
                      <textarea
                        className={classNames(is_awaiting ? "bg-gray-50" : "", "block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6")}
                        rows={2}
                        value={campaign["campaign_metadata"]["simulation_reply_info_objective"]}
                        onChange={(e) => handle_user_input("simulation_reply_info_objective", e.target.value)}
                        disabled={is_awaiting}
                      />
                    </div>
                  </div>

                </div>
              }
              default: {
                return
              }
            }
          })()}



          {/* SINGLE SEND */}
          {["single_send"].includes(campaign["campaign_metadata"]["simulation_type"])
          ? <>

            {/* Custom HTML and preview */}
            <Tab.Group onChange={(index) => { if (index === 1) generate_email_preview(); else go_back_to_email_html_editor(); }} selectedIndex={is_email_preview ? 1 : 0}>
              <label className="block text-sm leading-6 text-gray-900 flex">
                <span className="font-semibold">Single-send email HTML</span>
                <span className="flex relative items-center">
                  <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                  <span className="peer-hover:opacity-100 peer-hover:z-20 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto">
                    {`Unsafe HTML content such as {"<script>"} tags will be automatically removed. The following placeholders must be included: ${campaign.campaign_metadata.simulation_user_properties.map(property => campaign_user_property_placeholder_map[property]).join(", ")}. For the "Get user to click on a link" objective, the {{objective_link}} placeholder must be included.`}
                  </span> 
                </span>
              </label>
              <Tab.List className="flex items-center mt-2">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                        : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                      'rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                    )
                  }
                >
                  Email HTML
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                        : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                      'ml-2 rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                    )
                  }
                >
                  Email preview
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel className="-m-0.5 rounded-lg p-0.5 ">
                  <CodeMirror 
                    value={campaign["campaign_metadata"]["simulation_single_send_email_html"]} 
                    className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 h-[600px] overflow-auto resize"
                    extensions={[html({})]} 
                    onChange={(code) => handle_user_input("simulation_single_send_email_html", code)} 
                  />
                </Tab.Panel>
                <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                  <iframe 
                    id="email_preview"
                    className="block w-full rounded-md border-0 py-2 px-4 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 h-[600px] overflow-auto resize"
                    // srcDoc={campaign["campaign_metadata"]["simulation_single_send_email_html"]}
                    srcDoc={DOMPurify.sanitize(campaign["campaign_metadata"]["simulation_single_send_email_html"], { WHOLE_DOCUMENT: true })}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

          </>
          : <></>}

          {/* MULTI MESSAGE */}
          {["multi_message"].includes(campaign["campaign_metadata"]["simulation_type"])
          ? <>

            {/* Custom prompt and preview */}
            <Tab.Group onChange={(index) => { if (index === 1) generate_prompt_preview(); else go_back_to_prompt_editor(); }} selectedIndex={is_prompt_preview ? 1 : 0}>
              <label className="block text-sm leading-6 text-gray-900 flex">
                <span className="font-semibold">Instructions for the Simulation AI</span>
                <span className="flex relative items-center">
                  <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                  <span className="peer-hover:opacity-100 peer-hover:z-20 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto">
                    Customize the prompt that you will use to construct the spear phishing simulation. For best results, use the 2nd person voice (e.g. "You are ...") and the instructions should include who the agent is impersonating, and the scenario (i.e. why the user is being reached out to).
                  </span> 
                </span>
              </label>
              <Tab.List className="flex items-center">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                        : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                      'rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                    )
                  }
                >
                  Prompt editor
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                        : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                      'ml-2 rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                    )
                  }
                >
                  Prompt preview
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel className="-m-0.5 rounded-lg p-0.5 space-y-6">
                  {/* Instructions */}
                  <textarea
                    className={classNames(is_awaiting ? "bg-gray-50" : "", "block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6")}
                    rows={6}
                    value={campaign["campaign_metadata"]["simulation_multi_message_instructions"]}
                    onChange={(e) => handle_user_input("simulation_multi_message_instructions", e.target.value)}
                    disabled={is_awaiting}
                  />

                  {/* Optional field for simulation_multi_message_excerpt */}
                  <Disclosure as="div" className="">
                    {({ open }) => (
                      <>
                        <dt>
                          <Disclosure.Button className="flex w-full items-center text-left text-gray-900">
                            <label className="block text-sm leading-6 text-gray-900 flex cursor-pointer">
                              <span className="font-semibold">Optional: paste example email excerpt for tone of voice reference</span>
                              <span className="flex relative items-center">
                                <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                                <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                                  You may optionally paste in an example of email excerpt for the Simulation AI to reference as a tone of voice for all its generated emails.
                                </span>
                              </span>
                            </label>
                            <span className="ml-2 flex h-7 items-center">
                              {open ? (
                                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                              ) : (
                                <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="div" className="mt-2 rounded-lg p-0.5 space-y-6">
                          <textarea
                            className={classNames(is_awaiting ? "bg-gray-50" : "", "block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6")}
                            rows={6}
                            value={campaign["campaign_metadata"]["simulation_multi_message_excerpt"]}
                            onChange={(e) => handle_user_input("simulation_multi_message_excerpt", e.target.value)}
                            disabled={is_awaiting}
                          />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  
                </Tab.Panel>
                <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                  <textarea
                    className="block w-full rounded-md border-0 py-2 px-4 bg-gray-50 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    rows={24}
                    value={get_prompt_text(campaign, organization_data["organization_metadata"]["name"])}
                    disabled
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            
          
          </>
          : <></>}





        </>
        : <></>}

        
        {/* TRAINING INPUTS */}
        {["training"].includes(campaign["campaign_metadata"]["type"])
        ? <>


          {/* Training module */}
          <div>
            <Listbox value={training_module_options.find(module_option => module_option === campaign["campaign_metadata"]["training_module"])} onChange={(e) => handle_user_input("training_module", e)} >
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm leading-6 text-gray-900 flex">
                    <span className="font-semibold">Select training module</span>
                    <span className="flex relative items-center">
                      <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                      <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                        Select the training course for this campaign.
                      </span>
                    </span>
                  </Listbox.Label>
                  <div className="relative mt-2 w-96">
                    <Listbox.Button 
                      className="bg-white relative cursor-default rounded-md px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 w-full"
                    >
                      <span className="block truncate">{training_module_map[campaign["campaign_metadata"]["training_module"]]}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {training_module_options.map((module_option) => (
                          <Listbox.Option
                            key={module_option}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={module_option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                  {training_module_map[module_option]}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-blue-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          {/* TODO URGENT Skip completed users enabled */}
          {/* <div 
            className="relative flex items-start cursor-pointer" 
            onClick={() => handle_user_input("training_skip_completed_users_is_enabled", null)}
          >
            <div className="flex h-6 items-center">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                checked={campaign["campaign_metadata"]["training_skip_completed_users_is_enabled"]}
                onChange={() => handle_user_input("training_skip_completed_users_is_enabled", null)}
              />
            </div>
            <div className="ml-3 text-sm leading-6 text-gray-900 flex">
              <span className="font-semibold">Skip users who have previously completed this module</span>
              <span className="flex relative items-center">
                <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                  Of the selected users, those who had already completed this training module will not be promopted to complete this module.
                </span>
              </span>
            </div>
          </div> */}


        </>
        : <></>}

      </div>


      {/* Error message */}
      {error_message
      ? <div className="mt-6 flex space-x-2 items-start">
          <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
          {/* Multi-line error message */}
          <div className="text-sm font-medium text-red-400">
            {error_message.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
      : <></>}

      {/* Success message */}
      {success_message
      ? <div className="mt-6 flex space-x-2 items-start">
          <CheckCircleIcon className="pt-[2px] w-4 h-4 text-green-600 h-full"/>
          <div className="text-sm font-medium text-green-600">{success_message}</div>
        </div>
      : <></>}

      {/* Loading wheel */}
      {is_awaiting
      ? <div className="mt-6 flex space-x-2 items-start">
        <ReactLoading
          type='spokes'
          color='#000000'
          height={20}
          width={20}
        />
      </div>
      : <></>}



      {/* Buttons */}
      <div className="mt-12 flex justify-between">
        <div
          className="flex items-center text-sm font-medium text-gray-900 hover:text-gray-700 cursor-pointer"
          onClick={save_and_go_back}
        >
          <ArrowLongLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
          Save & go back
        </div>

        <button
          type="submit"
          onClick={save_progress}
          className={classNames(is_awaiting ? "cursor-default bg-gray-50" : "", "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50")}
          disabled={is_awaiting}
        >
          Save progress
        </button>

        <button
          type="submit"
          onClick={set_campaign_to_created}
          className={classNames(is_awaiting ? "cursor-default bg-blue-500" : "bg-blue-600", "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")}
          disabled={is_awaiting}
        >
          Create campaign
        </button>
      </div>

      {/* Modals */}
      <SelectSimulationUserPropertiesModal 
        is_open={select_simulation_user_properties_modal}
        set_is_open={set_select_simulation_user_properties_modal}
        campaign={campaign}
        handle_user_input={handle_user_input}
      />
    </div>
    : <div className="mt-8">
      <ReactLoading
        type='spokes'
        color='#000000'
        height={20}
        width={20}
      />
    </div>
  )
}

export default CampaignsCampaignIdEditPageThreePage