
import axios from 'axios';
import { useEffect, useState, useRef } from 'react'


// GlobalProvider
import { useGlobal, useGlobalUpdate } from "../../contexts/GlobalContext";

import ReactLoading from "react-loading"
import { useNavigate, useSearchParams, Link } from 'react-router-dom';

import { InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'


import { isDesktop } from "react-device-detect";

import { ReactComponent as SquareLogo } from "../../media/logos/dynamic_logo_64x64.svg"
import { handle_enter_keydown } from '../../libs/helpers';
import { mixpanel_client_track } from '../../libs/mixpanelClient';


const ValidateEmailPage = ({
  
} : {

}) => {

  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Search params
  const [search_params, _] = useSearchParams()

  const email_validation_token_id = search_params.get("email_validation_token_id")
  const user_id = search_params.get("user_id")


  // Navigate
  const navigate = useNavigate()

  // Autofocus on code field
  const code_ref = useRef<any>(null)

  // User input
  const [code, set_code] = useState("")

  // Error message
  const [error_message, set_error_message] = useState("")
  const [error_message_is_displayed, set_error_message_is_displayed] = useState(false)

  // Awaiting
  const [is_awaiting, set_is_awaiting] = useState(false)

  // Reset variables
  const reset = () => {
    set_is_awaiting(false)
  }

  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "code": {
        set_code(value)

        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
    set_error_message_is_displayed(false)
  }



  // Submit function
  const submit = async () => {
    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate code
    if (code.length !== 6) {
      reset()

      // Show error message
      set_error_message("Incorrect code")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }

    // END OF USER INPUT CHECK

    // Submit code
    const post_auth_signup_code_res = await axios.post(`/api/auth/signup/code`, {
      email_validation_token_id: email_validation_token_id,
      user_id: user_id,
      code: code,
    })

    if (!post_auth_signup_code_res.data.success) {
      switch (post_auth_signup_code_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to signup
          navigate(`/signup`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    switch (post_auth_signup_code_res.data.status) {
      case "CODE_IS_CORRECT": {
        // Redirect to set-password page
        navigate(`/set-password?user_id=${post_auth_signup_code_res.data.user_id}`)

        // Always break
        break
      }
      case "USER_DOES_NOT_EXIST": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      ///////
      // Note: this case will likely never occur
      case "USER_EXISTS_AND_PASSWORD_NOT_SET": {
        
        alert("Email address had already been verified previously. Continuing to password setup.")

        // Redirect to set-password page
        navigate(`/set-password?user_id=${post_auth_signup_code_res.data.user_id}`)

        // Always break
        break
      }
      ///////
      case "USER_EXISTS_AND_ALREADY_COMPLETED_SIGNUP": {
        alert("This account had already completed signup previously. Redirecting to login page.")

        // Redirect to login page
        navigate(`/login`)

        // Always break
        break
      }
      case "VALIDATION_TOKEN_DOES_NOT_EXIST": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      case "CODE_IS_INCORRECT": {
        // Show error message
        set_error_message("Incorrect code")
        set_error_message_is_displayed(true)

        // Reset 
        reset()
        
        // Always break
        break
      }
      case "TOKEN_TYPE_IS_WRONG": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)
        
        // Always break
        break
      }
      case "USER_ID_DOES_NOT_MATCH": {
        alert("Please try again from the beginning.")

        // Redirect to signup
        navigate(`/signup`)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }
  }
  
  // Renders
  useEffect(() => {

    // Focus on code field upon load
    code_ref.current.focus()
    
    // Mixpanel tracking
    mixpanel_client_track("app_validate_email_visited", null)

  }, [])

  return (
    <div className="absolute w-full h-screen flex flex-col justify-center items-center z-10">
      {isDesktop
      ? <div className="flex w-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
            <SquareLogo className="h-8 w-auto" />
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm leading-6 text-gray-900 flex">
                  <span className="font-medium">Verification code</span>
                  <span className="flex relative items-center">
                    <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                    <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                      Please check your inbox. If you don't see the verification code email in your inbox, please check your promotions tab & spam folder as well.
                    </span>
                  </span>
                </label>
                <div className="mt-2">
                  <input
                    type="tel"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    ref={code_ref}
                    value={code}
                    onChange={(e) => handle_user_input("code", e.target.value)}
                    onKeyDown={async (e) => handle_enter_keydown(e, submit)}
                  />
                </div>
              </div>

              {error_message_is_displayed
              ? <div className="flex space-x-2 items-start">
                  <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                  <div className="text-sm font-medium text-red-400">{error_message}</div>
                </div>
              : <></>}

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={submit}
                >
                  {is_awaiting ? (
                    <ReactLoading
                      type='spokes'
                      color='#ffffff'
                      height={20}
                      width={20}
                    />
                  ) : (
                    <span>Continue</span>
                  )}
                </button>
              </div>
            </div>

            <p className="mt-10 text-center text-sm text-gray-500">
              Already have an account?{" "}
              <Link to="/login" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                Log in
              </Link>
            </p>
          </div>
        </div>
      : <div className="flex min-h-full flex-col bg-transparent pt-16 pb-12">
          <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="text-base font-semibold text-gray-600">Error</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Unsupported access.</h1>
                <p className="mt-2 text-base text-gray-500">This page can only be accessed from a desktop.</p>
                <div className="mt-6">
                  <Link to='/'>
                    <span className="text-base font-medium text-gray-600 hover:text-gray-500">
                      Go back home
                      <span aria-hidden="true"> &rarr;</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </main>
          <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-center space-x-4">
              <Link to="mailto:support@vansec.com" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Contact Support
              </Link>
            </nav>
          </footer>
        </div>
      }
    </div>
  )
}

export default ValidateEmailPage