
import { useEffect, useState } from 'react'

import ReactLoading from "react-loading"

import { useGlobal } from '../../../contexts/GlobalContext'
import { useProtected, useProtectedUpdate } from '../../../contexts/ProtectedContext'


import { useLocation, useNavigate } from "react-router-dom"
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { show_notification, classNames, timestamp_to_local_datetime } from '../../../libs/helpers'
import { auth_axios } from '../../../libs/authWeb';
import { campaign_status_map, campaign_type_map, user_type_map } from '../../../libs/formats';
import { campaigns_stats_default_value } from '../../../libs/stats'

import { mixpanel_client_track } from '../../../libs/mixpanelClient';
import { campaign_search_filter_fn } from '../../../libs/searchFilterFunctions'

import TerminateCampaignsModal from './TerminateCampaignsModal'
import ArchiveCampaignsModal from './ArchiveCampaignsModal'
import DeleteCampaignsModal from './DeleteCampaignsModal'




const Campaign = ({
  campaign,
  campaign_checked_map,
  set_campaign_checked_map,
} : {
  campaign : any
  campaign_checked_map : any
  set_campaign_checked_map : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr 
      // className={classNames(campaign_checked_map[campaign.campaign_id] ? 'bg-gray-50' : "", campaign.active ? "cursor-pointer" : "" , "hover:bg-gray-100")}
      // onClick={campaign.active ? campaign.status === "draft" ? () => navigate(`/dashboard/campaigns/${campaign.campaign_id}/edit`) : () => navigate(`/dashboard/campaigns/${campaign.campaign_id}`) : null}
      className={classNames(campaign_checked_map[campaign.campaign_id] ? 'bg-gray-50' : "", "hover:bg-gray-100 cursor-pointer")}
      onClick={campaign.active && campaign.status === "draft" ? () => navigate(`/dashboard/campaigns/${campaign.campaign_id}/edit`) : () => navigate(`/dashboard/campaigns/${campaign.campaign_id}`)}
    >
      <td className="relative px-7 sm:w-12 sm:px-6">
        {campaign_checked_map[campaign.campaign_id] && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          checked={campaign_checked_map[campaign.campaign_id]}
          onChange={(e) =>
            set_campaign_checked_map({
            ...campaign_checked_map,
            [campaign.campaign_id]: e.target.checked
          })}
          onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
        />
      </td>


      <td
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm font-medium',
          campaign_checked_map[campaign.campaign_id] ? 'text-blue-600' : 'text-gray-900'
        )}
      >
        {campaign.campaign_metadata.name === "UNSET" ? "(Unspecified)" : `${campaign.campaign_metadata.name}`}
      </td>
      <td 
        className={classNames(((() => {
          switch (campaign.status) {
            case "draft": {
              return "text-red-500"
            }
            case "creating": {
              return "text-yellow-500"
            }
            case "active": {
              return "text-green-500"
            }
            case "terminated": {
              return "text-gray-500"
            }
            default: {
              return "text-gray-500"
            }
          }
        })()), "whitespace-nowrap px-3 py-4 text-sm")}
      >
        {campaign_status_map[campaign.status]}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {campaign.campaign_metadata.type === "UNSET" ? "(Unspecified)" : campaign_type_map[campaign.campaign_metadata.type]}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {campaign.campaign_metadata.start_time === -1 ? "(Unspecified)" : `${timestamp_to_local_datetime(campaign.campaign_metadata.start_time)[0]} ${timestamp_to_local_datetime(campaign.campaign_metadata.start_time)[1]}`}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {campaign.campaign_metadata.user_ids.length}
      </td>
    </tr>
  )
}


const CampaignsPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()

  // Protected context
  const protected_context = useProtected()
  const protected_update = useProtectedUpdate()

  // Navigate
  const navigate = useNavigate()

  // States
  const [organization_data, set_organization_data] = useState({})
  const [campaigns, set_campaigns] = useState([])
  const [stats, set_stats] = useState(campaigns_stats_default_value)

  const [active_campaign_checked_map, set_active_campaign_checked_map] = useState({})
  const [inactive_campaign_checked_map, set_inactive_campaign_checked_map] = useState({})

  const [organization_data_and_campaigns_are_fetched, set_organization_data_and_campaigns_are_fetched] = useState(false)
  const [current_tab, set_current_tab] = useState("active")

  // Refresh variable
  const [refresh_variable, set_refresh_variable] = useState(Date.now())

  // Search & filters
  const [search_text, set_search_text] = useState("")

  // Status
  const [is_awaiting, set_is_awaiting] = useState(false)

  // Modal
  const [deactivate_campaigns_modal_is_open, set_deactivate_campaigns_modal_is_open] = useState(false)
  const [terminate_campaigns_modal_is_open, set_terminate_campaigns_modal_is_open] = useState(false)
  const [delete_campaigns_modal_is_open, set_delete_campaigns_modal_is_open] = useState(false)


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "search_text": {
        set_search_text(value)

        // Always break
        break
      }
    }
  }

  const get_organization_data_and_campaigns = async () => {
    // Set is_fetched to false
    set_organization_data_and_campaigns_are_fetched(false)

    // Execute get organization data
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get campaigns
    const get_org_campaigns_res = await auth_axios.get(`/api/campaigns`)

    if (!get_org_campaigns_res.data.success) {
      switch (get_org_campaigns_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_campaigns(get_org_campaigns_res.data.campaigns)
    set_stats(get_org_campaigns_res.data.stats)
    set_active_campaign_checked_map(get_org_campaigns_res.data.campaigns.filter(campaign => campaign.active).reduce((acc, campaign) => ({...acc, [campaign.campaign_id]: false}), {}))
    set_inactive_campaign_checked_map(get_org_campaigns_res.data.campaigns.filter(campaign => !campaign.active).reduce((acc, campaign) => ({...acc, [campaign.campaign_id]: false}), {}))
    set_organization_data_and_campaigns_are_fetched(true)
  }

  const toggle_all_active_campaigns = (e) => {
    set_active_campaign_checked_map(Object.keys(active_campaign_checked_map).reduce((acc, campaign_id) => ({ ...acc, [campaign_id]: e.target.checked }), {}))
  }

  const toggle_all_inactive_campaigns = (e) => {
    set_inactive_campaign_checked_map(Object.keys(inactive_campaign_checked_map).reduce((acc, campaign_id) => ({ ...acc, [campaign_id]: e.target.checked }), {}))
  }

  const uncheck_all_active_campaigns = () => {
    set_active_campaign_checked_map(Object.keys(active_campaign_checked_map).reduce((acc, campaign_id) => ({ ...acc, [campaign_id]: false }), {}))
  }

  const uncheck_all_inactive_campaigns = () => {
    set_inactive_campaign_checked_map(Object.keys(inactive_campaign_checked_map).reduce((acc, campaign_id) => ({ ...acc, [campaign_id]: false }), {}))
  }

  const create_campaign_draft = async () => {
    // Set awaiting
    set_is_awaiting(true)

    // If status is not active, alert and redirect them to the plan page
    if (organization_data["status"] !== "active") {
      // // Show alert
      // alert("You cannot create a campaign without an active plan")

      // Show error notification
      show_notification(protected_context, protected_update, "warning", "Inactive plan", "You cannot create a campaign without an active plan")

      // Redirect to dashboard/plan page
      navigate(`/dashboard/plan`)

      // End of the line
      return
    }

    // If domains have not been allowlisted, redirect them to organization page
    if (!organization_data["organization_metadata"]["domains_are_allowlisted"]) {
      // // Show alert
      // alert("You cannot create a campaign without an active plan")

      // Show error notification
      show_notification(protected_context, protected_update, "warning", "Domains have not been allowlisted", "You must allowlist all domains available to your organization before creating a campaign.")

      // Redirect to dashboard/organization page
      navigate(`/dashboard/organization`)

      // End of the line
      return
    }

    // Execute add users
    const post_campaign_res = await auth_axios.post(`/api/campaigns/draft`, {
      user_ids: []
    })

    if (!post_campaign_res.data.success) {
      switch (post_campaign_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
  
          // Redirect to dashboard/users page
          navigate(`/dashboard/campaigns`)
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // React to response
    switch (post_campaign_res.data.status) {
      case "SUCCESS": {
        // Toggle
        set_is_awaiting(false)

        // Redirect to edit campaigns page
        navigate(`/dashboard/campaigns/${post_campaign_res.data.campaign_id}/edit`)

        // Always break
        break
      }
      case "FAILURE": {
        // Show error message
        // alert("Error occurred while creating a new campaign")
        show_notification(protected_context, protected_update, "error", "Error", "Error occurred while creating a new campaign")

        set_is_awaiting(false)

        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }  
  }

  // Renders  
  // render upon load & upon action_fetch_start_index change
  useEffect(() => {

    get_organization_data_and_campaigns()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_campaigns_visited", global_context.user_id)

  }, [refresh_variable])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">

      {/* Header */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Campaigns</h1>
          <p className="mt-2 text-sm text-gray-700">
            Campaigns past and present
          </p>
        </div>
        <button
          type="button"
          className={classNames(is_awaiting || !organization_data_and_campaigns_are_fetched ? "bg-blue-500" : "bg-blue-600", "block rounded-md px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600")} 
          onClick={create_campaign_draft}
          disabled={is_awaiting || !organization_data_and_campaigns_are_fetched}
        >
          Create a new campaign
        </button>
      </div>

      {/* Data */}
      <div className="mt-2 flow-root">

        {/* Analytics area */}
        <div className="mt-2">
          {/* <h3 className="text-sm font-medium leading-6 text-gray-900">Campaign statistics</h3> */}
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {organization_data_and_campaigns_are_fetched
            ? stats.map((item) => (
              <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="text-3xl font-semibold tracking-tight text-gray-900">
                    {item.primary}
                  </div>
                  <div className="bg-gray-500 text-white inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                    {item.secondary}
                  </div>
                </dd>
              </div>
            ))
            : stats.map((item) => (
              <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="text-3xl font-semibold tracking-tight text-gray-900">
                    <ReactLoading
                      type='spokes'
                      color='#000'
                      height={20}
                      width={20}
                    />
                  </div>
                  <div className="bg-gray-500 text-white inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                    <ReactLoading
                      type='spokes'
                      color='#ffffff'
                      height={12}
                      width={12}
                    />
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* Tabs */}
        <div className="mt-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {/* Active tab */}
              <div
                className={classNames(
                  current_tab === "active"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_inactive_campaigns(); set_current_tab("active") }}
              >
                Campaigns
                <span
                  className={classNames(
                    current_tab === "active" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {campaigns.filter(campaign => campaign.active).length}
                </span>
              </div>

              {/* Inactive tab */}
              <div
                className={classNames(
                  current_tab === "inactive"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_active_campaigns(); set_current_tab("inactive") }}
              >
                Archived campaigns
                <span
                  className={classNames(
                    current_tab === "inactive" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {campaigns.filter(campaign => !campaign.active).length}
                </span>
              </div>
            </nav>
          </div>
        </div>

        {/* Table area */}
        <div className="mt-2">
          {/* Top row - bulk actions and search field */}
          <div className="flex justify-between items-center px-2 py-2 space-x-4">
            <div>
              {Object.values(active_campaign_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-medium" >{`${Object.values(active_campaign_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_terminate_campaigns_modal_is_open(true)}
                >
                  Terminate selected
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_deactivate_campaigns_modal_is_open(true)}
                >
                  Archive selected
                </button>
              </div>
              : <></>}
              {Object.values(inactive_campaign_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-medium" >{`${Object.values(inactive_campaign_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_delete_campaigns_modal_is_open(true)}
                >
                  Delete selected
                </button>
              </div>
              : <></>}
            </div>
            <div className="w-64">
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div
                    className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                    aria-hidden="true"
                  >
                    <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    placeholder="Search"
                    value={search_text}
                    onChange={(e) => handle_user_input("search_text", e.target.value)}
                  />
                </div>
              </div>
          </div>

          {/* Table */}
          <div className="overflow-x-auto resize">
            <div className="inline-block min-w-full align-middle h-[500px]">
              <div className="relative">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="h-14">
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                          checked={(() => {
                            switch(current_tab) {
                              case "active": {
                                return Object.values(active_campaign_checked_map).length > 0 && Object.values(active_campaign_checked_map).every(is_checked => is_checked)
                              }
                              case "inactive": {
                                return Object.values(inactive_campaign_checked_map).length > 0 && Object.values(inactive_campaign_checked_map).every(is_checked => is_checked)
                              }
                              default: {
                                return
                              }
                            }
                          })()}
                          onChange={(() => {
                            switch(current_tab) {
                              case "active": {
                                return toggle_all_active_campaigns
                              }
                              case "inactive": {
                                return toggle_all_inactive_campaigns
                              }
                              default: {
                                return
                              }
                            }
                          })()}
                        />
                      </th>
                      <th scope="col" 
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                      >
                        Campaign name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Start date
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Number of users
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {organization_data_and_campaigns_are_fetched
                    ? campaigns.filter((() => {
                      switch(current_tab) {
                        case "active": {
                          return campaign => campaign.active
                        }
                        case "inactive": {
                          return campaign => !campaign.active
                        }
                        default: {
                          return
                        }
                      }
                    })()).filter((campaign) => campaign_search_filter_fn(campaign, search_text)).map((campaign) => (
                      <Campaign 
                        key={campaign.campaign_id} 
                        campaign={campaign}
                        campaign_checked_map={(() => {
                          switch(current_tab) {
                            case "active": {
                              return active_campaign_checked_map
                            }
                            case "inactive": {
                              return inactive_campaign_checked_map
                            }
                            default: {
                              return
                            }
                          }
                        })()}
                        set_campaign_checked_map={(() => {
                          switch(current_tab) {
                            case "active": {
                              return set_active_campaign_checked_map
                            }
                            case "inactive": {
                              return set_inactive_campaign_checked_map
                            }
                            default: {
                              return
                            }
                          }
                        })()}
                      />
                    ))
                    : <tr>
                      <td className="py-4 px-4">
                        <ReactLoading
                          type='spokes'
                          color='#000'
                          height={20}
                          width={20}
                        />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        
      </div>

      {/* Modals */}
      <ArchiveCampaignsModal
        is_open={deactivate_campaigns_modal_is_open}
        set_is_open={set_deactivate_campaigns_modal_is_open}
        campaign_ids={Object.keys(active_campaign_checked_map).filter(campaign_id => active_campaign_checked_map[campaign_id])}
        set_refresh_variable={set_refresh_variable}
      />
      <TerminateCampaignsModal
        is_open={terminate_campaigns_modal_is_open}
        set_is_open={set_terminate_campaigns_modal_is_open}
        campaign_ids={Object.keys(active_campaign_checked_map).filter(campaign_id => active_campaign_checked_map[campaign_id])}
        set_refresh_variable={set_refresh_variable}
      />
      <DeleteCampaignsModal
        is_open={delete_campaigns_modal_is_open}
        set_is_open={set_delete_campaigns_modal_is_open}
        campaign_ids={Object.keys(inactive_campaign_checked_map).filter(campaign_id => inactive_campaign_checked_map[campaign_id])}
        set_refresh_variable={set_refresh_variable}
      />
    </div>
  )
}

export default CampaignsPage