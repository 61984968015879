import { Navigate, useLocation, Outlet } from "react-router-dom"
import { useGlobal } from '../contexts/GlobalContext';

import Navbar from "../components/Navbar";

const UnprotectedLayout = ({

} : {
  
}) => {

  const global_context = useGlobal();
  const location = useLocation();

  if(global_context.authenticated) {
    return <Navigate to={location.state ? location.state.from : "/dashboard"} />;
  }
  
  return (
    <div>
      <Navbar />
      <Outlet />
    </div>
  )
}

export default UnprotectedLayout;
