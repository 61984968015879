
import { useEffect } from 'react'
import ReactLoading from "react-loading"

const SessionUncheckedPage = ({
  
} : {
  
}) => {
  
  useEffect(() => {

  }, [])

  return (
    <div className="w-[100vw] h-[100vh] flex justify-center items-center">
      {/* TODO - some wording about reloading the page or interacting with it offline */}
      <ReactLoading
        type='spokes'
        color='#1A202C'
        height={32}
        width={32}
      />
    </div>
  )
}

export default SessionUncheckedPage