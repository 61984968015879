
import { useEffect, useState } from 'react'
import ReactLoading from 'react-loading';

import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'

import { useLocation } from "react-router-dom"
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'


import { auth_axios } from '../../../libs/authWeb';
import { mixpanel_client_track } from '../../../libs/mixpanelClient';
import { campaign_type_map, program_status_map, user_type_map } from '../../../libs/formats';
import { program_search_filter_fn } from '../../../libs/searchFilterFunctions';
import { classNames } from '../../../libs/helpers'



const Program = ({
  program,
  program_checked_map,
  set_program_checked_map,
} : {
  program : any
  program_checked_map : any
  set_program_checked_map : any
}) => {

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr className={program_checked_map[program.program_id] ? 'bg-gray-50' : undefined}>
      <td className="relative px-7 sm:w-12 sm:px-6">
        {program_checked_map[program.program_id] && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          checked={program_checked_map[program.program_id]}
          onChange={(e) =>
            set_program_checked_map({
            ...program_checked_map,
            [program.program_id]: e.target.checked
          })}
          onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
        />
      </td>


      <td
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm font-medium',
          program_checked_map[program.program_id] ? 'text-blue-600' : 'text-gray-900'
        )}
      >
        {program.campaign_metadata.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {campaign_type_map[program.campaign_metadata.type]}
      </td>
      <td 
        className={classNames((() => {
          switch (program.simulation_status) {
            case "none": {
              return "text-gray-500"
            }
            case "draft": {
              return "text-yellow-500"
            }
            case "active": {
              return "text-blue-500"
            }
            case "reported": {
              return "text-green-500"
            }
            case "failed": {
              return "text-red-500"
            }
            case "terminated": {
              return "text-gray-500"
            }
            default: {
              return "text-gray-500"
            }
          }
        })(), "whitespace-nowrap px-3 py-4 text-sm")}
      >
        {program_status_map[program.simulation_status]}
      </td>
      <td 
        className={classNames((() => {
          switch (program.training_status) {
            case "none": {
              return "text-gray-500"
            }
            case "draft": {
              return "text-yellow-500"
            }
            case "active": {
              return "text-blue-500"
            }
            case "completed": {
              return "text-green-500"
            }
            case "terminated": {
              return "text-gray-500"
            }
            default: {
              return "text-gray-500"
            }
          }
        })(), "whitespace-nowrap px-3 py-4 text-sm")}
      >
        {program_status_map[program.training_status]}
      </td>
    </tr>
  )
}


const ProgramsPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // Location
  const location = useLocation()

  // States
  const [programs, set_programs] = useState([])
  const [program_checked_map, set_program_checked_map] = useState({})
  const [programs_are_fetched, set_programs_are_fetched] = useState(false)

  // Tab
  const [current_tab, set_current_tab] = useState("active")

  // Search & filters
  const [search_text, set_search_text] = useState("")


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "search_text": {
        set_search_text(value)

        // Always break
        break
      }
    }
  }

  const get_programs = async () => {
    // Set is_fetched to false
    set_programs_are_fetched(false)

    // Execute get programs
    const get_org_programs_res = await auth_axios.get(`/api/users/${global_context.user_id}/programs/active`)

    if (!get_org_programs_res.data.success) {
      switch (get_org_programs_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_programs(get_org_programs_res.data.programs)
    set_program_checked_map(get_org_programs_res.data.programs.reduce((acc, program) => ({...acc, [program.program_id]: false}), {}))
    set_programs_are_fetched(true)
  }

  const toggle_all_programs = (e) => {
    set_program_checked_map(Object.keys(program_checked_map).reduce((acc, program_id) => ({ ...acc, [program_id]: e.target.checked }), {}))
  }

  

  // Renders  
  // render upon load & upon action_fetch_start_index change
  useEffect(() => {

    get_programs()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_programs_visited", global_context.user_id)

  }, [])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">

      {/* Header */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Programs</h1>
          <p className="mt-2 text-sm text-gray-700">
            My programs
          </p>
        </div>
        {/* <Link to="add" className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-blue-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Create a campaign
          </button>
        </Link> */}
      </div>

      {/* Data */}
      <div className="mt-8 flow-root">

        {/* Tabs */}
        <div className="mt-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">

              {/* Active tab */}
              <div
                className={classNames(
                  current_tab === "active"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { set_current_tab("active") }}
              >
                Programs
                <span
                  className={classNames(
                    current_tab === "active" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {programs.filter(program => program.active).length}
                </span>
              </div>

              {/* Inactive tab */}
              {/* <div
                className={classNames(
                  current_tab === "inactive"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_active_programs(); set_current_tab("inactive") }}
              >
                Archived programs
                <span
                  className={classNames(
                    current_tab === "inactive" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {programs.filter(program => !program.active).length}
                </span>
              </div> */}
            </nav>
          </div>
        </div>

        {/* Table area */}
        <div className="mt-2">

          {/* Top row - bulk actions and search field */}
          <div className="flex justify-between items-center px-2 py-2 space-x-4">
            {Object.values(program_checked_map).some(is_checked => is_checked)
            ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
              <div className="text-blue-600 text-sm font-medium" >{`${Object.values(program_checked_map).filter(is_checked => is_checked).length} selected`}</div>
              {/* <button
                type="button"
                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
              >
                Edit selected
              </button> */}
              {/* <button
                type="button"
                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
              >
                Delete selected
              </button> */}
            </div>
            : <div></div>}
            {/* <div className="w-64">
              <div className="relative mt-1 rounded-md shadow-sm">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  value={search_text}
                  onChange={(e) => handle_user_input("search_text", e.target.value)}
                />
              </div>
            </div> */}
          </div>

          {/* Table */}
          <div className="overflow-x-auto resize">
            <div className="inline-block min-w-full align-middle h-[500px]">
              <div className="relative">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="h-14">
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                          checked={Object.values(program_checked_map).length > 0 && Object.values(program_checked_map).every(is_checked => is_checked)}
                          onChange={toggle_all_programs}
                        />
                      </th>
                      <th scope="col" 
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                      >
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Simulation status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Training status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {programs_are_fetched
                    ? programs.filter((program) => program_search_filter_fn(program, search_text)).map((program) => (
                      <Program 
                        key={program.program_id} 
                        program={program} 
                        program_checked_map={program_checked_map}
                        set_program_checked_map={set_program_checked_map}
                      />
                    ))
                    : <tr>
                      <td className="py-4 px-4">
                        <ReactLoading
                          type='spokes'
                          color='#000'
                          height={20}
                          width={20}
                        />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
        </div>

      </div>

      
    </div>
  )
}

export default ProgramsPage