import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition, Listbox, Switch, Tab } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useGlobal, useGlobalUpdate } from '../../../../contexts/GlobalContext'

import ReactLoading from "react-loading"

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import { Link } from "react-router-dom";

import { auth_axios } from '../../../../libs/authWeb';
import { action_type_map, get_date_string, job_status_map, job_type_map, training_module_map, submit_website_field_map } from '../../../../libs/formats'
import { classNames } from '../../../../libs/helpers'





const EventModal = ({
  is_open,
  set_is_open,
  event
} : {
  is_open : boolean
  set_is_open : any // todo
  event : any // todo
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()



  // States
  const [job, set_job] = useState({})
  const [action, set_action] = useState({})
  const [organization_data, set_organization_data] = useState({})

  // Status 
  const [job_or_action_is_fetched, set_job_or_action_is_fetched] = useState(false)

  // Preview states
  const [is_preview, set_is_preview] = useState(false)


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      // case "full_name": {
      //   set_full_name(value)

      //   // Always break
      //   break
      // }
      default: {

        // Always break
        break
      }
    }
  }

  const get_job_or_action = async () => {
    // Set is_fetched to false
    set_job_or_action_is_fetched(false)

    switch (event.event_type) {
      case "job": {

        // Execute get user data
        const get_job_res = await auth_axios.get(`/api/jobs/${event.event_id}`)

        if (!get_job_res.data.success) {
          switch (get_job_res.data.status) {
            case "FATAL_ERROR": {
              alert("Fatal error")
              
              // Always break
              break
            }
            default: {
              // Always break
              break
            }
          }
          return
        }

        // If type is send_email, set preview to true
        if (["send_email"].includes(get_job_res.data.job.type)) {
          set_is_preview(true)
        }

        // Set state
        set_job(get_job_res.data.job)
        
        // Always break
        break
      }
      case "action": {

        // Execute get user data
        const get_action_res = await auth_axios.get(`/api/actions/${event.event_id}`)

        if (!get_action_res.data.success) {
          switch (get_action_res.data.status) {
            case "FATAL_ERROR": {
              alert("Fatal error")
              
              // Always break
              break
            }
            default: {
              // Always break
              break
            }
          }
          return
        }

        // If type is reply_email or reply_email_objective, set preview to true
        if (["reply_email", "reply_email_objective"].includes(get_action_res.data.action.type)) {
          set_is_preview(true)
        }

        // Set state
        set_action(get_action_res.data.action)
        
        // Always break
        break
      }
      default: {
        // Always break
        break
      }
    }

    // Also get organization data (for content visibility)
    const get_org_res = await auth_axios.get(`/api/organizations`)

    if (!get_org_res.data.success) {
      switch (get_org_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_organization_data(get_org_res.data.organization_data)
    set_job_or_action_is_fetched(true)
  }

  // Renders  
  useEffect(() => {

    if (is_open) {
      get_job_or_action()
    }
    else {
      set_job_or_action_is_fetched(false)
    }

  }, [is_open])

  return (
    <Transition.Root show={is_open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={set_is_open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">

                {/* Dismiss button */}
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <div
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                    onClick={() => set_is_open(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>

                {/* Modal body */}
                <div className="">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Event
                      </h1>
                      <p className="mt-2 text-sm text-gray-700">
                        Event information
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 flow-root">

                    {/* Event information */}
                    
                    <dl className="mt-4 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm">

                      {/* Event type */}
                      <div className="pt-6 sm:flex">
                        <dt className="font-semibold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">User / simulation</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">
                            {job_or_action_is_fetched 
                            ? (() => {
                              switch(event.event_type) {
                                case "job": {
                                  return "Simulation"
                                }
                                case "action": {
                                  return "User"
                                }
                                default: {
                                  return "Unknown"
                                }
                              }
                            })()
                            : (
                            <ReactLoading
                              type='spokes'
                              color='#343D46'
                              height={20}
                              width={20}
                            />)}
                          </div>
                        </dd>
                      </div>

                      {/* Event type */}
                      <div className="pt-6 sm:flex">
                        <dt className="font-semibold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Event type</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">
                            {job_or_action_is_fetched 
                            ? (() => {
                              switch (event.event_type) {
                                case "job": {
                                  return job_type_map[event.type]
                                }
                                case "action": {
                                  return action_type_map[event.type]
                                }
                                default: {
                                  return ""
                                }
                              }
                            })() 
                            : (
                            <ReactLoading
                              type='spokes'
                              color='#343D46'
                              height={20}
                              width={20}
                            />)}
                          </div>
                        </dd>
                      </div>

                      {/* Event status */}
                      <div className="pt-6 sm:flex">
                        <dt className="font-semibold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Status</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">
                            {job_or_action_is_fetched 
                            ? (() => {
                              switch (event.event_type) {
                                case "job": {
                                  return job["job_is_executed"] ? "Executed" : "Scheduled"
                                }
                                case "action": {
                                  return "Tracked"
                                }
                                default: {
                                  return ""
                                }
                              }
                            })() 
                            : (
                            <ReactLoading
                              type='spokes'
                              color='#343D46'
                              height={20}
                              width={20}
                            />)}
                          </div>
                        </dd>
                      </div>

                      {/* Delivery status */}
                      <div className="pt-6 sm:flex">
                        <dt className="font-semibold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Delivery status</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">
                            {job_or_action_is_fetched 
                            ? (() => {
                              switch (event.event_type) {
                                case "job": {
                                  return job_status_map[job["job_status"]]
                                }
                                case "action": {
                                  return "-"
                                }
                                default: {
                                  return ""
                                }
                              }
                            })() 
                            : (
                            <ReactLoading
                              type='spokes'
                              color='#343D46'
                              height={20}
                              width={20}
                            />)}
                          </div>
                        </dd>
                      </div>

                      {/* Event timestamp */}
                      <div className="pt-6 sm:flex">
                        <dt className="font-semibold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Timestamp</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">
                            {job_or_action_is_fetched 
                            ? (() => {
                              switch (event.event_type) {
                                case "job": {
                                  return job["job_is_executed"] ? get_date_string(job["job_executed_at"]) : get_date_string(job["job_scheduled_at"])
                                }
                                case "action": {
                                  return get_date_string(action["action_taken_at"])
                                }
                                default: {
                                  return ""
                                }
                              }
                            })() 
                            : (
                            <ReactLoading
                              type='spokes'
                              color='#343D46'
                              height={20}
                              width={20}
                            />)}
                          </div>
                        </dd>
                      </div>




                      
                      {/* SIMULATION */}
                      {/* {job_or_action_is_fetched && ((event.event_type === "job" && job["job_metadata"]["type"] === "simulation") || event.event_type === "action" && action["action_metadata"]["type"] === "simulation") */}

                      {/* If type is submit_website, show the captured credentials if any */}
                      {job_or_action_is_fetched && ["submit_website"].includes(event["type"]) 
                      ? <div className="pt-6 sm:flex">
                        <dt className="font-semibold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Captured data</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900 flex flex-col space-y-2">
                            {Object.keys(action["action_metadata"]["simulation_submit_website_data"]).length > 0 
                            ? Object.keys(action["action_metadata"]["simulation_submit_website_data"]).map(key => <div key={key}>
                              {`${submit_website_field_map[key]}: ${action["action_metadata"]["simulation_submit_website_data"][key] ? action["action_metadata"]["simulation_submit_website_data"][key] : "(Not captured)"}`}
                            </div>)
                            : "(Not captured)"}
                          </div>
                        </dd>
                      </div>
                      : <></>}

                      {/* If some type of email send */}
                      {/* HTML and preview */}
                      {job_or_action_is_fetched && (["send_email"].includes(event["type"]) || ["reply_email", "reply_email_objective"].includes(event["type"]))
                      ? <div className="pt-6">

                        <Tab.Group onChange={(index) => { if (index === 1) set_is_preview(true); else set_is_preview(false); }} selectedIndex={is_preview ? 1 : 0}>
                          <label className="block text-sm leading-6 text-gray-900 flex">
                            <span className="font-semibold">Email HTML & preview</span>
                          </label>
                          {job_or_action_is_fetched && organization_data["organization_metadata"]["simulation_content_is_visible"]
                          ? <>
                            <Tab.List className="flex items-center mt-6">
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    selected
                                      ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                                      : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                                    'rounded-md border border-transparent px-3 py-1.5 text-sm font-semibold'
                                  )
                                }
                              >
                                Email HTML
                              </Tab>
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    selected
                                      ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                                      : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                                    'ml-2 rounded-md border border-transparent px-3 py-1.5 text-sm font-semibold'
                                  )
                                }
                              >
                                Email preview
                              </Tab>
                            </Tab.List>
                            <Tab.Panels className="mt-6">
                              <Tab.Panel className="-m-0.5 rounded-lg p-0.5 ">
                                <CodeMirror 
                                  value={(() => {
                                    switch (event.event_type) {
                                      case "job": {
                                        return job["job_metadata"]["simulation_email_html"]
                                      }
                                      case "action": {
                                        return action["action_metadata"]["simulation_email_html"]
                                      }
                                      default: {
                                        return ""
                                      }
                                    }
                                  })()} 
                                  className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 h-[600px] overflow-auto resize"
                                  extensions={[html({})]}
                                  editable={false}
                                />
                              </Tab.Panel>
                              <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                                <iframe 
                                  id="email_preview"
                                  className="block w-full rounded-md border-0 py-2 px-4 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 h-[600px] overflow-auto resize"
                                  srcDoc={(() => {
                                    switch (event.event_type) {
                                      case "job": {
                                        return job["job_metadata"]["simulation_email_html"]
                                      }
                                      case "action": {
                                        return action["action_metadata"]["simulation_email_html"]
                                      }
                                      default: {
                                        return ""
                                      }
                                    }
                                  })()} 
                                />
                              </Tab.Panel>
                            </Tab.Panels>
                          </>
                          : <div className="mt-6">Content is hidden per settings set by the organization owner</div>
                          }
                        </Tab.Group>

                      </div>
                      : <></>}

                        

                
                      {/* TRAINING */}
                      
                      {/* {job_or_action_is_fetched && ((event.event_type === "job" && job["job_metadata"]["type"] === "training") || (event.event_type === "action" && action["action_metadata"]["type"] === "training")) */}


                      {job_or_action_is_fetched && (["send_message"].includes(event["type"]) || ["reply_message", "reply_message_objective"].includes(event["type"]))
                      ? <>

                                  
                        {/* Training module */}
                        <div className="pt-6 sm:flex">
                          <dt className="font-semibold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Training module</dt>
                          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">
                              {job_or_action_is_fetched 
                              ? (() => {
                                switch (event.event_type) {
                                  case "job": {
                                    return training_module_map[job["job_metadata"]["training_module"]]
                                  }
                                  case "action": {
                                    return training_module_map[action["action_metadata"]["training_module"]]
                                  }
                                  default: {
                                    return ""
                                  }
                                }
                              })() 
                              : (
                              <ReactLoading
                                type='spokes'
                                color='#343D46'
                                height={20}
                                width={20}
                              />)}
                            </div>
                          </dd>
                        </div>

                        {/* Message */}
                        <div className="pt-6 sm:flex">
                          <dt className="font-semibold text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Message</dt>
                          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">
                              {job_or_action_is_fetched 
                              ? organization_data["organization_metadata"]["training_content_is_visible"]
                                ? (() => {
                                  switch (event.event_type) {
                                    case "job": {
                                      return job["job_metadata"]["training_message"]
                                    }
                                    case "action": {
                                      return action["action_metadata"]["training_message"]
                                    }
                                    default: {
                                      return ""
                                    }
                                  }
                                })() 
                                : <div className="">Content is hidden per settings set by the organization owner</div>
                              : (
                              <ReactLoading
                                type='spokes'
                                color='#343D46'
                                height={20}
                                width={20}
                              />)}
                            </div>
                          </dd>
                        </div>
                                
                      
                      </>
                      : <></>}


                      
                    </dl>

                    
                  </div>
                </div>

              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default EventModal;
