
import { useEffect, useState } from 'react'


import { useGlobal, useGlobalUpdate } from '../../../contexts/GlobalContext'

import ReactLoading from "react-loading"

import { useNavigate, Link } from "react-router-dom"
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'


import { auth_axios } from '../../../libs/authWeb';
import { mixpanel_client_track } from '../../../libs/mixpanelClient';
import { provision_type_map, user_type_map } from '../../../libs/formats';
import { group_search_filter_fn } from '../../../libs/searchFilterFunctions'
import { classNames } from '../../../libs/helpers'

import DeleteGroupsModal from './DeleteGroupsModal'
import { groups_stats_default_value } from '../../../libs/stats'





const Group = ({
  group,
  group_checked_map,
  set_group_checked_map,
} : {
  group : any
  group_checked_map : any
  set_group_checked_map : any
}) => {

  // Navigate
  const navigate = useNavigate()

  // Renders  
  useEffect(() => {

  }, [])

  return (
    <tr 
      className={classNames(group_checked_map[group.group_id] ? 'bg-gray-50' : "", "hover:bg-gray-100 cursor-pointer")}
      onClick={() => navigate(`/dashboard/groups/${group.group_id}`)}
    >
      <td className="relative px-7 sm:w-12 sm:px-6">
        {group_checked_map[group.group_id] && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          checked={group_checked_map[group.group_id]}
          onChange={(e) => 
            set_group_checked_map({
            ...group_checked_map,
            [group.group_id]: e.target.checked
          })}
          onClick={(e) => e.stopPropagation()} // stop click event from bubbling up
        />
      </td>


      <td
        className={classNames(
          'whitespace-nowrap px-3 py-4 text-sm font-medium',
          group_checked_map[group.group_id] ? 'text-blue-600' : 'text-gray-900'
        )}
      >
        {group.group_metadata.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {group.active_user_ids.length}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {provision_type_map[group.provision_metadata.type]}
      </td>
    </tr>
  )
}


const GroupsPage = ({
  
} : {
  
}) => {


  // Global context
  const global_context = useGlobal()
  const global_update = useGlobalUpdate()

  // States
  const [groups, set_groups] = useState([])
  const [stats, set_stats] = useState(groups_stats_default_value)

  const [group_checked_map, set_group_checked_map] = useState({})

  const [groups_are_fetched, set_groups_are_fetched] = useState(false)

  // Tab
  const [current_tab, set_current_tab] = useState("groups")

  // Refresh variable
  const [refresh_variable, set_refresh_variable] = useState(Date.now())

  // Search & filters
  const [search_text, set_search_text] = useState("")

  // Modal
  const [delete_groups_modal_is_open, set_delete_groups_modal_is_open] = useState(false)


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "search_text": {
        set_search_text(value)

        // Always break
        break
      }
    }
  }

  const get_groups = async () => {
    // Set is_fetched to false
    set_groups_are_fetched(false)

    // Execute get user data
    const get_org_groups_res = await auth_axios.get(`/api/groups`)

    if (!get_org_groups_res.data.success) {
      switch (get_org_groups_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Execute get user data
    const get_org_groups_stats_res = await auth_axios.get(`/api/groups/stats`)

    if (!get_org_groups_stats_res.data.success) {
      switch (get_org_groups_stats_res.data.status) {
        case "FATAL_ERROR": {
          alert("Fatal error")
          
          // Always break
          break
        }
        default: {
          // Always break
          break
        }
      }
      return
    }

    // Set states
    set_groups(get_org_groups_res.data.groups)
    set_stats(get_org_groups_stats_res.data.stats)
    set_group_checked_map(get_org_groups_res.data.groups.filter(group => !group.active).reduce((acc, group) => ({...acc, [group.group_id]: false}), {}))
    set_groups_are_fetched(true)
  }

  const toggle_all_groups = (e) => {
    set_group_checked_map(Object.keys(group_checked_map).reduce((acc, group_id) => ({ ...acc, [group_id]: e.target.checked }), {}))
  }

  // Renders  
  // render upon load & upon refresh_variable change
  useEffect(() => {

    get_groups()

    // Mixpanel tracking
    mixpanel_client_track("app_dashboard_groups_visited", global_context.group_id)

  }, [refresh_variable])

  return (
    <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">

      {/* Header */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Groups</h1>
          <p className="mt-2 text-sm text-gray-700">
            Groups in your organization
          </p>
        </div>
        <Link to="add" className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-blue-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Create a group
          </button>
        </Link>
      </div>

      {/* Data */}
      <div className="mt-2 flow-root">

        {/* Analytics area */}
        <div className="mt-0">
          {/* <h3 className="text-sm font-medium leading-6 text-gray-900">Campaign statistics</h3> */}
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {groups_are_fetched
            ? stats.map((item) => (
              <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="text-3xl font-semibold tracking-tight text-gray-900">
                    {item.primary}
                  </div>
                  <div className="bg-gray-500 text-white inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                    {item.secondary}
                  </div>
                </dd>
              </div>
            ))
            : stats.map((item) => (
              <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="text-3xl font-semibold tracking-tight text-gray-900">
                    <ReactLoading
                      type='spokes'
                      color='#000'
                      height={20}
                      width={20}
                    />
                  </div>
                  <div className="bg-gray-500 text-white inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                    <ReactLoading
                      type='spokes'
                      color='#ffffff'
                      height={12}
                      width={12}
                    />
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* Tabs */}
        <div className="mt-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {/* Groups tab */}
              <div
                className={classNames(
                  current_tab === "groups"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { set_current_tab("groups") }}
              >
                Groups
                <span
                  className={classNames(
                    current_tab === "groups" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {groups.length}
                </span>
              </div>

              {/* Inactive tab */}
              {/* <div
                className={classNames(
                  current_tab === "inactive"
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => { uncheck_all_active_programs(); set_current_tab("inactive") }}
              >
                Archived programs
                <span
                  className={classNames(
                    current_tab === "inactive" ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                  )}
                >
                  {programs.filter(program => !program.active).length}
                </span>
              </div> */}
            </nav>
          </div>
        </div>

        {/* Table area */}
        <div className="mt-2">

          {/* Top row - bulk actions and search field */}
          <div className="flex justify-between items-center px-2 py-2 space-x-4">
            <div>
              {/* {Object.values(active_user_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-medium" >{`${Object.values(active_user_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_deactivate_groups_modal_is_open(true)}
                >
                  {Object.values(active_user_checked_map).filter(checked => checked).length === 1 ? "Deactivate selected user" : "Deactivate selected groups"}
                </button>
              </div>
              : <></>} */}
              {Object.values(group_checked_map).some(is_checked => is_checked)
              ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <div className="text-blue-600 text-sm font-medium" >{`${Object.values(group_checked_map).filter(is_checked => is_checked).length} selected`}</div>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={() => set_delete_groups_modal_is_open(true)}
                >
                  {Object.values(group_checked_map).filter(checked => checked).length === 1 ? "Delete selected group" : "Delete selected group"}
                </button>
              </div>
              : <></>}
            </div>
            <div className="w-64">
              <div className="relative mt-1 rounded-md shadow-sm">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  value={search_text}
                  onChange={(e) => handle_user_input("search_text", e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Table */}
          <div className="overflow-x-auto resize">
            <div className="inline-block min-w-full align-middle h-[500px]">
              <div className="relative">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="h-14">
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                          checked={Object.values(group_checked_map).length > 0 && Object.values(group_checked_map).every(is_checked => is_checked)}
                          onChange={toggle_all_groups}
                        />
                      </th>
                      <th scope="col" 
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter"
                      >
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Number of users
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 backdrop-blur backdrop-filter">
                        Provision type
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {groups_are_fetched
                    ? groups.filter(group => group_search_filter_fn(group, search_text)).map((group) => (
                      <Group 
                        key={group.group_id} 
                        group={group} 
                        group_checked_map={group_checked_map}
                        set_group_checked_map={set_group_checked_map}
                      />
                    ))
                    : <tr>
                      <td className="py-4 px-4">
                        <ReactLoading
                          type='spokes'
                          color='#000'
                          height={20}
                          width={20}
                        />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        
      </div>

      {/* Modals */}
      <DeleteGroupsModal 
        is_open={delete_groups_modal_is_open}
        set_is_open={set_delete_groups_modal_is_open}
        group_ids={Object.keys(group_checked_map).filter(group_id => group_checked_map[group_id])}
        set_refresh_variable={set_refresh_variable}
      />
    </div>
  )
}

export default GroupsPage